import styled from 'styled-components'

export const DashboardItemDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 15px;
`
export const DashboardSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

export const DashboardSelectorLabel = styled.h6`
  font-weight: bold;
  font-size: 15px;
  opacity: 0.5;
  margin-right: 10px;
`
