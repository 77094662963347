/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  LabelInputFileDescription,
  LabelInputFileWrapper
} from '../../../../domain/feature/hub/company/challenge/FormWrapper/style'
import { Button } from 'react-bootstrap'
import { DescriptedLabel } from 'iebt-zing-storybook'
import { LabelInputFile } from 'sharedKernel/styledComponents/fileComponents/styles'

interface InputUploadSingleLabelProps {
  title: string
  addFile: string
  maxSize: string
  description: string
}

interface InputUploadSingleProps {
  fieldName: string
  acceptFiles: string
  labels: InputUploadSingleLabelProps
  onChange: React.EventHandler<any>
  maxSize: string
  isRequired: boolean
  isUploaded: boolean
  onUploadedClick: React.EventHandler<any>
  path?: string | null
  name?: string
  disabled?: boolean
  dataCy?: string
}

interface InputUploadedSingleProps {
  labels: InputUploadSingleLabelProps
  onUploadedClick: React.EventHandler<any>
  maxSize: string
  path?: string | null
  name?: string
  isRequired: boolean
  disabled?: boolean
  dataCy?: string
}

export const InputUploadSingle = ({
  fieldName,
  acceptFiles,
  labels,
  onChange,
  maxSize,
  isRequired,
  isUploaded = false,
  onUploadedClick,
  path,
  name,
  disabled = false,
  dataCy
}: InputUploadSingleProps): JSX.Element => {
  if (isUploaded) {
    return (
      <InputUploadedSingle
        onUploadedClick={onUploadedClick}
        path={path}
        name={name}
        labels={labels}
        maxSize={maxSize}
        isRequired={isRequired}
        disabled={disabled}
        dataCy={dataCy}
      />
    )
  }

  return (
    <LabelInputFileWrapper>
      <DescriptedLabel className="form-label" htmlFor={fieldName} description={labels.description} boldText={isRequired}>
        <span>
          {labels.title}
          {isRequired && <span style={{ color: 'red' }}> *</span>}
        </span>
      </DescriptedLabel>
      <LabelInputFile id={fieldName} isClickable>
        <FontAwesomeIcon className="pr-2" icon={['fas', 'upload']} />
        {labels.addFile}
        <input
          className="form-check-input"
          type="file"
          accept={acceptFiles}
          name={fieldName}
          id={fieldName}
          onChange={() => {
            onChange(event)
          }}
          data-cy={`${dataCy}Input`}
        />
      </LabelInputFile>
      <LabelInputFileDescription>
        {labels.description}
        <br/>
        <b>{labels.maxSize} {maxSize}</b>
      </LabelInputFileDescription>
    </LabelInputFileWrapper>
  )
}

const InputUploadedSingle = ({
  labels,
  onUploadedClick,
  maxSize,
  path,
  name,
  isRequired,
  disabled,
  dataCy
}: InputUploadedSingleProps): JSX.Element => {
  return (
    <LabelInputFileWrapper>
      <DescriptedLabel className="form-label" description={labels.description} boldText={isRequired}>
        <span>
          {labels.title}
          {isRequired && <span style={{ color: 'red' }}> *</span>}
        </span>
      </DescriptedLabel>
      <div className="d-flex gap-1 align-items-center py-1">
        <Button
          variant="danger"
          disabled={disabled}
          onClick={() => {
            onUploadedClick(event)
          }}
          data-cy={`${dataCy}DeleteButton`}
        >
          <FontAwesomeIcon icon={['far', 'trash-can']} />
        </Button>
        {/* eslint-disable-next-line multiline-ternary */}
        {path != null ? (
          <a href={String(path)} target="_blank">
            <LabelInputFile isClickable>
              <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
              {name}
            </LabelInputFile>
          </a>
        ) : (
          <LabelInputFile>
            <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
            {name}
          </LabelInputFile>
        )}
      </div>
      <LabelInputFileDescription>
        {labels.maxSize} {maxSize}
      </LabelInputFileDescription>
    </LabelInputFileWrapper>
  )
}
