import React from 'react'
import { OpenChallengesMarketplace } from './OpenChallengesMarketplace'

export const MarketplaceMain = (): JSX.Element => {
  return (
    <>
      <OpenChallengesMarketplace />
    </>
  )
}
