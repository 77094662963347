import styled from 'styled-components'

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 10px;
`

export const ChallengesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ChallengesListTitle = styled.h6` 
`

export const EtapaItem = styled.div`
  display: flex;
  flex-direction: column;
`
