import React from 'react'
import { useParams } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'
import { VisualizationScreen } from 'domain/feature/hub/VisualizationScreen'

export const HubStartupChallengeGet = (): JSX.Element => {
  const { challengeId } = useParams()

  const request: GetFormat = {
    url: `/v1/hub/resolver/challenge/${challengeId}`
  }
  const { data: challengeData, isFetching: isChallengeFetching } = useGet<any>(request, 'challenge-get')

  const filesRequest: GetFormat = {
    url: `v1/hub/resolver/challenge/${challengeId}/files`
  }
  const { data: fileData, isFetching: isFileFetching } = useGet<any>(filesRequest, 'challenge-files-get')

  return (
    <VisualizationScreen
      isFetching={isChallengeFetching}
      data={challengeData}
      isFileFetching={isFileFetching}
      fileData={fileData?.rows}
    />
  )
}
