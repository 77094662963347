/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react'
import { ICarouselNavItems } from 'components/swiper/swiperNav'
import {
  FormIdeaSectionName
} from './styles'
import { IdeaApplicationFormProps } from './contracts'
import { FormAnswer } from 'components/Evaluation/Form/Answer/FormAnswer'
import { trans } from 'sharedKernel/i18n'
import { NoContent } from 'components/NoContent'
import { FormIdeaDesktop } from './Desktop'
import { FormIdeaMobile } from './Mobile'
import { getDeviceType } from 'sharedKernel/behaviors'

export const IdeaApplicationForm = ({
  setIsNavigationBlocked,
  ideaApplicationData
}: IdeaApplicationFormProps): JSX.Element => {
  const labels = {
    emptyForm: trans('Não há informações a serem exibidas')
  }

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  }

  function buidSteps(
    sections: any[]
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ): { formSteps: any[]; carouselNavItems: ICarouselNavItems[] } {
    const carouselNavItems: ICarouselNavItems[] = []
    const formSteps: any[] = []

    sections.map((section, sectionIndex) => {
      const appFormSection = ideaApplicationData.challenge.applicationForm.sections?.find(item => item.id === section.sectionId)

      if (appFormSection?.isHidden === false) {
        carouselNavItems.push({
          name: section.name ?? '',
          itemHash: section.id
        })
        formSteps.push({
          name: section.id,
          component: (
            <div className='mt-2' key={`${sectionIndex}section`}>
              <div className='d-flex flex-column'>
                <FormIdeaSectionName>{`${String(section.name)}`}</FormIdeaSectionName>
              </div>
              {section.answers?.map((answer, answerIndex) => {
                const sectionField = appFormSection.fields.find(field => field.id === answer.questionId)
                if (sectionField?.isHidden === false) {
                  return <FormAnswer key={`${answerIndex}answer`} answer={answer} />
                }
                return <></>
              })}
            </div>
          )
        })
      }

      return section
    })

    return {
      carouselNavItems,
      formSteps
    }
  }

  const formIdeaSectionsBody = (): JSX.Element => {
    if (formSteps.length === 0 || formSteps.length === undefined) {
      return (
        <NoContent text={labels.emptyForm}></NoContent>
      )
    }

    return (
      <>
        {formSteps.map((step, stepIndex) => {
          return (
            <section id={step.name} key={stepIndex}>
              {step.component}
            </section>
          )
        })}
      </>
    )
  }

  const resolverId = ideaApplicationData.company.id
  const solution = !ideaApplicationData.challenge.config.hideIdeaTitle ? ideaApplicationData.title : 'Empresa oculta'
  const sections = ideaApplicationData.sections
  const evaluation = ideaApplicationData.evaluation

  const { carouselNavItems, formSteps } = buidSteps(sections)
  const [deviceType, setDeviceType] = useState(getDeviceType(window.innerWidth))

  const handleResize = (): void => {
    setDeviceType(getDeviceType(window.innerWidth))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (deviceType === 'mobile') {
    return (
      <FormIdeaMobile
        solutionTitle={solution}
        breakpoints={buildBreakpoints()}
        carouselNavItems={carouselNavItems}
        evaluation={evaluation}
        formIdeaSectionsBody={formIdeaSectionsBody()}
        resolverId={resolverId}
        setIsNavigationBlocked={setIsNavigationBlocked}
      />
    )
  }
  return (
      <FormIdeaDesktop
        solutionTitle={solution}
        breakpoints={buildBreakpoints()}
        carouselNavItems={carouselNavItems}
        evaluation={evaluation}
        formIdeaSectionsBody={formIdeaSectionsBody()}
        resolverId={resolverId}
        setIsNavigationBlocked={setIsNavigationBlocked}
      />
  )
}
