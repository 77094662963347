import React, { ReactChild, useEffect, useState } from 'react'
import { PageStyles, Sidebar, PageTop, CentralArea, Menu } from 'iebt-zing-storybook'
import { loggedUser } from 'sharedKernel/user/LoggedUser'
import { sideMenuBuilder } from 'components/menu/side/Builder'
import { TopMenuActions, topMenuBuider } from 'components/menu/top/Builder'
import { breadCrumbBuilder } from 'sharedKernel/breadCrumb/Builder'

import { store, changeTheme, useSelector, TReducers, changeSidebarState } from '../../../store'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { logout } from 'sharedKernel/auth'
import { useDispatch } from 'react-redux'
import { DeleteFormat, deleteProvider } from 'sharedKernel/api'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { ROUTES } from 'sharedKernel/constants'
import { getStartRoute } from 'sharedKernel/handlers'
import { regexPatterns } from 'sharedKernel/regex'
import { Breadcrumb } from 'components/Breadcrumb'
import { BreadcrumbContainer } from 'components/Breadcrumb/UI/style'

export interface PageControlProps {
  isSidebarOpen: boolean
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDarkModeOn: boolean
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

function pageControls(): PageControlProps {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isDarkModeOn, setDarkMode] = useState(false)

  const pageControls: PageControlProps = {
    isSidebarOpen: isSidebarOpen,
    setIsSidebarOpen: setIsSidebarOpen,
    isDarkModeOn: isDarkModeOn,
    setDarkMode: setDarkMode
  }
  return pageControls
}

export interface InnerPageProps {
  pageTitle?: string
}

export const InnerPage = ({ ...props }: InnerPageProps): JSX.Element => {
  const isDarkMode = useSelector((state: TReducers) => state.isDarkModeOn.isDarkModeOn)
  const featureType = useSelector((state: TReducers) => state.auth.permissions?.features?.map((feature) => feature.code))
  const onSuccessDeletion = async (): Promise<void> => {
    dispatch(logout())
  }

  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const pageControl = pageControls()
  const dispatch = useDispatch()

  const { mutate: deleteAuth } = deleteProvider(onSuccessDeletion)

  const handleDeleteAuth = (): void => {
    const request: DeleteFormat = {
      url: '/v1/auth/login'
    }
    navigate(ROUTES.USER_AUTH.LOGIN)
    deleteAuth(request)
  }
  const handleLogout = (): void => {
    showDoubleActionPopUp({
      title: 'Logout',
      message: 'Deseja realmente se desconectar?',
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      action: async () => handleDeleteAuth()
    })
  }
  const topMenuActions: TopMenuActions = {
    logoutAction: handleLogout
  }
  const user = loggedUser.get()
  const menuTopItems = topMenuBuider.build(topMenuActions, user)
  const menuItems = sideMenuBuilder.build(location.pathname, featureType)
  const breadcrumb = breadCrumbBuilder.build()
  useEffect(() => {
    dispatch(changeSidebarState(pageControl.isSidebarOpen))
  }, [pageControl.isSidebarOpen])

  const isFormPage = regexPatterns.isFormPage.test(location.pathname)

  return (
    <>
      <PageStyles {...props}>
        <Sidebar user={user} isOpen={pageControl.isSidebarOpen} isOpenHandler={pageControl.setIsSidebarOpen}>
          <Menu menuItems={menuItems} isSidebarOpen={pageControl.isSidebarOpen} />
        </Sidebar>
        <PageTop
          userAvatar={user.avatar}
          isSidebarOpen={pageControl.isSidebarOpen}
          sidebarToggleHandler={pageControl.setIsSidebarOpen}
          isDarkModeOn={isDarkMode}
          setDarkModeHandler={() => store.dispatch(changeTheme(!isDarkMode))}
          menuContent={menuTopItems}
          logoAction={() => {
            navigate(getStartRoute(featureType))
          }}
        />
        <CentralArea isOpen={pageControl.isSidebarOpen} isFormPage={isFormPage} >
          <>
            <BreadcrumbContainer>
              <Breadcrumb />
            </BreadcrumbContainer>
            {<Outlet />}
          </>
        </CentralArea>
      </PageStyles>
    </>
  )
}
