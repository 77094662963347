import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { api } from 'sharedKernel/api/index'
import { GetFormat, IUseGet } from './contracts'

export function useGet<T = unknown>(request: GetFormat, requestKey = 'simple-get', querySettings = {}): IUseGet {
  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useQuery<T>(
    requestKey,
    async () => {
      const response =
        request.externCallFlag === true
          ? await axios.get(request?.url, request?.httpConfig)
          : await api.get(request?.url, request?.httpConfig)

      return response.data
    },
    querySettings
  )

  return { data, isLoading, isFetching, isSuccess, isError, error, refetch }
}

export * from './contracts'

/* Query simple usage

  const request: GetFormat = {
    url: '/mock-post',
    params: {action:'teste', status:'working'}
  }

  const { data, isError, isLoading} = useGet(request)
*/
