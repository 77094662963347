/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import swal from '@sweetalert/with-react'
import React from 'react'
import { showModal } from '..'
import { PopUpContainer, PopUpContent, PopUpHeader, PopUpParagraph, PopUpTitle } from './style'

export interface AssignModalProps {
  title: string
  popUpMessage: string
  component: any
  buttons: Array<{ text: string, value?: string | null }>
  action: () => void
}

export const assignModal = ({ title, popUpMessage, component, buttons, action }: AssignModalProps): void => {
  const [confirm, cancel] = buttons
  swal({
    buttons: {
      confirm: {
        text: confirm.text,
        className: 'popup-button-confirm'
      },
      cancel: cancel.text
    },
    content: (
      <PopUpContainer>
        <PopUpHeader>
          <PopUpTitle>{title}</PopUpTitle>
        </PopUpHeader>
        <PopUpContent>{component}</PopUpContent>
      </PopUpContainer>
    )
  }).then((value) => {
    if (value) {
      swal({
        buttons: {
          confirm: {
            text: confirm.text,
            className: 'popup-button-confirm'
          },
          cancel: cancel.text
        },
        content: (
          <PopUpContainer style={{ height: '180px' }}>
            <PopUpHeader>
              <PopUpTitle>{title}</PopUpTitle>
            </PopUpHeader>
            <PopUpContent style={{ height: '50px' }}>
              <PopUpParagraph>{popUpMessage}</PopUpParagraph>
            </PopUpContent>
          </PopUpContainer>
        )
      }).then((willSave) => {
        if (willSave === true) {
          action()
        } else {
          showModal('assignModal', {
            title: title,
            popUpMessage: popUpMessage,
            component: React.cloneElement(component),
            buttons: [{ text: confirm.text }, { text: cancel.text }],
            action: () => action()
          })
        }
      })
    }
  })
}
