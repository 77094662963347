import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

export const DashboardWrapper = styled.main`
  display: flex;
  flex-direction: column;
`

export const DashboardItemBox = styled.article`
  display: flex;
  justify-content:center;
  align-items: start;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  height: 100%;
`
