import React, { useRef } from 'react'
import { trans } from 'sharedKernel/i18n'
import { ButtonWrapper, FormButton, FormInput, LandingPageFormBox, LandingPageFormWrapper, SecondEditionText, AdjustLabel, AdjustRadioInput } from './style'
import { LabeledCheckbox } from 'components/LabeledCheckbox'
import { Col, Row } from 'react-bootstrap'
import { showModal } from 'components/popup'
import { useSelector } from 'react-redux'
import { TReducers, store } from 'store'
import { resetRecommendationForm, updateRecommendationForm } from './store'
import { ifNotEmptyValidateEmail, isEmptyField } from '../../Forms/validations'
import { RadioGroupItemProps } from 'iebt-zing-storybook/dist/components/Fields'

export const LandingPageForm = (): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null)
  const contactFormState = useSelector((state: TReducers) => state.hotsiteILandingPageRecommendationFormState)
  const labels = {
    buttonText: trans('ENVIAR'),
    hubOption: trans('Hub'),
    acceleratorOption: trans('Aceleradora'),
    incubatorOption: trans('Incubadora'),
    name: trans('Nome'),
    email: trans('E-mail'),
    habitatType: trans('Quem é você no habitat de inovação:'),
    hubName: trans('Nome do habitat de inovação'),
    hubInstagram: trans('Instagram'),
    hubLinkedin: trans('Linkedin'),
    hubSite: trans('Site'),
    hubLocation: trans('Cidade e estado que o habitat de inovação está localizado'),
    hubDescription: trans('Breve descrição do habitat de inovação'),
    hubQuestion: trans('Quais iniciativas esse habitat de inovação possui?'),
    hubSugestions: trans('Gostaria de sugerir outros habitats de inovação que não podem ficar de fora do mapeamento?'),
    wantReceive: trans('Quero receber a '),
    secondEdition: trans('segunda edição do Mapa da Inovação'),
    modalTitle: trans('Enviar indicação'),
    modalMessage: trans('Obrigado pela sua indicação. Em breve entraremos em contato!')
  }

  const radioItens: RadioGroupItemProps[] = [
    {
      code: 'radio',
      id: 'hubType',
      label: labels.hubOption,
      name: 'entry.779809897',
      value: 'Hub',
      onClick: () => {
        const element = event?.target as HTMLInputElement
        handleChange(element.value, 'habitat')
      }
    },
    {
      code: 'radio',
      id: 'acceleratorType',
      label: labels.acceleratorOption,
      name: 'entry.779809897',
      value: 'Aceleradora',
      onClick: () => {
        const element = event?.target as HTMLInputElement
        handleChange(element.value, 'habitat')
      }
    },
    {
      code: 'radio',
      id: 'incubatorType',
      label: labels.incubatorOption,
      name: 'entry.779809897',
      value: 'Incubadora',
      onClick: () => {
        const element = event?.target as HTMLInputElement
        handleChange(element.value, 'habitat')
      }
    }
  ]

  const handleSubmit = (): void => {
    showModal('single', {
      title: labels.modalTitle,
      message: <div><p>{labels.modalMessage}</p></div>
    })
    setTimeout(() => {
      clearInputs()
    }, 1000)
  }
  const getSecondEditionLink = (): JSX.Element => {
    return (
      <>
        <span>{labels.wantReceive}</span>
        <SecondEditionText>{labels.secondEdition}</SecondEditionText>
      </>
    )
  }

  const validateForm = (): void => {
    contactFormState.isFormValid = validateInputs()
    store.dispatch(updateRecommendationForm(contactFormState))
  }

  const validateInputs = (): boolean => {
    for (const [key, value] of Object.entries(contactFormState.form)) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (value.isInvalid || (value.isRequired && value.value === '')) {
        return false
      }
    }
    return true
  }

  const clearInputs = (): void => {
    store.dispatch(resetRecommendationForm())
  }
  const handleChange = (value, key): void => {
    contactFormState.form[key].value = value
    store.dispatch(updateRecommendationForm(contactFormState))
    validateForm()
  }
  return (
    <LandingPageFormWrapper>
      <iframe
        name='hiddenConfirm'
        id='hiddenConfirm'
        style={{ display: 'none' }}
      />
      <LandingPageFormBox
        action='https://docs.google.com/forms/d/e/1FAIpQLSfUEw4Lpofafm-xYlNZnashT68ZewRBzSKqqO7k62gEXQWr0g/formResponse'
        onSubmit={handleSubmit}
        method='POST'
        target='hiddenConfirm'
        ref={formRef}
      >
        <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <AdjustRadioInput>
                <FormInput
                    id='habitatType'
                    isRequired
                    label={labels.habitatType}
                    code='radio-group'
                    name='entry.779809897'
                    radioItems={radioItens}
                    value={contactFormState.form.habitat.value}
                    isInvalid={contactFormState?.form.habitat.isInvalid}
                    notValidFieldMessage={contactFormState?.form.habitat.message}
                  ></FormInput>
              </AdjustRadioInput>
            </AdjustLabel>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='name'
                isRequired
                title={labels.name}
                label={labels.name}
                name='entry.1225969735'
                value={contactFormState.form.name.value}
                isInvalid={contactFormState?.form.name.isInvalid}
              notValidFieldMessage={contactFormState?.form.name.message}
              onChange={(event) => {
                handleChange(event.target.value, 'name')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.name)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='email'
                isRequired
                title={labels.email}
                label={labels.email}
                name='entry.74983536'
                value={contactFormState.form.email.value}
                isInvalid={contactFormState?.form.email.isInvalid}
              notValidFieldMessage={contactFormState?.form.email.message}
              onChange={(event) => {
                handleChange(event.target.value, 'email')
              }}
              onBlur={() => {
                ifNotEmptyValidateEmail(contactFormState.form.email)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubName'
                isRequired
                title={labels.hubName}
                label={labels.hubName}
                name='entry.1334342121'
                value={contactFormState.form.hubName.value}
                isInvalid={contactFormState?.form.hubName.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubName.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubName')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubName)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubInstagram'
                isRequired
                title={labels.hubInstagram}
                label={labels.hubInstagram}
                name='entry.725444116'
                value={contactFormState.form.hubInstagram.value}
                isInvalid={contactFormState?.form.hubInstagram.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubInstagram.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubInstagram')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubInstagram)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubLinkedin'
                isRequired
                title={labels.hubLinkedin}
                label={labels.hubLinkedin}
                name='entry.630993222'
                value={contactFormState.form.hubLinkedin.value}
                isInvalid={contactFormState?.form.hubLinkedin.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubLinkedin.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubLinkedin')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubLinkedin)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubSite'
                isRequired
                title={labels.hubSite}
                label={labels.hubSite}
                name='entry.821943420'
                value={contactFormState.form.hubSite.value}
                isInvalid={contactFormState?.form.hubSite.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubSite.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubSite')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubSite)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubLocation'
                isRequired
                title={labels.hubLocation}
                label={labels.hubLocation}
                name='entry.1680915663'
                value={contactFormState.form.hubLocation.value}
                isInvalid={contactFormState?.form.hubLocation.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubLocation.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubLocation')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubLocation)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubDescription'
                isRequired
                title={labels.hubDescription}
                label={labels.hubDescription}
                name='entry.1871052753'
                code='textarea'
                maxLength={800}
                value={contactFormState.form.hubDescription.value}
                isInvalid={contactFormState?.form.hubDescription.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubDescription.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubDescription')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubDescription)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubQuestion'
                isRequired
                title={labels.hubQuestion}
                label={labels.hubQuestion}
                name='entry.1882918495'
                code='textarea'
                maxLength={800}
                value={contactFormState.form.hubQuestion.value}
                isInvalid={contactFormState?.form.hubQuestion.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubQuestion.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubQuestion')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubQuestion)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
         <Row className='my-2'>
          <Col lg={true}>
            <AdjustLabel>
              <FormInput
                id='hubSugestions'
                title={labels.hubSugestions}
                label={labels.hubSugestions}
                name='entry.991680866'
                code='textarea'
                maxLength={800}
                value={contactFormState.form.hubRecommendation.value}
                isInvalid={contactFormState?.form.hubRecommendation.isInvalid}
              notValidFieldMessage={contactFormState?.form.hubRecommendation.message}
              onChange={(event) => {
                handleChange(event.target.value, 'hubRecommendation')
              }}
              onBlur={() => {
                isEmptyField(contactFormState.form.hubRecommendation)
                store.dispatch(updateRecommendationForm(contactFormState))
                validateForm()
              }}
            ></FormInput>
            </AdjustLabel>
          </Col>
        </Row>
        <Row className='my-3'>
          <Col lg={true}>
            <LabeledCheckbox
              id='secondEdition'
              label={getSecondEditionLink()}
              checked={undefined}
              onChange={undefined}
              name='entry.37249646'
              value='Sim'
            >
            </LabeledCheckbox>
          </Col>
        </Row>
        <ButtonWrapper>
          <FormButton variant='primary' type='submit' disabled={!contactFormState.isFormValid}>
            {labels.buttonText}
          </FormButton>
        </ButtonWrapper>
      </LandingPageFormBox>
    </LandingPageFormWrapper>
  )
}
