import { RESET_CHALLENGE_APPLICATION_FILES, UPDATE_CHALLENGE_APPLICATION_FILES } from './actions'
import { IHubStartupChallengeApplicationFilesState } from './contracts'

export const CHALLENGE_APPLICATION_FILES_STATE: IHubStartupChallengeApplicationFilesState = {
  applicationFormFiles: [],
  deletedApplicationFormFiles: []
}
export interface IHubStartupChallengeAplicationFilesStateAction {
  type: string
  payload: IHubStartupChallengeApplicationFilesState
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubStartupChallengeApplicationFiles = (
  state: IHubStartupChallengeApplicationFilesState = CHALLENGE_APPLICATION_FILES_STATE,
  action: IHubStartupChallengeAplicationFilesStateAction
): any => {
  switch (action.type) {
    case UPDATE_CHALLENGE_APPLICATION_FILES:
      return {
        ...state,
        applicationFormFiles: action.payload.applicationFormFiles,
        deletedApplicationFormFiles: action.payload.deletedApplicationFormFiles
      }
    case RESET_CHALLENGE_APPLICATION_FILES:
      return {
        ...state,
        applicationFormFiles: [],
        deletedApplicationFormFiles: []
      }
    default:
      return state
  }
}
