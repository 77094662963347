import { IPasswordForm } from '../../ChangePasswordForm/store'
import _ from 'lodash'
import { regexPatterns } from 'sharedKernel/regex'
import { setInvalid, setValid } from 'domain/feature/hotsite/Forms/validations'

const labels = {
  emptyField: 'O campo não pode ser vazio',
  notEqual: 'As senhas não são iguais',
  notNew: 'A senha nova não pode ser igual à anterior',
  notStrong: 'A senha nova não atende aos requisitos mínimos'
}

export const handlePasswordFormValidity = (form: IPasswordForm): IPasswordForm => {
  const validationArray = Object.values(form)

  if (validateForm(validationArray)) {
    form.isFormValid = true
    return form
  }

  form.isFormValid = false
  return form
}

const validateForm = (validationArray: any): boolean => {
  let isFormValid
  isFormValid = true
  validationArray.forEach((value: any) => {
    if (value.isRequired === false) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (value.isInvalid || _.isEmpty(String(value.value))) {
      isFormValid = false
    }
  })
  return isFormValid
}

export const handlePasswordValidation = (userPasswordState: IPasswordForm): IPasswordForm => {
  setValid(userPasswordState.confirmNewPassword)
  setValid(userPasswordState.currentPassword)
  setValid(userPasswordState.newPassword)
  checkPasswordFieldIsEmpty(userPasswordState)
  checkNewPasswordEqualConfirmPassword(userPasswordState)
  checkNewPasswordDifferentCurrentPassword(userPasswordState)
  checkNewPasswordStrong(userPasswordState)
  return userPasswordState
}

export const checkNewPasswordStrong = (userPasswordState: IPasswordForm): void => {
  if (userPasswordState.newPassword.neverChanged === true) {
    return
  }
  if (!regexPatterns.strongPassword.test(userPasswordState.newPassword.value)) {
    setInvalid(userPasswordState.newPassword, labels.notStrong)
    userPasswordState.isFormValid = false
  }
}

export const checkNewPasswordEqualConfirmPassword = (userPasswordState: IPasswordForm): void => {
  if (userPasswordState.confirmNewPassword.neverChanged === true || userPasswordState.newPassword.neverChanged === true) {
    return
  }
  if (userPasswordState.confirmNewPassword.value !== userPasswordState.newPassword.value) {
    setInvalid(userPasswordState.newPassword, labels.notEqual)
    setInvalid(userPasswordState.confirmNewPassword, labels.notEqual)
    userPasswordState.isFormValid = false
  }
}

export const checkNewPasswordDifferentCurrentPassword = (userPasswordState: IPasswordForm): void => {
  if (userPasswordState.newPassword.neverChanged === true) {
    return
  }
  if (userPasswordState.currentPassword.value === userPasswordState.newPassword.value) {
    setInvalid(userPasswordState.newPassword, labels.notNew)
    userPasswordState.isFormValid = false
  }
}

export const checkPasswordFieldIsEmpty = (userPasswordState: IPasswordForm): void => {
  _.forOwn(userPasswordState, function (userPasswordStateField) {
    if (typeof userPasswordStateField !== 'boolean' && userPasswordStateField.value === '' && userPasswordStateField.neverChanged === false) {
      setInvalid(userPasswordStateField, labels.emptyField)
      userPasswordState.isFormValid = false
    }
  })
}
