import { cookies, setCookie } from 'sharedKernel/cookies/behavior'
import { INITIALIZE_USER_CONFIG_FORM, RESET_USER_CONFIG_FORM, UPDATE_USER_CONFIG_FORM } from './actions'
import { IUserConfigForm } from './contracts'

export const USER_CONFIG_FORM_INITIAL_STATE: IUserConfigForm = {
  name: { value: '', isRequired: true, isInvalid: false, message: '' },
  phone: { value: '', isRequired: true, isInvalid: false, message: '' },
  mobile: { value: '', isRequired: true, isInvalid: false, message: '' },
  zipcode: { value: '', isRequired: true, isInvalid: false, message: '' },
  lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
  number: { value: '', isRequired: true, isInvalid: false, message: '' },
  lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
  city: { value: '', isRequired: true, isInvalid: false, message: '' },
  state: { value: '', isRequired: true, isInvalid: false, message: '' },
  isFormValid: true
}
export interface IHubIUserConfigFormStateAction {
  type: string
  payload: IUserConfigForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubUserConfigFormReducer = (
  state: IUserConfigForm = USER_CONFIG_FORM_INITIAL_STATE,
  action: IHubIUserConfigFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_USER_CONFIG_FORM:
      return {
        ...state,
        ...action.payload
      }

    case RESET_USER_CONFIG_FORM:
      setCookie('_usrData', action.payload)
      return {
        name: { value: '', isRequired: true, isInvalid: false, message: '' },
        phone: { value: '', isRequired: true, isInvalid: false, message: '' },
        mobile: { value: '', isRequired: true, isInvalid: false, message: '' },
        zipcode: { value: '', isRequired: true, isInvalid: false, message: '' },
        lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
        number: { value: '', isRequired: true, isInvalid: false, message: '' },
        lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
        city: { value: '', isRequired: true, isInvalid: false, message: '' },
        state: { value: '', isRequired: true, isInvalid: false, message: '' },
        isFormValid: false
      }
    default:
      return state
  }
}
