export const CHANGE_THEME = 'theme/CHANGE_THEME'

const changeTheme = (isDarkModeOn: boolean): any => {
  return {
    type: CHANGE_THEME,
    payload: isDarkModeOn
  }
}

export { changeTheme }
