/* eslint-disable no-useless-escape */
import { Validation } from '../contracts'
import { Pattern } from '../pattern'

export class Youtube implements Validation {
  valid: boolean
  errorMessage: string = 'Link do Youtube Inválido'
  patternValidator: Pattern

  constructor() {
    this.patternValidator = new Pattern(
      '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$'
    )
    this.valid = true
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    if (value === undefined || value === '') {
      this.valid = true
      return
    }
    this.patternValidator.validate(value)
    this.valid = this.patternValidator.isValid()
  }
}
