import { passwordAcceptance } from 'components/Password/Rules'
import { Input, Password } from 'iebt-zing-storybook'
import React from 'react'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { applyTitleOnError } from 'sharedKernel/handlers'
import { trans } from 'sharedKernel/i18n'
import { TReducers, store } from 'store'
import { FormTitle } from '../styles'
import { handlePasswordFormValidity, handlePasswordValidation } from './behavior/validation'
import { updatePasswordForm } from './store'

export const ChangePasswordForm = (): JSX.Element => {
  const userPasswordState = useSelector((state: TReducers) => state.hubIPasswordState)

  const labels = {
    emptyField: trans('O campo não pode ser vazio'),
    currentPasswordLabel: trans('Senha atual'),
    newPasswordLabel: trans('Nova senha'),
    confirmNewPasswordLabel: trans('Confirme nova senha'),
    currentPasswordPlaceholder: trans('Insira a senha atual'),
    newPasswordPlaceholder: trans('Insira a nova senha'),
    confirmNewPasswordPlaceholder: trans('Confirme a nova senha'),
    titleMessage: trans('Preencha este campo')
  }

  const handleChange = (event, key): void => {
    userPasswordState[key].value = event.target.value
    userPasswordState[key].neverChanged = false
    store.dispatch(updatePasswordForm(handlePasswordFormValidity(handlePasswordValidation(userPasswordState))))
  }
  return (
    <>
      <FormTitle>
        <h1>Alterar senha</h1>
        <hr></hr>
      </FormTitle>
      <Col sm={12} className="mb-4">
        <Password
          label={labels.currentPasswordLabel}
          placeholder={labels.currentPasswordPlaceholder}
          isRequired={true}
          title={applyTitleOnError(userPasswordState.currentPassword?.message, labels.titleMessage)}
          value={userPasswordState.currentPassword?.value}
          isInvalid={userPasswordState.currentPassword?.isInvalid}
          notValidFieldMessage={userPasswordState.currentPassword?.message}
          onChange={(event) => {
            handleChange(event, 'currentPassword')
          }}
        ></Password>
      </Col>
      <Col sm={12} className="mb-4">
        <Password
          label={labels.newPasswordLabel}
          description={passwordAcceptance()}
          placeholder={labels.newPasswordPlaceholder}
          isRequired={true}
          title={applyTitleOnError(userPasswordState.newPassword?.message, labels.titleMessage)}
          value={userPasswordState.newPassword?.value}
          isInvalid={userPasswordState.newPassword?.isInvalid}
          notValidFieldMessage={userPasswordState.newPassword?.message}
          onChange={(event) => {
            handleChange(event, 'newPassword')
          }}
        ></Password>
      </Col>
      <Col sm={12}>
        <Password
          label={labels.confirmNewPasswordLabel}
          description={passwordAcceptance()}
          placeholder={labels.confirmNewPasswordPlaceholder}
          isRequired={true}
          title={applyTitleOnError(userPasswordState.confirmNewPassword?.message, labels.titleMessage)}
          value={userPasswordState.confirmNewPassword?.value}
          isInvalid={userPasswordState.confirmNewPassword?.isInvalid}
          notValidFieldMessage={userPasswordState.confirmNewPassword?.message}
          onChange={(event) => {
            handleChange(event, 'confirmNewPassword')
          }}
        ></Password>
      </Col>
    </>
  )
}
