import styled from 'styled-components'

export const FormHeader = styled.div`
  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
  margin-bottom: 1rem
`
export const FormIdeaOuterDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--background)
`

export const FormIdeaInnerDiv = styled.div`
    max-width: -webkit-fill-available;
`
