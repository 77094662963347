import axios from 'axios'
import _ from 'lodash'

import { IHotsiteContactForm } from '../store'
import {
  GOOGLE_FORM_CONTACT_ACTION,
  GOOGLE_FORM_NAME_ID,
  GOOGLE_FORM_NICHE_ID,
  GOOGLE_FORM_COMPANY_NAME_ID,
  GOOGLE_FORM_ROLE_ID,
  GOOGLE_FORM_EMAIL_ID,
  GOOGLE_FORM_PHONE_ID,
  GOOGLE_FORM_CHECKBOX_GROUP_ID,
  GOOGLE_FORM_ACCEPT_TERMS_ID,
  GOOGLE_FORM_ANOTHER_ROLE_ID,
  GOOGLE_FORM_SUBJECT_ID
} from './GoogleForm'

export const makeGoogleFormContactRequest = (contactForm: IHotsiteContactForm): void => {
  const formData = new FormData()

  fillContactFormData(formData, contactForm)

  axios.post(GOOGLE_FORM_CONTACT_ACTION, formData)
}

const fillContactFormData = (formData: FormData, contactForm: IHotsiteContactForm): void => {
  formData.append(GOOGLE_FORM_NAME_ID, contactForm.form.name.value)
  formData.append(GOOGLE_FORM_NICHE_ID, contactForm.form.niche.value)
  formData.append(GOOGLE_FORM_COMPANY_NAME_ID, contactForm.form.companyName.value)
  formData.append(GOOGLE_FORM_ROLE_ID, contactForm.form.role.value)
  formData.append(GOOGLE_FORM_ANOTHER_ROLE_ID, '')
  formData.append(GOOGLE_FORM_EMAIL_ID, contactForm.form.email.value)
  formData.append(GOOGLE_FORM_PHONE_ID, contactForm.form.phone.value)
  formData.append(GOOGLE_FORM_SUBJECT_ID, contactForm.form.subject.value)
  if (contactForm.whatsappContact) {
    formData.append(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'Whatsapp')
  }
  if (contactForm.phoneContact) {
    formData.append(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'Ligação')
  }
  if (contactForm.emailContact) {
    formData.append(GOOGLE_FORM_CHECKBOX_GROUP_ID, 'E-mail')
  }
  if (contactForm.acceptUserTerms) {
    formData.append(GOOGLE_FORM_ACCEPT_TERMS_ID, 'Concordo com os termos de uso')
  }
}
