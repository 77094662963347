import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export const NoContentWrapper = styled(Container)``

export const NoContentText = styled.h6`
  font-weight: 600;
  color: var(--bs-grey-dark);
  margin: 0;
  line-height: 24px;
`
