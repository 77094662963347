import React from 'react'
import { Image } from 'react-bootstrap'
import { NoContentText, NoContentWrapper } from './styles'

export interface NoContentProps {
  text: string
  image?: string
  imageWidth?: string
  imageHeight?: string
}

export const NoContent = ({ text, image, imageWidth, imageHeight }: NoContentProps): JSX.Element => {
  return (
    <NoContentWrapper className="d-flex h-75 flex-column align-items-center justify-content-center">
      <Image src={image} className="mw-100 mb-3" width={imageWidth} height={imageHeight}/>
      <NoContentText>{text}</NoContentText>
    </NoContentWrapper>
  )
}
