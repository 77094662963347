import styled from 'styled-components'

export interface CardLabelProps {
  backgroundColor: string
}

export const DashboardCardLabelWrapper = styled.div<CardLabelProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
`
