import { DescriptedLabel } from 'iebt-zing-storybook'
import { Typeahead } from 'react-bootstrap-typeahead'
import { buildValidations } from '../../behaviors/validation'
import React, { useEffect, useState } from 'react'
import { ITypeahead } from '../../constants'
import { GetFormat, useGet } from '../../../../../../../../sharedKernel/api'
import { changeConfigSection, store } from '../../../../../../../../store'
import { ErrorLabel, FormItem } from '../../style'

export function BuildTypeahead(item: ITypeahead, itemKey: number | string): JSX.Element {
  const request: GetFormat = {
    url: item.endpointSource
  }
  const { data: options } = useGet(request, `typeahead-${String(itemKey)}`)
  const [allowNew, setAllowNew] = useState(true)

  const labels = {
    noNiches: 'Não há TAG/Nichos cadastrados',
    tagExists: 'Esta TAG/Nicho já existe'
  }

  const verifyIfTagAlreadyExist = (text: string): void => {
    const textWithoutWhiteSpacesBeforeAndAfter = text.trim()
    const tagFoundOnOptions = options.rows.find((value: any) => value.name.toLowerCase() === textWithoutWhiteSpacesBeforeAndAfter.toLowerCase())
    const tagFoundOnItemValue = item.value?.find((value: any) => value.name.toLowerCase() === textWithoutWhiteSpacesBeforeAndAfter.toLowerCase())

    if (tagFoundOnOptions !== undefined || tagFoundOnItemValue !== undefined) {
      setAllowNew(false)
    } else {
      setAllowNew(true)
    }
  }

  return (
    <FormItem key={itemKey}>
      <DescriptedLabel description={item.description} boldText={item.isRequired ?? false} htmlFor={item.id}>
        {String(item.label)}
      </DescriptedLabel>
      <Typeahead
        multiple
        className={buildValidations(item.validations)}
        id={item.id}
        labelKey='name'
        caseSensitive={false}
        emptyLabel={options?.rows.length === 0 ? labels.noNiches : labels.tagExists}
        defaultSelected={item.value}
        onChange={(selected) => {
          const options: Array<{ id: string, name: string }> = selected.map((tag: any) => {
            const isTagFromUser = Object.prototype.hasOwnProperty.call(tag, 'customOption')
            if (isTagFromUser) {
              tag.id = undefined
            }
            return {
              id: tag.id,
              name: tag.name.trim().toLowerCase()
            }
          })
          item.value = options
          if (item.updateFieldState !== undefined) {
            item.updateFieldState(item)
          }
        }}
        options={options?.rows ?? []}
        allowNew={allowNew}
        newSelectionPrefix={'Clique aqui para adicionar: '}
        onInputChange={(text: string) => {
          verifyIfTagAlreadyExist(text)
        }}
        disabled={item.disabled}
      />
      <ErrorLabel className={`errors-${String(item.id)}`}></ErrorLabel>
    </FormItem>
  )
}
