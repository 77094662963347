import { IPasswordForm } from './contracts'

export const UPDATE_PASSWORD_FORM = 'UPDATE_PASSWORD_FORM'
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM'

const updatePasswordForm = (applicationForm: IPasswordForm): any => {
  return {
    type: UPDATE_PASSWORD_FORM,
    payload: applicationForm
  }
}

const resetPasswordForm = (): any => {
  return {
    type: RESET_PASSWORD_FORM,
    payload: ''
  }
}

export { updatePasswordForm, resetPasswordForm }
