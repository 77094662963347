import styled from 'styled-components'
interface PasswordAcceptanceProps {
  isInvalid: boolean
}

export const PasswordAcceptance = styled.small<PasswordAcceptanceProps>`
  text-align: left;
  width: 100%;
  color: ${(props) => (props.isInvalid ? 'var(--error)' : 'var(--bs-body-color)')};
`
