import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-left: 1rem;
`

export const TitleAndButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
`

export const SaveButtonSection = styled.div`
  padding: 0 1rem 0 0;
`

export const SaveAndLaunchButtonSection = styled.div`
  padding: 0 0 0 1rem;
  flex: none;
`

export const FollowSteps = styled.span`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const ChallengeName = styled.h5`
`

export const FormLaunchedFieldWrapper = styled.div`
`

export const FormIdeaQuestion = styled.p`
  font-weight: 500;
  font-size: 14px;
`

export const FormIdeaAnswer = styled.p`
  font-weight: 400;
  font-size: 14px;
  padding-left: 8px;
`
