import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const QueryClientWrapper = ({ ...props }): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  })

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
}
