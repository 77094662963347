import styled from 'styled-components'
import { Link } from 'react-scroll'

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  padding-top: 1rem;
`

export const CarouselFormItemWrapper = styled.div`
  min-width: 150px;
  height: 100%;
  min-height: 4rem;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CarouselMenuItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 4rem;
  min-width: 8rem;
  

  display: flex;
  justify-content: center;
  align-items: center;
`

export const CarouselFormItem = styled(Link)`
  width: max-content;
  color: var(--grey);
  text-decoration: none;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem;
  }
`

export const CarouselMenuItem = styled.div`
  width: max-content;
  color: var(--grey);
  text-decoration: none;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  cursor: pointer;
`
