import _ from 'lodash'
import { IUserConfigForm, USER_CONFIG_FORM_INITIAL_STATE } from 'domain/feature/hub/user-config/PersonalConfigForm/store/configForm'
import { IProfileImage, PROFILE_IMAGE_INITIAL_STATE } from 'domain/feature/hub/user-config/PersonalConfigForm/store/profileImage'

export const userConfigResponseToReduxStateObject = (configForm: any): IUserConfigForm => {
  const initialState = JSON.parse(JSON.stringify(USER_CONFIG_FORM_INITIAL_STATE))
  _.forOwn(configForm, function (value, key) {
    initialState[key].value = value
  })

  return initialState
}

export const profileImageResponseToReduxStateObject = (imgPath: string): IProfileImage => {
  const initialState = PROFILE_IMAGE_INITIAL_STATE

  initialState.path = imgPath

  return initialState
}
