export const GOOGLE_FORM_CONTACT_ACTION = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeG8j8JYBUp5WYBYd1zo_mTd8dH_nwYXA_LYR6fnCi_qRzRdA/formResponse'

export const GOOGLE_FORM_NAME_ID = 'entry.1761483404'
export const GOOGLE_FORM_NICHE_ID = 'entry.250360415'
export const GOOGLE_FORM_COMPANY_NAME_ID = 'entry.465146355'
export const GOOGLE_FORM_ROLE_ID = 'entry.1928111007'
export const GOOGLE_FORM_ANOTHER_ROLE_ID = 'entry.302364132'
export const GOOGLE_FORM_EMAIL_ID = 'entry.393167920'
export const GOOGLE_FORM_PHONE_ID = 'entry.2060322009'
export const GOOGLE_FORM_SUBJECT_ID = 'entry.2013658380'
export const GOOGLE_FORM_CHECKBOX_GROUP_ID = 'entry.1372581606'
export const GOOGLE_FORM_ACCEPT_TERMS_ID = 'entry.1968932103'
