import _ from 'lodash'
import { IHotsiteContactForm } from './contracts'

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM'
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM'

const updateContactForm = (contactForm: IHotsiteContactForm): any => {
  return {
    type: UPDATE_CONTACT_FORM,
    payload: contactForm
  }
}

const resetContactForm = (contactForm?: IHotsiteContactForm): any => {
  return {
    type: RESET_CONTACT_FORM,
    payload: contactForm
  }
}

export { updateContactForm, resetContactForm }
