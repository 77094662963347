import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { AuthRoute } from './AuthRoute'
import { NotFoundPage } from 'main/pages/exceptions/notFound'
import { ForgotPasswordPage } from 'main/pages/user/forgotPassword'
import { ResetPasswordPage } from 'main/pages/user/reset'
import { InnerPage } from 'main/pages/defaults/InnerPage'
import { IndexPage } from 'main/pages/user/login'
import { MainPage } from 'main/pages/hotsite'
import { MarketplacePage } from 'main/pages/hotsite/marketplace'
import ScrollToTop from './ScrollToTop'
import { HotsiteInnerPage } from 'main/pages/defaults/HotsiteInnerPage'
import { HotsiteContactPage } from 'main/pages/hotsite/contact'
import { ROUTES } from 'sharedKernel/constants'
import { HotsiteEvaluatorRegisterPage } from 'main/pages/hotsite/register/evaluator'
import { HotsiteResolverRegisterPage } from 'main/pages/hotsite/register/resolver'
import { hubRoutes } from './hub'
import { HotsiteChallengePage } from 'main/pages/hotsite/challenge'
import { HotSiteLandingPage } from 'main/pages/hotsite/landingpage'
import { initializeGA, logPageView } from 'components/GoogleAnalytics/GAInitializer'

export const RoutesBuilder = (): JSX.Element => {
  const location = useLocation()

  useEffect(() => {
    initializeGA()
  }, [])

  useEffect(() => {
    logPageView()
  }, [location])

  return (
    <ScrollToTop>
      <Routes>
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
        {/* Hotsite */}
        <Route path={ROUTES.HOTSITE.ROOT} element={<HotsiteInnerPage></HotsiteInnerPage>}>
          <Route path={ROUTES.HOTSITE.ROOT} element={<MainPage />} />
          <Route path={ROUTES.HOTSITE.MARKETPLACE} element={<MarketplacePage />} />
          <Route path={ROUTES.HOTSITE.CHALLENGE} element={<HotsiteChallengePage />} />
          <Route path={ROUTES.HOTSITE.REGISTER.EVALUATOR} element={<HotsiteEvaluatorRegisterPage />} />
          <Route path={ROUTES.HOTSITE.REGISTER.RESOLVER} element={<HotsiteResolverRegisterPage />} />
          <Route path={ROUTES.HOTSITE.CONTACT} element={<HotsiteContactPage />} />
          <Route path={ROUTES.HOTSITE.LANDINGPAGE} element={<HotSiteLandingPage />} />
        </Route>

        {/* User */}
        <Route path={ROUTES.USER_AUTH.LOGIN} element={<IndexPage />} />
        <Route path={ROUTES.USER_AUTH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTES.USER_AUTH.RESET_PASSWORD} element={<ResetPasswordPage />} />

        {/* HUB */}
        <Route path={ROUTES.HUB.ROOT} element={<InnerPage></InnerPage>}>
          {hubRoutes.map((route, index) => {
            return (<AuthRoute key={index} path={route.path} features={route.features} element={route.component} />)
          })}
        </Route>
      </Routes>
    </ScrollToTop>
  )
}

export const CreateRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <RoutesBuilder />
    </BrowserRouter>
  )
}
