import styled from 'styled-components'
import { Button, Col } from 'react-bootstrap'

export const HeroInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const HeroTitle = styled.h1`
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  color: var(--primary);
  margin-bottom: 1.5rem;

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
    text-align: left;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem;
    text-align: left
  }
`

export const HeroText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: var(--grey);
  margin-bottom: 1.5rem;

  @media screen and (max-width: 425px) {
    font-size: 0.875rem
  }

  @media screen and (max-width: 768px) {
    font-size: 0.875rem
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem
  }
`
export const HeroStartText = styled.span`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: var(--primary);

`

export const HeroButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;

`

export const HeroButtonPrimary = styled(Button)`
  width: 100%;
  max-width: 340px;
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  border-radius: 0.25rem;
`

export const HeroButtonSecondary = styled(Button)`
  width:100%;
  max-width: 340px;
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  border-radius: 0.25rem;
  background-color: var(--white);
  color: var(--primary)
`
export const HeroButtonsColmun = styled(Col)`
  width: 100%;
`
