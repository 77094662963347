import React from 'react'
import DataTable from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { tableTheme } from './style'
import { trans } from 'sharedKernel/i18n'

export interface MaterialTableWrapperProps {
  title?: string
  data: any[]
  columns: any[]
}

export const MaterialTableWrapper = ({ title, data, columns, ...props }: MaterialTableWrapperProps): JSX.Element => {
  const sortIcon: [IconPrefix, IconName] = ['fas', 'arrow-down']

  const paginationText = {
    rowsPerPageText: trans('Linhas por página'),
    rangeSeparatorText: trans('de'),
    noRowsPerPage: true
  }

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      pagination
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5]}
      sortIcon={<FontAwesomeIcon style={{ marginLeft: '5px' }} icon={sortIcon} size={'xs'} />}
      customStyles={tableTheme}
      paginationComponentOptions={paginationText}
    />
  )
}
