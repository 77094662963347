import React, { useEffect, useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import { BaseButton, InfoArea, Input } from 'iebt-zing-storybook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetFormat, useGet, postProvider } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { useParams } from 'react-router'
import { toBase64 } from 'sharedKernel/file/handler'
import { AxiosResponse } from 'axios'
import { isEmptyField } from 'domain/feature/hotsite/Forms/validations'
import { EvaluatorInviteButton, EvaluatorInviteWrapper, ButtonArea } from './styles'
import { IFormFieldProperties } from 'domain/feature/hotsite/Forms/contracts'
import { validateEmail } from 'domain/feature/hotsite/Forms/validations/email'
import { Filter } from 'components/Filter'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'
import { Loading } from 'components/loading'
import { HubCompanyEvaluatorsInviteTable, HubCompanyEvaluatorsInviteTableProps } from './table'
import { EMAIL_COLUMN_INDEX, NAME_COLUMN_INDEX, STATUS_COLUMN_INDEX } from './table/contracts'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { hasTextMatch } from 'sharedKernel/handlers/text'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { NoContent } from 'components/NoContent'
import { Col, Row } from 'react-bootstrap'

interface EvaluatorsData {
  name: string
  email: string
  status: { code: string }
}

interface EvaluatorsResponseData {
  name: string
  email: string
  status: string
}

export const HubCompanyEvaluatorsInvite = (): JSX.Element => {
  const downloadFileRef: React.RefObject<HTMLAnchorElement> = React.createRef()
  const uploadFileRef: React.RefObject<HTMLInputElement> = React.createRef()

  const initialFormFieldProperty = {
    value: '',
    isInvalid: false,
    isRequired: true,
    message: ''
  }

  const [tableData, setTableData] = useState<HubCompanyEvaluatorsInviteTableProps>({ header: [], rows: [] })
  const [errorInvited, setErrorInvited] = useState<EvaluatorsData[]>([])
  const [newName, setNewName] = useState<IFormFieldProperties>(initialFormFieldProperty)
  const [newEmail, setNewEmail] = useState<IFormFieldProperties>(initialFormFieldProperty)
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  const labels = {
    pageTitle: trans('Gerenciar avaliadores'),
    name: trans('Nome'),
    email: trans('E-mail'),
    status: trans('Status'),
    evaluatorNameInput: { title: trans('Nome do(a) avaliador(a)'), placeholder: trans('Insira o nome do(a) avaliador(a) que deseja convidar') },
    evaluatorEmailInput: { title: trans('E-mail'), placeholder: trans('Insira o e-mail do(a) avaliador(a) que deseja convidar') },
    exportTitle: trans('Exportar'),
    importTitle: trans('Importar'),
    infoMsg: trans('Baixe o arquivo modelo abaixo para preenchimento dos avaliadores convidados e em seguida importe-os no botão abaixo.'),
    exportErrorMessage: trans('Um ou mais avaliadores não puderam ser importados!'),
    exportSuccessMessage: trans('Todos os avaliadores foram importados com sucesso!'),
    inviteConfirmationTitle: trans('Enviar convite'),
    inviteConfirmationMessage: trans('Tem certeza que deseja convidar esse avaliador?'),
    yes: trans('Sim'),
    no: trans('Não'),
    noEvaluators: trans('Ainda não há avaliadores para este desafio.')
  }

  const { challengeId } = useParams()

  const requestInvited: GetFormat = {
    url: `/v1/hub/company/challenge/${challengeId}/imported-evaluators`
  }

  const {
    data: requestInvitedData,
    isFetching: isInvitedFetching,
    refetch: refetchInvited
  } = useGet<EvaluatorsData[]>(requestInvited, 'invited-evaluators-get')

  useEffect(() => {
    if (!isInvitedFetching) {
      const invitedData = isInvitedFetching ? [] : requestInvitedData.rows ?? []
      const evaluatorsTableParams = invitedData.map(evaluator => {
        return [evaluator.name, evaluator.email, evaluator.status.code]
      })
      const errorEvaluatorsTableParams = isInvitedFetching
        ? []
        : errorInvited.map(evaluator => {
          return [evaluator.name, evaluator.email, evaluator.status.code]
        })
      const evaluatorsTableData = { header: [labels.name, labels.email, labels.status], rows: [...errorEvaluatorsTableParams, ...evaluatorsTableParams] }
      setTableData({ ...evaluatorsTableData })
    }
  }, [isInvitedFetching])

  const onErrorInviteEvaluator = async (response: AxiosResponse): Promise<void> => {
    showToast('error', response.data.message)
  }

  const onSuccessInviteEvaluator = async (response: AxiosResponse): Promise<void> => {
    const evaluators: EvaluatorsResponseData[] = response.data.data.evaluators
    const newErrorInvited: EvaluatorsData[] = []
    evaluators.forEach(evaluator => {
      if (evaluator.status === 'error') {
        newErrorInvited.unshift({ name: evaluator.name, email: evaluator.email, status: { code: evaluator.status } })
      }
    })
    if (newErrorInvited.length > 0) {
      showToast('error', labels.exportErrorMessage)
    } else {
      showToast('success', labels.exportSuccessMessage)
    }
    setErrorInvited(newErrorInvited)
    refetchInvited()
  }

  const { mutate: uploadFile } = postProvider(onSuccessInviteEvaluator, onErrorInviteEvaluator)
  const { mutate: inviteEvaluator } = postProvider(refetchInvited, onErrorInviteEvaluator)

  const triggerUpload = (): void => {
    uploadFileRef.current!.click()
  }

  const processFile = async (file: any): Promise<void> => {
    const fileContent = await toBase64(file)
    const mimetype = file.type
    const fileData = {
      mimetype: mimetype,
      name: btoa(unescape(encodeURIComponent(file.name))),
      content: fileContent,
      type: 'supportFiles'
    }
    uploadFile({
      url: `/v1/hub/company/challenge/${challengeId}/invite/upload`,
      data: { data: fileData }
    })
  }

  const handleInviteEvaluator = (name, email): void => {
    const evaluatorsData = {
      evaluators: [
        {
          name,
          email
        }
      ],
      challengeId: challengeId
    }
    inviteEvaluator({ url: `v1/hub/company/challenge/${challengeId}/invite`, data: { data: evaluatorsData } })
  }

  const onSearch = (searchTerm: string): void => {
    setCurrentSearchTerm(searchTerm)
  }

  const confirmation = (name: string, email: string): void => {
    showDoubleActionPopUp({
      title: labels.inviteConfirmationTitle,
      message: labels.inviteConfirmationMessage,
      buttons: [{ text: labels.yes }, { text: labels.no }],
      action: () => {
        handleInviteEvaluator(name, email)
        setNewName(initialFormFieldProperty)
        setNewEmail(initialFormFieldProperty)
      }
    })
  }

  const filteredRows = tableData.rows.filter(row => hasTextMatch(row[NAME_COLUMN_INDEX], currentSearchTerm) ||
    hasTextMatch(row[EMAIL_COLUMN_INDEX], currentSearchTerm))

  const getTableAreaElement = (): JSX.Element => {
    if (isInvitedFetching) {
      return <div className="pt-5"><Loading /></div>
    } else if (tableData.rows.length === 0) {
      return <NoContent text={labels.noEvaluators} image={NoContentImg} />
    }
    return <HubCompanyEvaluatorsInviteTable header={tableData.header} rows={filteredRows} />
  }

  return (
    <>
      <EvaluatorInviteWrapper>
        <Row>
          <Col xs={12} sm={5}>
            <Input
              id='evaluatorName'
              isRequired={true}
              value={newName.value}
              label={labels.evaluatorNameInput.title}
              placeholder={labels.evaluatorNameInput.placeholder}
              title={labels.evaluatorEmailInput.placeholder}
              isInvalid={newName.isInvalid}
              notValidFieldMessage={newName.message}
              onChange={(event) => {
                setNewName({ ...newName, value: event.target.value })
              }}
              onBlur={() => {
                const newNameValidated = { ...newName }
                isEmptyField(newNameValidated)
                setNewName(newNameValidated)
              }}
            ></Input>
          </Col>
          <Col xs={12} sm={5}>
            <Input
              id='evaluatorEmail'
              isRequired={true}
              value={newEmail.value}
              label={labels.evaluatorEmailInput.title}
              placeholder={labels.evaluatorEmailInput.placeholder}
              isInvalid={newEmail.isInvalid}
              notValidFieldMessage={newEmail.message}
              title={labels.evaluatorEmailInput.placeholder}
              onChange={(event) => {
                setNewEmail({ ...newEmail, value: event.target.value.split(' ').join('') })
              }}
              onBlur={() => {
                const newEmailValidated = { ...newEmail }
                const isEmptyEmail = isEmptyField(newEmailValidated)
                if (!isEmptyEmail) {
                  validateEmail(newEmailValidated)
                }
                setNewEmail(newEmailValidated)
              }}
            ></Input>
          </Col>
          <Col xs={12} sm={2}>
            <EvaluatorInviteButton>
              <BaseButton
                disabled={newName.isInvalid || newEmail.isInvalid || newName.value === '' || newEmail.value === ''}
                btnContent="Convidar"
                variant='primary'
                size='big'
                onClick={() => confirmation(newName.value, newEmail.value)}
              />
            </EvaluatorInviteButton>
          </Col>
        </Row>
      </EvaluatorInviteWrapper>
      <div className="pb-4">
        <InfoArea areaContent={labels.infoMsg} />
      </div>
      <FilterOrderBar>
        {tableData.rows.length === 0 ? <></> : <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />}
        <ButtonArea>
          <div>
            <a ref={downloadFileRef} href={require('../../../../../../../assets/files/Avaliadores.xlsx')} download="zing-modelo-convite-avaliadores.xlsx" hidden />
            <BaseButton
              btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'upload']} />
                {labels.importTitle}</span>}
              variant="secondary"
              onClick={() => triggerUpload()}
            />
          </div>
          <input
            hidden
            ref={uploadFileRef}
            type="file"
            accept='.xlsx'
            onChange={(event) => {
              const file = event.target.files !== null ? event.target.files[0] : null
              if (file !== null) {
                processFile(file)
              }
              event.target.value = ''
            }}
          />
          <BaseButton
            btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'download']} />
              {labels.exportTitle}</span>}
            onClick={() => { downloadFileRef.current!.click() }}
            variant="secondary"
          />
        </ButtonArea>
      </FilterOrderBar>
      {getTableAreaElement()}
    </>
  )
}
