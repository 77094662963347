import { IFormFieldProperties } from '../../contracts'
import { setValid, setInvalid } from '..'
import { regexPatterns } from 'sharedKernel/regex'

export const invalidMessages = {
  mobile: 'Celular inválido'
}

const MASKED_MOBILE_VALID_SIZE = 15

export const validateMobile = (mobile: IFormFieldProperties): boolean => {
  const mobileLength = mobile.value.length

  if (mobileLength !== MASKED_MOBILE_VALID_SIZE && mobileLength !== 0) {
    setInvalid(mobile, invalidMessages.mobile)
    return false
  }

  setValid(mobile)
  return true
}
