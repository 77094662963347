import { CHANGE_MENU, VISIBLE_ROWS } from './actions'

const INITIAL_STATE: IInital = {
  isEvaluationMenu: false,
  visibleRows: []
}
export interface IInital {
  isEvaluationMenu: boolean
  visibleRows: string[]
}

export interface IMenuAction {
  type: string
  payload: IEvaluationMenuState
}

export interface IEvaluationMenuState {
  isEvaluationMenu: boolean
  visibleRows: string[]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const EvaluationMenuReducer = (state: IInital = INITIAL_STATE, action: IMenuAction): any => {
  switch (action.type) {
    case CHANGE_MENU:
      return {
        ...state,
        isEvaluationMenu: action.payload
      }
    case VISIBLE_ROWS:
      return {
        ...state,
        visibleRows: action.payload
      }

    default:
      return state
  }
}
