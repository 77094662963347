
import React, { useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { IEvaluator } from '../table/contracts'
import { ContentDiv, EvaluatorNameDiv, LabelsStyle, SolutionName, SolutionList, SolutionTitleDiv } from './styles'

interface AssignModalProps {
  evaluator: IEvaluator
  evaluatorSolutions: string[]
  allSolutions: any
  updateAssignedSolution: (solutionId: string, isAssignSolution: boolean) => void
  initializeAssignedSolutions: (assignedSolutions: string[]) => void
  resetAssignedSolutions: () => void
}

export const AssignModal = ({ evaluator, evaluatorSolutions, allSolutions, updateAssignedSolution, initializeAssignedSolutions, resetAssignedSolutions }: AssignModalProps): JSX.Element => {
  const labels = {
    evaluator: trans('Avaliador'),
    solutions: trans('Soluções'),
    noSolutionsContent: trans('Sem soluções disponíveis para atribuição no momento')
  }

  const checkboxRefs = useRef<HTMLInputElement[]>([])

  const ListContent = (): JSX.Element => {
    return ((allSolutions === null || allSolutions === undefined || allSolutions.length === 0)
      ? <div className="d-flex justify-content-center">{labels.noSolutionsContent}</div>
      : allSolutions.map((solution, solutionIndex) => {
        return (
        <SolutionName key={solutionIndex}>
          <input
            id={solutionIndex}
            type="checkbox"
            className="larger"
            checked={evaluatorSolutions.includes(solution.id)}
            value={solution.id}
            onChange={() => { }}
            ref={(element) => {
              checkboxRefs.current[solutionIndex] = element as HTMLInputElement
            }}
          />
          <SolutionTitleDiv>{solution.title}</SolutionTitleDiv>
        </SolutionName>
        )
      })
    )
  }

  useEffect(() => {
    resetAssignedSolutions()
    initializeAssignedSolutions(evaluatorSolutions)
  }, [])

  useEffect(() => {
    checkboxRefs.current.forEach((element, elementIndex) => {
      if (element !== null) {
        element.addEventListener('change', (event) => {
          updateAssignedSolution(element.value, element.checked)
        })
      }
    })
  }, [checkboxRefs])

  return (
    <Row className="px-4">
      <ContentDiv>
        <LabelsStyle>{labels.evaluator}:</LabelsStyle>
        <EvaluatorNameDiv>{evaluator.name}</EvaluatorNameDiv>
        <LabelsStyle>{labels.solutions}:</LabelsStyle>
        <SolutionList>
          {ListContent()}
        </SolutionList>
      </ContentDiv>
    </Row>
  )
}
