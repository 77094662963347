import { MenuItemProps } from 'iebt-zing-storybook/dist/pages/Layout/Sidebar/MenuItem'
import { createMenu } from './createMenu'

class SideMenuBuilderFactory {
  build(): SideMenuBuilder {
    return new SideMenuBuilder()
  }
}

class SideMenuBuilder {
  build(currentLocation?: string, featureType?: string[]): MenuItemProps[] {
    return createMenu(currentLocation, featureType)
  }
}

const factory = new SideMenuBuilderFactory()
export const sideMenuBuilder = factory.build()
