import { trans } from 'sharedKernel/i18n'
import { IHubStartupChallengeApplicationFileStructure, IHubStartupChallengeApplicationForm } from '../../store'

export function getFile(
  applicationFormState: IHubStartupChallengeApplicationForm,
  questionId: string
): undefined | IHubStartupChallengeApplicationFileStructure {
  // eslint-disable-next-line no-undef-init
  let data: undefined | IHubStartupChallengeApplicationFileStructure = undefined

  applicationFormState.sections.forEach((section) => {
    section.answers.forEach((answer) => {
      if (questionId === answer.questionId) {
        data = answer.archive
      }
    })
  })

  return data
}

export function setFile(
  applicationFormState: IHubStartupChallengeApplicationForm,
  questionId: string,
  value: IHubStartupChallengeApplicationFileStructure | undefined
): IHubStartupChallengeApplicationForm {
  applicationFormState.sections.forEach((section) => {
    section.answers.forEach((answer) => {
      if (questionId === answer.questionId) {
        answer.archive = value
      }
    })
  })
  return applicationFormState
}

export function getValue(
  applicationFormState: IHubStartupChallengeApplicationForm,
  questionId: string
): undefined | string | IHubStartupChallengeApplicationFileStructure {
  // eslint-disable-next-line no-undef-init
  let data: undefined | string = undefined

  applicationFormState.sections.forEach((section) => {
    section.answers.forEach((answer) => {
      if (questionId === answer.questionId) {
        data = answer.value
      }
    })
  })

  return data
}

export function setValue(
  applicationFormState: IHubStartupChallengeApplicationForm,
  questionId: string,
  value: string,
  isRequired: boolean
): IHubStartupChallengeApplicationForm {
  applicationFormState.sections.forEach((section) => {
    section.answers.forEach((answer) => {
      if (questionId === answer.questionId) {
        answer.value = value
        answer.isRequired = isRequired
      }
    })
  })
  return applicationFormState
}

export function getDescription(initialDescription: string, isRequired: boolean): string {
  let descriptionResult = trans(initialDescription)

  const labels = {
    requiredField: trans('Você deve preecher este campo antes de submeter sua proposta. '),
    optionalField: trans('Campo opcional')
  }

  if (isRequired) {
    descriptionResult = labels.requiredField + descriptionResult
  } else if (descriptionResult === '') {
    descriptionResult = labels.optionalField
  }

  return descriptionResult
}
