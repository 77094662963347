import styled from 'styled-components'
import { Nav, Image } from 'react-bootstrap'

export const Logo = styled(Image)`
  width: 100%;
  max-width: 9.375rem;
  height: auto;
  max-height: 3.75rem;
  cursor: pointer;
`

export const HeaderLink = styled(Nav.Link)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  text-decoration: none;
  color: var(--grey);
  margin-inline: 2rem;
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }

  @media screen and (max-width: 992px) {
    margin-inline: 0;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 992px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }
`

export const LoginLink = styled.a`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-decoration: none;
  color: var(--primary);
  margin-inline: 1rem;
  cursor: pointer;
  &:hover {
    color: var(--primary-dark);
  }

  @media screen and (max-width: 992px) {
    margin-inline: 0;
    margin-right: 1rem;
  }
`
