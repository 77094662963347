import { Row } from 'react-bootstrap'
import styled from 'styled-components'

export const DashboardCardSection = styled(Row)`
  @media screen and (max-width: 1004px) {
   justify-content: center;
  }
  @media screen and (max-width: 858px) {
   flex-direction: column;
   width: 100%;
  }
`

export const StatsInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 10px;
  overflow: hidden;
`

export const StatsCountBox = styled.div`
  display: flex;
  width: calc(100%);
  overflow: hidden;
`

export const StatsTitle = styled.h1`
  margin-bottom: 10px;
  width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StatsDescription = styled.span`
  font-weight: bold;
  opacity: 0.5;
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StatsCount = styled.h4`
  font-weight: bold;
  margin-right: 10px;
`

export const StatsInfo = styled.div`
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  margin-left: auto;
`

export const CardsBox = styled.div`
  display: block;
  padding: 8px 12px;
  flex-wrap: wrap;
  width: 25%;
  flex-direction: column;
  @media screen and (max-width: 1232px) {
   width: 33%;
  }
  @media screen and (max-width: 1004px) {
   width: 43%;
  }
  @media screen and (max-width: 858px) {
   width: 340px;
   margin: auto;
  }
`
