import React from 'react'

import { HeroSection } from './HeroSection'
import { OpenChallengesSection } from './OpenChallengesSection'
import { OurServicesSection } from './OurServicesSection'
import { OurPartnersSection } from './OurPartnersSection'
import { Container } from 'react-bootstrap'

export const HotsiteMain = ({ challenges }): JSX.Element => {
  return (
    <Container>
      <HeroSection />
      <OpenChallengesSection openChallenges={challenges} />
      <OurServicesSection />
      {/*
        Plataforma ainda não possue parceiros, futuramente serão adicionados aqui
        <OurPartnersSection />
      */}
    </Container>
  )
}
