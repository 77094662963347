import styled from 'styled-components'
import { Table } from 'react-bootstrap'

export const BasicTable = styled(Table)`
  background: white;
  overflow-wrap: anywhere;
  border-radius: 10px;

  thead {
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  tbody {
    border-left: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
  }

  tr:last-child {
    border-bottom: 1px solid var(--primary);
  }
`
