import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { trans } from 'sharedKernel/i18n'
import { ChallengeButtonsProps } from '../contracts'
import { useNavigate } from 'react-router'

export const EvaluatorsButton = ({
  challengeId,
  isOwner
}: ChallengeButtonsProps): JSX.Element => {
  const haveAccess = hasAccess('challenge', 'launch')
  const translations = {
    btnText: 'Avaliadores'
  }
  const navigate = useNavigate()

  if (!haveAccess || !isOwner) {
    return <></>
  }
  return (
    <div className="pb-4">
      <BaseButton
        btnContent={trans(translations.btnText)}
        variant="secondary"
        size="big"
        isFullWidth
        onClick={() => {
          navigate(`/hub/company/challenge/${challengeId}/evaluators`)
        }}
      />
    </div>
  )
}
