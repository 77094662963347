import styled from 'styled-components'

export const ContentDiv = styled.div`
  margin: 54.5px 0 56px;
`

export const LabelsStyle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 11.5px;
  text-align: left;
`

export const EvaluatorNameDiv = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  width: 589px;
  border: 1px solid #838383;
  border-radius: 4px;
  margin-bottom: 38.5px;
  color: #838383;
`

export const SolutionList = styled.div`
  height: 11.75rem;
  width: 589px;
  border: 1px solid #464646;
  border-radius: 4px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`

export const SolutionName = styled.div`
  font-size: 1rem;
  text-align: left;
  display: flex;
  
  input.larger {
    width: 18px;
    height: 18px;
    margin: 15px 10px 15px 15px;
    cursor: pointer;
  }
`

export const SolutionTitleDiv = styled.div`
  max-width: calc(100% - 43px);
  height: fit-content;
  margin-top: 13px;
`
