import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Questions = styled(Col)`
  font-size: 14px;
  text-align: left;
`

export const QuestionsContainer = styled(Row)`
  overflow-y: auto;
  padding-right: 0;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const SectionTitleWrapper = styled(Swiper)`
  width: auto;
  max-width: auto;
  overflow-x: hidden;
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--primary);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
  }
`
export const SectionTitleHeader = styled.div`
  display: flex;
  height: 33px;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background-color: var(--secondary-f);
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
`

export const SectionTitleText = styled.div`
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const LikertScaleAnswer = styled.div`
  padding-top: 1rem;
  display: grid;
  align-items: start;
  grid-template-columns: auto 1.5rem min-content;
  grid-template-rows: 2rem;
`

export const LikertScaleRange = styled.div`
  input {
    width: 100%;
  }
  .inactive {
    accent-color: var(--grey-light);
  }
  .active {
    accent-color: var(--primary);
  }
`
