import styled from 'styled-components'

interface TrackerSection {
  progressValue: number
}

interface PercentageLabel {
  color: string
}

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

export const PercentageTrackerSection = styled.div<TrackerSection>`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.progressValue + 15)}%;
  transition: width 1s ease-in-out;
`

export const PercentageTrackerDiv = styled.div<PercentageLabel>`
  display: flex;
  background-color: ${(props) => (props.color)};
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
`

export const PercentageSpan = styled.span<PercentageLabel>`
  color: ${(props) => (props.color)};
`

export const TotalValueDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 21px;
  width: 30px;
`

export const TotalValue = styled.span`
  font-weight: bold;
`
