import styled from 'styled-components'

export const InputWrapper = styled.div`
    &.invalid-input{
        border: solid var(--error) 1px;
        border-radius: 0.375rem;
        padding: 4px;
        
        input{
            border: solid var(--error) 1px;
        }
    }
`
