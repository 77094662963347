import styled from 'styled-components'

export const UserTermsCheckboxContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`

export const CheckboxLabel = styled.span`
  font-family: 'Roboto';
`
