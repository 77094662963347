export const stepStyles = {
  '& .MuiStepLabel-label': {
    color: 'var(--grey) !important' // Cor da Label
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: 'var(--primary)', // Bolinha do do Ativo
    border: 'none'
  },

  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'var(--white)' // Texto do Ativo
  },
  '& .MuiStepIcon-root': {
    fontSize: '40px', // Bolinha padrão
    border: '3px solid var(--primary)',
    borderRadius: '20px',
    color: 'var(--white)',
    zIndex: '1'
  },
  '& .MuiStepIcon-text': {
    fill: 'var(--primary)' // Estilização o texto da bolinha
  },

  '& .MuiStepConnector-root': {
    top: '18px', // Definições da estrutura de linha
    zIndex: '0',
    left: 'calc(-50% + 0px)'
  },

  '& .MuiStepConnector-line': {
    borderColor: 'var(--primary)' // Estilização da linha
  }
}
