import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { LabelInputFile } from 'sharedKernel/styledComponents/fileComponents/styles'
import { checkJSON } from '..'
import { FileDiv } from '.././styles'
import { FormAnswerProps } from '../contracts'

export const FormAnswerUpload = ({ answer }: FormAnswerProps): JSX.Element => {
  if (answer.archive === null && answer.answerValue !== '') {
    return checkJSON(answer)
  }

  return (
    <FileDiv>
      {answer.archive.path != null
        ? (
          <a href={String(answer.archive.path)} target="_blank">
            <LabelInputFile isClickable>
              <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
              {answer.archive.name}
            </LabelInputFile>
          </a>
          )
        : (
      <LabelInputFile isClickable>
        <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
        {answer.archive.name}
      </LabelInputFile>
          )}
    </FileDiv>
  )
}
