import { IRegexPatterns } from './contract'

export const regexPatterns: IRegexPatterns = {
  // eslint-disable-next-line no-useless-escape
  email: /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/,
  strongPassword: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])(?=.*[0-9]).{8,}$/,
  hasLetter: /[A-Za-zÀ-ÖØ-öø-ÿ]+/,
  clearMaskedNumbers: /[^0-9]+/g,
  // eslint-disable-next-line no-useless-escape
  isFormPage: /\/hub\/evaluator\/challenge\/[0-9a-z\-]+\/step\/[0-9a-z\-]+\/idea\/[0-9a-z\-]+$/
}
