import { RESET_RECOMMENDATION_FORM, UPDATE_RECOMMENDATION_FORM } from './actions'
import { ILandingPageRecommendationForm } from './contracts'

export const RECOMMENDATION_FORM_INITIAL_STATE: ILandingPageRecommendationForm = {
  form: {
    habitat: { value: '', isRequired: true, isInvalid: false, message: '' },
    name: { value: '', isRequired: true, isInvalid: false, message: '' },
    email: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubName: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubInstagram: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubLinkedin: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubSite: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubLocation: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubDescription: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubQuestion: { value: '', isRequired: true, isInvalid: false, message: '' },
    hubRecommendation: { value: '', isRequired: false, isInvalid: false, message: '' }
  },
  isFormValid: false
}

export interface IHotsiteRecommendationFormStateAction {
  type: string
  payload: ILandingPageRecommendationForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HotsiteRecommendationFormReducer = (
  state: ILandingPageRecommendationForm = RECOMMENDATION_FORM_INITIAL_STATE,
  action: IHotsiteRecommendationFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_RECOMMENDATION_FORM:
      return {
        ...state,
        ...action.payload
      }

    case RESET_RECOMMENDATION_FORM:
      return {
        form: {
          habitat: { value: '', isRequired: true, isInvalid: false, message: '' },
          name: { value: '', isRequired: true, isInvalid: false, message: '' },
          email: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubName: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubInstagram: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubLinkedin: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubSite: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubLocation: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubDescription: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubQuestion: { value: '', isRequired: true, isInvalid: false, message: '' },
          hubRecommendation: { value: '', isRequired: false, isInvalid: false, message: '' }
        },
        isFormValid: false
      }

    default:
      return state
  }
}
