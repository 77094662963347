import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

export const LandingPageWrapper = styled(Container)`
  height: auto;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 2rem;
  flex-direction: column;
`
export const LandingPagePresentation = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LandingPageText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: var(--grey);
  margin-bottom: 1.5rem;

  &.highlight {
    color: var(--primary);
  }
  @media screen and (max-width: 768px) {
    font-size: 0.875rem
  }
`
export const LandingPageTitle = styled.h2`
  font-weight: bold;
  color: var(--black);
  text-align: center;
  
`
export const LandingPageSubtitle = styled.h4`
  font-weight: bold;
  color: var(--black);
  margin-bottom: 1.5rem;
  text-align: center;
  
`
export const LandingPagePartners = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const LandingPagePartnersList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LandingPagePartnersItem = styled.li`
  margin: 0px 8px;
  list-style-type: none;
`
