import { cookies } from 'sharedKernel/cookies/behavior'
import { changeMenu, resetChallengeFiles, resetChallengeSection, resetSidebarState, store } from 'store'
import { IAuthPermission, IAuthState } from './authReducer'

export const SET_AUTH = 'user/SET_AUTH'
export const SET_PERMISSIONS = 'user/SET_PERMISSIONS'
export const LOGOUT = 'user/LOGOUT'

interface ActionProps {
  type: string
  payload: any
}

const setAuth = (payload: IAuthState): ActionProps => {
  return {
    type: SET_AUTH,
    payload: payload
  }
}

const setPermissions = (payload: IAuthPermission): ActionProps => {
  return {
    type: SET_PERMISSIONS,
    payload: payload
  }
}

const logout = (): ActionProps => {
  resetBeforeLogout()

  return {
    type: LOGOUT,
    payload: {}
  }
}

const resetBeforeLogout = (): void => {
  store.dispatch(resetChallengeSection())
  store.dispatch(resetChallengeFiles())
  store.dispatch(changeMenu(false))
  store.dispatch(resetSidebarState())
}

export { setAuth, setPermissions, logout }
