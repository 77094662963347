import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { useNavigate } from 'react-router'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { trans } from 'sharedKernel/i18n'
import { LaunchIdeaButtonProps } from '../contracts'

export const LaunchIdeaButton = ({ challengeId, remainingAttempts, preview = false }: LaunchIdeaButtonProps): JSX.Element => {
  const navigate = useNavigate()

  const btnText = trans('Inscreva-se')

  const haveAccess = hasAccess('idea', 'launch')
  if (!haveAccess || remainingAttempts < 1) {
    return <></>
  }

  const handleLaunchIdea = (): void => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!preview) {
      navigate(`/hub/startup/challenge/${String(challengeId)}/apply`)
    }
  }

  return (
    <BaseButton
      btnContent={btnText}
      variant="primary"
      size="big"
      isFullWidth
      onClick={() => handleLaunchIdea()}
    />
  )
}
