import React from 'react'
import { HubCompanyDashboardSolutionsNiche } from '../../../../domain/feature/hub/dashboard/charts/SolutionsArea'
import { HubCompanyDashboardSolutionsCity } from '../../../../domain/feature/hub/dashboard/charts/SolutionsCity'
import { DashboardWrapper } from './style'
import { Row } from 'react-bootstrap'
import { ChallengesTable } from 'domain/feature/hub/dashboard/ChallengesTable'
import { DashboardEvaluatorsList } from 'domain/feature/hub/dashboard/evaluators'
import { DashboardGeneralStatsList } from 'domain/feature/hub/dashboard/generalStats'
import { trans } from 'sharedKernel/i18n'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { loggedUser } from 'sharedKernel/user/LoggedUser'

export const HubDashboardPage = (): JSX.Element => {
  const user = loggedUser.get()

  const labels = {
    title: trans('Bem-vindo(a), ')
  }

  return (
    <DashboardWrapper>
      <PageTitle>{labels.title + user.name}</PageTitle>
      <DashboardGeneralStatsList />
      <Row className="pb-md-1" as="section">
        <ChallengesTable />
        <DashboardEvaluatorsList />
      </Row>
      <Row as="section">
        <HubCompanyDashboardSolutionsNiche />
        <HubCompanyDashboardSolutionsCity />
      </Row>
    </DashboardWrapper>
  )
}
