import styled from 'styled-components'

export const SwiperDiv = styled.div`
  background-color: var(--background);
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  top: 0;
  align-items: flex-end;
`

export const IdeaEvaluationSwiperHeader = styled.div`
  display: flex;
  padding-bottom: 0.25rem;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
`

export const IdeaEvaluationSwiperTitle = styled.div`
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IdeaEvaluationSwiperSectionIndex = styled.div`
  display: flex;
  font-size: 14px;
  color: var(--primary);
  font-weight: bold;
  justify-content: center;
`

export const IdeaEvaluationWrapper = styled.div`
  position: fixed;
  margin-left: 3rem;
  margin-top: -6rem;
  right: 0;
  height: 100%;
  z-index: 40;
  width: 392px;
  background-color: var(--background);
  @media screen and (max-width: 768px) {
    display: contents;
  }
`

export const IdeaEvaluationHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  height: auto;
`

export const IdeaEvaluationTitle = styled.b`
  font-size: 28px;
  line-height: 48px;
`

export const IdeaEvaluationSubtitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  margin-left: 4px;
  line-height: 19px;
`

export const IdeaEvaluationGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IdeaEvaluationGroupBottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 1rem 0 0.5rem;
`

export const IdeaEvaluationGroupRightItem = styled.div`
  padding-left: 1rem;

  .total-items {
    margin-bottom: -4px;
  }
`

export interface PointsProps {
  pointsFontSize: string
}

export const PointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  height: 25px;
  width: 88px;
`

export const PointsDiv = styled.div<PointsProps>`
  font-size: ${(props) => props.pointsFontSize};
`

export const IdeaEvaluationSections = styled.div`
  height: calc(100% - 300px);
  overflow: scroll;
  padding: 0 2rem 1.5rem;
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--primary);
    top: 11px;
    width: 3rem;
    height: 3rem;
    font-weight: 800;
    &::after {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
      padding: 0rem 0.5rem; 
    }
`
