import React from 'react'

import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { HubCompanyDashboardStatsCardProps } from './contracts'
import { DashboardCardLabel } from 'domain/feature/hub/dashboard/generalStats/dashboardCardLabel'
import {
  CardsBox,
  StatsCount,
  StatsCountBox,
  StatsDescription,
  StatsInfo,
  StatsInfoBox,
  StatsTitle
} from 'domain/feature/hub/dashboard/generalStats/style'
import { DashboardItemBox } from 'main/pages/hub/dashboard/style'
import { trans } from 'sharedKernel/i18n'

export const HubCompanyDashboardStatsCard = (statsProps: HubCompanyDashboardStatsCardProps): JSX.Element => {
  const infoIcon: [IconPrefix, IconName] = ['fas', 'info']

  return (
    <CardsBox>
      <DashboardItemBox>
        <DashboardCardLabel
          icon={statsProps.cardLabel.icon}
          color={statsProps.cardLabel.color}
          background={statsProps.cardLabel.background}
          size={statsProps.cardLabel.size}
        />
        <StatsInfoBox>
          <StatsTitle className="fs-6">{trans(statsProps.name)}</StatsTitle>
          <StatsCountBox>
            <StatsCount>{statsProps.count}</StatsCount>
            <StatsDescription>{trans(statsProps.description)}</StatsDescription>
          </StatsCountBox>
        </StatsInfoBox>
        <OverlayTrigger overlay={<Tooltip>{trans(statsProps.info)}</Tooltip>}>
          <StatsInfo>
            <FontAwesomeIcon fixedWidth={true} icon={infoIcon} size={'sm'} />
          </StatsInfo>
        </OverlayTrigger>
      </DashboardItemBox>
    </CardsBox>
  )
}
