import { SingleActionAlertProps, showSingleActionPopUp } from './singleAction'
import { DoubleActionAlertProps, showDoubleActionPopUp } from './doubleAction'
import { evaluationModal, EvaluationModalProps } from './evaluationModal'
import { showTemplatePopup, TemplatePopupProps } from './templateModal'
import { assignModal, AssignModalProps } from './assignModal'

export const showModal = (
  type: string,
  info: SingleActionAlertProps | DoubleActionAlertProps | EvaluationModalProps | AssignModalProps | TemplatePopupProps
): void => {
  switch (type) {
    case 'single':
      showSingleActionPopUp(info as SingleActionAlertProps)
      break
    case 'double':
      showDoubleActionPopUp(info as DoubleActionAlertProps)
      break
    case 'template':
      showTemplatePopup(info as TemplatePopupProps)
      break
    case 'evaluationModal':
      evaluationModal(info as EvaluationModalProps)
      break
    case 'assignModal':
      assignModal(info as AssignModalProps)
      break
    default:
      break
  }
}
