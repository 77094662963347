import React from 'react'
import { DateInput } from './styles'

interface DateFieldProps {
  name: string
  questionPlaceholder: string
  dateFormat?: string
  locale?: string
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  selectedDate?: Date | null
  isRequired?: boolean
  isInvalid?: boolean
  notValidFieldMessage?: string
  onChange?: React.EventHandler<any>
}

export const DateField = ({
  name,
  questionPlaceholder,
  dateFormat = 'dd/MM/yyyy',
  locale = 'pt-BR',
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
  selectedDate = null,
  isRequired = false,
  isInvalid = false,
  notValidFieldMessage,
  onChange = () => {
  }
}: DateFieldProps): JSX.Element => {
  return (
    <>
    <DateInput
      name={name}
      className='form-control'
      placeholderText={questionPlaceholder}
      dateFormat={dateFormat}
      locale={locale}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      selected={selectedDate}
      onChange={onChange}
      required={isRequired}
      invalid={isInvalid}
      />
      <div className="d-flex justify-content-between">
        {isInvalid && (
          <small style={{ color: 'var(--error)', width: '100%', textAlign: 'left' }}>
            {notValidFieldMessage}
          </small>
        )}
      </div>
    </>
  )
}
