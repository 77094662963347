import styled from 'styled-components'

export interface LabelInputFileProps {
  isClickable?: boolean
}

export const LabelInputFile = styled.label<LabelInputFileProps>`
  display: flex;
  padding: 10px 10px 10px 15px;
  width: fit-content;
  text-align: center;
  align-items: center;
  margin: 0;
  color: var(--primary);
  background-color: #e5d5ff;
  border-radius: 4px;
  input {
    visibility: hidden;
  }

  :hover {
    cursor: ${(p: LabelInputFileProps) => (p.isClickable === true ? 'pointer' : 'auto')};
    ${(p: LabelInputFileProps) => (p.isClickable === true ? 'color: #0d6efd !important' : 'red')};
  }
`
