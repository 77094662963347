
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { matchPath, PathMatch } from 'react-router'
import { BreadcrumbData } from 'use-react-router-breadcrumbs'

import { hubRoutes } from 'routes/hub'
import { IHubRouteBuilder } from 'routes/hub/contract'

export const filterBreadcrumbs = (breadcrumbs: BreadcrumbData[]): any => {
  const filteredBreadcrumbs: any = []

  breadcrumbs.forEach((crumb) => {
    hubRoutes.every((route: IHubRouteBuilder) => {
      let match
      if ((match = getCrumbMatchWithSystemRoute(route, crumb)) !== null) {
        if (checkRouteIsNotAlreadyACrumb(filteredBreadcrumbs, route)) {
          filteredBreadcrumbs.push({ name: route.breadcrumb, path: `/hub${String(match.pathname)}` })

          return false
        }
      }

      return true
    })
  })

  return filteredBreadcrumbs
}

const getCrumbMatchWithSystemRoute = (route: any, crumb: any): PathMatch | null => {
  return matchPath({ path: route.path, caseSensitive: false, end: false }, crumb.location.pathname.replace('/hub', ''))
}

const checkRouteIsNotAlreadyACrumb = (filteredBreadcrumbs: any, route: IHubRouteBuilder): boolean => {
  return !filteredBreadcrumbs.find(breadcrumb => { return breadcrumb.name === route.breadcrumb })
}
