import axios from 'axios'
import { cookies } from 'sharedKernel/cookies/behavior'
import { showToast } from 'components/toast'
import { store } from 'store'
import { logout } from 'sharedKernel/auth'
import { API_BASE_URL } from 'sharedKernel/constants'

export const api = axios.create({
  baseURL: API_BASE_URL
})

api.interceptors.request.use(
  async (config) => {
    const cookieData = cookies.get('_cred')
    const token = `Bearer ${String((cookieData ?? '""')?.data?.token)}`
    if (config.headers !== undefined || config.params === undefined) {
      if (token !== 'Bearer undefined') {
        // @ts-expect-error
        config.headers.Authorization = token
      }
      // @ts-expect-error
      config.headers['Current-Language'] = localStorage.getItem('language') ?? 'pt-BR'
    }
    return config
  },
  async (error) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const responseData = error.response.data
    if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
      const errorMessage = responseData.message
      switch (error.response.status) {
        case 400:
        case error.response.status >= 402 && error.response.status <= 451:
          showToast(
            'error',
            `
          ${String(errorMessage)}
          `
          )
          break
        case 401:
          if (cookies.get('_cred') != null) {
            store.dispatch(logout())
            showToast('error', 'Por motivos de segurança você foi deslogado! Efetue o login e tente novamente')
          } else {
            showToast('error', responseData.errorMessage)
          }
          break
        case 500:
          showToast('error', 'Ocorreu um erro, tente mais tarde')
          break
      }
    }

    return Promise.reject(error)
  }
)
