export const handleApplyAction = (isAuthenticated: boolean, userPermissions: any, challengeId: string): string => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isAuthenticated && userPermissions?.features?.some((feature) => feature.code === 'idea')) {
    return getApplyRoute(challengeId)
  }
  return getChallengeRoute(challengeId)
}

export const getApplyRoute = (challengeId: string): string => {
  return `/hub/startup/challenge/${challengeId}`
}

export const getChallengeRoute = (challengeId: string): string => {
  return `/challenge/${challengeId}`
}
