import _ from 'lodash'

import { IFormFieldProperties } from '../contracts'
import { validateEmail } from './email'
import { validateNumber } from './number'
import { validateMobile } from './mobile'
import { validateCEP } from './cep'
import { validatePhone } from './phone'
import { IPasswordFormProperties } from 'domain/feature/hub/user-config/ChangePasswordForm/store'

export const invalidMessages = {
  emptyField: 'O campo não pode ser vazio'
}

export const ifNotEmptyValidateEmail = (email: IFormFieldProperties): boolean => {
  if (!isEmptyField(email)) {
    return handleEmailValidity(email)
  }
  return false
}

export const handleEmailValidity = (email: IFormFieldProperties): boolean => {
  return validateEmail(email)
}

export const ifNotEmptyValidateMobile = (mobile: IFormFieldProperties): boolean => {
  if (!isEmptyField(mobile)) {
    return handleMobileValidity(mobile)
  }
  return false
}

export const handleMobileValidity = (mobile: IFormFieldProperties): boolean => {
  return validateMobile(mobile)
}

export const ifNotEmptyValidatePhone = (phone: IFormFieldProperties): boolean => {
  if (!isEmptyField(phone)) {
    return handlePhoneValidity(phone)
  }
  return false
}

export const handlePhoneValidity = (phone: IFormFieldProperties): boolean => {
  return validatePhone(phone)
}

export const ifNotEmptyValidateCEP = (cep: IFormFieldProperties): boolean => {
  if (!isEmptyField(cep)) {
    return handleCEPValidity(cep)
  }
  return false
}

export const handleCEPValidity = (cep: IFormFieldProperties): boolean => {
  return validateCEP(cep)
}

export const ifNotEmptyValidateNumber = (number: IFormFieldProperties): boolean => {
  if (!isEmptyField(number)) {
    return handleNumberValidity(number)
  }
  return false
}

export const handleNumberValidity = (number: IFormFieldProperties): boolean => {
  return validateNumber(number)
}

export const isEmptyField = (field: IFormFieldProperties): boolean => {
  if (!field.isRequired) {
    return false
  }

  if (_.isEmpty(String(field.value))) {
    field.isInvalid = true
    field.message = invalidMessages.emptyField
    return true
  }

  field.isInvalid = false
  field.message = ''
  return false
}

export const setValid = (field: IFormFieldProperties | IPasswordFormProperties): void => {
  field.isInvalid = false
  field.message = ''
}

export const setInvalid = (field: IFormFieldProperties | IPasswordFormProperties, message: string): void => {
  field.isInvalid = true
  field.message = message
}
