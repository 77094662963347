import styled from 'styled-components'

export const EvaluatorInviteWrapper = styled.div`
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: auto auto min-content;
    gap: 1rem;
`

export const EvaluatorInviteButton = styled.div`
    padding-top: 33px;
`

export const ButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: end;
    
    @media (max-width: 577px) {
        flex-direction: column;
        justify-content: center;
        align-items: end;
    }
`
