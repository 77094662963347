import { IFormFieldProperties } from '../../contracts'
import { setValid, setInvalid } from '..'
import { regexPatterns } from 'sharedKernel/regex'

export const invalidMessages = {
  cep: 'CEP inválido'
}

const CEP_VALID_SIZE = 8

export const validateCEP = (cep: IFormFieldProperties): boolean => {
  if (cep.value.replace(regexPatterns.clearMaskedNumbers, '').length < CEP_VALID_SIZE) {
    setInvalid(cep, invalidMessages.cep)
    return false
  }

  setValid(cep)
  return true
}
