import { Accordion } from 'react-bootstrap'
import styled from 'styled-components'

export const AccordionHeader = styled(Accordion.Header)`
  max-width: 100%;
  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const AccordionTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100w;
`
export const AccordionDate = styled.div`
  width: auto;
`
export const AccordionIcon = styled.a`
  cursor:pointer;
`
