import { DynamicFormField } from '../../contracts'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../../store'
import { Option } from 'iebt-zing-storybook/dist/components/Fields/Inputs/Input'
import { Input } from 'iebt-zing-storybook'
import { getValue, setValue, getDescription } from '../../actions/dataHandler'
import React, { useEffect } from 'react'

export const ApplicationFormInputSelect = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const isLaunched = applicationFormState.isLaunched ?? false
  const options: Option[] = []
  question.options?.map((option) => {
    options.push({
      value: option.value,
      label: option.value
    })
    return option
  })

  useEffect(() => {
    const currentValue = getValue(applicationFormState, question.id)
    if (currentValue == null || currentValue === '') {
      const itemDefaultValue = options[0].value
      const newApplicationFormState = setValue(applicationFormState, question.id, itemDefaultValue, question.isRequired)
      store.dispatch(changeApplicationForm(newApplicationFormState))
    }
  }, [])

  return (
    <div className="mb-3" key={question.id}>
      <Input
        code={question.type}
        type={question.type}
        name={question.name}
        disabled={isLaunched}
        description={getDescription(question.description, question.isRequired)}
        label={question.name}
        options={options}
        isRequired={question.isRequired}
        isInvalid={question.isInvalid}
        notValidFieldMessage={question.notValidFieldMessage}
        value={getValue(applicationFormState, question.id) as string}
        onClick={() => {
          const element = event?.target as HTMLInputElement
          const newApplicationFormState = setValue(applicationFormState, question.id, element.value, question.isRequired)
          store.dispatch(changeApplicationForm(newApplicationFormState))
        }}
      />
    </div>
  )
}
