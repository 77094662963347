export const translateStatus = (status: string): string => {
  switch (status) {
    case 'created':
      return 'Evaluating'
    case 'evaluated':
      return 'Evaluated'
    case 'canceled':
    default:
      return 'Pending'
  }
}
