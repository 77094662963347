/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import { InputUploadSingle } from 'components/inputs/Upload/Single'
import { IFile } from '../../constants'
import { showToast } from '../../../../../../../../components/toast'
import { v4 as uuidv4 } from 'uuid'
import { ErrorLabel, FormItem } from '../../style'
import { trans } from 'sharedKernel/i18n'

export function BuildFile(item: IFile, key: number | string): any {
  const [state, setState] = useState<IFile>(item)

  const isVideoFile = item.acceptedFile.includes('video')
  const isFirstImage = item.id === 'coverImage'
  const allowedTypes = ['image/jpeg', 'image/png', 'image/png', 'image/jpg', 'image/gif', 'image/tiff', 'image/psd', 'image/eps', 'image/ai',
    'image/indd', 'image/raw', 'image/cdr', 'image/tif', 'image/bmp', 'image/cr2', 'image/nef', 'image/orf', 'image/sr2', 'video/wma',
    'video/mp3', 'video/mp4', 'video/wav', 'video/ogm', 'video/wmv', 'video/mpg', 'video/mpeg', 'video/webm', 'video/ogv', 'video/mov', 'video/asx',
    'video/avi', 'video/m4v']
  return (
    <FormItem key={key} style={{ marginTop: isFirstImage ? '20px' : '0px', marginBottom: '-10px' }}>
      <InputUploadSingle
        fieldName={state.id}
        acceptFiles={state.acceptedFile}
        labels={{
          title: String(state.label),
          addFile: 'Adicionar Arquivo',
          maxSize: 'Tamanho máximo',
          description: isVideoFile
            ? `Selecione um vídeo para ser exibido na visualização do desafio. O formato ideal é de ${state.recommendedFormat}`
            : `Selecione uma imagem para ser exibida no Market Place. O formato ideal é de ${state.recommendedFormat}`
        }}
        maxSize={state.maxSize}
        onChange={(event) => {
          const element = event?.target as HTMLInputElement
          [].slice.call(element.files).forEach((file: File) => {
            if (isVideoFile) {
              if (file.size > 50000000) {
                showToast('warning', 'Cada vídeo deve ter um tamanho máximo de 50 MB')
                return
              }
            } else {
              if (file.size > 5242880) {
                showToast('warning', 'Cada arquivo deve ter um tamanho máximo de 5 MB')
                return
              }
            }

            if (!allowedTypes.includes(file.type)) {
              showToast('warning', `Arquivo ${file.name} não é permitido.`)
              return
            }

            state.archive = {
              id: `${String(uuidv4())}_idScreen`,
              path: URL.createObjectURL(file),
              isDeleted: false,
              name: file.name.replace(/ /g, '_'),
              size: file.size,
              mimetype: file.type,
              extension: null,
              type: state.type,
              content: null,
              sourceFile: file
            }

            setState({ ...state })
            if (state.updateFieldState !== undefined && state.updateFieldState !== null) {
              state.updateFieldState(state)
            }
          })
        }}
        isRequired={state.isRequired ?? false}
        isUploaded={!state.archive.isDeleted}
        name={state.archive?.name ?? ''}
        path={state.archive?.path ?? ''}
        onUploadedClick={() => {
          if (!confirm('Tem certeza que deseja excluir este arquivo')) {
            return
          }

          if (state.removed === undefined) {
            state.removed = []
          }

          if (!state.archive.id.includes('_idScreen')) {
            state.removed.push(state.archive.id)
          }

          state.archive.isDeleted = true
          setState({ ...state })
        }}
        dataCy={item.dataCy}
      />
      <ErrorLabel className={`errors-${state.id}`}></ErrorLabel>
    </FormItem>
  )
}
