/* eslint-disable no-restricted-globals */
import { IField } from '../../constants'
import { buildValidations, handleValidation } from '../../behaviors/validation'
import React from 'react'
import { Input } from 'iebt-zing-storybook'
import { ErrorLabel, FormItem } from '../../style'

export function BuildInput(item: IField, key: number | string): any {
  const hasMaxLength = item.maxLength !== undefined
  switch (item.type) {
    case 'youtubeLink':
      return (
        <FormItem hasMaxLength={hasMaxLength} key={key}>
          <Input
            id={item.id}
            label={item.label}
            description={item.description}
            code={'text'}
            type="url"
            value={item.value}
            isRequired={item.isRequired ?? false}
            maxLength={item.maxLength}
            className={buildValidations(item.validations)}
            onChange={() => {
              const element = event?.target as HTMLInputElement
              item.value = element.value
              if (item.updateFieldState !== undefined) {
                item.updateFieldState(item)
              }
              handleValidation(element, 'save')
            }}
            data-cy={item.dataCy}
            disabled={item.disabled}
          />
          <ErrorLabel className={`errors-${item.id}`}></ErrorLabel>
        </FormItem>
      )
    case 'textarea':
      return (
        <FormItem hasMaxLength={hasMaxLength} key={key}>
          <Input
            id={item.id}
            code={item.type}
            type={item.type}
            label={item.label}
            value={item.value}
            description={item.description}
            isRequired={item.isRequired ?? false}
            maxLength={item.maxLength}
            className={buildValidations(item.validations)}
            onChange={() => {
              const element = event?.target as HTMLInputElement
              item.value = element.value
              if (item.updateFieldState !== undefined) {
                item.updateFieldState(item)
              }
              handleValidation(element, 'save')
            }}
            data-cy={item.dataCy}
            disabled={item.disabled}
          />
          <ErrorLabel className={`errors-${item.id}`}></ErrorLabel>
        </FormItem>
      )
    case 'text':
    default:
      return (
        <FormItem hasMaxLength={hasMaxLength} key={key}>
          <Input
            id={item.id}
            className={buildValidations(item.validations)}
            value={item.value ?? ''}
            placeholder={item.namePlaceholder}
            label={item.label}
            maxLength={item.maxLength}
            isRequired={item.isRequired ?? false}
            description={item.description}
            onChange={() => {
              const element = event?.target as HTMLInputElement
              item.value = element.value
              if (item.updateFieldState !== undefined) {
                item.updateFieldState(item)
              }
              handleValidation(element, 'save')
            }}
            data-cy={item.dataCy}
            disabled={item.disabled}
          />
          <ErrorLabel className={`errors-${item.id}`}></ErrorLabel>
        </FormItem>
      )
  }
}
