import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { useSelector } from 'react-redux'
import { store, TReducers } from 'store'
import { UserTerms } from 'components/UserTerms'
import { updateRegisterForm } from '../store'
import { handleFormValidity } from './validations'
import { postProvider, PostFormat } from 'sharedKernel/api'
import { createRegisterRequest } from './behavior'
import { showToast } from 'components/toast'
import { ROUTES } from 'sharedKernel/constants'
import { useNavigate } from 'react-router'

export interface RegisterActionsProps {
  registerUrl: string
  userType: string
}

export const RegisterActions = ({ registerUrl, userType }: RegisterActionsProps): JSX.Element => {
  const registerFormState = useSelector((state: TReducers) => state.hotsiteIHotsiteRegisterFormState)
  const { mutate: subscribeStartup } = postProvider()
  const navigate = useNavigate()

  const labels = {
    subscribe: trans('Cadastrar'),
    login: trans('Login'),
    alreadyHaveAccount: trans('Já tem cadastro? Faça o login.'),
    registerSuccess: trans('Cadastro realizado com sucesso!')
  }

  const handleChange = (value, key): void => {
    registerFormState[key] = value
    store.dispatch(updateRegisterForm(registerFormState))
  }

  const handleSave = (): void => {
    const registerPost: PostFormat = createRegisterRequest(registerFormState.form, registerUrl, registerFormState.acceptUserTerms)

    subscribeStartup(registerPost, {
      onSuccess: () => {
        showToast('success', labels.registerSuccess)
        navigate(ROUTES.USER_AUTH.LOGIN)
      },
      onError: (error) => {
        showToast('error', error.response.data.message)
      }
    })
  }

  return (
    <>
      <Row>
        <Col lg={true} className='mt-2'>
          <UserTerms
            userType={userType}
            checked={registerFormState?.acceptUserTerms}
            onCheck={(event) => {
              handleChange(event.target.checked, 'acceptUserTerms')
              store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
            }}
          />
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <BaseButton onClick={() => handleSave()} disabled={!registerFormState.isFormValid} size="big" btnContent={labels.subscribe} variant="primary" />
        <hr className='mt-4' />
      </Row>
      <Row className='px-2 mt-2'>
        <span className='mb-4'>{labels.alreadyHaveAccount}</span>
        <BaseButton onClick={() => navigate(ROUTES.USER_AUTH.LOGIN)} size="big" btnContent={labels.login} variant="risk-bg" />
      </Row>
    </>
  )
}
