import React from 'react'
import { IdeaAllTile } from '../IdeaAllTile'
import { IdeaContainerBox } from './styles'
export const IdeaContainer = (ideas: any): JSX.Element => {
  return (
    <IdeaContainerBox>
      {ideas.ideas.map((idea, index) => {
        return <IdeaAllTile idea={idea} key={index} />
      })}
    </IdeaContainerBox>
  )
}
