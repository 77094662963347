import React from 'react'
import { trans } from 'sharedKernel/i18n'

export const passwordAcceptance = (): JSX.Element => {
  const labels = {
    description: trans('Sua senha deve conter no mínimo'),
    minLength: trans('8 caracteres'),
    upper: trans('Uma letra maiúscula'),
    lower: trans('Uma letra minúscula'),
    number: trans('Um número'),
    special: trans('Um caracter especial')
  }
  return (
        <>
            <p>{labels.description}</p>
            <ul>
                <li>{labels.minLength}</li>
                <li>{labels.upper}</li>
                <li>{labels.lower}</li>
                <li>{labels.number}</li>
                <li>{labels.special}</li>
            </ul>
        </>
  )
}
