import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import {
  STATUS_COLUMN_INDEX
} from './contracts'
import { HubTable } from 'components/HubDefaultTable'
import { COMPANY_EVALUATION_INVITE_STATUS } from 'sharedKernel/constants'
import { CenterContent } from '../../../solutions/table/style'
import { AnimatedSortButtons } from 'components/HubDefaultTable/OrderByButton'
import { sortRows } from './behavior'

export interface HubCompanyEvaluatorsInviteTableProps {
  header: string[]
  rows: string[][]
}

export interface SortTable {
  statusAsc: boolean
  nameAsc: boolean
}
export interface OrderClick {
  statusClick: boolean
  nameClick: boolean
}
export const HubCompanyEvaluatorsInviteTable = ({ header, rows }: HubCompanyEvaluatorsInviteTableProps): JSX.Element => {
  const [sortTable, setSortTable] = useState<SortTable>({ statusAsc: false, nameAsc: false })
  const [orderClick, setOrderClick] = useState<OrderClick>({ statusClick: false, nameClick: true })

  const status = {}
  status[COMPANY_EVALUATION_INVITE_STATUS.ACCEPTED] = { color: 'green', label: trans('Aceito') }
  status[COMPANY_EVALUATION_INVITE_STATUS.INVITED] = { color: 'orange', label: trans('Convidado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.ERROR] = { color: 'red', label: trans('Erro') }
  status[COMPANY_EVALUATION_INVITE_STATUS.REFUSED] = { color: 'red', label: trans('Recusado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.EXPIRED] = { color: 'gray', label: trans('Expirado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.CANCELED] = { color: 'gray', label: trans('Cancelado') }
  const labels = {
    name: trans('Nome'),
    email: trans('E-mail'),
    status: trans('Status')
  }

  const tableHeader = (): any[] => {
    return [
      getCenteredContent(buildHeaderName()),
      getCenteredContent(labels.email),
      getCenteredContent(buildHeaderStatus())
    ]
  }

  const buildHeaderName = (): JSX.Element => {
    return (
      <>
        {labels.name}
        <div className='ml-1'>
          <AnimatedSortButtons orderByAsc={sortTable.nameAsc}
            onClick={() => {
              setSortTable({ ...sortTable, nameAsc: !sortTable.nameAsc })
              setOrderClick({ ...orderClick, nameClick: true, statusClick: false })
            }} />
        </div>
      </>
    )
  }

  const buildHeaderStatus = (): JSX.Element => {
    return (
      <>
        {labels.status}
        <div className='ml-1'>
          <AnimatedSortButtons orderByAsc={sortTable.statusAsc}
            onClick={() => {
              setSortTable({ ...sortTable, statusAsc: !sortTable.statusAsc })
              setOrderClick({ ...orderClick, statusClick: true, nameClick: false })
            }} />
        </div>
      </>
    )
  }

  const getCenteredContent = (content: any): any => {
    return (
      <CenterContent>
        {content}
      </CenterContent>
    )
  }
  const getColumn = (column: any, columnIndex: number): JSX.Element => {
    if (columnIndex === STATUS_COLUMN_INDEX) {
      return (
        <>
          <FontAwesomeIcon className="pr-2" color={status[column]?.color} icon={['fas', 'circle']} />
          {status[column]?.label}
        </>
      )
    }
    return column
  }

  const getRows = (): JSX.Element[][] => {
    const styledRows = rows.map(row => {
      return (
        row.map((column, columnIndex) => {
          return (
            getColumn(column, columnIndex)
          )
        })
      )
    })
    return filterRows(styledRows)
  }

  const filterRows = (styledRows: any): any => {
    let newRows = sortRows(styledRows, sortTable.nameAsc, orderClick.nameClick)

    if (orderClick.statusClick) {
      const acceptedEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.ACCEPTED].label)
      const invitedEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.INVITED].label)
      const errorEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.ERROR].label)
      const refusedEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.REFUSED].label)
      const expiredEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.EXPIRED].label)
      const canceledEvaluators = newRows.filter((row) => row[STATUS_COLUMN_INDEX].props.children[1] === status[COMPANY_EVALUATION_INVITE_STATUS.CANCELED].label)
      if (sortTable.statusAsc) {
        newRows = [...canceledEvaluators, ...expiredEvaluators, ...refusedEvaluators, ...errorEvaluators, ...invitedEvaluators, ...acceptedEvaluators]
      } else {
        newRows = [...acceptedEvaluators, ...invitedEvaluators, ...errorEvaluators, ...refusedEvaluators, ...expiredEvaluators, ...canceledEvaluators]
      }
    }
    return [...newRows]
  }

  const headerSize = ['33%', '42%', '25%']
  return (
    <HubTable header={tableHeader()} rows={getRows()} headerSize={headerSize} />
  )
}
