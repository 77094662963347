import React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper } from 'swiper/react'
import { Navigation, Mousewheel, Keyboard } from 'swiper'
import { CarouselStyle } from '../style'
import { CarouselWrapper } from '../swiperNav/style'
import './navStyle.css'

export interface SwiperWrapperProps {
  breakpoints: any
  arrowColor?: string
  sections?: React.ReactNode
}

export interface ICarouselNavItems {
  name: string
  itemHash: string
}

export const SwiperNav = ({
  breakpoints,
  arrowColor = 'var(--primary)',
  sections
}: SwiperWrapperProps): JSX.Element => {
  return (
    <CarouselWrapper>
      <CarouselStyle arrowColor={arrowColor} isMenu={true}>
        <Swiper
          keyboard={{
            enabled: true
          }}
          breakpoints={breakpoints}
          modules={[Mousewheel, Navigation, Keyboard]}
          navigation={true}
          allowSlideNext={true}
          mousewheel={true}
          grabCursor={true}
        >
          {sections}
        </Swiper>
      </CarouselStyle>
    </CarouselWrapper >
  )
}
