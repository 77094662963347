import styled from 'styled-components'

export const UserTermsCheckboxContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
`

export const UserTermsLink = styled.a`
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
`
