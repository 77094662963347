import styled from 'styled-components'

export const FormIdeaSectionName = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`
export const FormIdeaSections = styled.div`
  padding-top: 2rem;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  ::-webkit-scrollbar-track {
  background-color: var(--white);
  }
`

export const FormIdeaHeader = styled.div`
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 2;
  background-color: var(--white);
`
