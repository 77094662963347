import React from 'react'
import { ApplicationFormSectionFields, DynamicFormField, IHubChallengeBasicFormState } from 'store'
import { SwiperNavForm } from 'components/swiper/swiperNav/swiperNavForm'
import { DescriptedLabel, Input } from 'iebt-zing-storybook'
import { ICarouselNavItems } from 'components/swiper/swiperNav'
import { getDescription } from 'domain/feature/hub/startup/challenge/applicationForm/actions/dataHandler'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { CheckboxGroupItemProps } from 'iebt-zing-storybook/dist/components/Fields/Inputs/CheckboxGroup'
import { Option } from 'iebt-zing-storybook/dist/components/Fields/Inputs/Input'
import { RadioGroupItemProps } from 'iebt-zing-storybook/dist/components/Fields/Inputs/RadioGroup'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import { DateField } from 'components/inputs/date'
import { InputUploadSingle } from 'components/inputs/Upload/Single'
import { trans } from 'sharedKernel/i18n'
import { ApplicationFormWrapper, Hr, IdeaTitle, TitleWrapper } from 'sharedKernel/styledComponents/solutionFormComponents/styles'
import { FormPreviewHeader, InputCheckQuestionType, PreviewFormWrapperHeader, QuestionDiv, SectionTitle } from '../style'

interface FormStep {
  name?: string
  component: JSX.Element | undefined
}

interface FormPreviewProps {
  stateValues: IHubChallengeBasicFormState
}

export const FormPreview = ({ stateValues }: FormPreviewProps): JSX.Element => {
  const labels = {
    titleName: trans('Qual o nome da sua startup/empresa'),
    titleDescription: trans('Campo Obrigatório'),
    titleLabel: trans('Qual o nome da sua startup/empresa'),
    titlePlaceholder: trans('Insira o nome da sua startup/empresa aqui.'),
    addFile: trans('Adicionar arquivo'),
    maxSize: trans('Tamanho máximo'),
    emptySectionName: trans('Seção sem nome'),
    title: trans('Título'),
    section: trans('Nome da Seção'),
    question: trans('Pergunta'),
    option: trans('Opção')
  }

  const verifyIfElementIsEmpty = (value: string, type: string, order?: number): string => {
    let response
    if (order === undefined) {
      response = value === '' ? `O campo "${type}" se encontra vazio` : value
    } else {
      response = value === '' ? `O campo "${type} ${order + 1}" se encontra vazio` : value
    }
    return response
  }

  const PreviewFormInputSelect = (question: DynamicFormField): JSX.Element => {
    const options: Option[] = []
    question.options?.map((option) => {
      options.push({
        value: option.value,
        label: verifyIfElementIsEmpty(option.value, labels.option, option.order)
      })
      return option
    })
    return (
      <QuestionDiv className="mb-3" key={question.id} questionName={question.name}>
        <Input
          code={question.type}
          type={question.type}
          name={question.name}
          description={getDescription(question.description, question.isRequired)}
          label={verifyIfElementIsEmpty(question.name, labels.question, question.order)}
          options={options}
          isRequired={question.isRequired}
        />
      </QuestionDiv>
    )
  }

  const PreviewFormInputCheckbox = (question: DynamicFormField): JSX.Element => {
    const items: CheckboxGroupItemProps[] = []

    question.options?.map((option, key) => {
      items.push({
        code: 'checkbox',
        name: option.value,
        label: verifyIfElementIsEmpty(option.value, labels.option, option.order),
        value: option.value,
        id: option.id
      })
      return option
    })

    return (
          <InputCheckQuestionType className="mb-3" key={question.id} questionName={question.name}>
            <Input
              code={question.type}
              type={question.type}
              name={question.name}
              description={getDescription(question.description, question.isRequired)}
              label={verifyIfElementIsEmpty(question.name, labels.question, question.order)}
              items={items}
              isRequired={question.isRequired}
            />
          </InputCheckQuestionType>
    )
  }

  const PreviewFormInputRadio = (question: DynamicFormField): JSX.Element => {
    const radioItems: RadioGroupItemProps[] = []
    question.options?.map((option) => {
      radioItems.push({
        code: 'radio',
        name: option.value,
        label: verifyIfElementIsEmpty(option.value, labels.option, option.order),
        value: option.value
      })
      return option
    })
    return (
          <InputCheckQuestionType className="mb-3" key={question.id} questionName={question.name}>
            <Input
              code={question.type}
              type={question.type}
              name={question.name}
              description={getDescription(question.description, question.isRequired)}
              label={verifyIfElementIsEmpty(question.name, labels.question, question.order)}
              radioItems={radioItems}
              isRequired={question.isRequired}
            />
          </InputCheckQuestionType>
    )
  }

  const PreviewFormInputDate = (question: DynamicFormField): JSX.Element => {
    return (
      <QuestionDiv className="mb-3" key={question.id} questionName={question.name}>
        <DescriptedLabel boldText={question.isRequired}>
          <span>
            {verifyIfElementIsEmpty(question.name, labels.question, question.order)}
            <Tippy
              content={getDescription(question.description, question.isRequired)}
              followCursor={true}
              plugins={[followCursor]}
              animation="scale"
              arrow={false}
              theme="primary"
            >
              <span>
                {question.isRequired && <span style={{ color: 'red' }}> *</span>}
              </span>
            </Tippy>
          </span>
        </DescriptedLabel>
        {
          <DateField
              name={question.name}
              questionPlaceholder={question.description}
            />
        }
      </QuestionDiv>
    )
  }

  const PreviewFormInputUpload = (question: DynamicFormField): JSX.Element => {
    return (
          <QuestionDiv className="mb-3" key={question.id} questionName={question.name}>
            <InputUploadSingle
              fieldName={'applicationFormFile'}
              acceptFiles={'*'}
              labels={{
                title: (verifyIfElementIsEmpty(question.name, labels.question, question.order)),
                addFile: labels.addFile,
                maxSize: `${labels.maxSize}:`,
                description: getDescription(question.description, question.isRequired)
              }}
              maxSize={'50 MB'}
              isRequired={question.isRequired} onChange={function (event: any): void {
                throw new Error('Function not implemented.')
              } } isUploaded={false} onUploadedClick={function (event: any): void {
                throw new Error('Function not implemented.')
              } }/>
          </QuestionDiv>
    )
  }

  const PreviewFormInput = (question: DynamicFormField): JSX.Element => {
    switch (question.type) {
      case 'select':
        return <PreviewFormInputSelect {...question} />
      case 'checkbox-group':
        return <PreviewFormInputCheckbox {...question} />
      case 'radio-group':
        return <PreviewFormInputRadio {...question} />
      case 'date':
        return <PreviewFormInputDate {...question} />
      case 'upload':
        return <PreviewFormInputUpload {...question} />
      default:
        // eslint-disable-next-line no-case-declarations
        let size
        if (question.size != null && question.size !== 0 && question.type === 'text') {
          size = question.size
        }
        if (size === undefined && question.type === 'text') {
          size = 1024
        }

        return (
          <QuestionDiv className="mb-4" key={question.id} questionName={question.name}>
            {
              <Input
                code={question.type === 'text' ? 'textarea' : question.type}
                type={question.type}
                name={question.name.replace(/"/g, "'")}
                label={verifyIfElementIsEmpty(question.name, labels.question, question.order)}
                placeholder={question.description}
                maxLength={size}
                isRequired={question.isRequired}
              />
            }
          </QuestionDiv>
        )
    }
  }

  const buildSteps = (sections: ApplicationFormSectionFields[]): {formSteps: FormStep[], carouselNavItems: ICarouselNavItems[]} => {
    const carouselNavItems: ICarouselNavItems[] = []
    const formSteps: FormStep[] = []

    sections.forEach((section, sectionIndex) => {
      section.id = `PreviewSection${sectionIndex}`
      carouselNavItems.push({
        name: String(section.name) === '' ? labels.emptySectionName : String(section.name),
        itemHash: section.id
      })
      formSteps.push({
        name: section.id ?? '',
        component: (
          <div className='mt-4' key={`${sectionIndex}section`}>
            <div className='d-flex flex-column'>
              <SectionTitle sectionName={String(section.name)}>
                {verifyIfElementIsEmpty(String(section.name), labels.section)}
              </SectionTitle>
              <Hr />
            </div>
            {section.fields?.map((question, index) => {
              const questionWithSectionId = { ...question, sectionId: section.id }
              return <PreviewFormInput key={`${index}field`} {...questionWithSectionId} />
            })}
          </div>
        )
      })
      return section
    })

    return {
      carouselNavItems,
      formSteps
    }
  }

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
  }

  const { carouselNavItems, formSteps } = buildSteps(stateValues.applicationForm.sections)

  return (
    <ApplicationFormWrapper>
      <FormPreviewHeader>
        <TitleWrapper>
          <PageTitle titleName={stateValues.config.title}>
            {verifyIfElementIsEmpty(stateValues.config.title, labels.title)}
          </PageTitle>
        </TitleWrapper>
        <PreviewFormWrapperHeader>
          {
            <SwiperNavForm breakpoints={buildBreakpoints()} carouselNavItems={carouselNavItems} offset={-220} containerId='PreviewModal'/>
          }
        </PreviewFormWrapperHeader>
      </FormPreviewHeader>
      <IdeaTitle>
        <Input
          code={'text'}
          type={'text'}
          name={labels.titleName}
          description={labels.titleDescription}
          label={labels.titleLabel}
          placeholder={labels.titlePlaceholder}
          maxLength={256}
        />
      </IdeaTitle>
      {formSteps.map((step, stepIndex) => {
        return (
          <section id={step.name} key={stepIndex}>
            {step.component}
          </section>
        )
      })}
    </ApplicationFormWrapper>
  )
}
