import React from 'react'

import { LabeledCheckbox } from '../LabeledCheckbox'
import { GetFormat, useGet } from 'sharedKernel/api'
import { UserTermsLink } from './style'
import { trans } from 'sharedKernel/i18n'
import { showModal } from 'components/popup'

export interface UserTermsProps {
  checked: boolean
  onCheck: (event: any) => void
  userType: string
}

export const UserTerms = ({ checked, onCheck, userType }: UserTermsProps): JSX.Element => {
  const userTermsRequest: GetFormat = {
    url: `v1/system/user/terms/${userType}`
  }

  const { data: userTerms, isSuccess } = useGet<any>(userTermsRequest, 'user-terms')

  const labels = {
    userTermsAgreement: trans('Concordo com os '),
    userTerms: trans('termos de uso e termos de privacidade'),
    userTermsTitle: trans('Termos de uso'),
    failureGetUserTerms: trans('Termos de uso indisponível, tente novamente mais tarde.')
  }

  const getUserTermsLink = (): JSX.Element => {
    return (
      <>
        <span>{labels.userTermsAgreement}</span>
        <UserTermsLink
          onClick={() => {
            renderUserTermsModal()
          }}>
          {labels.userTerms}
        </UserTermsLink>
      </>
    )
  }

  const renderUserTermsModal = (): void => {
    showModal('single', {
      title: labels.userTermsTitle,
      message: <div dangerouslySetInnerHTML={{ __html: getUserTermsContent() }} />
    })
  }

  const getUserTermsContent = (): string => {
    if (!isSuccess) {
      return labels.failureGetUserTerms
    }

    return userTerms?.data?.content
  }

  return (
    <>
      <LabeledCheckbox
        id="userTerms"
        checked={checked}
        label={getUserTermsLink()}
        onChange={(event) => {
          onCheck(event)
        }}
      />
    </>
  )
}
