import { DateTime } from 'luxon'

export const dateFormater = (date: string, format: string = 'dd/MM'): string => {
  const luxonDate = DateTime.fromISO(date)

  if (!luxonDate.isValid) {
    return '-'
  }

  return luxonDate.toFormat(format)
}

export const isStartDateGreaterThanCurrentDate = (startDate: string): boolean => {
  const formattedStartDate = DateTime.fromISO(startDate)
  const today = DateTime.now()

  return today < formattedStartDate
}
export const isCurrentDateBetweenDates = (startDate: string, endtDate: string): boolean => {
  const formattedStartDate = DateTime.fromISO(startDate)
  const formattedEndDate = DateTime.fromISO(endtDate)
  const today = DateTime.now()

  return formattedStartDate <= today && today <= formattedEndDate
}

export const isCurrentDateBiggerThanDeadline = (deadline: string): boolean => {
  const formattedDeadline = DateTime.fromISO(deadline).plus({ days: 1 })
  const today = DateTime.now()

  return today > formattedDeadline
}

export const formatDateToDayYear = (date: string): string => {
  return DateTime.fromISO(date).setLocale('pt-BR').toFormat("dd 'de' LLLL")
}

export const getUnnabreviatedDate = (date: string): string => {
  return DateTime.fromISO(date).setLocale('pt').toLocaleString(DateTime.DATE_FULL)
}
