import styled from 'styled-components'

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`

export const EvaluationLabel = styled.span`
  color: var(--${props => props.color});
`
export const CellLink = styled.a`
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  max-width: 100%;
`
export const IdeaTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: normal;
  max-width: 100%;
`
