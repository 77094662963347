import { DashboardData } from './contracts'

export const setNewData = (evaluatorStatsList: any[], data: DashboardData): any[] => {
  return setProgressBar(setTotalValue(evaluatorStatsList, data), data)
}

export const setTotalValue = (evaluatorStatsList: any[], data: DashboardData): any[] => {
  evaluatorStatsList[0].progressBarList.totalValue = data.data.sent
  evaluatorStatsList[1].progressBarList.totalValue = data.data.accepted
  evaluatorStatsList[2].progressBarList.totalValue = data.data.evaluating

  return evaluatorStatsList
}

export const setProgressBar = (evaluatorStatsList: any[], data: DashboardData): any[] => {
  evaluatorStatsList[0].progressBarList.progressValue = setPercent(data.data.sent, data.data.sent)
  evaluatorStatsList[1].progressBarList.progressValue = setPercent(data.data.accepted, data.data.sent)
  evaluatorStatsList[2].progressBarList.progressValue = setPercent(data.data.evaluating, data.data.sent)

  return evaluatorStatsList
}

const setPercent = (value: number, total: number): number => {
  const percent = Math.trunc(value * 100 / total)
  return percent
}
