import { DynamicFormField } from '../../contracts'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../../store'
import { CheckboxGroupItemProps } from 'iebt-zing-storybook/dist/components/Fields/Inputs/CheckboxGroup'
import { getDescription, getValue, setValue } from '../../actions/dataHandler'
import { Input } from 'iebt-zing-storybook'
import React from 'react'
import { showToast } from 'components/toast'
import { InputWrapper } from '../invalidInput/style'

export const ApplicationFormInputCheckbox = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)

  const isLaunched = applicationFormState.isLaunched ?? false

  const items: CheckboxGroupItemProps[] = []

  const changeValue = (event, id): void => {
    const element = event?.target as HTMLInputElement
    let currentValue = getValue(applicationFormState, question.id) ?? '{}'
    if (currentValue === '' || currentValue === undefined || currentValue === null) {
      currentValue = '{}'
    }
    const jsonValue = JSON.parse(currentValue as string)

    let value = ''
    if (element.checked) {
      value = element.value
    }
    jsonValue[id] = value
    const newValue = JSON.stringify(jsonValue)

    const newApplicationFormState = setValue(applicationFormState, question.id, newValue, question.isRequired)
    store.dispatch(changeApplicationForm(newApplicationFormState))
  }
  const checkIsSelected = (optionId, value): boolean => {
    const currentValue = getValue(applicationFormState, question.id) ?? '{}'
    if (currentValue === '' || currentValue === undefined || currentValue === null) {
      return false
    }
    let jsonValue
    try {
      jsonValue = JSON.parse(currentValue as string)
    } catch (exception) {
      showToast('error', 'Houve um erro')
      return false
    }
    return jsonValue[optionId] === value
  }
  question.options?.map((option, key) => {
    items.push({
      code: 'checkbox',
      disabled: isLaunched,
      name: question.name,
      label: option.value,
      value: option.value,
      id: option.id,
      defaultChecked: checkIsSelected(option.id, option.value),
      onClick: () => {
        if (isLaunched) {
          return
        }
        changeValue(event, option.id)
      }
    })
    return option
  })

  return (
    <div className='mb-3' key={question.id}>
      <InputWrapper className={`${(question.isInvalid ?? false) ? 'invalid-input' : ''}`}>
        <Input
          code={question.type}
          type={question.type}
          name={question.name}
          description={getDescription(question.description, question.isRequired)}
          label={question.name}
          items={items}
          isRequired={question.isRequired}
          isInvalid={question.isInvalid}
          notValidFieldMessage={question.notValidFieldMessage}
        />
      </InputWrapper>
      <div className="d-flex justify-content-between">
        {(question.isInvalid ?? false) && (
          <small style={{ color: 'var(--error)', width: '100%', textAlign: 'left' }}>
            {question.notValidFieldMessage}
          </small>
        )}
      </div>
    </div>
  )
}
