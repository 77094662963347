import React from 'react'

export interface IValidation {
  type: string
  stage?: string
  param?: string | number | boolean
}

export interface IField {
  id: string
  fatherId?: string | number
  name?: string
  namePlaceholder?: string
  type: string
  isDeleted: boolean
  isRequired?: boolean
  isCriterion?: boolean
  weight?: number
  maxLength?: number
  label?: string
  value?: any
  step?: IField[]
  questionType?: string
  size?: number | null | undefined
  description?: string
  descriptionPlaceholder?: string
  options?: IOption[]
  path: string
  updateFieldState?: React.EventHandler<any>
  validations?: IValidation[]
  dataCy?: string
  disabled?: boolean
  isHidden?: boolean
  gradeInfo?: string
}

export interface IFile {
  id: string
  acceptedFile: string
  recommendedFormat: string
  maxSize: string
  name?: string
  type: string
  isDeleted: boolean
  isRequired?: boolean
  label?: string
  value?: any
  description?: string
  path: string
  updateFieldState?: React.EventHandler<any>
  archive: IArchive
  removed?: string[]
  dataCy?: string
  disabled?: boolean
}

export interface IMultiFile {
  id: string
  acceptedFile: string
  recommendedSize: string
  name?: string
  type: string
  isDeleted: boolean
  isRequired?: boolean
  label?: string
  value?: any
  description?: string
  path: string
  updateFieldState?: React.EventHandler<any>
  archives?: IArchive[]
  removed?: string[]
}

export interface IArchive {
  id: string
  path: string | null
  name: string | null
  size: number | null
  mimetype: string | null
  extension: string | null
  type: string | null
  content: string | null
  sourceFile: File | null
  isDeleted: boolean
}

export interface IDynamicFieldOption {
  value: string
  label: string
}

export interface IDynamicField {
  id: string
  sectionId?: string
  fatherId?: string | number
  name?: string
  namePlaceholder?: string
  type: string
  isDeleted: boolean
  isRequired?: boolean
  isCriterion?: boolean
  weight?: number
  maxLength?: number
  label?: string
  value?: any
  step?: IField[]
  questionType?: string
  availableQuestionTypes?: IDynamicFieldOption[]
  size?: number | null | undefined
  description?: string
  descriptionPlaceholder?: string
  options?: IOption[]
  path: string
  updateFieldState?: React.EventHandler<any>
  validations?: IValidation[]
  dataCy?: string
  disabled?: boolean
  isHidden?: boolean
  gradeInfo?: string
}

export interface IDatePicker {
  id: string
  fatherId?: string | number
  name?: string
  minDate: Date | null | undefined
  maxDate: Date | null | undefined
  type: string
  isDeleted: boolean
  isRequired?: boolean
  isCriterion?: boolean
  weight?: number
  maxLength?: number
  label?: string
  value: string | null | undefined
  step?: IField[]
  questionType?: string
  size?: number | null | undefined
  description?: string
  options?: IOption[]
  path: string
  updateFieldState?: React.EventHandler<any>
  validations?: IValidation[]
  dataCy?: string
  disabled?: boolean
}

export interface IOption {
  id: string
  value: string
  label?: string
  isDeleted: boolean
  path: string
}

export interface ITab {
  id: string
  name: string
  content: Array<IField | IDatePicker | IApplicationForm | IEvaluation | IFile | IMultiFile | ITypeahead>
}

export interface IApplicationForm {
  id: string
  name: string
  type: 'applicationForm'
  sections: ISection[]
}

export interface IEvaluation {
  id: string
  name: string
  type: 'evaluation'
  rounds: number
  likertScale: 1 | 2 | 3 | 4 | 5
  steps: IStep[]
}

export interface ISection {
  id: string
  name: string
  namePlaceholder?: string
  isHidden?: boolean
  isDeleted: boolean
  path: string
  fields?: IField[] | IDynamicField[]
}

export interface IStep {
  id: string
  name: string
  namePlaceholder?: string
  path: string
  isDeleted: boolean
  sections: ISection[]
}

export interface ITypeahead {
  id: string
  fatherId?: string | number
  name?: string
  endpointSource: string
  type: string
  isDeleted: boolean
  isRequired?: boolean
  isCriterion?: boolean
  weight?: number
  maxLength?: number
  label?: string
  value?: Array<{ id: string, name: string }>
  step?: IField[]
  questionType?: string
  size?: number | null | undefined
  description?: string
  options?: IOption[]
  path: string
  updateFieldState?: React.EventHandler<any>
  validations?: IValidation[]
  dataCy?: string
  disabled?: boolean
}

export const ADJUST_TOP = -250
