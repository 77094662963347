import { RESET_PASSWORD_FORM, UPDATE_PASSWORD_FORM } from './actions'
import { IPasswordForm } from './contracts'

export const PASSWORD_FORM_INITIAL_STATE: IPasswordForm = {
  currentPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
  newPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
  confirmNewPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
  isFormValid: false
}
export interface IHubIPasswordFormStateAction {
  type: string
  payload: IPasswordForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubPasswordFormReducer = (
  state: IPasswordForm = PASSWORD_FORM_INITIAL_STATE,
  action: IHubIPasswordFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_PASSWORD_FORM:
      return {
        ...state,
        ...action.payload
      }
    case RESET_PASSWORD_FORM:
      return {
        ...state,
        currentPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
        newPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
        confirmNewPassword: { value: '', isInvalid: false, message: '', neverChanged: true },
        isFormValid: false
      }
    default:
      return state
  }
}
