import { ROUTES } from 'sharedKernel/constants'

export const getStartRoute = (permissions: string[] | undefined): string => {
  if (permissions === undefined) {
    return '#'
  }

  if (permissions.includes('challenge')) {
    return getHubRoute(ROUTES.HUB.DASHBOARD)
  } else if (permissions.includes('idea')) {
    return getHubRoute(ROUTES.HUB.STARTUP.CHALLENGE_LIST)
  } else if (permissions.includes('evaluation')) {
    return getHubRoute(ROUTES.HUB.EVALUATOR.CHALLENGE_LIST)
  }

  return '#'
}

export const getHubRoute = (targetRoute: string): string => {
  return `${ROUTES.HUB.ROOT}${targetRoute}`
}
