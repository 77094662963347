import React, { useEffect } from 'react'
// Componentes
import { HotsiteHeader } from 'domain/feature/hotsite/Header'
import { HotsiteFooter } from 'domain/feature/hotsite/Footer'
import { Outlet, useLocation } from 'react-router'

export const HotsiteInnerPage = (): JSX.Element => {
  const location = useLocation()
  return (
    <>
      {location.pathname !== '/mapadainovacao' ? <HotsiteHeader /> : <></>}
      <div style={{ backgroundColor: 'var(--secondary-h)' }}>
        <Outlet />
      </div>
      <HotsiteFooter />
    </>
  )
}
