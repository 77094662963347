/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { HubStartupChallengeApplicationForm } from 'domain/feature/hub/startup/challenge/applicationForm'
import { IBuildUIApplicationForm, store, resetApplicationForm, changeApplicationForm, TReducers } from 'store'
import { initDataToSave } from 'domain/feature/hub/startup/challenge/applicationForm/actions/init'
import { GetFormat, useGet } from 'sharedKernel/api'
import { ApplicationFormSection } from 'domain/feature/hub/startup/challenge/applicationForm/contracts'
import { Loading } from 'components/loading'

let initData: IBuildUIApplicationForm = {
  dataFromRequest: {
    applicationFormid: '',
    applicationFormSections: [],
    isLaunched: false,
    id: ''
  },
  challengeTitle: ''
}

export const HubStartupChallengeApplicationEditFormPage = ({ ...props }): JSX.Element => {
  const { challengeId, ideaId } = useParams()

  const requestForm: GetFormat = {
    url: `/v1/hub/resolver/challenge/${challengeId}`
  }

  const requestIdea: GetFormat = {
    url: `/v1/hub/resolver/solution/${ideaId}`
  }

  const {
    data: formData,
    isError: formError,
    isFetching: formFetching
  } = useGet<ApplicationFormSection>(requestForm, 'form-get')

  const {
    data: ideaData,
    isError: ideaError,
    isFetching: ideaFetching
  } = useGet<ApplicationFormSection>(requestIdea, 'idea-get')

  useEffect(() => {
    if (formFetching || ideaFetching || ideaError || formError) {
      return
    }

    initData.dataFromRequest.applicationFormid = formData.data.applicationForm.id
    initData.dataFromRequest.applicationFormSections = formData.data.applicationForm.sections
    initData.dataFromRequest.isLaunched = formData.data.isLaunched
    initData.dataFromRequest.id = formData.data.id
    initData.challengeTitle = formData.data.config.title

    initDataToSave(initData, ideaData)

    return () => {
      store.dispatch(resetApplicationForm())
    }
  }, [formData, ideaData, formFetching, ideaFetching])

  useEffect(() => {
    return () => {
      componentUnmount()
    }
  }, [])

  const componentUnmount = (): void => {
    store.dispatch(resetApplicationForm())
    initData = {
      dataFromRequest: {
        applicationFormid: '',
        applicationFormSections: [],
        isLaunched: false,
        id: ''
      },
      challengeTitle: ''
    }
  }

  return (
    <>
      {!ideaFetching ? (
        <HubStartupChallengeApplicationForm applicationForm={initData} challengeId={challengeId} />
      ) : (
        <Loading />
      )}
    </>
  )
}
