import React, { useCallback, useEffect, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { useParams, useNavigate } from 'react-router-dom'
import { BlankPage, ResetPasswordForm, AuthPageLayout } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { PostFormat, updateProvider } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { ResetPasswordFields } from 'iebt-zing-storybook/dist/forms/ResetPasswordForm'
import { ROUTES } from 'sharedKernel/constants'
interface ResetPasswordPageParams {
  dateOnToken: string
  requestTokenOnToken: string
}

const EXPIRATION_TIME = 30

export const ResetPasswordPage = ({ ...props }): JSX.Element => {
  const resetPasswordParams: ResetPasswordPageParams = {
    dateOnToken: '',
    requestTokenOnToken: ''
  }
  const { userId, token } = useParams()
  const navigate = useNavigate()
  const { mutate: resetPassword } = updateProvider()

  useEffect(() => {
    redirectIfInvalidToken()
  }, [token])

  const redirectIfInvalidToken = (): void => {
    decodeToken()
    validateToken()
  }

  const decodeToken = (): void => {
    const tokenDecoded = window.atob(token)
    const splitString = tokenDecoded.split('_')

    resetPasswordParams.dateOnToken = splitString[1].slice(1)
    resetPasswordParams.requestTokenOnToken = splitString[2].slice(1)
  }

  const validateToken = (): void => {
    const tokenDate = DateTime.fromSeconds(Number(resetPasswordParams.dateOnToken))
    const now = DateTime.local().setZone('UTC')

    const difference = Duration.fromObject({
      minutes: now.diff(tokenDate).as('minutes')
    })

    const isInvalid: boolean = difference.minutes > EXPIRATION_TIME

    if (isInvalid) {
      showToast('error', 'Seu link expirou, gere outro, por favor')
      navigate(ROUTES.USER_AUTH.FORGOT_PASSWORD)
    }
  }

  const handleSubmit = useCallback((formData: ResetPasswordFields) => {
    const request: PostFormat = {
      url: `/v1/system/user/reset-password/${userId}`,
      data: {
        data: {
          requestToken: resetPasswordParams.requestTokenOnToken,
          ...formData
        }
      }
    }

    resetPassword(request, {
      onSuccess: (response) => {
        showToast('success', 'Senha alterada com sucesso')
        navigate(ROUTES.USER_AUTH.LOGIN)
      },
      onError: (error) => {
        const errors = error?.response?.data?.errors
        if (errors.length > 0) {
          errors.forEach(e => {
            showToast('error', e.message)
          })
        }
      }
    })
  }, [])

  return (
    <AuthPageLayout>
      <ResetPasswordForm
        descriptionLabel={trans('Crie uma nova senha para acessar a plataforma')}
        newPasswordFormLabel={trans('Nova Senha')}
        confirmNewPasswordLabel={trans('Confirme sua senha')}
        onSubmit={handleSubmit}
      />
    </AuthPageLayout>
  )
}
