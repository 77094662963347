import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Image } from 'react-bootstrap'
import {
  HeroInfoWrapper,
  HeroTitle,
  HeroText,
  HeroButtonsWrapper,
  HeroButtonPrimary,
  HeroButtonSecondary,
  HeroButtonsColmun,
  HeroStartText
} from './styles'

import imgHero from 'assets/images/defaults/dashboard.png'
import { ROUTES } from 'sharedKernel/constants'

export const HeroSection = (): JSX.Element => {
  const navigate = useNavigate()

  const heroLabels = {
    title: trans('Uma Plataforma completa de Gestão da Inovação'),
    startFirstText: trans('Para Empresas e Governos:'),
    firstText: trans('Faça a gestão de Programas de Aceleração de startups, Programas de Incubação / Pré-Incubação, Programas de Idéias e Programas de Inovação Aberta.'),
    startSecondText: trans('Para Startups:'),
    secondText: trans('Submeta suas propostas e faça parte de um ecossistema de mais de 10.000 startups.'),
    primaryButton: trans('Para Empresas e Governos'),
    secondaryButton: trans('Para Startups/Solucionadores')
  }

  return (
    <Row>
      <Col lg={6}>
        {/* As classes com prefixo "animate" são necessários para realizar as animações. Doc: https://animate.style/ */}
        <HeroInfoWrapper className="animate__animated animate__fadeInUp animate__slow">
          <HeroTitle>{heroLabels.title}</HeroTitle>
          <HeroText><HeroStartText>{heroLabels.startFirstText}</HeroStartText>{heroLabels.firstText}</HeroText>
          <HeroText><HeroStartText>{heroLabels.startSecondText}</HeroStartText> {heroLabels.secondText}</HeroText>
          <HeroButtonsWrapper className='d-flex flex-row flex-lg-column flex-xl-row align-items-center align-items-lg-start'>
            <HeroButtonsColmun>
              <HeroButtonPrimary
                onClick={() => navigate(ROUTES.HOTSITE.CONTACT, { state: { hotsite: true } })}
              >
                {heroLabels.primaryButton}
              </HeroButtonPrimary>
            </HeroButtonsColmun>
            <HeroButtonsColmun>
              <HeroButtonSecondary
                onClick={() => navigate(ROUTES.HOTSITE.MARKETPLACE, { state: { hotsite: true } })}
              >
                {heroLabels.secondaryButton}
              </HeroButtonSecondary>
            </HeroButtonsColmun>
          </HeroButtonsWrapper>
        </HeroInfoWrapper>
      </Col>
      {/* Como está sendo utilizado o componente do react-bootstrap direto, utilizo as proprias classes que o bootstrap fornece */}
      <Col lg={6} className="d-flex justify-content-end">
        <Image
          fluid
          className="animate__animated animate__fadeInRight animate__slow"
          src={imgHero}
          alt="Image Zing Platform"
          width="650"
          height="420"
          loading='lazy'
        />
      </Col>
    </Row>
  )
}
