import React from 'react'
import swal from '@sweetalert/with-react'
import '../style.css'
import { PopUpContainer, PopUpHeader, PopUpTitle } from '../singleAction/style'
import { api, GetFormat } from 'sharedKernel/api'
import { fixDatas } from 'main/pages/hub/company/challenge/edit'
import {
  store,
  changeConfigSection,
  changeChallengeFiles,
  IFileStructure,
  IHubChallengeBasicFormFilesState
} from 'store'
import { fillChallenge } from 'main/pages/hub/company/challenge/formBuilder/fillForm'
import { TemplatePreview } from './TemplatePreview'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PopUpContent } from './style'

export interface TemplatePopupProps {
  confirmButton: string
  action: () => void
  title?: string
  challengeId: string
}

export const showTemplatePopup = ({ title, action, confirmButton = 'Usar', challengeId }: TemplatePopupProps): void => {
  let formStateValues
  let formFilesState
  let tabs

  const getRequest: GetFormat = {
    url: `/v1/hub/company/challenge/${challengeId}`
  }
  const filesRequest: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}/files`
  }

  const queryClient = new QueryClient()
  api
    .get(getRequest.url)
    .then((response) => {
      const challengeState = response.data?.data
      challengeState.id = undefined
      fixDatas(challengeState)

      store.dispatch(changeConfigSection(challengeState))
    })
    .then(() => {
      api.get(filesRequest.url).then((response) => {
        const files: IFileStructure[] = response.data?.rows

        const filesChallengeState: IHubChallengeBasicFormFilesState = {
          challengeFiles: files.filter((file) => file.type === 'supportFiles'),
          deletedChallengeFiles: [],
          homePageSlider: files.find((file) => file.type === 'homePageSlider'),
          presentationVideo: files.find((file) => file.type === 'presentationVideo'),
          coverImage: files.find((file) => file.type === 'coverImage')
        }
        store.dispatch(changeChallengeFiles(filesChallengeState))
      })
    })
    .then(() => {
      formStateValues = store.getState().hubChallengeBasicFormState
      formFilesState = store.getState().hubChallengeBasicFormFilesState
      tabs = fillChallenge(formStateValues, formFilesState)
    })
    .then(() => {
      swal({
        buttons: {
          confirm: {
            text: confirmButton
          }
        },
        content: (
          <QueryClientProvider client={queryClient}>
            <PopUpContainer>
              <PopUpHeader>{title != null && <PopUpTitle>{title}</PopUpTitle>}</PopUpHeader>
              <PopUpContent>
                <div onClick={(e) => { e.preventDefault() }}>
                  <TemplatePreview tabs={tabs} formStateValues={formStateValues}></TemplatePreview>
                </div>
              </PopUpContent>
            </PopUpContainer>
          </QueryClientProvider>
        )
      }).then((value: string) => {
        value !== null && action()
      })
    })
}
