import React from 'react'
import {
  ChartHeader,
  MainChart,
  ChartIndex,
  ChartCardContent,
  IndexTitle,
  IndexValue,
  ChartIndexItem,
  IndexLabel
} from './style'
import { DashboardItemBox } from '../../../../../main/pages/hub/dashboard/style'
import { PieChartProps, PieChartWrapper } from '../common/pieChart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { trans } from 'sharedKernel/i18n'
import { DashboardCardSelector } from '../common/cardSelector'
import { Col } from 'react-bootstrap'
import { Loading } from 'components/loading'
import { NoContent } from 'components/NoContent'
import NoContentImg from 'assets/images/defaults/no_challenges.png'

export interface DashboardChartCard {
  title: string
  metric: string
  isError?: boolean
  isLoading?: boolean
  dataVisualization: PieChartProps
}

const getPercentage = (currentValue: number, totalValue: number): string => {
  return ((100 * currentValue) / totalValue).toFixed(0)
}

export const DashboardCharts = ({ title, metric, isError = false, isLoading = false, dataVisualization }: DashboardChartCard): JSX.Element => {
  const labels = {
    requestError: trans('Falha ao obter dados'),
    noData: trans('Nenhum dado encontrado')
  }
  const indexIcon: [IconPrefix, IconName] = ['fas', 'circle']

  const renderContent = (): JSX.Element => {
    if (isLoading) {
      return <MainChart><Loading /></MainChart>
    }
    if (isError) {
      return <MainChart><NoContent text={labels.requestError} image={NoContentImg} imageWidth='350' imageHeight='182' /></MainChart>
    }

    if (dataVisualization.data.length < 1) {
      return <MainChart><NoContent text={labels.noData} image={NoContentImg} imageWidth='350' imageHeight='182' /></MainChart>
    }

    return (
      <>
        <MainChart>
          <PieChartWrapper
            data={dataVisualization.data}
            colors={dataVisualization.colors}
            dimensions={dataVisualization.dimensions}
            totalValue={dataVisualization.totalValue}
          />
        </MainChart>
        <ChartIndex>
          {dataVisualization.data.map((item, index) => {
            return (
              <ChartIndexItem key={index}>
                <IndexLabel>
                  <FontAwesomeIcon
                    style={{ marginTop: '3px' }}
                    icon={indexIcon}
                    color={dataVisualization.colors[index]}
                    size={'xs'}
                  />
                  <IndexTitle className="fs-6">{item.name}</IndexTitle>
                  <IndexValue className="fs-6 ml-auto">{`${getPercentage(
                    item.value,
                    dataVisualization.totalValue
                  )}%`}</IndexValue>
                </IndexLabel>
              </ChartIndexItem>
            )
          })}
        </ChartIndex>
      </>
    )
  }

  return (
    <Col xs={12} md={6} xl={4}>
      <DashboardItemBox>
        <ChartCardContent>
          <ChartHeader>
            <h1 className="fs-6">{`${trans(title)} / ${trans(metric)}`}</h1>
            <DashboardCardSelector label={metric} />
          </ChartHeader>
          {renderContent()}
        </ChartCardContent>
      </DashboardItemBox>
    </Col>
  )
}
