import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { HubCompanyDashboardStatsCard } from '../StatsCard'
import { HubCompanyChallengeDashboardChallengeStatsApiResult } from './contracts'

export const HubCompanyDashboardChallengeCounter = (): JSX.Element => {
  const labels = {
    statsName: trans('Desafios'),
    statsDescription: trans('Ativos'),
    statsInfo: trans('Total de Desafios Lançados por sua empresa')
  }

  const statsProps: any = {
    name: labels.statsName,
    count: 0,
    description: labels.statsDescription,
    info: labels.statsInfo,
    cardLabel: {
      icon: ['fas', 'lightbulb'],
      color: 'var(--green)',
      background: 'var(--green-light)',
      size: '2x'
    }
  }

  const request: GetFormat = {
    url: 'v1/hub/company/dashboard/challenge/total'
  }
  const { data, isError, isLoading, isFetching } = useGet<HubCompanyChallengeDashboardChallengeStatsApiResult>(
    request,
    'challenge-stats-get'
  )

  if (isFetching || isLoading || isError) {
    return <HubCompanyDashboardStatsCard {...statsProps} />
  }

  if (data.data.launchedChallenges === null || data.data.launchedChallenges === undefined) {
    return <HubCompanyDashboardStatsCard {...statsProps} />
  }

  statsProps.count = data.data.launchedChallenges
  return <HubCompanyDashboardStatsCard {...statsProps} />
}
