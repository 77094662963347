export const applyMaskIfNotEmpty = (value: string, mask: string): string => {
  return value !== '' ? mask : ''
}

export const applyTitleOnError = (errorTitle: string, defaultTitle: string): string => {
  return errorTitle === '' ? defaultTitle : errorTitle
}

export const hasTextMatch = (term: any, match: any): boolean => {
  return String(term).toLowerCase().includes(String(match).toLowerCase())
}
