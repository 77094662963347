import styled from 'styled-components'

export const ChartsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const ChartCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
`

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ChartHeaderMetricSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

export const MainChart = styled.div`
  display: flex;
  justify-content: center;
`

export const ChartIndex = styled.div`
  display: flex;
  max-height:300px;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 700px;
  padding: 20px;

  border-radius: 15px;
  box-shadow: 1px 5px 15px 0 var(--shadow-smooth);
`

export const ChartIndexItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  margin-right: 40px;
  margin-left: 40px;
`

export const IndexLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
`

export const IndexTitle = styled.h6`
  font-size: 15px;
  margin-left: 5px;
  opacity: 0.5;
`

export const IndexValue = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-dark);
`

export const ChartTitle = styled.h6``

export const ChartMetric = styled.h6`
  font-weight: bold;
  opacity: 0.5;
  margin-right: 10px;
`
