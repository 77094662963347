import { CHANGE_THEME } from './themeActions'

const INITIAL_STATE: IInitalState = {
  isDarkModeOn: Boolean(localStorage.getItem('isDarkMode') === 'true') ?? false
}

export interface IInitalState {
  isDarkModeOn: boolean
}

export interface IAction {
  type: string
  payload: boolean
}

export interface IThemeState {
  isDarkModeOn: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ThemeReducer = (state: IInitalState = INITIAL_STATE, action: IAction): any => {
  switch (action.type) {
    case CHANGE_THEME:
      localStorage.setItem('isDarkMode', String(action.payload))
      return {
        ...state,
        isDarkModeOn: action.payload
      }

    default:
      return state
  }
}
