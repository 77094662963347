import React from 'react'
import { Input } from 'iebt-zing-storybook'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../store'
import { DynamicFormField } from '../contracts'
import { getValue, setValue, getDescription } from '../actions/dataHandler'
import { ApplicationFormInputSelect } from './select'
import { ApplicationFormInputCheckbox } from './checkbox'
import { ApplicationFormInputRadio } from './radio'
import { ApplicationFormInputDate } from './date'
import { ApplicationFormInputUpload } from './upload'
import { FormIdeaAnswer, FormIdeaQuestion, FormLaunchedFieldWrapper } from '../style'

export const ApplicationFormInput = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const isLaunched = applicationFormState.isLaunched ?? false

  const renderLaunchedAnswer = (): JSX.Element => {
    return (
      <FormLaunchedFieldWrapper>
        <FormIdeaQuestion>{question.name}</FormIdeaQuestion>
        <FormIdeaAnswer>{getValue(applicationFormState, question.id) as string}</FormIdeaAnswer>
      </FormLaunchedFieldWrapper>
    )
  }

  switch (question.type) {
    case 'select':
      return <ApplicationFormInputSelect {...question} />
    case 'checkbox-group':
      return <ApplicationFormInputCheckbox {...question} />
    case 'radio-group':
      return <ApplicationFormInputRadio {...question} />
    case 'date':
      return <ApplicationFormInputDate {...question} />
    case 'upload':
      return <ApplicationFormInputUpload {...question} />
    default:
      // eslint-disable-next-line no-case-declarations
      let size
      if (question.size != null && question.size !== 0 && question.type === 'text') {
        size = question.size
      }
      if (size === undefined && question.type === 'text') {
        size = 1024
      }

      return (
        <div className="mb-4" key={question.id}>
          {
            isLaunched
              ? renderLaunchedAnswer()
              : <Input
                code={question.type === 'text' ? 'textarea' : question.type}
                type={question.type}
                name={question.name}
                disabled={isLaunched}
                label={question.name}
                placeholder={question.description}
                maxLength={size}
                isRequired={question.isRequired}
                isInvalid={question.isInvalid}
                notValidFieldMessage={question.notValidFieldMessage}
                value={getValue(applicationFormState, question.id) as string}
                onChange={() => {
                  const element = event?.target as HTMLInputElement
                  const newApplicationFormState = setValue(applicationFormState, question.id, element.value, question.isRequired)
                  store.dispatch(changeApplicationForm(newApplicationFormState))
                }}
              />
          }
        </div>
      )
  }
}
