import { Validation } from '../contracts'
import { MinSize } from '../minSize'
import { MaxSize } from '../maxSize'
import { NotEmpty } from '../notEmpty'

export class FieldSize implements Validation {
  valid: boolean
  validSizes: number[]

  constructor () {
    this.valid = true
    this.validSizes = [
      128, 256, 512
    ]
  }

  message (): string {
    return 'Tamanho inválido'
  }

  isValid (): boolean {
    return this.valid
  }

  validate (value: any): void {
    this.valid = this.validSizes.includes(parseInt(value))
  }
}
