import styled from 'styled-components'
import { Form } from 'react-bootstrap'

export const EvaluationFilterSpan = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--grey-opacity-70);
  white-space: nowrap;
  padding-right: 1rem;
`

export const FilterSelect = styled(Form.Select)`
  border: none;
  font-size: 0.875rem;
  color: var(--grey);
`
export const FilterArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  
  @media (max-width: 723px) {
    justify-content: start;
  }
`
