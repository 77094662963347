import { Input } from 'iebt-zing-storybook'
import { Button, FormCheck } from 'react-bootstrap'
import styled from 'styled-components'

export const LandingPageFormWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const LandingPageFormBox = styled.form`
  padding: 26px 45px;
  display: flex;
  flex-direction: column;
  border-radius: 1.875rem;
  background-color: var(--primary-light);
`

export const FormInput = styled(Input)`
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--primary);
  border-radius: 0;
  resize: none;
  overflow: hidden;
  color: var(--grey);
  :focus {
    background: transparent;
    color: var(--grey);
  }
`

export const AdjustRadioInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
    margin-bottom: 10px;
  }
  justify-content: space-between;
`

export const AdjustLabel = styled.div`
  label {
    padding: 0;
    padding-left: 4px;
    color: var(--grey-light);
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const FormButton = styled(Button)`
  border: 0;
  border-radius: 17px;
  font-size: 16px;
  width: 125px;
  height: 48px;
  line-height: 23px;
`

export const SecondEditionText = styled.a`
  color: var(--primary);
  text-decoration: none;
`
