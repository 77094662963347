import React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Mousewheel, Keyboard } from 'swiper'
import { CarouselStyle, CentralizeCards } from './style'

export interface SwiperWrapperProps {
  carouselItems: JSX.Element[]
  breakpoints: any
  arrowColor?: string
  swipeOnMouseWheel?: boolean
}

export const SwiperWrapper = ({ carouselItems, breakpoints, arrowColor = '#FF4F1C', swipeOnMouseWheel = true }: SwiperWrapperProps): JSX.Element => {
  return (
    <CarouselStyle arrowColor={arrowColor} isMenu={false}>
      <Swiper
        keyboard={{
          enabled: true
        }}
        breakpoints={breakpoints}
        modules={[Mousewheel, Navigation, Keyboard]}
        navigation={{
          nextEl: '#nextButton',
          prevEl: '#prevButton'
        }}
        allowSlideNext={true}
        mousewheel={swipeOnMouseWheel}
        grabCursor={true}
        pagination={{ type: 'bullets', clickable: true }}
      >
        {carouselItems.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <CentralizeCards>
                {item}
              </CentralizeCards>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div id={'prevButton'} className={'swiper-button-prev'}/>
      <div id={'nextButton'} className={'swiper-button-next'}/>
    </CarouselStyle>
  )
}
