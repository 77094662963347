import styled from 'styled-components'

export const CustomTd = styled.td`
  display: table-cell;
  vertical-align: middle;
  max-width: 16vw;
`

export const TitleContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeaderText = styled.th`
  @media (max-width: 577px) {
    writing-mode: sideways-lr;
  }
`
export const CaptionText = styled.caption`
   caption-side: bottom;
    text-align: left;
    font-style: italic;
    margin-top: 10px;
`
