import React from 'react'
import {
  EvaluatorListWrapper,
  EvalutorListHeader,
  EvalutorListTitle,
  EvaluatorListItemContainer,
  EvaluatorListItemLabel,
  EvaluatorListContainer,
  EvaluatorListItemLabelDiv
} from './style'
import { DashboardEvaluatorProgressBar, ProgressBarProps } from './evaluatorProgressBar'
import { trans } from 'sharedKernel/i18n'
import { DashboardCardSelector } from '../common/cardSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { Col } from 'react-bootstrap'
import { DashboardItemBox } from 'main/pages/hub/dashboard/style'
import { GetFormat, useGet } from 'sharedKernel/api'
import { DashboardData } from './contracts'
import { Loading } from 'components/loading'
import { setNewData } from './behavior'
export interface EvaluatorListProps {
  statsName: string
  progressBarList: ProgressBarProps
}
export let evaluatorStatsList: EvaluatorListProps[] = [
  {
    statsName: 'Convidados',
    progressBarList: {
      progressColor: 'var(--primary)',
      progressValue: 0,
      backgroundColor: 'var(--primary-light)',
      totalValue: 0,
      height: '10px',
      width: '100px'
    }
  },
  {
    statsName: 'Aceitos',
    progressBarList: {
      progressColor: 'var(--yellow)',
      progressValue: 0,
      backgroundColor: 'var(--yellow-light)',
      totalValue: 0,
      height: '10px',
      width: '100px'
    }
  },
  {
    statsName: 'Em avaliação',
    progressBarList: {
      progressColor: 'var(--orange)',
      progressValue: 0,
      backgroundColor: 'var(--orange-light)',
      totalValue: 0,
      height: '10px',
      width: '100px'
    }
  }
]
export const DashboardEvaluatorsList = (): JSX.Element => {
  const indexIcon: [IconPrefix, IconName] = ['fas', 'circle']
  const labels = {
    title: trans('Avaliadores')
  }

  const request: GetFormat = {
    url: '/v1/hub/company/dashboard/evaluator/total'
  }
  const { data, isLoading } = useGet<DashboardData>(
    request,
    'evaluator-status-get'
  )

  if (isLoading) {
    return (
      <Col xs={12} md={6} lg={5}>
        <DashboardItemBox>
          <EvaluatorListWrapper>
            <EvalutorListHeader>
              <EvalutorListTitle>{labels.title}</EvalutorListTitle>
              <DashboardCardSelector label={'Todos'} />
            </EvalutorListHeader>
            <EvaluatorListContainer>
              <Loading />
            </EvaluatorListContainer>
          </EvaluatorListWrapper>
        </DashboardItemBox>
      </Col>
    )
  }

  evaluatorStatsList = setNewData(evaluatorStatsList, data)

  return (
    <Col xs={12} md={6} lg={5}>
      <DashboardItemBox>
        <EvaluatorListWrapper>
          <EvalutorListHeader>
            <EvalutorListTitle>{labels.title}</EvalutorListTitle>
            <DashboardCardSelector label={'Todos'} />
          </EvalutorListHeader>
          <EvaluatorListContainer>
            {evaluatorStatsList.map((item, index) => {
              return (
                <EvaluatorListItemContainer key={index}>
                  <EvaluatorListItemLabelDiv>
                    <FontAwesomeIcon
                      color={item.progressBarList.progressColor}
                      fixedWidth={true}
                      icon={indexIcon}
                      size={'sm'}
                      style={{ marginTop: '3px' }}
                    />
                    <EvaluatorListItemLabel>{item.statsName}</EvaluatorListItemLabel>
                  </EvaluatorListItemLabelDiv>
                  <DashboardEvaluatorProgressBar
                    progressColor={item.progressBarList.progressColor}
                    progressValue={item.progressBarList.progressValue}
                    backgroundColor={item.progressBarList.backgroundColor}
                    totalValue={item.progressBarList.totalValue}
                    height={item.progressBarList.height}
                    width={item.progressBarList.width}
                  />
                </EvaluatorListItemContainer>
              )
            })}
          </EvaluatorListContainer>
        </EvaluatorListWrapper>
      </DashboardItemBox>
    </Col>
  )
}
