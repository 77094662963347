import styled, { keyframes } from 'styled-components'

const opacityAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
export const SortIcon = styled.div`
  cursor: pointer;
  &.rotating {
    animation: ${opacityAnimation} 0.3s linear;
    animation-fill-mode: forwards;
  }
`
