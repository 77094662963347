import React from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

import { FooterWrapper, FooterLinksWrapper, FooterCopyright, FooterEmail, FooterHr } from './styles'
import imgLogo from 'assets/images/defaults/logoZingIEBT.svg'

import imgFacebook from 'assets/images/defaults/facebookIcon.png'
import imgInstagram from 'assets/images/defaults/instagramIcon.png'
import imgLinkedin from 'assets/images/defaults/linkedinIcon.png'
import { trans } from 'sharedKernel/i18n'

export const HotsiteFooter = (): JSX.Element => {
  const socialMediaImgLinks = [
    {
      id: `${String(uuidv4())}`,
      image: imgFacebook,
      altText: 'Logo Facebook',
      width: 30,
      height: 30,
      href: 'https://www.facebook.com/iebtgestaoeinovacao/',
      tooltipContent: 'Facebook'
    },
    {
      id: `${String(uuidv4())}`,
      image: imgInstagram,
      altText: 'Logo Instagram',
      width: 40,
      height: 40,
      href: 'https://www.instagram.com/iebtinnovation/',
      tooltipContent: 'Instagram'
    },
    {
      id: `${String(uuidv4())}`,
      image: imgLinkedin,
      altText: 'Logo Linkedin',
      width: 30,
      height: 30,
      href: 'https://www.linkedin.com/company/iebt/',
      tooltipContent: 'LinkedIn'
    }
  ]

  const labels = {
    emailContact: trans('Contate-nos: zing@zinginnovation.com'),
    rightsReserved: trans('Todos os direitos reservados')
  }

  return (
    <FooterWrapper fluid>
      <FooterLinksWrapper>
        <a className="d-flex w-full align-items-center justify-content-center mt-4">
          <Image
            src={imgLogo}
            width="150"
            height="50"
            loading='lazy'
          />
        </a>
        <ul className='nav align-items-center justify-content-center mt-3'>
          {socialMediaImgLinks.map((img, index) => {
            return (
              <li className="mx-3" key={index}>
                <a className="text-white" href={img.href} target="_blank">
                  <OverlayTrigger overlay={<Tooltip>{img.tooltipContent}</Tooltip>}>
                    <Image src={img.image} fluid width={img.width} height={img.height} />
                  </OverlayTrigger>
                </a>
              </li>
            )
          })}
        </ul>
        <FooterHr />
        <FooterEmail>{labels.emailContact}</FooterEmail>
        <FooterCopyright>Copyright &copy; 2022 ZING. {labels.rightsReserved}</FooterCopyright>
      </FooterLinksWrapper>
    </FooterWrapper >
  )
}
