import { IHubStartupChallengeApplicationForm } from './contracts'

export const UPDATE_APPLICATION_FORM = 'CHANGE_STARTUP_HUB_CHALLENGE_APPLICATION_FORM'
export const RESET_APPLICATION_FORM = 'RESET_STARTUP_HUB_CHALLENGE_APPLICATION_FORM'

const changeApplicationForm = (applicationForm: IHubStartupChallengeApplicationForm): any => {
  return {
    type: UPDATE_APPLICATION_FORM,
    payload: applicationForm
  }
}

const resetApplicationForm = (applicationForm?: IHubStartupChallengeApplicationForm): any => {
  return {
    type: RESET_APPLICATION_FORM,
    payload: applicationForm
  }
}

export { changeApplicationForm, resetApplicationForm }
