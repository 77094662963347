import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toBase64 } from 'sharedKernel/file/handler'
import { trans } from 'sharedKernel/i18n'
import { extractData } from 'main/pages/hub/company/challenge/formBuilder/extractData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IFileStructure } from '../../FormWrapper/files/store/contracts'
import { TReducers, useSelector } from 'store'
import _ from 'lodash'
import { FormPreview } from './formPreview'
import { ModalButtonDiv, PreviewModal } from './style'

interface PreviewButtonProps {
  tabs: any
}

export const PreviewButton = ({ tabs }: PreviewButtonProps): JSX.Element => {
  const tabsJson = JSON.stringify(tabs)

  const formStateValues = useSelector((state: TReducers) => state.hubChallengeBasicFormState)
  const formFilesState = useSelector((state: TReducers) => state.hubChallengeBasicFormFilesState)
  const localFormFilesState = JSON.parse(JSON.stringify(formFilesState))

  const [previewShow, setPreviewShow] = useState(false)
  const [dataFiles, setDataFiles] = useState<any[]>([])

  const handlePreviewClose = (): void => setPreviewShow(false)
  const handlePreviewOpen = (): void => setPreviewShow(true)

  const labels = {
    close: trans('Fechar')
  }

  const newDataFiles: any[] = []

  const getFileUrl = async (file: any): Promise<any> => {
    if (_.isEmpty(file.sourceFile)) {
      return
    }
    return await toBase64(file.sourceFile)
  }

  const isFileDeleted = (file: any): boolean => {
    return localFormFilesState?.deletedChallengeFiles?.find(deletedFile => deletedFile.id === file.id) !== undefined
  }

  const updateApiCoverImage = (): void => {
    if (localFormFilesState?.coverImage !== undefined) {
      if (!isFileDeleted(localFormFilesState?.coverImage)) {
        newDataFiles.push(localFormFilesState?.coverImage)
      }
    }
  }

  const updateApiPresentationVideo = (): void => {
    if (localFormFilesState?.presentationVideo !== undefined) {
      if (!isFileDeleted(localFormFilesState?.presentationVideo)) {
        newDataFiles.push(localFormFilesState?.presentationVideo)
      }
    }
  }

  const updateApiHomePageSlider = (): void => {
    if (localFormFilesState?.homePageSlider !== undefined) {
      if (!isFileDeleted(localFormFilesState?.homePageSlider)) {
        newDataFiles.push(localFormFilesState?.homePageSlider)
      }
    }
  }

  const updateLocalFiles = (file: IFileStructure): void => {
    getFileUrl(file).then(url => {
      newDataFiles.push({ ...file, path: url })
    })
  }

  const updateApiSupportFiles = (file: IFileStructure): void => {
    if (!isFileDeleted(file)) {
      newDataFiles.push(file)
    }
  }

  const updateLocalFilesAndApiSupportFiles = (): void => {
    if (localFormFilesState?.challengeFiles !== undefined && localFormFilesState?.challengeFiles.length > 0) {
      localFormFilesState?.challengeFiles.forEach((file) => {
        if (file.path !== undefined) {
          updateApiSupportFiles(file)
        } else {
          updateLocalFiles(file)
        }
      })
    }
  }

  useEffect(() => {
    extractData(formStateValues, localFormFilesState, tabs)
    updateApiCoverImage()
    updateApiPresentationVideo()
    updateApiHomePageSlider()
    updateLocalFilesAndApiSupportFiles()
    setDataFiles(newDataFiles)
  }, [tabsJson])

  return (
    <>
      <FontAwesomeIcon className="cursor-pointer fa-lg" icon={['far', 'eye']} onClick={handlePreviewOpen} data-cy={'generalConfigEyeIcon'} />
      <PreviewModal show={previewShow} fullscreen onHide={handlePreviewClose}>
        <Modal.Body id='PreviewModal'>
          <ModalButtonDiv>
            <Button variant="primary" onClick={handlePreviewClose}>
              {labels.close}
            </Button>
          </ModalButtonDiv>
          <FormPreview
            stateValues={formStateValues}
          />
        </Modal.Body>
      </PreviewModal >
    </>
  )
}
