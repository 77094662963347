/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { validateAll } from './behaviors/validation'
import { IApplicationForm, IEvaluation, IField, IFile, IMultiFile, ITab, ITypeahead } from './constants'
import { BuildField } from './items/field'
import {
  ButtonsWrapper,
  FormChallengeWrapper,
  TitleAndButtonsWrapper,
  TitleWrapper
} from './style'
import { ICarouselNavItems } from 'components/swiper/swiperNav'
import { SwiperNavForm } from 'components/swiper/swiperNav/swiperNavForm'
import { ApplicationFormArea } from './items/applicationFormArea'
import { CarouselHeader } from 'iebt-zing-storybook'
import { EvaluationArea } from './items/evaluationArea'
import { SaveButton } from './SaveButton'
import { BuildMultiFile } from './items/field/multiFile'
import { BuildTypeahead } from './items/field/typeahead'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { trans } from 'sharedKernel/i18n'
import { PreviewButton } from './PreviewButton'
import { IHubChallengeBasicFormState } from '../FormWrapper/store'
import { FormChallengeHeader } from 'sharedKernel/styledComponents/stickHeader/style'
import { limitDataFields } from 'components/LimitDateFields'

interface FormWrapperProps {
  tabs: ITab[]
  setIsNavigationBlocked: any
  formStateValues: any
  formFilesState: any
  requireDates: boolean
  setFilesSaved: React.Dispatch<React.SetStateAction<boolean>>
}

export const FormWrapper = ({
  tabs,
  setIsNavigationBlocked,
  formFilesState,
  formStateValues,
  requireDates,
  setFilesSaved
}: FormWrapperProps): JSX.Element => {
  const carouselItems: ICarouselNavItems[] = []
  const today = new Date()
  const [dateRestrictions, setDateRestrictions] = useState({
    min: {
      launchDate: today,
      applicationStartDate: today,
      finalSelectionDate: today,
      evaluationStartDate: today,
      closingDate: today
    },
    max: {
      launchDate: null,
      applicationStartDate: null,
      finalSelectionDate: null,
      evaluationStartDate: null,
      closingDate: null
    }
  })

  useEffect(() => {
    initializeDataLimits(formStateValues, dateRestrictions)
  }, [])

  function fixDateLimits(selectedDate: string, previousDate: string, fieldName: string): void {
    limitDataFields(selectedDate, previousDate, fieldName, dateRestrictions)
    setDateRestrictions({ ...dateRestrictions })
  }

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
  }

  const labels = {
    title: trans('Criar novo Desafio'),
    edit: trans('Editar Desafio'),
    saveAsDraft: trans('Salvar como rascunho'),
    saveAndLaunch: trans('Salvar e publicar')
  }

  const pageTitle = window.location.href.includes('/edit') ? labels.edit : labels.title
  const isLaunched = formStateValues.isLaunched

  function setSectionAvailable(startDate: string): boolean {
    const newDate = new Date(`${startDate} 00:00:00`)
    const today = new Date(new Date().toDateString())
    return isLaunched ? newDate < today : false
  }

  return (
    <form>
      <FormChallengeWrapper>
        <FormChallengeHeader style={{ margin: '0px 0px 50px 0px' }}>
          <TitleAndButtonsWrapper>
            <TitleWrapper>
              <PageTitle>{pageTitle}</PageTitle>
            </TitleWrapper>
            <ButtonsWrapper>
              <SaveButton
                className="mr-2"
                setIsNavigationBlocked={setIsNavigationBlocked}
                tabs={tabs}
                text={isLaunched ? labels.saveAndLaunch : labels.saveAsDraft}
                formFilesState={formFilesState}
                formStateValues={formStateValues}
                launchAction={isLaunched}
                isLaunched={isLaunched}
                validateForm={() => {
                  return validateAll(isLaunched ? 'all' : 'save')
                }}
                setFilesSaved={setFilesSaved}
                data-cy={'challengeSaveButton'}
              />
              {!isLaunched && (
                <SaveButton
                  formFilesState={formFilesState}
                  formStateValues={formStateValues}
                  tabs={tabs}
                  text={labels.saveAndLaunch}
                  setIsNavigationBlocked={setIsNavigationBlocked}
                  validateForm={() => {
                    return validateAll('all')
                  }}
                  launchAction
                  setFilesSaved={setFilesSaved}
                  data-cy={'challengePublishButton'}
                />
              )}
            </ButtonsWrapper>
          </TitleAndButtonsWrapper>
          <SwiperNavForm breakpoints={buildBreakpoints()} carouselNavItems={carouselItems} offset={-240} />
        </FormChallengeHeader>
        {tabs.map((tab, tabKey) => {
          carouselItems.push({
            itemHash: tab.id,
            name: tab.name
          })

          return (
            <section id={tab.id} key={tab.id} style={{ margin: '0px 0px 50px 10px' }}>
              {tab.id === 'generalConfig' ? (
                <CarouselHeader title={`${String(tab.name)}`} button={<PreviewButton tabs={tabs} />} />
              ) : (
                <CarouselHeader title={`${String(tab.name)}`} />
              )}
              {tab.content.map((item, key) => {
                if (item.type === 'applicationForm') {
                  return ApplicationFormArea(item as IApplicationForm, key
                    , setSectionAvailable(formStateValues.config.step.applicationStartDate))
                }
                if (item.type === 'evaluation') {
                  return EvaluationArea(item as IEvaluation, key
                    , setSectionAvailable(formStateValues.config.step.evaluationStartDate))
                }
                if (item.type === 'uploadImage' || item.type === 'uploadVideo') {
                  return BuildField(
                    {
                      ...item,
                      updateFieldState: (field) => {
                        item = field
                        tab.content[key] = field as IFile
                      }
                    },
                    key
                  )
                }
                if (item.type === 'multiFile') {
                  return BuildMultiFile(
                    {
                      ...(item as IMultiFile),
                      updateFieldState: (field) => {
                        item = field
                        tab.content[key] = field as IFile
                      }
                    },
                    key
                  )
                }
                if (item.type === 'typeahead') {
                  return BuildTypeahead(
                    {
                      ...(item as ITypeahead),
                      endpointSource: '/v1/system/niche',
                      updateFieldState: (field) => {
                        item = field
                        tab.content[key] = field as ITypeahead
                      },
                      disabled: setSectionAvailable(formStateValues.config.step.applicationStartDate)
                    },
                    key
                  )
                }
                if (item.type === 'datePicker') {
                  if (item.id === 'applicationStartDate') {
                    return <></>
                  }
                  let item1 = item as IField

                  if (requireDates) {
                    item1 = {
                      ...item1,
                      validations: [
                        {
                          type: 'required',
                          stage: 'save'
                        }
                      ]
                    }
                  }
                  return BuildField(
                    {
                      ...item1,
                      minDate: dateRestrictions.min[item.id],
                      maxDate: dateRestrictions.max[item.id],
                      updateFieldState: (field) => {
                        const previousDate = item1.value ?? ''
                        tab.content[key] = field
                        fixDateLimits(field.value ?? '', previousDate, item.id)
                      },
                      disabled: setSectionAvailable(item.value)
                    },
                    key
                  )
                }
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return BuildField(
                  {
                    ...(item as IField),
                    updateFieldState: (field) => {
                      item = field
                      tab.content[key] = field as IField
                    },
                    disabled: setSectionAvailable(formStateValues.config.step.applicationStartDate)
                  },
                  key
                )
              })}
            </section>
          )
        })}
      </FormChallengeWrapper>
    </form>
  )
}

function initializeDataLimits(formStateValues: IHubChallengeBasicFormState, dateRestrictions: any): void {
  if (formStateValues.config.step.launchDate !== undefined && formStateValues.config.step.launchDate !== null) {
    limitDataFields(formStateValues.config.step.launchDate ?? '', '', 'launchDate', dateRestrictions)
  }
  if (
    formStateValues.config.step.finalSelectionDate !== undefined &&
    formStateValues.config.step.finalSelectionDate !== null
  ) {
    limitDataFields(formStateValues.config.step.finalSelectionDate ?? '', '', 'finalSelectionDate', dateRestrictions)
  }
  if (
    formStateValues.config.step.evaluationStartDate !== undefined &&
    formStateValues.config.step.evaluationStartDate !== null
  ) {
    limitDataFields(formStateValues.config.step.evaluationStartDate ?? '', '', 'evaluationStartDate', dateRestrictions)
  }
  if (formStateValues.config.step.closingDate !== undefined && formStateValues.config.step.closingDate !== null) {
    limitDataFields(formStateValues.config.step.closingDate ?? '', '', 'closingDate', dateRestrictions)
  }
}
