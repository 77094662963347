import React from 'react'
import { checkJSON } from '..'
import { FormAnswerProps } from '../contracts'

export const FormAnswerCheckbox = ({ answer }: FormAnswerProps): JSX.Element => {
  return (
    <>
      {checkJSON(answer)}
    </>
  )
}
