import { Validation } from '../contracts'
import { MinSize } from '../minSize'
import { MaxSize } from '../maxSize'
import { NotEmpty } from '../notEmpty'

export class Title implements Validation {
  valid: boolean
  minSize: number = 4
  maxSize: number = 256
  errorMessage: string = ''
  notEmptyValidator: NotEmpty
  minSizeValidator: MinSize
  maxSizeValidator: MaxSize

  constructor() {
    this.notEmptyValidator = new NotEmpty()
    this.minSizeValidator = new MinSize(this.minSize)
    this.maxSizeValidator = new MaxSize(this.maxSize)
    this.valid = true
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    this.notEmptyValidator.validate(value)
    this.valid = this.notEmptyValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.notEmptyValidator.message()
      return
    }
    this.minSizeValidator.validate(value)
    this.valid = this.minSizeValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.minSizeValidator.message()
      return
    }
    this.maxSizeValidator.validate(value)
    this.valid = this.maxSizeValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.maxSizeValidator.message()
    }
  }
}
