export const SAVE_HOTSITE_CHALLENGES_DATA = 'SAVE_HOTSITE_CHALLENGES_DATA'

const saveHotsiteChallenges = (hotsiteChallenges: any): any => {
  return {
    type: SAVE_HOTSITE_CHALLENGES_DATA,
    payload: hotsiteChallenges
  }
}

export { saveHotsiteChallenges }
