import styled from 'styled-components'
import { Button, Image } from 'react-bootstrap'

export const OurServicesImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 425px) {
    width: 100%;
    padding: 1rem 0;
  }
`

export const OurServicesImage = styled(Image)`
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`

export const OurServicesInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const OurServicesTitle = styled.h3`
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1.5rem;

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    padding-left: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem;
  }
`

export const OurServicesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: var(--grey);
  margin-bottom: 1.5rem;

  @media screen and (max-width: 425px) {
    font-size: 0.875rem;
    padding-left: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem;
  }
`

export const OurServicesButtonsWrapper = styled.div`
  align-self: flex-start;
  margin-top: 1.5rem;

  @media screen and (max-width: 425px) {
    padding-left: 1rem;
  }
`

export const OurServicesButton = styled(Button)`
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem
`
