import { TableStyles } from 'react-data-table-component'

export const tableTheme: TableStyles = {
  head: {
    style: {
      color: 'var(--primary)',
      fontSize: 'bold'
    }
  },
  headRow: {
    style: {}
  },
  headCells: {
    style: {}
  },
  rows: {
    style: {
      fontSize: 'bold'
    }
  },
  cells: {
    style: {}
  },
  responsiveWrapper: {
    style: {}
  }
}
