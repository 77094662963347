import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useNavigate } from 'react-router'

import { hubRoutes } from 'routes/hub'
import { semanthicPathsBreadcrumbIgnore } from './constants'
import { BreadcrumbUI } from './UI'
import { filterBreadcrumbs } from './behavior'

export interface IRouteProps {
  path: string
  name: string
}

export const Breadcrumb = (): JSX.Element => {
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs(hubRoutes, { excludePaths: semanthicPathsBreadcrumbIgnore })

  const filteredBreadcrumbs = filterBreadcrumbs(breadcrumbs)

  return (
    <>
      <BreadcrumbUI filteredBreadcrumbs={filteredBreadcrumbs} navigate={navigate} />
    </>
  )
}
