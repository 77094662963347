import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DescriptedLabel, Input } from 'iebt-zing-storybook'
import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import { useSelector } from 'react-redux'
import { trans } from 'sharedKernel/i18n'
import { showToast } from 'components/toast'
import { store, TReducers } from 'store'
import { FormTitle } from '../styles'
import { DROPDOWN_BRAZIL_STATES, INPUT_MASK_FORMAT } from 'sharedKernel/constants'
import { updateConfigForm } from './store/configForm'
import { IProfileImage, updateProfileImage } from './store/profileImage'
import { handleMobileValidity, handlePhoneValidity, ifNotEmptyValidateCEP, ifNotEmptyValidateMobile, ifNotEmptyValidatePhone, isEmptyField, setInvalid } from 'domain/feature/hotsite/Forms/validations'
import { getAddressByZIP } from 'sharedKernel/api/utils/cep'
import { IViaCepServiceAddressResponse } from 'sharedKernel/api/utils/cep/contract'
import { applyMaskIfNotEmpty, applyTitleOnError } from 'sharedKernel/handlers'
import { regexPatterns } from 'sharedKernel/regex'
import { handleUserConfigFormValidity, isPhoneOrMobileFilled } from './behavior/validation'
import { IFormFieldProperties } from 'domain/feature/hotsite/Forms/contracts'
import { LabelInputFile } from 'sharedKernel/styledComponents/fileComponents/styles'

export const PersonalConfigForm = (): JSX.Element => {
  const userConfigFormState = useSelector((state: TReducers) => state.hubIUserConfigFormState)
  const profileImage = useSelector((state: TReducers) => state.hubIProfileImage)

  const labels = {
    name: trans('Insira seu nome'),
    phone: trans('Insira seu telefone'),
    mobile: trans('Insira seu celular'),
    zipcode: trans('Insira seu CEP'),
    lineOne: trans('Insira seu endereço'),
    number: trans('Insira seu numero'),
    lineTwo: trans('Insira seu complemento'),
    city: trans('Insira sua cidade'),
    state: trans('Insira seu estado'),
    fileSize: trans('Cada arquivo deve ter um tamanho máximo de 5 MB'),
    profileImage: trans('Alterar imagem de perfil'),
    invalidNotFoundCEP: trans('CEP inválido ou não encontrado'),
    titleMessage: trans('Preencha este campo')
  }

  const [previewFile, setPreview] = useState<any>(profileImage.path)
  const noImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'

  const handleChange = (value, key): void => {
    userConfigFormState[key].value = value
    store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
  }

  useEffect(() => {
    if (profileImage != null) {
      setPreview(profileImage.path)
    } else {
      setPreview(noImage)
    }
  }, [profileImage])

  const handleZIPActions = async (zipcode: IFormFieldProperties): Promise<void> => {
    if (ifNotEmptyValidateCEP(zipcode)) {
      await autoFillAddressByZIP(userConfigFormState.zipcode.value.replace(regexPatterns.clearMaskedNumbers, ''))
    }
  }

  const autoFillAddressByZIP = async (zipcode: string): Promise<void> => {
    await getAddressByZIP(zipcode)
      .then((response) => {
        fillAddress(response)
      }).catch((error) => {
        showToast('error', error)
        setInvalid(userConfigFormState.zipcode, labels.invalidNotFoundCEP)
        store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
      })
  }

  const fillAddress = (address: IViaCepServiceAddressResponse): void => {
    handleChange(address.uf, 'state')
    handleChange(address.logradouro, 'lineOne')
    handleChange(address.localidade, 'city')
  }

  return (
    <>
      <FormTitle>
        <h1>Configurações pessoais</h1>
        <hr></hr>
      </FormTitle>
      <Row>
        <Col sm={12} className="d-flex flex-row gap-3">
          <Image style={{ objectFit: 'cover' }} src={previewFile} rounded width={60} height={60} />
          <LabelInputFile style={{ fontWeight: '700', height: '50%', marginTop: '25px' }} isClickable>
            <FontAwesomeIcon className="pr-2" icon={['fas', 'upload']} />
            {labels.profileImage}
            <input
              className="form-check-input"
              type="file"
              accept="image/*"
              onChange={(event) => {
                const files = event.target.files ?? []

                const file = files[0]

                if (file.size > 5242880) {
                  showToast('warning', trans(labels.fileSize))
                  return
                }

                const selectedFile: IProfileImage = {
                  // id: `${String(uuidv4())}_idScreen`,
                  path: URL.createObjectURL(file),
                  isDeleted: false,
                  name: file.name.replace(/ /g, '_'),
                  size: file.size,
                  mimetype: file.type,
                  extension: null,
                  type: 'profileImage',
                  content: null,
                  sourceFile: file
                }
                store.dispatch(updateProfileImage(selectedFile))
              }}
            />
          </LabelInputFile>
        </Col>
        <Col sm={12}>
          <Input
            label="Nome"
            value={userConfigFormState.name?.value}
            isRequired={userConfigFormState.name?.isRequired}
            title={applyTitleOnError(userConfigFormState.name?.message, labels.titleMessage)}
            isInvalid={userConfigFormState.name?.isInvalid}
            notValidFieldMessage={userConfigFormState.name?.message}
            placeholder={labels.name}
            maxLength={256}
            onChange={(event) => {
              handleChange(event.target.value, 'name')
              isEmptyField(userConfigFormState.name)
              store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
            }}
          ></Input>
        </Col>
        <Col sm={6}>
          <Input
            label="Telefone"
            isInvalid={userConfigFormState.phone?.isInvalid}
            notValidFieldMessage={userConfigFormState.phone?.message}
            value={userConfigFormState.phone?.value}
            isRequired={userConfigFormState.phone?.isRequired}
            title={applyTitleOnError(userConfigFormState.phone?.message, labels.titleMessage)}
            placeholder={labels.phone}
            code="masked"
            mask={applyMaskIfNotEmpty(userConfigFormState.phone?.value, INPUT_MASK_FORMAT.PHONE)}
            onChange={(event) => {
              handleChange(event.target.value, 'phone')
              if (isPhoneOrMobileFilled(userConfigFormState)) {
                handlePhoneValidity(userConfigFormState.phone)
              }
              store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
            }}
          ></Input>
        </Col>
        <Col sm={6}>
          <Input
            label="Celular"
            isInvalid={userConfigFormState.mobile?.isInvalid}
            notValidFieldMessage={userConfigFormState.mobile?.message}
            value={userConfigFormState.mobile?.value}
            isRequired={userConfigFormState.mobile?.isRequired}
            title={applyTitleOnError(userConfigFormState.mobile?.message, labels.titleMessage)}
            placeholder={labels.mobile}
            code="masked"
            mask={applyMaskIfNotEmpty(userConfigFormState.mobile?.value, INPUT_MASK_FORMAT.MOBILE)}
            onChange={(event) => {
              handleChange(event.target.value, 'mobile')
              if (isPhoneOrMobileFilled(userConfigFormState)) {
                handleMobileValidity(userConfigFormState.mobile)
              }
              store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
            }}
          ></Input>
        </Col>
        <Col sm={3}>
          <Input
            label="CEP"
            isInvalid={userConfigFormState.zipcode?.isInvalid}
            notValidFieldMessage={userConfigFormState.zipcode?.message}
            value={userConfigFormState.zipcode?.value}
            isRequired={userConfigFormState.zipcode?.isRequired}
            title={applyTitleOnError(userConfigFormState.zipcode?.message, labels.titleMessage)}
            placeholder={labels.zipcode}
            code="masked"
            mask={applyMaskIfNotEmpty(userConfigFormState.zipcode?.value, INPUT_MASK_FORMAT.CEP)}
            onChange={(event) => {
              handleChange(event.target.value, 'zipcode')
              handleZIPActions(userConfigFormState?.zipcode)
              store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
            }}
          ></Input>
        </Col>
        <Col sm={9}>
          <Input
            label="Endereço"
            disabled={true}
            isInvalid={userConfigFormState.lineOne?.isInvalid}
            notValidFieldMessage={userConfigFormState.lineOne?.message}
            value={userConfigFormState.lineOne?.value}
            isRequired={userConfigFormState.lineOne?.isRequired}
            placeholder={labels.lineOne}
            maxLength={256}
            onChange={(event) => {
              handleChange(event.target.value, 'lineOne')
            }}
          ></Input>
        </Col>
        <Col sm={3}>
          <Input
            label="Numero"
            isInvalid={userConfigFormState.number?.isInvalid}
            notValidFieldMessage={userConfigFormState.number?.message}
            value={userConfigFormState.number?.value}
            isRequired={userConfigFormState.number?.isRequired}
            title={applyTitleOnError(userConfigFormState.number?.message, labels.titleMessage)}
            placeholder={labels.number}
            maxLength={10}
            onChange={(event) => {
              handleChange(event.target.value, 'number')
              isEmptyField(userConfigFormState.number)
              store.dispatch(updateConfigForm(handleUserConfigFormValidity(userConfigFormState)))
            }}
          ></Input>
        </Col>
        <Col sm={3}>
          <Input
            label="Complemento"
            isInvalid={userConfigFormState.lineTwo?.isInvalid}
            notValidFieldMessage={userConfigFormState.lineTwo?.message}
            value={userConfigFormState.lineTwo?.value}
            isRequired={userConfigFormState.lineTwo?.isRequired}
            placeholder={labels.lineTwo}
            maxLength={256}
            onChange={(event) => {
              handleChange(event.target.value, 'lineTwo')
            }}
          ></Input>
        </Col>
        <Col sm={3}>
          <Input
            label="Cidade"
            disabled={true}
            isInvalid={userConfigFormState.city?.isInvalid}
            notValidFieldMessage={userConfigFormState.city?.message}
            value={userConfigFormState.city?.value}
            isRequired={userConfigFormState.city?.isRequired}
            placeholder={labels.city}
            maxLength={256}
            onChange={(event) => {
              handleChange(event.target.value, 'city')
            }}
          ></Input>
        </Col>
        <Col sm={2}>
          <DescriptedLabel>
            {
              <span style={{ fontWeight: 'bold' }}>
                {'Estado'}
                {<span style={{ color: 'red' }}> *</span>}
              </span>
            }
          </DescriptedLabel>
          <Form.Select
            disabled={true}
            value={String(userConfigFormState.state?.value)}
            onChange={(event) => {
              handleChange(event.target.value, 'state')
            }}
          >
            {DROPDOWN_BRAZIL_STATES.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
        </Col>
      </Row>
    </>
  )
}
