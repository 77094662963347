import styled from 'styled-components'

const evaluationFormWidth = 370

export const FormIdeaOuterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const FormIdeaInnerDiv = styled.div`
  width: 100%;
`

export const FormIdeaSections = styled.div`
  padding-top: 0.5rem;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  ::-webkit-scrollbar-track {
  background-color: var(--white);
  }
`

export const FormIdeaHeader = styled.div`
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
  align-items: center;
  background-color: var(--white);
`

export const FormIdeaSectionName = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

export const FormIdeaQuestion = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.875rem;
  text-align: left;
  font-weight: bold
`

export const FormIdeaAnswer = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1.75rem;
  text-align: left;
`

export const FileDiv = styled.div`
  width: fit-content;
`
export const AnswerLink = styled.a`
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
`
export const FormTitle = styled.h1`
  font-size: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-bottom: 1rem;
`
