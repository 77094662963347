import { trans } from 'sharedKernel/i18n'

export const setStatusColor = (status: string, fillPercentage: number): string => {
  let color

  switch (status) {
    case 'Created':
      color = '#464646'
      break
    case 'Launched':
      color = '#FFBF00'
      break
    case 'Canceled':
      color = '#DD1D1D'
      break
    case 'Approved':
      color = '#4BBD64'
      break
    case 'Disapproved':
      color = '#FF0000'
      break
  }
  return color
}

export const getIdeaImage = (url?: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const fallBackideaImage = require('../../../../../../../assets/images/Imagem_do_programa_small.png')

  return url != null && url.length > 0 ? url : fallBackideaImage
}

export const getBtnName = (isLaunched: boolean, fillPercentage: number): string => {
  if (isLaunched || fillPercentage === 100) {
    return trans('Ver ideia')
  }
  return trans('Continuar')
}
