import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { DescriptedLabel, Input, Password } from 'iebt-zing-storybook'
import { store } from 'store'
import _ from 'lodash'

import { HotsiteFormMain } from '..'
import { trans } from 'sharedKernel/i18n'
import { DROPDOWN_BRAZIL_STATES, INPUT_MASK_FORMAT } from 'sharedKernel/constants'
import { RegisterActions } from './FormActions'
import { IHotsiteRegisterForm, resetRegisterForm, updateRegisterForm } from './store'
import {
  handleFormValidity,
  handlePasswordAndConfirmPasswordEquality, ifNotEmptyValidatePassword
} from './FormActions/validations'
import { IFormFieldProperties } from '../contracts'
import { ifNotEmptyValidateCEP, ifNotEmptyValidateEmail, ifNotEmptyValidateMobile, isEmptyField } from '../validations'
import { getAddressByZIP } from 'sharedKernel/api/utils/cep'
import { showToast } from 'components/toast'
import { IViaCepServiceAddressResponse } from 'sharedKernel/api/utils/cep/contract'
import { applyMaskIfNotEmpty, applyTitleOnError } from 'sharedKernel/handlers'
import { regexPatterns } from 'sharedKernel/regex'
import { passwordAcceptance } from 'components/Password/Rules'
import { PasswordAcceptance } from './style'

export interface HotsiteRegisterFormProps {
  registerFormStateProps: IHotsiteRegisterForm
  registerUrl: string
  userType: string
}

export const HotsiteRegisterForm = ({ registerFormStateProps, registerUrl, userType }: HotsiteRegisterFormProps): JSX.Element => {
  const registerFormState = { ...registerFormStateProps }

  const labels = {
    formTitleResolver: trans('Cadastre-se e seja um solucionador de desafios'),
    formTitleEvaluator: trans('Cadastre-se e seja um avaliador de soluções'),
    startupName: trans('Nome do solucionador'),
    startupNamePlaceholder: trans('Insira o nome da sua empresa'),
    contactName: trans('Nome'),
    namePlaceholder: trans('Insira seu nome'),
    email: trans('E-mail'),
    emailPlaceholder: trans('Insira seu e-mail'),
    phone: trans('Celular'),
    phonePlaceholder: trans('Insira seu número de celular'),
    cep: trans('CEP'),
    cepSearch: trans('Não sabe seu CEP?'),
    cepPlaceholder: trans('Insira seu cep'),
    lineOne: trans('Endereço'),
    lineOnePlaceholder: trans('Insira seu endereço'),
    addressLineOneNumber: trans('Número'),
    addressLineOneNumberPlaceholder: trans('Insira o número'),
    lineTwo: trans('Complemento'),
    lineTwoPlaceholder: trans('Insira o complemento'),
    city: trans('Cidade'),
    cityPlaceholder: trans('Insira sua cidade'),
    state: trans('Estado'),
    password: trans('Senha'),
    passwordPlaceholder: trans('Insira sua senha'),
    passwordConfirmation: trans('Confirmação de senha'),
    passwordConfirmationPlaceholder: trans('Confirme sua senha'),
    titleMessage: trans('Preencha este campo'),
    passwordAcceptance: trans('Sua senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um número, um caracter especial')
  }

  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    return () => {
      store.dispatch(resetRegisterForm())
    }
  }, [])

  const handleChange = (value, key): void => {
    registerFormState.form[key].value = value
    store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
  }

  const handleLineOneChange = (value, key): void => {
    if (value === '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
    handleChange(value, key)
  }

  const passwordAndConfirmPasswordValidations = (currentPassword: IFormFieldProperties): boolean => {
    if (ifNotEmptyValidatePassword(currentPassword)) {
      return handlePasswordAndConfirmPasswordEquality(
        registerFormState?.form.password,
        registerFormState?.form.confirmPassword
      )
    }
    return false
  }

  const handleZIPActions = async (zipcode: IFormFieldProperties): Promise<void> => {
    if (ifNotEmptyValidateCEP(zipcode)) {
      await autoFillAddressByZIP(registerFormState.form.zipcode.value.replace(regexPatterns.clearMaskedNumbers, ''))
    }
  }

  const autoFillAddressByZIP = async (zipcode: string): Promise<void> => {
    await getAddressByZIP(zipcode)
      .then((response) => {
        fillAddress(response)
      }).catch((error) => {
        showToast('error', error)
      })
  }

  const fillAddress = (address: IViaCepServiceAddressResponse): void => {
    handleChange(address.uf, 'state')
    handleLineOneChange(address.logradouro, 'lineOne')
    handleChange(address.localidade, 'city')
  }

  return (
    <>
      <HotsiteFormMain formTitle={userType === 'resolver' ? labels.formTitleResolver : labels.formTitleEvaluator}>
        {registerFormState.form.startupName != null
          ? <Row className='my-2'>
            <Col lg={true}>
              <Input
                id='startupName'
                maxLength={256}
                isRequired={true}
                title={applyTitleOnError(registerFormState?.form.startupName.message, labels.titleMessage)}
                label={`${labels.startupName}`}
                placeholder={labels.startupNamePlaceholder}
                value={registerFormState?.form.startupName?.value}
                isInvalid={registerFormState?.form.startupName?.isInvalid}
                notValidFieldMessage={registerFormState?.form.startupName?.message}
                onChange={(event) => {
                  handleChange(event.target.value, 'startupName')
                }}
                onBlur={() => {
                  if (registerFormState.form.startupName != null) {
                    isEmptyField(registerFormState.form.startupName)
                  }
                  store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                }}
              ></Input>
            </Col>
          </Row>
          : <></>}
        <Row className='my-2'>
          <Col lg={true}>
            <Input
              id='contactName'
              maxLength={256}
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.contactName.message, labels.titleMessage)}
              label={`${labels.contactName}`}
              placeholder={labels.namePlaceholder}
              value={registerFormState?.form.contactName.value}
              isInvalid={registerFormState?.form.contactName.isInvalid}
              notValidFieldMessage={registerFormState?.form.contactName.message}
              onChange={(event) => {
                handleChange(event.target.value, 'contactName')
              }}
              onBlur={() => {
                isEmptyField(registerFormState.form.contactName)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <Input
              id='email'
              maxLength={256}
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.email.message, labels.titleMessage)}
              label={`${labels.email}`}
              placeholder={labels.emailPlaceholder}
              value={registerFormState?.form.email.value}
              isInvalid={registerFormState?.form.email.isInvalid}
              notValidFieldMessage={registerFormState?.form.email.message}
              onChange={(event) => {
                handleChange(event.target.value.trim(), 'email')
              }}
              onBlur={() => {
                ifNotEmptyValidateEmail(registerFormState?.form.email)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <Input
              id='phone'
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.phone.message, labels.titleMessage)}
              label={`${labels.phone}`}
              placeholder={labels.phonePlaceholder}
              value={registerFormState?.form.phone.value}
              code="masked"
              mask={applyMaskIfNotEmpty(registerFormState?.form.phone.value, INPUT_MASK_FORMAT.MOBILE)}
              isInvalid={registerFormState?.form.phone.isInvalid}
              notValidFieldMessage={registerFormState?.form.phone.message}
              onChange={(event) => {
                handleChange(event.target.value, 'phone')
              }}
              onBlur={() => {
                ifNotEmptyValidateMobile(registerFormState?.form.phone)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={4}>
            <Input
              id='zipcode'
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.zipcode.message, labels.titleMessage)}
              label={`${labels.cep}`}
              code='masked'
              mask={applyMaskIfNotEmpty(registerFormState?.form.zipcode.value, INPUT_MASK_FORMAT.CEP)}
              placeholder={labels.cepPlaceholder}
              value={registerFormState?.form.zipcode.value}
              isInvalid={registerFormState?.form.zipcode.isInvalid}
              notValidFieldMessage={registerFormState?.form.zipcode.message}
              onChange={(event) => {
                handleChange(event.target.value, 'zipcode')
                handleZIPActions(registerFormState?.form.zipcode)
              }}
            ></Input>
            <a className="text-decoration-none" href="https://www2.correios.com.br/sistemas/buscacep/buscaCep.cfm" target="_blank">
              {labels.cepSearch}
            </a>
          </Col>
          <Col lg={8}>
            <Input
              id='lineOne'
              maxLength={256}
              isRequired={true}
              disabled={isDisabled}
              label={`${labels.lineOne}`}
              placeholder={labels.lineOnePlaceholder}
              value={registerFormState.form.lineOne.value}
              isInvalid={registerFormState.form.lineOne.isInvalid}
              notValidFieldMessage={registerFormState.form.lineOne.message}
              onChange={(event) => {
                handleChange(event.target.value, 'lineOne')
              }}
              onBlur={() => {
                isEmptyField(registerFormState.form.lineOne)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <Input
              id='number'
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.number.message, labels.titleMessage)}
              label={`${labels.addressLineOneNumber}`}
              placeholder={labels.addressLineOneNumberPlaceholder}
              value={registerFormState?.form.number.value}
              isInvalid={registerFormState?.form.number.isInvalid}
              notValidFieldMessage={registerFormState?.form.number.message}
              onChange={(event) => {
                handleChange(event.target.value.trim(), 'number')
              }}
              onBlur={() => {
                isEmptyField(registerFormState?.form.number)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
          <Col lg={true}>
            <Input
              id='lineTwo'
              maxLength={256}
              label={`${labels.lineTwo}`}
              placeholder={labels.lineTwoPlaceholder}
              value={registerFormState?.form.lineTwo.value}
              isInvalid={registerFormState?.form.lineTwo.isInvalid}
              notValidFieldMessage={registerFormState?.form.lineTwo.message}
              onChange={(event) => {
                handleChange(event.target.value, 'lineTwo')
              }}
            ></Input>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={8}>
            <Input
              id='city'
              maxLength={256}
              isRequired={true}
              disabled={true}
              label={`${labels.city}`}
              placeholder={labels.cityPlaceholder}
              value={registerFormState?.form.city.value}
              isInvalid={registerFormState?.form.city.isInvalid}
              notValidFieldMessage={registerFormState?.form.city.message}
              onChange={(event) => {
                handleChange(event.target.value, 'city')
              }}
              onBlur={() => {
                isEmptyField(registerFormState.form.city)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Input>
          </Col>
          <Col lg={4}>
            <DescriptedLabel>{`${labels.state}`}</DescriptedLabel><span style={{ color: 'red' }}> *</span>
            <Form.Select
              id='state'
              disabled={true}
              value={registerFormState?.form.state.value}
              onChange={(event) => {
                handleChange(event.target.value, 'state')
              }}
            >
              {DROPDOWN_BRAZIL_STATES.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col lg={true}>
            <Row>
              <Password
                id='password'
                isRequired={true}
                title={applyTitleOnError(registerFormState?.form.password.message, labels.titleMessage)}
                label={`${labels.password}`}
                description={passwordAcceptance()}
                placeholder={labels.passwordPlaceholder}
                value={registerFormState?.form.password.value}
                isInvalid={registerFormState?.form.password.isInvalid}
                notValidFieldMessage={registerFormState?.form.password.message}
                onChange={(event) => {
                  handleChange(event.target.value.trim(), 'password')
                }}
                onBlur={() => {
                  passwordAndConfirmPasswordValidations(registerFormState?.form.password)
                  store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
                }}
              ></Password>
            </Row>
          </Col>
          <Col lg={true}>
            <Password
              id='confirmPassword'
              isRequired={true}
              title={applyTitleOnError(registerFormState?.form.confirmPassword.message, labels.titleMessage)}
              label={`${labels.passwordConfirmation}`}
              description={passwordAcceptance()}
              placeholder={labels.passwordConfirmationPlaceholder}
              value={registerFormState?.form.confirmPassword.value}
              isInvalid={registerFormState?.form.confirmPassword.isInvalid}
              notValidFieldMessage={registerFormState?.form.confirmPassword.message}
              onChange={(event) => {
                handleChange(event.target.value.trim(), 'confirmPassword')
              }}
              onBlur={() => {
                passwordAndConfirmPasswordValidations(registerFormState?.form.confirmPassword)
                store.dispatch(updateRegisterForm(handleFormValidity(registerFormState)))
              }}
            ></Password>
          </Col>
        </Row>
        <Row>
          <PasswordAcceptance isInvalid={registerFormState?.form.password.isInvalid}>{labels.passwordAcceptance}</PasswordAcceptance>
        </Row>
        <RegisterActions registerUrl={registerUrl} userType={userType} />
      </HotsiteFormMain>
    </>
  )
}
