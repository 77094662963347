/* eslint-disable multiline-ternary */
import { trans } from 'sharedKernel/i18n'
import React, { createContext, useEffect, useState } from 'react'
import { Prompt, useSearchParams } from 'react-router-dom'
import { resetChallengeFiles, resetChallengeSection, store, TReducers, useSelector } from 'store'
import { FormWrapper } from 'domain/feature/hub/company/challenge/FormWrapper'
import { Loading } from 'components/loading'
import { validateAll } from 'domain/feature/hub/company/challenge/FormWrapper/behaviors/validation'
import { fillChallenge } from '../formBuilder/fillForm'

export const InitialFormContext = createContext('')

export const HubCompanyNewChallengePage = (): any => {
  const [isNavigationBlocked, setIsNavigationBlocked] = useState<boolean>(false)
  const formStateValues = useSelector((state: TReducers) => state.hubChallengeBasicFormState)
  const formFilesState = useSelector((state: TReducers) => state.hubChallengeBasicFormFilesState)
  const [initialFormValue, setInitialFormValue] = useState('')
  const filled = fillChallenge(formStateValues, formFilesState)
  const [searchParams, setSearchParams] = useSearchParams()
  const validate = searchParams.get('validate')
  if (validate !== undefined && (validate === 'launch' || validate === 'save')) {
    validateAll(validate)
  }
  const [tabs, setTabs] = useState(filled)
  const [isFilesSaved, setIsFilesSaved] = useState(false)
  useEffect(() => {
    setInitialFormValue(JSON.stringify(formStateValues))

    return () => {
      store.dispatch(resetChallengeSection())
      store.dispatch(resetChallengeFiles())
    }
  }, [])

  return (
    <>
      <Prompt
        when={isNavigationBlocked}
        message={trans('O formulário contém alterações não salvas. Tem certeza que deseja sair?')}
      />
      <InitialFormContext.Provider value={initialFormValue}>
        {isFilesSaved ? (
          <Loading />
        ) : (
          <FormWrapper
            tabs={tabs}
            setIsNavigationBlocked={setIsNavigationBlocked}
            formFilesState={formFilesState}
            formStateValues={formStateValues}
            requireDates={formStateValues.isLaunched ?? false}
            setFilesSaved={setIsFilesSaved}
          ></FormWrapper>
        )}
      </InitialFormContext.Provider>
    </>
  )
}
