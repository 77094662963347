import styled from 'styled-components'

export const ChallengeCardDescription = styled.div`
  margin-top: 1%;

  padding: 0 1rem;

  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
`

export const ChallengeCardShortDescription = styled.span`
  width: 100%;
  word-break: break-all;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
`

export const ChallengeCardLimit = styled.div`
  border-radius: 4px;
  font-weight: 700;
  min-width: max-content;
  opacity: 1;
  font-size: 14px;
  color: var(--risk);
  background-color: var(--background-risk);
  border: 2px solid var(--background-risk);
  height: 32px;
  padding: 4px 16px;
  width: 32px;
`
