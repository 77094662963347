/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
import { IField, IOption, ISection } from '../constants'
import { v4 as uuidv4 } from 'uuid'

export function duplicateOptions(options?: IOption[]): IOption[] {
  const newOptions: IOption[] = []

  if (options === undefined || options === null) {
    return newOptions
  }
  options.map((option, optionKey) => {
    const newOption = { ...option }
    newOption.id = `${String(uuidv4())}_idScreen`
    newOptions.push(newOption)
  })

  return newOptions
}

export function duplicateField(field: IField) {
  const newField = { ...field }
  newField.id = `${String(uuidv4())}_idScreen`
  newField.options = duplicateOptions(field.options)
  return newField
}

export function duplicateFields(fields?: IField[]) {
  const newFields: IField[] = []
  if (fields === undefined || fields === null) {
    return newFields
  }
  fields.map((field, fieldKey) => {
    const newField = { ...field }
    newField.id = `${String(uuidv4())}_idScreen`
    newFields.push(newField)
  })

  return newFields
}

export function duplicateSection(section: ISection) {
  const newSection = { ...section }
  newSection.id = `${String(uuidv4())}_idScreen`
  newSection.fields = duplicateFields(section.fields)
  return newSection
}
