import styled from 'styled-components'

const evaluationFormWidth = 370

export const FormIdeaOuterDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FormIdeaInnerDiv = styled.div`
  width: calc(100% - ${evaluationFormWidth}px);
  max-height: 70vh;
  margin-top: 50px;
`
