import { cookies } from 'sharedKernel/cookies/behavior'

export const hasAccess = (permissionToFind: string, accessToFind: string): boolean => {
  const features = cookies.get('_perm')?.features

  if (features !== undefined) {
    const permission = features.find((feature) => feature.code === permissionToFind)

    if (permission !== undefined) {
      const accesses = permission.accesses
      const access = accesses.find((access) => access.code === accessToFind)

      if (access !== undefined) {
        return true
      }
    }
  }
  return false
}
