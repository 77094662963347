/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Button, Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

interface FormItemProps {
  hasMaxLength?: boolean
}

export const FormItem = styled.div<FormItemProps>`
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: ${(props) => (props.hasMaxLength ? '-10px' : '10px')};
  margin-left: 10px;
`

export const ErrorLabel = styled.div`
  font-size: 0.725rem;
  padding-bottom: 1rem;
  color: #dc3545;
  font-weight: 400;
`
export const StyledSwitch = styled.div`
  display: block;
  label {
    display: inline-block;
    padding: 0 0.5rem 0 0;
  }
  .form-switch {
    display: inline-block;
    vertical-align: middle;
  }
`

export const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
    color: red !important;
  }
`

export const ActionButton = styled(Button)`
  font-size: 14px;
  float: none !important;
  flex: none;
`

export const LabelInputFileWrapper = styled.div`
  margin-bottom: 24px;
`

export const LabelInputFileDescription = styled.small`
  font-size: 0.625rem;
  color: #838383;
  font-weight: 400;
`

export const FormChallengeHeader = styled.div`
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 2;

  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
`

export const FormChallengeWrapper = styled.div`
  height: fit-content;
  .form-control:focus,
  .form-control:active,
  .rbt-input-multi.focus {
    border-color: rgba(95, 42, 178, 0.5) !important;
    box-shadow: 0 0 0 0.25rem rgba(95, 42, 178, 0.5) !important;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
`

export const TitleAndButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding-top: 1rem;
  height: 51px;
`

export const AddButton = styled(Button)`
  color: var(--primary);
  background: #fff;
  border: 2px dotted var(--primary);
  font-size: 14px;
  :hover {
    color: #0d6efd;
    background: #fff;
    border: 2px dotted var(--primary);
  }
  :focus {
    background: #fff;
    border: 2px dotted var(--primary);
    color: var(--primary);
  }
`

export const LightButton = styled(Button)`
  background-color: #ece2fc;
  border-color: #ece2fc;

  :hover,
  :focus {
    background-color: #ece2fc;
    border-color: #ece2fc;
  }
`
