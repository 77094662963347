import { EVALUATOR_INVITE_STATUS } from 'sharedKernel/constants'
import { trans } from 'sharedKernel/i18n'

export const getInviteStatusLabel = (statusCode: string, plural: boolean = false): string => {
  switch (statusCode) {
    case EVALUATOR_INVITE_STATUS.INVITED:
      return plural ? trans('Pendentes') : trans('Pendente')
    case EVALUATOR_INVITE_STATUS.REFUSED:
      return plural ? trans('Recusados') : trans('Recusado')
    case EVALUATOR_INVITE_STATUS.ACCEPTED:
      return plural ? trans('Aceitos') : trans('Aceito')
    case EVALUATOR_INVITE_STATUS.CANCELED:
      return plural ? trans('Cancelados') : trans('Cancelado')
    default:
      return ''
  }
}
