import { Validation } from '../contracts'

export class Sections implements Validation {
  valid: boolean
  errorMessage: string = ''

  constructor() {
    this.valid = true
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    const sessionsLength = value.sections.length
    let deletedSections = 0
    let deletedFields = 0
    this.valid = true
    this.errorMessage = ''
    value.sections.forEach((section) => {
      if (section.isDeleted === true) {
        deletedSections = deletedSections + 1
      }
      return deletedSections
    })
    if (deletedSections === sessionsLength) {
      this.valid = false
      this.errorMessage = 'É necessário haver ao menos uma seção'
    }

    if (deletedSections === sessionsLength - 1) {
      value.sections.forEach((section) => {
        if (section.isDeleted === false) {
          if (section.fields.length === 0) {
            this.valid = false
            this.errorMessage = 'É necessário haver ao menos uma pergunta'
          } else {
            section.fields.forEach((field) => {
              if (field.isDeleted === true) {
                deletedFields = deletedFields + 1
              }
            })
            if (section.fields.length === deletedFields) {
              this.valid = false
              this.errorMessage = 'É necessário haver ao menos uma pergunta'
            }
          }
        }
        return deletedSections
      })
    }
  }
}
