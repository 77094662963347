import React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { SwiperSlide } from 'swiper/react'
import { CarouselMenuItemWrapper, CarouselMenuItem } from '../../swiperNav/style'
import { SwiperNav, ICarouselNavItems } from '..'
import '../navStyle.css'

export interface SwiperWrapperProps {
  carouselNavItems: ICarouselNavItems[]
  breakpoints: any
  activeSection: number
  setActiveSection: Function
}

export const SwiperNavMenu = ({
  carouselNavItems,
  breakpoints,
  activeSection,
  setActiveSection
}: SwiperWrapperProps): JSX.Element => {
  const menuSections = <>{carouselNavItems.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <CarouselMenuItemWrapper>
          <CarouselMenuItem className={index === activeSection ? 'activeNavItem' : ''} onClick={() => setActiveSection(index)}>
            {item.name}
          </CarouselMenuItem>
        </CarouselMenuItemWrapper>
      </SwiperSlide>
    )
  })}
  </>

  return (
    <SwiperNav breakpoints={breakpoints} sections={menuSections} />
  )
}
