import React, { useState } from 'react'
import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'
import { IdeaEvaluation } from '../IdeaEvaluation'
import {
  FormIdeaInnerDiv,
  FormHeader,
  FormIdeaOuterDiv
} from './styles'
import {
  FormIdeaHeader,
  FormIdeaSections
} from '../styles'
import { SwiperNavForm } from 'components/swiper/swiperNav/swiperNavForm'
import { FormTitle as FormIdeaResolverName } from 'sharedKernel/styledComponents/formComponents/styles'

export interface FormIdeaMobileProps{
  solutionTitle: string
  breakpoints: any
  carouselNavItems: any
  setIsNavigationBlocked: Function
  resolverId: string
  evaluation: any
  formIdeaSectionsBody: JSX.Element
}
export const FormIdeaMobile = ({
  solutionTitle
  , breakpoints
  , carouselNavItems
  , setIsNavigationBlocked
  , resolverId
  , evaluation
  , formIdeaSectionsBody
}: FormIdeaMobileProps): JSX.Element => {
  const [activeSection, setActiveSection] = useState<number>(0)

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 2,
        spaceBetween: 5
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 5
      },
      700: {
        slidesPerView: 4,
        spaceBetween: 5
      },
      900: {
        slidesPerView: 5,
        spaceBetween: 5
      }
    }
  }
  const navBarBuilder = (): any[] => {
    return [
      { itemHash: 'personal-config', name: 'Ideia' },
      { itemHash: 'change-password', name: 'Avaliação' }
    ]
  }
  const formsBuilder = (): JSX.Element => {
    const forms = [buildIdeaAnswers(), buildIdeaEvaluation()]
    return forms[activeSection]
  }

  const buildIdeaEvaluation = (): JSX.Element => {
    return (
        <IdeaEvaluation
            evaluation={evaluation}
            resolverId={resolverId}
            setIsNavigationBlocked={setIsNavigationBlocked}
        />
    )
  }
  const buildIdeaAnswers = (): JSX.Element => {
    return (
        <FormIdeaInnerDiv>
            <FormIdeaHeader>
                <FormIdeaResolverName>{solutionTitle}</FormIdeaResolverName>
                <SwiperNavForm breakpoints={breakpoints} carouselNavItems={carouselNavItems} offset={-10} containerId='FormIdeaSections' />
            </FormIdeaHeader>
            <FormIdeaSections id='FormIdeaSections'>
                {formIdeaSectionsBody}
            </FormIdeaSections>
        </FormIdeaInnerDiv>
    )
  }
  return (
    <>
        <FormHeader>
            <SwiperNavMenu
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                breakpoints={buildBreakpoints()}
                carouselNavItems={navBarBuilder()}
            />
        </FormHeader>
        <FormIdeaOuterDiv>
          {formsBuilder()}
        </FormIdeaOuterDiv>
    </>
  )
}
