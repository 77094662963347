import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { HotsiteChallengeProps } from 'domain/feature/hotsite/store/contracts'
import { saveHotsiteChallenges, store, TReducers, useSelector } from 'store'
// Componentes
import { MarketplaceMain } from 'domain/feature/hotsite/Marketplace'
import { Loading } from 'components/loading'
import { HotsiteInnerPage } from 'main/pages/defaults/HotsiteInnerPage'

export const MarketplacePage = (): JSX.Element => {
  const request: GetFormat = {
    url: '/v1/hotsite/challenge'
  }
  const { data, isFetching } = useGet<HotsiteChallengeProps[]>(request, 'hotsite-challenges')

  const renderResponse = (): JSX.Element => {
    if (isFetching) {
      return (
        <div style={{ height: '100vh' }}>
          <Loading />
        </div>
      )
    }
    if (data !== undefined) {
      store.dispatch(saveHotsiteChallenges(data))
    }

    return <MarketplaceMain />
  }

  return (
    <>
      {renderResponse()}
    </>
  )
}
