import { UPDATE_APPLICATION_FORM, RESET_APPLICATION_FORM } from './actions'
import { IHubStartupChallengeApplicationForm } from './contracts'

export const APPLICATION_FORM_INITIAL_STATE: IHubStartupChallengeApplicationForm = {
  id: null,
  title: '',
  challenge: {
    id: '',
    applicationForm: {
      id: ''
    }
  },
  sections: [],
  isLaunched: false,
  status: null
}
export interface IHubStartupChallengeApplicationFormStateAction {
  type: string
  payload: IHubStartupChallengeApplicationForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubStartupChallengeApplicationForm = (
  state: IHubStartupChallengeApplicationForm = APPLICATION_FORM_INITIAL_STATE,
  action: IHubStartupChallengeApplicationFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_APPLICATION_FORM:
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
        challenge: action.payload.challenge,
        sections: action.payload.sections,
        isLaunched: action.payload.isLaunched,
        status: action.payload.status
      }

    case RESET_APPLICATION_FORM:
      return {
        ...state,
        id: null,
        title: '',
        challenge: {
          id: '',
          applicationForm: {
            id: ''
          }
        },
        sections: [],
        isLaunched: false,
        status: null
      }

    default:
      return state
  }
}
