import { IFormFieldProperties } from '../../contracts'
import { regexPatterns } from 'sharedKernel/regex'
import { setValid, setInvalid } from '..'

export const invalidMessages = {
  invalidEmail: 'E-mail inválido'
}

export const validateEmail = (email: IFormFieldProperties): boolean => {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!regexPatterns.email.test(email.value.trim())) {
    setInvalid(email, invalidMessages.invalidEmail)
    return false
  }

  setValid(email)
  return true
}
