import React from 'react'
import { LandingPage } from 'domain/feature/hotsite/LandingPage'

export const HotSiteLandingPage = (): JSX.Element => {
  return (
    <>
      <LandingPage></LandingPage>
    </>
  )
}
