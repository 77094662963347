import { DescriptedLabel } from 'iebt-zing-storybook'
import React, { useState } from 'react'
import { IEvaluation, IField } from '../constants'
import { BuildField } from './field'
import { SectionArea } from './sectionArea'

export function EvaluationArea(evaluation: IEvaluation, evaluationKey: number, disabled: boolean = false): JSX.Element {
  let countSteps = 1
  const [evaluationState, setEvaluationState] = useState<IEvaluation>(evaluation)

  return (
    <div key={evaluationKey}>
      {evaluationState.steps.map((step, stepKey) => {
        if (step.isDeleted) {
          return true
        }

        return (
          <div style={{ margin: '0px 0px 10px 30px' }} key={stepKey}>
            <DescriptedLabel boldText={true}>{`Rodada de Avaliação #${countSteps++}`}</DescriptedLabel>
            <div style={{ margin: '0px 0px 0px 10px' }}>
              <div className="mb-4">
                {BuildField(
                  {
                    id: step.id,
                    type: 'text',
                    value: step.name,
                    namePlaceholder: step.namePlaceholder,
                    label: 'Nome da Etapa',
                    isRequired: true,
                    description: 'Campo Obrigatório',
                    maxLength: 140,
                    isDeleted: false,
                    path: `evaluation.steps[${stepKey}]`,
                    validations: [
                      {
                        type: 'required',
                        stage: 'launch'
                      },
                      {
                        type: 'maxLength',
                        param: 140,
                        stage: 'save'
                      },
                      {
                        type: 'letterRequired',
                        stage: 'all'
                      }
                    ],
                    updateFieldState: (field: IField) => {
                      evaluationState.steps[stepKey].name = field.value
                      setEvaluationState({ ...evaluationState })
                    },
                    dataCy: `evaluationStep${stepKey + 1}NameInput`,
                    disabled: disabled
                  },
                  step.id
                )}
              </div>
            </div>
            <div>
              <SectionArea
                initialState={step}
                stateKey={stepKey}
                sectionIdentifier={`evaluation.steps[${stepKey}]`}
                updateState={(item) => {
                  evaluationState.steps[stepKey].sections = item.sections
                  setEvaluationState({ ...evaluationState })
                }}
                availableQuestionTypes={[
                  {
                    label: 'Pontuação',
                    value: 'score'
                  }
                ]}
                defaultQuestionType="score"
                disabled={disabled}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
