import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { ChallengeButtonsProps } from '../contracts'
import { trans } from 'sharedKernel/i18n'
export const EditChallengeButton = ({ challengeId, isOwner }: ChallengeButtonsProps): JSX.Element => {
  const labels = {
    toolTipContent: trans('Editar')
  }
  const haveAccess = hasAccess('challenge', 'update')
  const navigate = useNavigate()
  if (!haveAccess) {
    return <></>
  }
  if (!isOwner) {
    return <></>
  }
  const handleEditChallenge = (challengeId: string): void => {
    navigate(`/hub/company/challenge/${challengeId}/edit`)
  }
  const icon = <FontAwesomeIcon icon={['fas', 'pen']} size="xl" />
  return (
    <BaseButton
      btnContent={icon}
      variant="primary-invert"
      size="icon"
      tippyContent={labels.toolTipContent}
      onClick={() => {
        handleEditChallenge(challengeId)
      }}
    />
  )
}
