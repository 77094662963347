import _ from 'lodash'
import { PostFormat } from 'sharedKernel/api'
import { cookies } from 'sharedKernel/cookies/behavior'
import { IPasswordForm } from '../../ChangePasswordForm/store'
import { IUserConfigForm } from '../../PersonalConfigForm/store/configForm'
import { IProfileImage } from '../../PersonalConfigForm/store/profileImage'

export const createUserRequest = (userConfigFormState: IUserConfigForm): PostFormat => {
  const userId: string = cookies.get('_cred').id
  const configFormArray = Object.entries(userConfigFormState)
  const entriesArray: any = []
  configFormArray.forEach((pair) => {
    entriesArray.push([pair[0], pair[1].value])
  })
  const object = Object.fromEntries(entriesArray)

  const contact = _.pick(object, ['phone', 'mobile'])
  const address = _.pick(object, ['zipcode', 'lineOne', 'number', 'lineTwo', 'city', 'state'])
  const postObject: PostFormat = {
    url: `/v1/system/user/${userId}`,
    data: { data: { name: object.name, contact: contact, address: address } }
  }
  return postObject
}

export const createProfileImageRequest = (profileImage: IProfileImage): PostFormat => {
  const userId: string = cookies.get('_cred').id
  const profileImageData = _.pick(profileImage, ['mimetype', 'name', 'size', 'type', 'content'])
  const postObject: PostFormat = {
    url: '/v1/system/user/profile-image',
    data: { id: `${userId}`, data: profileImageData }
  }
  return postObject
}
