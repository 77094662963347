import React from 'react'
import { HotsiteContactForm } from 'domain/feature/hotsite/Forms/Contact'

export const HotsiteContactPage = (): JSX.Element => {
  return (
    <>
      <HotsiteContactForm />
    </>
  )
}
