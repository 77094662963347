/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { PieChartProps } from '../../common/pieChart'
import { HubCompanyChallengeDashboardSolutionsCity } from './contracts'
import { DashboardCharts } from '../index'

export interface DashboardChartCard {
  title: string
  metric: string
  dataVisualization: PieChartProps
}

export const HubCompanyDashboardSolutionsCity = (): JSX.Element => {
  const labels = {
    title: trans('Soluções '),
    metric: trans('Cidade')
  }

  const statsProps: DashboardChartCard = {
    title: labels.title,
    metric: labels.metric,
    dataVisualization: {
      data: [],
      totalValue: 0,
      colors: ['#5F2AB2', '#996fdc', '#c5adeb'],
      dimensions: {
        height: 200,
        width: 200,
        outerRadius: 80
      }
    }
  }

  const request: GetFormat = {
    url: '/v1/hub/company/dashboard/city/stats'
  }
  const { data, isFetching, isLoading, isError } = useGet<HubCompanyChallengeDashboardSolutionsCity>(
    request,
    'evaluartor-city-stats-get'
  )

  const getData = (data: any[]): any[] => {
    const mappedArray = data.map((city) => {
      return { name: `${city.city}/${city.state} `, value: city.totalSolutions }
    })

    return mappedArray
  }
  const getTotalValues = (data: DashboardChartCard): number => {
    let totalValue = 0
    data.dataVisualization.data.forEach((city) => {
      totalValue += city.value
    })
    return totalValue
  }

  if (isFetching || isLoading || isError) {
    return <DashboardCharts title={statsProps.title} metric={statsProps.metric} dataVisualization={statsProps.dataVisualization} isError={isError} isLoading={isFetching || isLoading} />
  }

  statsProps.dataVisualization.data = getData(data.data.cities)
  statsProps.dataVisualization.totalValue = getTotalValues(statsProps)

  return <DashboardCharts title={statsProps.title} metric={statsProps.metric} dataVisualization={statsProps.dataVisualization} />
}
