import React from 'react'
import { BasicTable } from 'sharedKernel/styledComponents/table'
import { CustomTd, HeaderText, CaptionText } from './style'

export interface HubTableProps {
  header: any[]
  rows: any[]
  headerSize?: any[]
  caption?: JSX.Element | string
}

export const HubTable = ({ header, rows, headerSize, caption }: HubTableProps): JSX.Element => {
  return (
    <BasicTable>
      <CaptionText>{caption}</CaptionText>
      <thead>
        <tr>
          {header.map((item, headerIndex) => {
            return <HeaderText style={{ width: headerSize != null ? headerSize[headerIndex] : 'auto' }} key={`th-${String(headerIndex)}`}>{item}</HeaderText>
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => {
          return (
            <tr key={`tr-${String(rowIndex)}`}>
              {
                row.map((colData, indexCol) => {
                  return (
                    <CustomTd className='align-items-center' key={`tr-${String(rowIndex)}-td-${String(indexCol)}`}>
                      {colData}
                    </CustomTd>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </BasicTable >
  )
}
