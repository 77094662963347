import styled from 'styled-components'

export const ApplicationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ApplicationFormWrapperHeader = styled.div`
  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
`

export const TitleWrapper = styled.div`
  width: 100%;
`

export const IdeaTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  @media only screen and (max-width: 690px) {
    width: 100%;
  }
`

export const Hr = styled.hr`
  width: 100%;
  color: var(--grey-light);
`
