/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState } from 'react'
import { BaseButton, BlankPage } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { PersonalConfigForm } from 'domain/feature/hub/user-config/PersonalConfigForm'
import { FormHeader } from 'sharedKernel/styledComponents/formComponents/styles'
import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'
import { ChangePasswordForm } from 'domain/feature/hub/user-config/ChangePasswordForm'
import { ChangeDataButtonArea } from 'domain/feature/hub/user-config/styles'
import { showToast } from 'components/toast'
import { useSelector } from 'react-redux'
import { store, TReducers } from 'store'

import { IPasswordForm, resetPasswordForm, updatePasswordForm } from 'domain/feature/hub/user-config/ChangePasswordForm/store'

import {
  handlePasswordFormValidity,
  handlePasswordValidation
} from 'domain/feature/hub/user-config/ChangePasswordForm/behavior/validation'
import {
  createPasswordRequest
} from 'domain/feature/hub/user-config/ChangePasswordForm/behavior/createRequests'

import {
  handleUserConfigFormValidity
} from 'domain/feature/hub/user-config/PersonalConfigForm/behavior/validation'
import {
  createProfileImageRequest,
  createUserRequest
} from 'domain/feature/hub/user-config/PersonalConfigForm/behavior/createRequests'

import { api, GetFormat, postProvider, updateProvider, useGet } from 'sharedKernel/api'
import {
  IUserConfigForm,
  resetConfigForm,
  updateConfigForm
} from 'domain/feature/hub/user-config/PersonalConfigForm/store/configForm'
import { cookies, setCookie } from 'sharedKernel/cookies/behavior'
import { toBase64 } from 'sharedKernel/file/handler'
import {
  IProfileImage,
  resetProfileImage,
  updateProfileImageLink
} from 'domain/feature/hub/user-config/PersonalConfigForm/store/profileImage'
import _ from 'lodash'
import { CircularProgress } from 'react-cssfx-loading'
import { Loading } from 'components/loading'
import { Prompt } from 'react-router-dom'
import { profileImageResponseToReduxStateObject, userConfigResponseToReduxStateObject } from './behavior'
export interface navItemProps {
  itemHash: string
  name: string
}

export const UserConfigPage = (): JSX.Element => {
  const userConfigFormState = useSelector((state: TReducers) => state.hubIUserConfigFormState)
  const userPasswordState = useSelector((state: TReducers) => state.hubIPasswordState)
  const profileImage = useSelector((state: TReducers) => state.hubIProfileImage)
  const [userConfigFormInitialState, setUserConfigFormInitialState] = useState<IUserConfigForm>()
  const [profileImageInitialState, setProfileImageInitialState] = useState<IProfileImage>()
  const [successfullSave, setSuccessfullSave] = useState(false)
  const userId: string = cookies.get('_cred').id
  const labels = {
    title: trans('Configurações'),
    success: trans('Os seus dados foram alterados com sucesso!'),
    error: trans('Os seus dados não puderam ser alterados!'),
    sucessUpload: trans('A imagem do perfil foi alterada com sucesso'),
    errorUpload: trans('A imagem do perfil não pôde ser alterada'),
    leavePage: trans('O formulário contém alterações não salvas. Tem certeza que deseja sair?'),
    btnContent: trans('Salvar')
  }

  enum Menu {
    PersonalConfigForm = 0,
    ChangePasswordForm = 1
  }

  const requestUserData: GetFormat = {
    url: `/v1/system/user/${userId}`
  }

  const { data, isFetching, refetch } = useGet<any>(requestUserData, 'user-data')

  useEffect(() => {
    if (!isFetching) {
      const address = Object.entries(data.data.address)
      const contact = Object.entries(data.data.contact)
      const dataArray = address.concat(contact)
      const entriesArray: any = []

      dataArray.forEach((pair) => {
        entriesArray.push([pair[0], pair[1]])
      })

      const object = Object.fromEntries(entriesArray)
      Object.assign(object, { name: data.data.name })

      const userData = _.omit(object, 'neighborhood')
      const noImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
      const userProfileImage = data.data.profileImage ?? noImage

      store.dispatch(updateConfigForm(userConfigResponseToReduxStateObject(userData)))
      store.dispatch(updateProfileImageLink(userProfileImage))

      setUserConfigFormInitialState(JSON.parse(JSON.stringify(userConfigResponseToReduxStateObject(userData))))
      setProfileImageInitialState(JSON.parse(JSON.stringify(profileImageResponseToReduxStateObject(userProfileImage))))
    }
  }, [isFetching])

  useEffect(() => {
    return () => {
      store.dispatch(resetConfigForm())
      store.dispatch(resetPasswordForm())
      store.dispatch(resetProfileImage())
    }
  }, [])

  const [activeSection, setActiveSection] = useState(0)

  const handleValidation = (): any => {
    switch (activeSection) {
      case Menu.PersonalConfigForm:
        store.dispatch(updateConfigForm(userConfigFormState))
        return handleUserConfigFormValidity(userConfigFormState)
      case Menu.ChangePasswordForm:
        userPasswordState.confirmNewPassword.neverChanged = false
        userPasswordState.newPassword.neverChanged = false
        userPasswordState.currentPassword.neverChanged = false
        store.dispatch(updatePasswordForm(handlePasswordValidation(userPasswordState)))
        return handlePasswordFormValidity(userPasswordState)
    }
  }
  const { mutate: updateUserData } = updateProvider()
  const { mutate: updatePassword } = updateProvider()
  const { mutate: updateProfileImage } = postProvider()
  const handlePost = (): void => {
    switch (activeSection) {
      case Menu.PersonalConfigForm:
        updateUserData(createUserRequest(userConfigFormState), {
          onSuccess: async () => {
            setSuccessfullSave(true)
            showToast('success', labels.success)
            setCookie('_usrData', userConfigFormState)
            const userName = cookies.get('_cred')
            userName.data.name = userConfigFormState.name?.value
            setCookie('_cred', userName)
            handleSaveImage()
          },
          onError: () => {
            showToast('error', labels.error)
          }
        })
        break
      case Menu.ChangePasswordForm:
        updatePassword(createPasswordRequest(userPasswordState), {
          onSuccess: () => {
            showToast('success', labels.success)
            store.dispatch(resetPasswordForm())
            refetch()
          }
        })
        break
    }
  }

  const handleSaveImage = async (): Promise<void> => {
    if (profileImage.sourceFile === null || profileImage.sourceFile === undefined) {
      return
    }

    let fileContent = await toBase64(profileImage.sourceFile)
    // @ts-expect-error
    fileContent = String(fileContent).substring(fileContent.indexOf(',') + 1)
    profileImage.content = fileContent

    profileImage.name = window.btoa(String(profileImage.name))
    updateProfileImage(createProfileImageRequest(profileImage), {
      onSuccess: () => {
        showToast('success', labels.sucessUpload)
        setCookie('_profImg', profileImage.path)
      },
      onError: () => {
        setSuccessfullSave(false)
        showToast('error', labels.errorUpload)
      }
    })
  }

  const handleSave = (): void => {
    const validatedForm: IUserConfigForm | IPasswordForm = handleValidation()

    if (!validatedForm.isFormValid) {
      showToast('error', labels.error)
    } else {
      handlePost()
    }
  }

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  }
  const navBarBuilder = (): navItemProps[] => {
    return [
      { itemHash: 'personal-config', name: 'Configurações Pessoais' },
      { itemHash: 'change-password', name: 'Alterar senha' }
    ]
  }
  const formsBuilder = (activeSection: number): JSX.Element => {
    const forms = [<PersonalConfigForm />, <ChangePasswordForm />]
    return forms[activeSection]
  }

  const checkIfUserCannotLeave = (): boolean => {
    if (successfullSave) {
      return false
    }

    if (!hasUserConfigFormChanged()) {
      return false
    }

    return true
  }

  const disableSubmitButton = (): boolean => {
    if (activeSection === Menu.PersonalConfigForm) {
      return !userConfigFormState.isFormValid || !hasUserConfigFormChanged()
    } else {
      return !userPasswordState.isFormValid
    }
  }

  const hasUserConfigFormChanged = (): boolean => {
    const hasUserConfigChanged = !_.isEqual(_.omit(userConfigFormInitialState, 'isFormValid'), _.omit(userConfigFormState, 'isFormValid'))
    const hasProfileImageChanged = !_.isEqual(profileImageInitialState, profileImage)

    if (hasUserConfigChanged) {
      return true
    }

    if (hasProfileImageChanged) {
      return true
    }

    return false
  }

  return (
    <BlankPage>
      <Prompt when={checkIfUserCannotLeave()} message={labels.leavePage} />
      <div className="d-flex flex-row">
        <PageTitle>{labels.title}</PageTitle>
        <ChangeDataButtonArea>
          <BaseButton
            btnContent={labels.btnContent}
            size="big"
            variant={'primary'}
            disabled={disableSubmitButton()}
            onClick={() => handleSave()}
          />
        </ChangeDataButtonArea>
      </div>
      {isFetching ? (
        <Loading>
          <CircularProgress color="var(--primary)" />
        </Loading>
      ) : (
        <>
          <FormHeader>
            <SwiperNavMenu
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              breakpoints={buildBreakpoints()}
              carouselNavItems={navBarBuilder()}
            />
          </FormHeader>
          {formsBuilder(activeSection)}
        </>
      )}
    </BlankPage>
  )
}
