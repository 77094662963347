import React, { useEffect, useState } from 'react'
import {
  ProgressBarWrapper,
  TotalValue,
  OuterDiv,
  PercentageTrackerDiv, PercentageTrackerSection, TotalValueDiv, PercentageSpan
} from './style'
import { ProgressBar } from '../../common/progressBar'

export interface ProgressBarProps {
  progressColor: string
  progressValue: number
  backgroundColor: string
  totalValue?: number
  height?: string
  width?: string
}

export const DashboardEvaluatorProgressBar = ({ progressColor, progressValue, backgroundColor, totalValue, height = '10px', width = '100px', ...props }: ProgressBarProps): JSX.Element => {
  const [progressTransition, setProgressTransition] = useState(0)

  useEffect(() => {
    setTimeout(() => setProgressTransition(progressValue), 300)
  }, [])

  return (
    <ProgressBarWrapper>
      <OuterDiv>
        <PercentageTrackerSection progressValue={progressTransition}>
          <PercentageTrackerDiv color={backgroundColor}>
            <PercentageSpan color={progressColor}>{`${progressValue}%`}</PercentageSpan>
          </PercentageTrackerDiv>
        </PercentageTrackerSection>
        <ProgressBar
          progressColor={progressColor}
          progressValue={progressTransition}
          backgroundColor={backgroundColor}
          totalValue={totalValue}
          height={height}
          width={width}
        />
      </OuterDiv>
      {/* eslint-disable-next-line */}
      {totalValue != null ?
        <TotalValueDiv>
          <TotalValue>{`${totalValue}`}</TotalValue>
        </TotalValueDiv>
        : <></>}
    </ProgressBarWrapper>
  )
}
