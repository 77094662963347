/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { limitDataFields } from 'components/LimitDateFields'
import {
  ITab,
  IApplicationForm,
  IEvaluation,
  ITypeahead,
  IField
} from 'domain/feature/hub/company/challenge/FormWrapper/constants'
import { ApplicationFormArea } from 'domain/feature/hub/company/challenge/FormWrapper/items/applicationFormArea'
import { EvaluationArea } from 'domain/feature/hub/company/challenge/FormWrapper/items/evaluationArea'
import { BuildField } from 'domain/feature/hub/company/challenge/FormWrapper/items/field'
import { BuildTypeahead } from 'domain/feature/hub/company/challenge/FormWrapper/items/field/typeahead'
import { CarouselHeader } from 'iebt-zing-storybook'
import React, { useEffect, useState } from 'react'
import { IHubChallengeBasicFormState } from 'store'
import { FormWrapper } from './style'

interface FormWrapperProps {
  tabs: ITab[]
  formStateValues: any
}

export const TemplatePreview = ({
  tabs,
  formStateValues
}: FormWrapperProps): JSX.Element => {
  const today = new Date()
  const [dateRestrictions, setDateRestrictions] = useState({
    min: {
      launchDate: today,
      applicationStartDate: today,
      finalSelectionDate: today,
      evaluationStartDate: today,
      closingDate: today
    },
    max: {
      launchDate: null,
      applicationStartDate: null,
      finalSelectionDate: null,
      evaluationStartDate: null,
      closingDate: null
    }
  })

  useEffect(() => {
    initializeDataLimits(formStateValues, dateRestrictions)
  }, [])

  function fixDateLimits(selectedDate: string, previousDate: string, fieldName: string): void {
    limitDataFields(selectedDate, previousDate, fieldName, dateRestrictions)
    setDateRestrictions({ ...dateRestrictions })
  }
  return (
    <FormWrapper>
      <form>
        {tabs.map((tab, tabKey) => {
          return (
            <section id={tab.id} key={tab.id} style={{ margin: '0px 0px 50px 10px' }}>
              <CarouselHeader title={`${String(tab.name)}`} />
              {tab.content.map((item, key) => {
                if (item.type === 'applicationForm') {
                  return ApplicationFormArea(item as IApplicationForm, key)
                }
                if (item.type === 'evaluation') {
                  return EvaluationArea(item as IEvaluation, key)
                }
                if (item.type === 'uploadImage' || item.type === 'uploadVideo') {
                  return <></>
                }
                if (item.type === 'multiFile') {
                  return <></>
                }
                if (item.type === 'typeahead') {
                  return BuildTypeahead(
                    {
                      ...(item as ITypeahead),
                      endpointSource: '/v1/system/niche',
                      updateFieldState: (field) => {
                        item = field
                        tab.content[key] = field as ITypeahead
                      }
                    },
                    key
                  )
                }
                if (item.type === 'datePicker') {
                  if (item.id === 'applicationStartDate') {
                    return <></>
                  }
                  const item1 = item as IField

                  return BuildField(
                    {
                      ...item1,
                      minDate: dateRestrictions.min[item.id],
                      maxDate: dateRestrictions.max[item.id],
                      updateFieldState: (field) => {
                        const previousDate = item1.value ?? ''
                        tab.content[key] = field
                        fixDateLimits(field.value ?? '', previousDate, item.id)
                      }
                    },
                    key
                  )
                }
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return BuildField(
                  {
                    ...(item as IField),
                    updateFieldState: (field) => {
                      item = field
                      tab.content[key] = field as IField
                    }
                  },
                  key
                )
              })}
            </section>
          )
        })}
      </form>
    </FormWrapper>
  )
}

function initializeDataLimits(formStateValues: IHubChallengeBasicFormState, dateRestrictions: any): void {
  if (formStateValues.config.step.launchDate !== undefined && formStateValues.config.step.launchDate !== null) {
    limitDataFields(formStateValues.config.step.launchDate ?? '', '', 'launchDate', dateRestrictions)
  }
  if (
    formStateValues.config.step.finalSelectionDate !== undefined &&
    formStateValues.config.step.finalSelectionDate !== null
  ) {
    limitDataFields(formStateValues.config.step.finalSelectionDate ?? '', '', 'finalSelectionDate', dateRestrictions)
  }
  if (
    formStateValues.config.step.evaluationStartDate !== undefined &&
    formStateValues.config.step.evaluationStartDate !== null
  ) {
    limitDataFields(formStateValues.config.step.evaluationStartDate ?? '', '', 'evaluationStartDate', dateRestrictions)
  }
  if (formStateValues.config.step.closingDate !== undefined && formStateValues.config.step.closingDate !== null) {
    limitDataFields(formStateValues.config.step.closingDate ?? '', '', 'closingDate', dateRestrictions)
  }
}
