import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import Cache from 'i18next-localstorage-cache'
import PtBrTranslation from 'assets/languages/pt/pt-BR-Translation.json'

export const fallbackLng = ['pt-BR']
export const availableLanguages = ['pt-BR']

const resources = {
  'pt-BR': {
    translation: PtBrTranslation
  }
}

i18n.use(Backend).use(LanguageDetector).use(Cache).use(initReactI18next).init({
  resources,
  fallbackLng,
  debug: false,
  supportedLngs: availableLanguages
})

export default i18n
