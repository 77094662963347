import { Validation } from './contracts'

export class NotEmpty implements Validation {
  valid: boolean
  errorMessage: string
  constructor() {
    this.valid = true
    this.errorMessage = 'Campo obrigatório'
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    this.valid = value !== undefined && value !== null && value.toString().trim() !== ''
  }
}
