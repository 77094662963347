import styled from 'styled-components'

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const PopUpHeader = styled.div`
  background-color: #5f2ab2;
  height: 3.25rem;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
`
export const PopUpTitle = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: 500;

  @media (max-width: 425px) {
    font-size: 18px;
  }
`
export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PopUpParagraph = styled.div`
  padding-top: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18.75px;
  color: #464646;
  overflow: auto;
`
