import { cookies } from 'sharedKernel/cookies/behavior'
import { SAVE_HOTSITE_CHALLENGES_DATA } from './actions'
import { HotsiteChallengeProps } from './contracts'

const INITIAL_STATE: HotsiteChallengeProps = cookies.get('_eval') ?? {
  rows: []
}

export interface HotsiteChallengesAction {
  type: string
  payload: any
}

export const HotsiteChallengesReducer = (state: HotsiteChallengeProps = INITIAL_STATE, action: HotsiteChallengesAction): any => {
  switch (action.type) {
    case SAVE_HOTSITE_CHALLENGES_DATA:
      return {
        ...state,
        rows: action.payload.rows
      }
    default:
      return state
  }
}
