import React from 'react'
import { trans } from 'sharedKernel/i18n'
import {
  Logo,
  LinksWrapper,
  LoginLink,
  HeaderLink
} from './styles'
import { BaseButton } from 'iebt-zing-storybook'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'sharedKernel/constants'

import imgLogoHeader from 'assets/images/defaults/logoZingIEBTPreta.png'

export const HotsiteHeader = (): JSX.Element => {
  const navigate = useNavigate()

  const labels = {
    open_challenges: trans('Programas e Desafios Abertos'),
    contact: trans('Contato'),
    login: trans('Login'),
    demo: trans('Solicitar Demonstração')
  }

  return (
    <Navbar bg="white shadow-sm sticky-top" expand="lg">
      <Container >
        <Navbar.Brand onClick={() => navigate('/')} >
          <Logo src={imgLogoHeader} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar" >
          <Nav className='me-auto'>
            <HeaderLink onClick={() => navigate(ROUTES.HOTSITE.MARKETPLACE, { state: { hotsite: true } })}>{labels.open_challenges}</HeaderLink>
            <HeaderLink onClick={() => navigate(ROUTES.HOTSITE.CONTACT, { state: { hotsite: true } })}>{labels.contact}</HeaderLink>
          </Nav>
          <LinksWrapper>
            <LoginLink className='mt-2 mt-lg-0' onClick={() => navigate(ROUTES.USER_AUTH.LOGIN, { state: { hotsite: true } })}>{labels.login}</LoginLink>
            <BaseButton
              size="medium"
              btnContent={labels.demo}
              variant="risk-bg"
              onClick={() => navigate(ROUTES.HOTSITE.CONTACT, { state: { hotsite: true } })}
            />
          </LinksWrapper>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
