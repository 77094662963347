import { CHANGE_SIDEBAR_STATE, RESET_SIDEBAR_STATE } from './sidebarActions'

const DEFAULT_IS_SIDEBAR_OPEN_VALUE = false

const INITIAL_STATE: ISidebarInitalState = {
  isSidebarOpen:
    localStorage.getItem('isSidebarOpen') !== null ? localStorage.getItem('isSidebarOpen') === 'true' : DEFAULT_IS_SIDEBAR_OPEN_VALUE
}

export interface ISidebarInitalState {
  isSidebarOpen: boolean
}

export interface ISidebarAction {
  type: string
  payload: boolean
}

export interface ISidebarState {
  isSidebarOpen: boolean
}

export const SidebarReducer = (state: ISidebarInitalState = INITIAL_STATE, action: ISidebarAction): any => {
  switch (action.type) {
    case CHANGE_SIDEBAR_STATE:
      localStorage.setItem('isSidebarOpen', String(action.payload))
      return {
        ...state,
        isSidebarOpen: action.payload
      }

    case RESET_SIDEBAR_STATE:
      localStorage.removeItem('isSidebarOpen')
      return {
        ...state,
        isSidebarOpen: DEFAULT_IS_SIDEBAR_OPEN_VALUE
      }

    default:
      return state
  }
}
