import styled from 'styled-components'

interface PageTitleProps {
  titleName?: string
}

export const PageTitle = styled.h2<PageTitleProps>`
  max-width: 62.5rem;
  font-weight: 700;
  font-family: 'Roboto';
  font-size: 1.75rem;
  line-height: 2.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.titleName === '' ? 'var(--red)' : 'none')}
`

export const PageDescription = styled.p`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem
`
