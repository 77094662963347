import _ from 'lodash'
import { ILandingPageRecommendationForm } from './contracts'

export const UPDATE_RECOMMENDATION_FORM = 'UPDATE_RECOMMENDATION_FORM'
export const RESET_RECOMMENDATION_FORM = 'RESET_RECOMMENDATION_FORM'

const updateRecommendationForm = (contactForm: ILandingPageRecommendationForm): any => {
  return {
    type: UPDATE_RECOMMENDATION_FORM,
    payload: contactForm
  }
}

const resetRecommendationForm = (contactForm?: ILandingPageRecommendationForm): any => {
  return {
    type: RESET_RECOMMENDATION_FORM,
    payload: contactForm
  }
}

export { updateRecommendationForm, resetRecommendationForm }
