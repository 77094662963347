import React from 'react'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { HubCompanyDashboardStatsCard } from '../StatsCard'
import { HubCompanyChallengeDashboardProgramStatsApiResult } from './contracts'

export const HubCompanyDashboardProgramCounter = (): JSX.Element => {
  const labels = {
    statsName: trans('Programas'),
    statsDescription: trans('Ativos'),
    statsInfo: trans('Total de Programas ativos')
  }

  const statsProps: any = {
    name: labels.statsName,
    count: 0,
    description: labels.statsDescription,
    info: labels.statsInfo,
    cardLabel: {
      icon: ['fas', 'file'],
      color: 'var(--primary)',
      background: 'var(--primary-light)',
      size: '2x'
    }
  }

  const request: GetFormat = {
    url: '/v1/hub/company/dashboard/program/total'
  }
  const { data, isError, isLoading, isFetching } = useGet<HubCompanyChallengeDashboardProgramStatsApiResult>(
    request,
    'program-stats-get'
  )

  if (isFetching || isLoading || isError) {
    return <HubCompanyDashboardStatsCard {...statsProps}/>
  }

  if (data.data.totalPrograms === null || data.data.totalPrograms === undefined) {
    return <HubCompanyDashboardStatsCard {...statsProps} />
  }

  statsProps.count = data.data.totalPrograms
  return <HubCompanyDashboardStatsCard {...statsProps} />
}
