export const sortRows = (solutionsRows: any[], nameAsc: boolean, nameClick: boolean): any[] => {
  if (nameClick) {
    if (nameAsc) {
      solutionsRows.sort((a, b) => {
        const x = a[0].toLowerCase()
        const y = b[0].toLowerCase()
        if (x < y) {
          return 1
        } else {
          return -1
        }
      })
    } else {
      solutionsRows.sort((a, b) => {
        const x = a[0].toLowerCase()
        const y = b[0].toLowerCase()
        if (x < y) {
          return -1
        } else {
          return 1
        }
      })
    }
  }
  return solutionsRows
}
