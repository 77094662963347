import React from 'react'
import { HotsiteRegisterForm } from 'domain/feature/hotsite/Forms/Register'
import { useSelector } from 'react-redux'
import { TReducers } from 'store'
import { USER_TERMS_TYPE } from 'sharedKernel/constants'

export const HotsiteResolverRegisterPage = (): JSX.Element => {
  const registerFormState = useSelector((state: TReducers) => state.hotsiteIHotsiteRegisterFormState)
  const registerUrl = '/v1/system/create-resolver'

  return (
    <>
      <HotsiteRegisterForm registerFormStateProps={registerFormState} registerUrl={registerUrl} userType={USER_TERMS_TYPE.RESOLVER} />
    </>
  )
}
