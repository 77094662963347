class BreadCrumbBuilderFactory {
  build(): BreadCrumbBuilder {
    return new BreadCrumbBuilder()
  }
}

class BreadCrumbBuilder {
  build(): any {
    return [
    ]
  }
}

const factory = new BreadCrumbBuilderFactory()
export const breadCrumbBuilder = factory.build()
