export const CHANGE_MENU_EVALUATOR = 'menuEval/CHANGE_MENU_EVALUATOR'

const changeMenuEvaluator = (menuEvaluator: any): any => {
  return {
    type: CHANGE_MENU_EVALUATOR,
    payload: menuEvaluator
  }
}

export { changeMenuEvaluator }
