import _ from 'lodash'
import { PostFormat } from 'sharedKernel/api'
import { IPasswordForm } from '../../ChangePasswordForm/store'

export const createPasswordRequest = (userPasswordState: IPasswordForm): PostFormat => {
  const configFormArray = Object.entries(userPasswordState)
  const entriesArray: any = []
  configFormArray.forEach((pair) => {
    entriesArray.push([pair[0], pair[1].value])
  })
  const postObject: PostFormat = {
    url: '/v1/system/user/password',
    data: { data: Object.fromEntries(entriesArray) }
  }
  return postObject
}
