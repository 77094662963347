import styled from 'styled-components'

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const PopUpHeader = styled.div`
  background-color: #5f2ab2;
  height: 70px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
`
export const PopUpTitle = styled.h4`
  color: #fff;
  font-size: 20px;

  @media (max-width: 425px) {
    font-size: 18px;
  }
`
export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
