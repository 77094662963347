import React from 'react'
import { getUnnabreviatedDate } from 'sharedKernel/handlers'
import { checkJSON } from '..'
import { FormIdeaAnswer } from '.././styles'
import { FormAnswerProps } from '../contracts'

export const FormAnswerDate = ({ answer }: FormAnswerProps): JSX.Element => {
  const formattedDate = getUnnabreviatedDate(answer.value)
  if (formattedDate !== 'Invalid DateTime') {
    return <FormIdeaAnswer>{formattedDate}</FormIdeaAnswer>
  }
  return checkJSON(answer)
}
