import styled from 'styled-components'

export const LoadindContainer = styled.div`
  height: 40rem;
`

export const ScreenContainer = styled.div`
  width: 90%;
  padding: 3rem 0;
  margin: auto;
`
