import { Solution } from './contracts'
import { CHANGE_SOLUTION } from './solutionActions'

const INITIAL_STATE: Solution = {}

export interface ISolutionAction {
  type: string
  payload: any
}

export const solutionReducer = (state: Solution = INITIAL_STATE, action: ISolutionAction): any => {
  switch (action.type) {
    case CHANGE_SOLUTION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
