import styled from 'styled-components'

export const EvaluatorListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 10px;
`

export const EvalutorListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const EvalutorListTitle = styled.h6``

export const EvaluatorListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EvaluatorListItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
`

export const EvaluatorListItemLabelDiv = styled.div`
  display: flex;
  width: 50%;
  padding-top: 20px;
`

export const EvaluatorListItemLabel = styled.h6`
  margin-right: 20px;
  margin-left: 10px;
  font-weight: bold;
  opacity: 0.8;
  margin-top: 1px;
`
