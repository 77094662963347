import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Input, DescriptedLabel } from 'iebt-zing-storybook'
import { resetContactForm, store, TReducers, updateContactForm } from 'store'
import { useSelector } from 'react-redux'

import { HotsiteFormMain } from '..'
import { trans } from 'sharedKernel/i18n'
import { INPUT_MASK_FORMAT, RESOLVER_DROPDOWN_ROLES, USER_TERMS_TYPE } from 'sharedKernel/constants'
import { LabeledCheckbox } from 'components/LabeledCheckbox'
import { useNavigate } from 'react-router'
import { ContactActions } from './FormActions'
import { GetFormat, useGet } from 'sharedKernel/api'
import { ifNotEmptyValidateEmail, ifNotEmptyValidateMobile, isEmptyField } from '../validations'
import { handleFormValidity } from './FormActions/validations'
import { applyMaskIfNotEmpty, applyTitleOnError } from 'sharedKernel/handlers'
import { UserTerms } from 'components/UserTerms'

export const HotsiteContactForm = (): JSX.Element => {
  const nichesRequest: GetFormat = {
    url: 'v1/system/niche'
  }
  const { data: niches, isFetching } = useGet<any>(nichesRequest, 'niches')

  const contactFormState = useSelector((state: TReducers) => state.hotsiteIHotsiteContactFormState)
  const navigate = useNavigate()

  const labels = {
    formTitle: trans('Solicitar contato'),
    name: trans('Nome'),
    namePlaceholder: trans('Insira seu nome'),
    niches: trans('Áreas de atuação'),
    companyName: trans('Nome da empresa'),
    companyNamePlaceholder: trans('Insira o nome da empresa'),
    role: trans('Cargo'),
    email: trans('E-mail corporativo'),
    emailPlaceholder: trans('Insira seu e-mail corporativo'),
    phone: trans('Celular'),
    phonePlaceholder: trans('Insira seu número de celular'),
    subject: trans('Assunto'),
    subjectPlaceholder: trans('Insira o assunto do seu contato'),
    contact: trans('Como prefere ser contactado?'),
    userTermsAgreement: trans('Concordo com os '),
    userTerms: trans('termos de uso'),
    titleMessage: trans('Preencha este campo')
  }

  useEffect(() => {
    return () => {
      store.dispatch(resetContactForm())
    }
  }, [])

  const handleChange = (value, key): void => {
    contactFormState.form[key].value = value
    store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
  }

  return (
    <HotsiteFormMain formTitle={labels.formTitle}>
      <Row className='my-2'>
        <Col lg={true}>
          <Input
            id='name'
            maxLength={256}
            isRequired={contactFormState?.form.name.isRequired}
            title={applyTitleOnError(contactFormState?.form.name.message, labels.titleMessage)}
            label={`${labels.name}`}
            placeholder={labels.namePlaceholder}
            value={contactFormState?.form.name.value}
            isInvalid={contactFormState?.form.name.isInvalid}
            notValidFieldMessage={contactFormState?.form.name.message}
            onChange={(event) => {
              handleChange(event.target.value, 'name')
            }}
            onBlur={() => {
              isEmptyField(contactFormState.form.name)
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          ></Input>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={6}>
          <DescriptedLabel>{`${labels.niches}`}</DescriptedLabel><span style={{ color: 'red' }}> *</span>
          <Form.Select
            id='niches'
            value={contactFormState?.form.niche.value}
            onChange={(event) => {
              handleChange(event.target.value, 'niche')
            }}
          >
            <option key={-1} value={''}>
              {'Selecione'}
            </option>
            {isFetching
              ? <></>
              : niches?.rows?.map((niche, index) => {
                return (
                  <option key={index} value={niche.value}>
                    {niche.name}
                  </option>
                )
              })}
          </Form.Select>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={8}>
          <Input
            id='companyName'
            maxLength={256}
            isRequired={contactFormState?.form.companyName.isRequired}
            title={applyTitleOnError(contactFormState?.form.companyName.message, labels.titleMessage)}
            label={`${labels.companyName}`}
            placeholder={labels.companyNamePlaceholder}
            value={contactFormState?.form.companyName.value}
            isInvalid={contactFormState?.form.companyName.isInvalid}
            notValidFieldMessage={contactFormState?.form.companyName.message}
            onChange={(event) => {
              handleChange(event.target.value, 'companyName')
            }}
            onBlur={() => {
              isEmptyField(contactFormState.form.companyName)
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          ></Input>
        </Col>
        <Col lg={4}>
          <DescriptedLabel>{`${labels.role}`}</DescriptedLabel><span style={{ color: 'red' }}> *</span>
          <Form.Select
            id='role'
            value={contactFormState?.form.role.value}
            onChange={(event) => {
              handleChange(event.target.value, 'role')
            }}
          >
            {RESOLVER_DROPDOWN_ROLES.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={true}>
          <Input
            id='email'
            maxLength={256}
            isRequired={contactFormState?.form.email.isRequired}
            title={applyTitleOnError(contactFormState?.form.email.message, labels.titleMessage)}
            label={`${labels.email}`}
            placeholder={labels.emailPlaceholder}
            value={contactFormState?.form.email.value}
            isInvalid={contactFormState?.form.email.isInvalid}
            notValidFieldMessage={contactFormState?.form.email.message}
            onChange={(event) => {
              handleChange(event.target.value, 'email')
            }}
            onBlur={() => {
              ifNotEmptyValidateEmail(contactFormState.form.email)
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          ></Input>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={true}>
          <Input
            id='phone'
            maxLength={256}
            isRequired={contactFormState?.form.phone.isRequired}
            title={applyTitleOnError(contactFormState?.form.phone.message, labels.titleMessage)}
            label={`${labels.phone}`}
            placeholder={labels.phonePlaceholder}
            value={contactFormState?.form.phone.value}
            isInvalid={contactFormState?.form.phone.isInvalid}
            notValidFieldMessage={contactFormState?.form.phone.message}
            code="masked"
            mask={applyMaskIfNotEmpty(contactFormState?.form.phone.value, INPUT_MASK_FORMAT.MOBILE)}
            onChange={(event) => {
              handleChange(event.target.value, 'phone')
            }}
            onBlur={() => {
              ifNotEmptyValidateMobile(contactFormState.form.phone)
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          ></Input>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={true}>
          <Row className='my-2'>
            <Col lg={true}>
              <Input
                id='subject'
                code='textarea'
                maxLength={256}
                isRequired={contactFormState?.form.subject.isRequired}
                label={`${labels.subject}`}
                placeholder={labels.subjectPlaceholder}
                value={contactFormState?.form.subject.value}
                isInvalid={contactFormState?.form.subject.isInvalid}
                notValidFieldMessage={contactFormState?.form.subject.message}
                onChange={(event) => {
                  handleChange(event.target.value, 'subject')
                }}
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col lg={true}>
          <DescriptedLabel>{`${labels.contact}`}</DescriptedLabel><span style={{ color: 'red' }}> *</span>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col lg={4}>
          <LabeledCheckbox
            id="whatsappContact"
            checked={contactFormState?.whatsappContact}
            label={'Whatsapp'}
            onChange={(event) => {
              contactFormState.whatsappContact = event.target.checked
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          />
        </Col>
        <Col lg={4}>
          <LabeledCheckbox
            id="emailContact"
            checked={contactFormState?.emailContact}
            label={'E-mail'}
            onChange={(event) => {
              contactFormState.emailContact = event.target.checked
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          />
        </Col>
        <Col lg={4}>
          <LabeledCheckbox
            id="phoneContact"
            checked={contactFormState?.phoneContact}
            label={'Ligação'}
            onChange={(event) => {
              contactFormState.phoneContact = event.target.checked
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          />
        </Col>
      </Row>
      <Row className='mt-4 mb-2'>
        <Col lg={true}>
          <UserTerms
            userType={USER_TERMS_TYPE.USER}
            checked={contactFormState?.acceptUserTerms}
            onCheck={(event) => {
              contactFormState.acceptUserTerms = event.target.checked
              store.dispatch(updateContactForm(handleFormValidity(contactFormState)))
            }}
          />
        </Col>
      </Row>
      <ContactActions />
    </HotsiteFormMain>
  )
}
