/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import { IMultiFile } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DescriptedLabel } from 'iebt-zing-storybook'
import { Button } from 'react-bootstrap'
import { showToast } from '../../../../../../../../components/toast'
import { v4 as uuidv4 } from 'uuid'
import { ACCEPTED_SUPPORT_FILES } from './constants'
import { LabelInputFile } from 'sharedKernel/styledComponents/fileComponents/styles'
import { LabelInputFileDescription } from '../../style'

export function BuildMultiFile(item: IMultiFile, key: number | string): any {
  const [state, setState] = useState<IMultiFile>(item)
  const isSupportFile = item.id === 'supportFiles'
  const allowedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'image/jpeg', 'image/png', 'image/png', 'image/jpg', 'image/gif', 'image/tiff', 'image/psd', 'image/eps', 'image/ai',
    'image/indd', 'image/raw', 'image/cdr', 'image/tif', 'image/bmp', 'image/cr2', 'image/nef', 'image/orf', 'image/sr2', 'video/wma',
    'video/mp3', 'video/mp4', 'video/wav', 'video/ogm', 'video/wmv', 'video/mpg', 'video/mpeg', 'video/webm', 'video/ogv', 'video/mov', 'video/asx',
    'video/avi', 'video/m4v'
  ]

  return (
    <div style={{ margin: '0px 0px 10px 10px' }} key={key}>
      <DescriptedLabel description={item.description} boldText={item.isRequired ?? false} htmlFor={item.id}>
        {item.label}
      </DescriptedLabel>
      <LabelInputFile id={item.id} isClickable>
        <FontAwesomeIcon className="pr-2" icon={['fas', 'upload']} />
        {'Adicionar Arquivos'}
        <input
          className="form-check-input"
          type="file"
          multiple
          accept={isSupportFile ? ACCEPTED_SUPPORT_FILES : '*'}
          name={item.id}
          id={item.id}
          onChange={(event) => {
            const element = event?.target as HTMLInputElement
              ;[].slice.call(element.files).forEach((file: File) => {
                if (isSupportFile && file.size > 52428800) {
                  showToast('warning', 'Cada arquivo deve ter um tamanho máximo de 50 MB')
                  return
                }
                if (!isSupportFile && file.size > 5242880) {
                  showToast('warning', 'Cada arquivo deve ter um tamanho máximo de 5 MB')
                  return
                }

                if (!allowedTypes.includes(file.type)) {
                  showToast('warning', `Arquivo ${file.name} não é permitido.`)
                  return
                }

                if (state.archives === undefined) {
                  state.archives = []
                }

                state.archives.push({
                  id: `${String(uuidv4())}_idScreen`,
                  path: URL.createObjectURL(file),
                  isDeleted: false,
                  name: file.name,
                  size: file.size,
                  mimetype: file.type,
                  extension: null,
                  type: state.type,
                  content: null,
                  sourceFile: file
                })

                setState({ ...state })
                if (state.updateFieldState !== undefined && state.updateFieldState !== null) {
                  state.updateFieldState(state)
                }
              })
            event.target.value = ''
          }}
        />
      </LabelInputFile>
      <LabelInputFileDescription>
        {item.description}
      </LabelInputFileDescription>
      {state.archives?.map((file, index) => {
        if (file.isDeleted) {
          // eslint-disable-next-line array-callback-return
          return
        }

        return (
          <div className="d-flex gap-1 align-items-center py-1" key={index}>
            <Button
              variant="danger"
              onClick={() => {
                if (!confirm('Tem certeza que deseja excluir este arquivo')) {
                  return
                }

                if (state.removed === undefined) {
                  state.removed = []
                }

                if (!file.id.includes('_idScreen')) {
                  state.removed.push(file.id)
                }
                // @ts-expect-error
                state.archives[index].isDeleted = true
                setState({ ...state })
              }}
            >
              <FontAwesomeIcon icon={['far', 'trash-can']} />
            </Button>
            {/* eslint-disable-next-line multiline-ternary */}
            {file.path != null ? (
              <a href={file.path} target="_blank">
                <LabelInputFile style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
                  {file.name}
                </LabelInputFile>
              </a>
            ) : (
              <LabelInputFile style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon className="pr-2" icon={['fas', 'panorama']} />
                {file.name}
              </LabelInputFile>
            )}
          </div>
        )
      })}
    </div>
  )
}
