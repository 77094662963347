import { cookies } from 'sharedKernel/cookies/behavior'
import { UPDATE_PROFILE_IMAGE, UPDATE_PROFILE_IMAGE_LINK, RESET_PROFILE_IMAGE } from './actions'
import { IProfileImage } from './contracts'

export const PROFILE_IMAGE_INITIAL_STATE: IProfileImage = {
  id: null,
  path: null,
  isDeleted: false,
  name: null,
  size: 0,
  mimetype: null,
  extension: null,
  type: null,
  content: null,
  sourceFile: null
}
export interface IHubIProfileImageStateAction {
  type: string
  payload: IProfileImage
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubProfileImageReducer = (
  state: IProfileImage = PROFILE_IMAGE_INITIAL_STATE,
  action: IHubIProfileImageStateAction
): any => {
  switch (action.type) {
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_PROFILE_IMAGE_LINK:
      return {
        ...state,
        path: action.payload
      }
    case RESET_PROFILE_IMAGE:
      return {
        id: null,
        path: null,
        isDeleted: false,
        name: null,
        size: 0,
        mimetype: null,
        extension: null,
        type: null,
        content: null,
        sourceFile: null
      }
    default:
      return state
  }
}
