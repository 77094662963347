import axios from 'axios'

import { VIA_CEP_SERVICE_BASE_URL } from '..'
import { IViaCepServiceAddressResponse } from './contract'

const errorMessages = {
  invalidZipcode: 'O CEP não pode ser encontrado',
  errorZipcodeApi: 'Falha ao recuperar endereço pelo CEP'
}

export const getAddressByZIP = async (zipcode: string): Promise<IViaCepServiceAddressResponse> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${VIA_CEP_SERVICE_BASE_URL}/${zipcode}/json`)
      .then((response) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(response.data.erro)) {
          reject(errorMessages.invalidZipcode)
        }

        resolve(response.data)
      })
      .catch(() => {
        reject(errorMessages.errorZipcodeApi)
      })
  })
}
