import React from 'react'
import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
`

export const PageTitle = styled.h4`
    font-size: 2.5rem;
`

export const PageDescription = styled.p`
    font-size: 1.25rem;
`
