import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'
import { HubCompanyEvaluatorsInvite } from 'domain/feature/hub/company/challenge/evaluators/invite'
import React, { useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import { FormHeader, FormSections, FormTitle } from 'sharedKernel/styledComponents/formComponents/styles'
import { HubCompanyEvaluatorsAssign } from './assign'

export const HubCompanyEvaluatorsManagement = (): JSX.Element => {
  const [activeSection, setActiveSection] = useState(0)

  const sections = [{
    itemHash: 'inviteEvaluator',
    name: trans('Convidar')
  }, {
    itemHash: 'assignEvaluator',
    name: trans('Atribuir avaliador')
  }]

  const navBar = sections.map((section) => { return { itemHash: section.itemHash, name: section.name } })

  const labels = {
    pageTitle: trans('Gerenciar avaliadores')
  }

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  }

  return (
    <>
      <div className='mt-4'>
        <FormHeader>
          <FormTitle>{labels.pageTitle}</FormTitle>
          <SwiperNavMenu activeSection={activeSection} setActiveSection={setActiveSection} breakpoints={buildBreakpoints()} carouselNavItems={navBar} />
        </FormHeader>
      </div>
      <FormSections>
        {sections[activeSection].itemHash === 'inviteEvaluator' ? <HubCompanyEvaluatorsInvite /> : <HubCompanyEvaluatorsAssign />}
      </FormSections >
    </>
  )
}
