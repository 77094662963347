/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState } from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { LikertScale } from 'domain/feature/hub/evaluator/idea/evaluate/IdeaApplicationForm/IdeaEvaluation/LikertScale'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Keyboard } from 'swiper'
import {
  IdeaEvaluationSections,
  IdeaEvaluationHeader,
  IdeaEvaluationWrapper,
  IdeaEvaluationTitle,
  IdeaEvaluationSubtitle,
  IdeaEvaluationGroup,
  IdeaEvaluationGroupBottom,
  IdeaEvaluationGroupRightItem,
  IdeaEvaluationSwiperHeader,
  IdeaEvaluationSwiperTitle,
  PointsDiv,
  PointsWrapper,
  IdeaEvaluationSwiperSectionIndex,
  SwiperDiv
} from './style'
import { BaseButton, BaseTag, Input } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { showModal } from 'components/popup'
import { postProvider, PostFormat } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { DashboardEvaluatorProgressBar } from 'domain/feature/hub/dashboard/evaluators/evaluatorProgressBar'
import { useParams } from 'react-router'
import {
  criterionCount,
  criterionIsMet,
  calculateScore,
  bankersRound,
  currentSection,
  changeSolutionPoints,
  goToSectionTop
} from './behavior'
import { IMenuEvaluators } from 'components/menu/state/content/store/contracts'
import { changeMenuEvaluator, store } from 'store'
import { NoContent } from 'components/NoContent'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import 'tippy.js/themes/translucent.css'
export interface Evaluation {
  name: string
  rounds: number
  fillPercentage: number
  points: number
  comments: string
  order: number
  status: string
  sections: any[]
}

export interface SwiperWrapperProps {
  evaluation: Evaluation
  setIsNavigationBlocked: Function
  resolverId: string
}

export const IdeaEvaluation = ({ setIsNavigationBlocked, evaluation, resolverId }: SwiperWrapperProps): JSX.Element => {
  const labels = {
    evaluationTitle: trans('Avaliação'),
    evaluationPoints: trans('Nota Geral'),
    saveButton: trans('Salvar'),
    saveTitle: trans('Salvar Avaliação'),
    saveMessage: trans('Deseja realmente salvar essa avaliação?'),
    sendMessage: trans('Deseja realmente salvar e enviar essa avaliação?'),
    onSuccessMsg: trans('Avaliação salva com sucesso!'),
    onInvalidSection: trans('Há campos que devem ser preenchidos'),
    onNoSection: trans('Ao menos uma seção deve ser preenchida'),
    confirm: { text: trans('Sim'), value: 'yes' },
    cancel: { text: trans('Não') },
    noEvaluationForm: trans(
      'Ainda não há um formulário de avaliação disponível para esta solução. Entre em contato com a empresa responsável pelo desafio.'
    ),
    criterion: trans('Critérios'),
    alertTitle: trans('O campo "Feedback geral" é obrigatório'),
    alertMessage: trans('Para salvar a avaliação é necessário preencher o campo "Feedback geral"'),
    commentsPlaceHolder: trans('O seu comentário será visualizado apenas pela empresa organizadora do desafio!'),
    labelComments: trans('Comentários por seção (Opcional)'),
    feedback: trans('Feedback'),
    labelFeedback: trans('Feedback geral'),
    toastMessage: trans('Atenção: O campo \'{}\' deve ser preenchido.'),
    sendEvaluation: trans('Enviar')
  }
  const [activeSection, setActiveSection] = useState(0)
  const [evaluationProps, setEvaluationProps] = useState(evaluation)
  const changeEvaluation = (newValue: number | null, indexSection: number, indexAnswer: number): void => {
    setIsNavigationBlocked(true)
    const newEvaluation = { ...evaluationProps }
    newEvaluation.sections[indexSection].answers[indexAnswer].value = newValue
    setEvaluationProps(newEvaluation)
  }
  // Utilizado para fazer o reload da ProgressBar, caso o contrário ela não atualiza
  const [reloadProgressBar, setReloadProgressBar] = useState(1)

  const state = store.getState()
  const menuEvaluatorsState: IMenuEvaluators = state.menuEvaluatorsState

  const { mutate: persistEvaluationForm } = postProvider()

  const { challengeId, stepId, ideaId } = useParams()

  const requestSave: PostFormat = {
    url: `v1/hub/evaluator/challenge/${challengeId}/step/${stepId}/solution/${ideaId}`,
    data: {
      data: {
        challenge: { id: challengeId },
        resolver: { id: resolverId },
        evaluation: {
          ...evaluationProps,
          stepId: stepId,
          sections: evaluationProps.sections.map((section) => {
            return {
              ...section,
              sectionId: section.sectionId,
              answers: section.answers.map((answer) => {
                return { id: answer.id, questionId: answer.questionId, value: answer.value }
              })
            }
          })
        }
      }
    }
  }

  const postFunction = (request: any): void => {
    persistEvaluationForm(request, {
      onSuccess: () => {
        showToast('success', labels.onSuccessMsg)
        setIsNavigationBlocked(false)
      }
    })
  }

  const saveEvaluation = (launch: boolean): void => {
    const isEvaluationVAlidate: boolean = launch ? validateEvaluation() : true
    if (isEvaluationVAlidate) {
      showModal('double', {
        title: labels.saveTitle,
        message: labels.saveMessage,
        buttons: [{ text: labels.confirm.text, value: labels.confirm.value }, { text: labels.cancel.text }],
        action: handleSaveEvaluation.bind(null, evaluation)
      })
    }
  }

  const validateEvaluation = (): boolean => {
    let isValid = true

    if (evaluationProps.comments === '') {
      showToast('error', labels.toastMessage.replace('{}', 'Feedback geral'))
      isValid = false
    }

    evaluationProps.sections.forEach(section => {
      section.answers.forEach(answer => {
        if (answer.value === null) {
          showToast('error', labels.toastMessage.replace('{}', answer.question))
          isValid = false
        }
      })
    })
    return isValid
  }

  const handleSaveEvaluation = (): void => {
    postFunction(requestSave)
    const menuEvaluator = changeSolutionPoints(
      menuEvaluatorsState,
      challengeId,
      stepId,
      resolverId,
      ideaId,
      evaluationPoints
    )
    store.dispatch(changeMenuEvaluator(menuEvaluator))
  }

  const confirmReload = (value: number): void => {
    if (value === null) {
      setReloadProgressBar(Math.random())
    }
  }

  const total = criterionCount(evaluation)
  const current = criterionIsMet(evaluation)
  const percentage = Math.round((current * 100) / total)

  const progressBar = (percentage: number): JSX.Element => {
    return (
      <DashboardEvaluatorProgressBar
        key={reloadProgressBar}
        progressColor={'var(--primary)'}
        progressValue={percentage}
        backgroundColor={'var(--primary-light)'}
      />
    )
  }

  const currentProgressBar = progressBar(percentage)
  const generalScore = calculateScore(evaluation)
  const roundGeneralScore = bankersRound(generalScore)
  const evaluationPoints = roundGeneralScore.toFixed(2)

  const criterionGroup = `${current}/${total} ${labels.criterion}`

  const baseTagContent = (points: string): JSX.Element => {
    return (
      <PointsWrapper>
        <PointsDiv pointsFontSize={'21px'}>{points}</PointsDiv>
        <PointsDiv pointsFontSize={'12px'}>/5.00</PointsDiv>
      </PointsWrapper>
    )
  }

  const noEvaluationFormElement = (
    <IdeaEvaluationWrapper>
      <IdeaEvaluationHeader>
        <IdeaEvaluationTitle>{labels.evaluationTitle}</IdeaEvaluationTitle>
      </IdeaEvaluationHeader>
      <hr className="m-0" />
      <IdeaEvaluationSections>
        <NoContent text={labels.noEvaluationForm} image={NoContentImg} />
      </IdeaEvaluationSections>
    </IdeaEvaluationWrapper>
  )

  if (evaluation.sections.length === 0) {
    return noEvaluationFormElement
  }
  const handleFeedbackChange = (e): void => {
    const newEvaluation = { ...evaluationProps }
    newEvaluation.comments = e.target.value
    setEvaluationProps(newEvaluation)
    setIsNavigationBlocked(true)
  }

  return (
    <IdeaEvaluationWrapper>
      <IdeaEvaluationHeader>
        <IdeaEvaluationTitle>{labels.evaluationTitle}</IdeaEvaluationTitle>
        <IdeaEvaluationGroup>
          <IdeaEvaluationSubtitle>{`${labels.evaluationPoints}:`}</IdeaEvaluationSubtitle>
          <IdeaEvaluationGroupRightItem>
            <BaseTag tagContent={baseTagContent(evaluationPoints)} variant="primary-invert" />
          </IdeaEvaluationGroupRightItem>
        </IdeaEvaluationGroup>
        <IdeaEvaluationGroupBottom>
          {currentProgressBar}
          <IdeaEvaluationGroupRightItem>
            <div className="total-items">{criterionGroup}</div>
          </IdeaEvaluationGroupRightItem>
        </IdeaEvaluationGroupBottom>
        <IdeaEvaluationGroup>
          <BaseButton
            btnContent={labels.saveButton}
            size="medium"
            onClick={() => {
              saveEvaluation(false)
            }}
            variant="primary"
            disabled={currentProgressBar.props.progressValue === 100}
          />
          <BaseButton
            btnContent={labels.sendEvaluation}
            size="medium"
            onClick={() => {
              saveEvaluation(true)
            }}
            variant="primary"
          />
        </IdeaEvaluationGroup>
      </IdeaEvaluationHeader>
      <hr className="m-0" />
      <IdeaEvaluationSections id="IdeaEvaluationSections">
        <SwiperDiv>
          <Swiper
            keyboard={{
              enabled: true
            }}
            onSlideChange={(swiper) => {
              setActiveSection(swiper.activeIndex)
              goToSectionTop('IdeaEvaluationSections')
            }}
            slidesPerView={1}
            modules={[Navigation, Keyboard]}
            navigation={true}
            allowSlideNext={true}
            mousewheel={false}
            grabCursor={false}
          >
            {evaluationProps.sections.map((section, sectionIndex) => {
              return (
                <SwiperSlide key={`swiperSlide.${sectionIndex}`}>
                  <IdeaEvaluationSwiperHeader>
                    <Tippy
                      content={section.name}
                      followCursor={true}
                      plugins={[followCursor]}
                      animation="scale"
                      arrow={false}
                      theme="primary"
                    >
                      <IdeaEvaluationSwiperTitle>{section.name}</IdeaEvaluationSwiperTitle>
                    </Tippy>
                  </IdeaEvaluationSwiperHeader>
                </SwiperSlide>
              )
            })}
            <SwiperSlide key={`swiperSlide.${evaluationProps.sections.length}`}>
              <IdeaEvaluationSwiperHeader>
                <IdeaEvaluationSwiperTitle>{labels.feedback}</IdeaEvaluationSwiperTitle>
              </IdeaEvaluationSwiperHeader>
            </SwiperSlide>
            <IdeaEvaluationSwiperSectionIndex>
              {currentSection(activeSection, parseInt(evaluationProps.sections.length.toString()) + 1)}
            </IdeaEvaluationSwiperSectionIndex>
          </Swiper>
        </SwiperDiv>
        {evaluationProps.sections[activeSection]?.answers.map((answer, answerIndex) => {
          const updateAnswer = (newValue: number | null): void => {
            confirmReload(answer.value)
            changeEvaluation(newValue, activeSection, answerIndex)
            confirmReload(answer.value)
          }
          return (
            <LikertScale
              key={`likertScale.${activeSection}.${answerIndex}`}
              onChange={updateAnswer}
              onClick={updateAnswer}
              range={5}
              answer={answer.value}
              order={answerIndex}
              question={answer.question}
              description={answer.description}
              gradeInfo={answer.gradeInfo}
            />
          )
        })}
        <div className="pt-4">
          {activeSection < evaluationProps.sections.length
            ? (
              <>
                {evaluationProps.sections.map((section, sectionIndex) => {
                  const handleCommentsChange = (e: any): void => {
                    const newEvaluation = { ...evaluationProps }
                    newEvaluation.sections[activeSection].comments = e.target.value
                    setEvaluationProps(newEvaluation)
                    setIsNavigationBlocked(true)
                  }
                  if (sectionIndex === activeSection) {
                    return (
                      <Input
                        notValidFieldMessage="Esse campo deve ser preenchido"
                        key={`sectionComments.${sectionIndex}`}
                        code="textarea"
                        label={labels.labelComments}
                        name="comments"
                        onChange={handleCommentsChange}
                        value={section.comments ?? ''}
                        placeholder={labels.commentsPlaceHolder}
                        maxLength={1024}
                      ></Input>
                    )
                  }
                  return <div key={`sectionComments.${sectionIndex}`}></div>
                })}
              </>
              )
            : (
              <>
                <Input
                  notValidFieldMessage="Esse campo deve ser preenchido"
                  key={`sectionComments.${evaluationProps.sections.length}`}
                  code="textarea"
                  label={labels.labelFeedback}
                  name="comments"
                  onChange={handleFeedbackChange}
                  value={evaluationProps.comments ?? ''}
                  isRequired={true}
                  placeholder={labels.commentsPlaceHolder}
                  maxLength={1024}
                ></Input>
              </>
              )}
        </div>
      </IdeaEvaluationSections>
    </IdeaEvaluationWrapper>
  )
}
