/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BreadcrumbData } from 'use-react-router-breadcrumbs'

import { BreadcrumbLink } from './style'

export interface IBreadcrumbUIProps {
  filteredBreadcrumbs: BreadcrumbData[]
  navigate?: (path: string) => void
}

const PAGE_TREE_ROOT = 1

export const BreadcrumbUI = ({ filteredBreadcrumbs, navigate = (path: string): void => { } }): JSX.Element => {
  return (
    <div data-testid='breadcrumb'>
      {filteredBreadcrumbs?.length > PAGE_TREE_ROOT
        ? filteredBreadcrumbs?.map((link, index) => {
          return (
            index !== 0
              ? <span key={`${String(index)}_crumbSpan`}>
                <FontAwesomeIcon key={`${String(index)}_crumbIcon`} className="px-2" icon={['fas', 'greater-than']} color={'var(--primary)'} />
                <BreadcrumbLink
                  key={`${String(index)}_crumbLink`}
                  data-testid='breadcrumbLink'
                  onClick={(e) => {
                    filteredBreadcrumbs.length === Number(index) + 1 ? e.preventDefault() : navigate(link.path)
                  }}
                  isCurPage={filteredBreadcrumbs.length === Number(index) + 1}>
                  {link.name}
                </BreadcrumbLink>
              </span>
              : <span key={`${String(index)}_crumbSpan`}>
                <BreadcrumbLink
                  key={`${String(index)}_crumbLink`}
                  onClick={() => { navigate(link.path) }}
                  isCurPage={false}
                  data-testid='breadcrumbLink'>
                  {link.name}
                </BreadcrumbLink>
              </span>
          )
        })
        : <></>
      }
    </div>
  )
}
