import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import { GetFormat, PostFormat, getProvider, updateProvider, useGet } from 'sharedKernel/api'
import { Loading } from 'components/loading'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { FormChallengeHeader } from 'sharedKernel/styledComponents/stickHeader/style'
import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { trans } from 'sharedKernel/i18n'
import { Filter } from 'components/Filter'
import { SOLUTION_STATUS } from 'sharedKernel/constants'
import { HubCompanyChallengeSolutionsTable } from './table'
import { showToast } from 'components/toast'
import { triggerFileDownloadOnClientSide } from 'sharedKernel/file/handler'
import { BaseButton } from 'iebt-zing-storybook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import 'tippy.js/themes/translucent.css'

const SWIPER_TABS = {
  SUBSCRIPTIONS: 0,
  APPROVED: 1,
  DISAPPROVED: 2
}

export const HubCompanyChallengeSolutionsManagement = (): JSX.Element => {
  const { challengeId } = useParams()

  const { mutate: approveSolution } = updateProvider()
  const { mutate: archiveSolution } = updateProvider()

  const [activeSection, setActiveSection] = useState(0)

  const [downloadingPdf, setDownloadingPdf] = useState(false)

  const [tabValue, setTabValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { mutate: downloadFile } = getProvider()

  const labels = {
    pageTitle: trans('Gerenciar inscrições'),
    subscriptions: trans('Inscrições'),
    approve: trans('Aprovado'),
    archive: trans('Reprovado'),
    approveConfirmationModal: trans('Tem certeza que deseja aprovar essa solução?'),
    approveConfirmationModalTitle: trans('Aprovar solução'),
    archiveConfirmationModal: trans('Tem certeza que deseja reprovar essa solução?'),
    archiveConfirmationModalTitle: trans('Reprovar solução'),
    successApprove: trans('Solução aprovada com sucesso!'),
    successArchive: trans('Solução reprovada com sucesso!'),
    exportEvaluationData: trans('Exportar dados de avaliação'),
    exportExcelToolTip: trans('Exportar relatório em excel'),
    exportPdfToolTip: trans('Exportar respostas em PDF'),
    exportSolutionData: trans('Exportar dados de inscrição'),
    exportSolutionsPdf: trans('Exportar PDF')
  }
  const downloadFileRef: React.RefObject<HTMLAnchorElement> = React.createRef()
  const request: GetFormat = {
    url: `/v1/hub/company/challenge/${challengeId}/report/analytical/standard-deviation`
  }

  const {
    data: solutionsData,
    isFetching: isSolutionsFetching,
    refetch: solutionsRefetch
  } = useGet<any>(request, 'solutions-get')

  useEffect(() => {
    switch (activeSection) {
      case SWIPER_TABS.SUBSCRIPTIONS:
        changeTabActions('')
        break
      case SWIPER_TABS.APPROVED:
        changeTabActions(SOLUTION_STATUS.APPROVED)
        break
      case SWIPER_TABS.DISAPPROVED:
        changeTabActions(SOLUTION_STATUS.DISAPPROVED)
        break
    }
  }, [activeSection])

  const changeTabActions = (tabValue: string): void => {
    setTabValue(tabValue)
    setSearchTerm('')
  }

  const onSearch = (value: string): void => {
    setSearchTerm(value)
  }

  const handleApproveSolution = (solutionId: string): void => {
    const approveSolutionPost: PostFormat = {
      url: `/v1/hub/company/challenge/${challengeId}/solution/${solutionId}/approve`
    }

    showDoubleActionPopUp({
      title: labels.approveConfirmationModalTitle,
      message: labels.approveConfirmationModal,
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      action: () => executeApproveSolutionPost(approveSolutionPost)
    })
  }

  const executeApproveSolutionPost = (approveSolutionPost: PostFormat): void => {
    approveSolution(approveSolutionPost, {
      onSuccess: () => {
        showToast('success', labels.successApprove)
        solutionsRefetch()
      },
      onError: (error) => {
        showToast('error', error.response.data.message)
      }
    })
  }

  const handleArchiveSolution = (solutionId: string): void => {
    const archiveSolutionPost: PostFormat = {
      url: `/v1/hub/company/challenge/${challengeId}/solution/${solutionId}/archive`
    }

    showDoubleActionPopUp({
      title: labels.archiveConfirmationModalTitle,
      message: labels.archiveConfirmationModal,
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      action: () => executeArchiveSolutionPost(archiveSolutionPost)
    })
  }

  const executeArchiveSolutionPost = (archiveSolutionPost: PostFormat): void => {
    archiveSolution(archiveSolutionPost, {
      onSuccess: () => {
        showToast('success', labels.successArchive)
        solutionsRefetch()
      },
      onError: (error) => {
        showToast('error', error.response.data.message)
      }
    })
  }

  const buildBreakpoints = (): any => {
    return {
      250: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      350: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    }
  }

  const buildNavItems = (): any => {
    return [
      {
        name: labels.subscriptions,
        itemHash: 'inscricoes'
      },
      {
        name: labels.approve,
        itemHash: 'aprovado'
      },
      {
        name: labels.archive,
        itemHash: 'reprovado'
      }
    ]
  }

  return (
    <>
      <div className='mb-6'>
        <PageTitle>{labels.pageTitle}</PageTitle>
        <FormChallengeHeader>
          <SwiperNavMenu activeSection={activeSection} setActiveSection={setActiveSection} breakpoints={buildBreakpoints()} carouselNavItems={buildNavItems()} />
        </FormChallengeHeader>
      </div>
      <Row className='mb-4'>
        <Col className='mb-2' lg={6}>
          <Filter
            currentSearchTerm={searchTerm}
            btnClick={onSearch}
            onFilterChange={(val) => { val === '' ?? setSearchTerm(val) }}
            minSearchTermLength={1}
          />
        </Col>
        <Col lg={6} className='d-flex justify-content-end gap-2'>
          <Tippy
            content={labels.exportPdfToolTip}
            followCursor={true}
            plugins={[followCursor]}
            animation="scale"
            arrow={false}
            theme="primary"
          >
            <span>
              <BaseButton
                btnContent={
                  <span>
                    {!downloadingPdf
                      ? <>
                        <FontAwesomeIcon className="pr-2" icon={['fas', 'download']} />
                        {labels.exportSolutionsPdf}
                      </>
                      : <Loading width='28px' />
                    }
                  </span>}
                onClick={() => {
                  setDownloadingPdf(true)
                  downloadFile(
                    {
                      url: `v1/hub/company/challenge/${challengeId}/report/analytical/qa/pdf`,
                      httpConfig: { responseType: 'blob' }
                    },
                    {
                      onSuccess: (res) => {
                        triggerFileDownloadOnClientSide(res.data, 'relatorio-solucoes-pdf.zip')
                        setDownloadingPdf(false)
                      },
                      onError: () => {
                        showToast('error', 'Ocorreu um erro ao baixar o arquivo. Por favor, tente novamente mais tarde.')
                        setDownloadingPdf(false)
                      }
                    }
                  )
                }}
                variant="secondary"
              />
            </span>
          </Tippy>
          <Tippy
            content={labels.exportExcelToolTip}
            followCursor={true}
            plugins={[followCursor]}
            animation="scale"
            arrow={false}
            theme="primary"
          >
            <span>
              <BaseButton
                btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'download']} />
                  {labels.exportSolutionData}</span>}
                onClick={() => {
                  downloadFile(
                    {
                      url: `v1/hub/company/challenge/${challengeId}/report/analytical/qa`,
                      httpConfig: { responseType: 'blob' }
                    },
                    {
                      onSuccess: (res) => {
                        triggerFileDownloadOnClientSide(res.data, 'relatorio-solucoes-zing.xlsx')
                      },
                      onError: () => {
                        showToast('error', 'Ocorreu um erro ao baixar o arquivo. Por favor, tente novamente mais tarde.')
                      }
                    }
                  )
                }}
                variant="secondary"
              />
            </span>
          </Tippy>
          <Tippy
            content={labels.exportExcelToolTip}
            followCursor={true}
            plugins={[followCursor]}
            animation="scale"
            arrow={false}
            theme="primary"
          >
            <span>
              <BaseButton
                btnContent={<span><FontAwesomeIcon className="pr-2" icon={['fas', 'download']} />
                  {labels.exportEvaluationData}</span>}
                onClick={() => {
                  downloadFile(
                    {
                      url: `v1/hub/company/challenge/${challengeId}/report/detailed`,
                      httpConfig: { responseType: 'blob' }
                    },
                    {
                      onSuccess: (res) => {
                        triggerFileDownloadOnClientSide(res.data, 'zing-modelo-atribuicao-avaliadores.xlsx')
                      },
                      onError: () => {
                        showToast('error', 'Impossível exportar. Nenhuma avaliação realizada.')
                      }
                    }
                  )
                }}
                variant="secondary"
              />
            </span>
          </Tippy>
        </Col>
      </Row>
      {isSolutionsFetching
        ? <Loading />
        : <HubCompanyChallengeSolutionsTable
          solutions={solutionsData}
          filterValue={searchTerm}
          tabStatus={tabValue}
          approveSolution={handleApproveSolution}
          archiveSolution={handleArchiveSolution}
        />
      }
    </>
  )
}
