/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { cookies } from 'sharedKernel/cookies/behavior'
import { getStartRoute, getHubRoute } from 'sharedKernel/handlers'
import { trans } from 'sharedKernel/i18n'
import { changeMenu, changeVisibleRows, store } from 'store'
import { IMenuEvaluators } from '../state/content/store/contracts'
import { showModal } from 'components/popup'
import React from 'react'
import { EvaluationModal } from 'domain/feature/hub/evaluator/challenge/EvaluationModal'
import { ROUTES } from 'sharedKernel/constants'

const setshowGoBackMenuItem = (features): boolean => {
  return features?.includes('evaluation')
}

const getSolutionTitle = (hide: boolean, solution: any): string => {
  if (hide) {
    return 'Empresa oculta'
  } else {
    return solution.title
  }
}

const setGoBackRoute = (features): string => {
  const permissions: string[] = []
  features?.map((feature, index): boolean => {
    permissions.push(String(feature.code))
    return true
  })

  return getStartRoute(permissions)
}

const handleVisibleRows = (rowIndexString: string, visibleValues: string[]): void => {
  if (visibleValues.includes(rowIndexString)) {
    visibleValues = visibleValues.filter((value) => value !== rowIndexString)
  } else if (rowIndexString !== null) {
    visibleValues.push(rowIndexString)
  }

  store.dispatch(changeVisibleRows(visibleValues))
}

export function createMenu(currentLocation?: string, feature?: string[]): any[] {
  const labels = {
    tooltipMessage: trans('Indisponível')
  }
  const features = cookies.get('_perm')?.features
  const state = store.getState()
  const menuEvaluatorsState: IMenuEvaluators = state.menuEvaluatorsState
  const menuEvaluators = menuEvaluatorsState.rows
  const menuEvaluatorsItems: any[] = [
    {
      title: trans('Voltar'),
      icon: ['fas', 'fa-reply'],
      path: setGoBackRoute(features),
      onClick: () => {
        store.dispatch(changeMenu(false))
        store.dispatch(changeVisibleRows([]))
      }
    },
    {
      title: trans('Minhas Avaliações'),
      icon: ['fas', 'clipboard-check'],
      active: currentLocation?.includes('/hub/evaluator/challenge'),
      path: getHubRoute(ROUTES.HUB.EVALUATOR.CHALLENGE_LIST),
      onClick: () => {
        store.dispatch(changeVisibleRows([]))
      }
    }
  ]
  // @ts-expect-error
  menuEvaluators.forEach((row, rowIndex) => {
    const evaluationStartDate = new Date(row.config.step.evaluationStartDate)
    const evaluationEndDate = new Date(row.config.step.closingDate)
    const today = new Date(Date.now())
    const availableToEvaluate = evaluationStartDate <= today && today <= evaluationEndDate
    const solutions: any[] = []
    const rowIndexString = rowIndex.toString()
    row.evaluation.steps.forEach((step) => {
      step.resolvers.forEach((resolver) => {
        resolver.solutions.forEach((solution) => {
          solutions.push({
            title: getSolutionTitle(row.config.hideIdeaTitle, solution),
            path: `/hub/evaluator/challenge/${row.id}/step/${step.id}/idea/${solution.id}`,
            tooltipContent: getSolutionTitle(row.config.hideIdeaTitle, solution),
            grade: solution.points !== 0 ? solution.points : 0.0,
            fillPercentage: solution.evaluationFillPercentage,
            disabled: !availableToEvaluate,
            onGradeClick: () => {
              const idList = { challengeId: row.id, stepId: step.id, ideaId: solution.id }
              showModal('evaluationModal', {
                title: solution.title,
                component: <EvaluationModal disabled={!availableToEvaluate} />,
                buttons: [{ text: 'Salvar', value: 'labels.confirm.value' }, { text: 'Cancelar' }],
                data: idList,
                isGetFromRedux: false
              })
            }
          })
        })
      })
    })
    menuEvaluatorsItems.push({
      title: row.config.title,
      icon: ['fas', 'puzzle-piece'],
      path: window.location.pathname,
      active: currentLocation?.includes(`${row.id}`),
      isSubMenu: true,
      subMenuItems: solutions,
      accordionOpen: state.isEvaluationMenu.visibleRows.includes(rowIndexString),
      onClick: () => {
        handleVisibleRows(rowIndexString, state.isEvaluationMenu.visibleRows)
      }
    })
  })
  menuEvaluatorsItems.push({
    title: trans('Meus Convites'),
    icon: ['fas', 'bell'],
    active: currentLocation?.includes('/hub/evaluator/invites'),
    path: getHubRoute(ROUTES.HUB.EVALUATOR.INVITES),
    onClick: () => {
      store.dispatch(changeMenu(true))
    }
  })
  const showGoBackMenuItem = setshowGoBackMenuItem(features)
  const goBackRoute = setGoBackRoute(features)
  const goBack = showGoBackMenuItem ? [{ title: trans('Voltar'), icon: ['fas', 'fa-reply'], path: goBackRoute }] : []

  // Only Dashboard
  const dashboardMenu = [
    {
      title: trans('Visão geral'),
      icon: ['fas', 'fa-chart-bar'],
      active: currentLocation?.includes('/hub/dashboard'),
      path: getHubRoute(ROUTES.HUB.DASHBOARD)
    }
  ]
  // Only feature challenge
  const companyMenu = [
    ...dashboardMenu,
    {
      title: trans('Programas e desafios'),
      icon: ['fas', 'puzzle-piece'],
      active: currentLocation?.includes('/hub/company/challenge'),
      path: getHubRoute(ROUTES.HUB.COMPANY.CHALLENGE_LIST)
    }
  ]
  // Only feature idea
  const startupMenu = [
    {
      title: trans('Desafios'),
      icon: ['fas', 'rocket'],
      active: currentLocation?.includes('/hub/startup/challenge'),
      path: getHubRoute(ROUTES.HUB.STARTUP.CHALLENGE_LIST)
    },
    {
      title: trans('Minhas Inscrições'),
      icon: ['fas', 'lightbulb'],
      active: currentLocation?.includes('/hub/startup/idea'),
      path: getHubRoute(ROUTES.HUB.STARTUP.IDEA_LIST)
    }
  ]
  // Only feature evaluation
  const evaluatorMenu = [
    {
      title: trans('Avaliar Ideias'),
      icon: ['fas', 'star-half-stroke'],
      active: currentLocation?.includes('/hub/evaluator/challenge'),
      path: getHubRoute(ROUTES.HUB.EVALUATOR.CHALLENGE_LIST),
      onClick: () => {
        store.dispatch(changeMenu(true))
      }
    },
    {
      title: trans('Meus Convites'),
      icon: ['fas', 'bell'],
      active: currentLocation?.includes('/hub/evaluator/invites'),
      path: getHubRoute(ROUTES.HUB.EVALUATOR.INVITES),
      onClick: () => {
        store.dispatch(changeMenu(true))
      }
    }
  ]
  const configMenu = [
    {
      title: trans('Configurações'),
      icon: ['fas', 'wrench'],
      active: currentLocation?.includes('/hub/user-config'),
      path: getHubRoute(ROUTES.HUB.USER_CONFIG)
    }
  ]

  let menuItems: any = [...goBack]
  if (feature?.includes('challenge') ?? false) {
    menuItems = [...menuItems, ...companyMenu]
  }

  if (feature?.includes('idea') ?? false) {
    menuItems = [...menuItems, ...startupMenu]
  }

  if (feature?.includes('evaluation') ?? false) {
    menuItems = [...menuItems, ...evaluatorMenu]
  }

  menuItems = [...menuItems, ...configMenu]

  if (state.isEvaluationMenu.isEvaluationMenu) {
    menuItems = menuEvaluatorsItems
  }

  return menuItems
}
