import _ from 'lodash'

import { IHotsiteRegisterForm } from '../../store'
import { IFormFieldProperties } from '../../../contracts'
import { validatePassword, validatePasswordEquality } from './password'
import { isEmptyField } from '../../../validations'

export const handleFormValidity = (registerFormState: IHotsiteRegisterForm): IHotsiteRegisterForm => {
  const validationArray = Object.values(registerFormState.form)
  const validForm = validateForm(validationArray)

  if (validForm && registerFormState.acceptUserTerms) {
    registerFormState.isFormValid = true
    return registerFormState
  }

  registerFormState.isFormValid = false
  return registerFormState
}

const validateForm = (validationArray: any): boolean => {
  let validFormFlag: boolean = true

  validationArray.forEach((field: any) => {
    if (field.isRequired === false) {
      return
    }

    if (field.isInvalid === true || _.isEmpty(String(field.value))) {
      validFormFlag = false
    }
  })

  return validFormFlag
}

export const ifNotEmptyValidatePassword = (password: IFormFieldProperties): boolean => {
  if (!isEmptyField(password)) {
    return handlePasswordValidity(password)
  }
  return false
}

const handlePasswordValidity = (password: IFormFieldProperties): boolean => {
  return validatePassword(password)
}

export const handlePasswordAndConfirmPasswordEquality = (
  password: IFormFieldProperties,
  confirmPassword: IFormFieldProperties
): boolean => {
  return validatePasswordEquality(password, confirmPassword)
}
