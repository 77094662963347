import styled from 'styled-components'

export const FormSections = styled.div`
  padding-top: 2rem;
`

export const FormTitle = styled.h1`
  font-weight: 700;
  font-size: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  @media screen and (min-width: 768px) {
    line-height: 48px;
    margin-top: -3.5rem;
    margin-bottom: -1rem;
    max-width: 50rem;
    font-size: 28px;
  }
`

export const FormHeader = styled.div`
  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
  padding-top: 20px;
`
