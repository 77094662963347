import React from 'react'
import { ProgressMainBar, InnerProgress } from './style'

export interface ProgressBarProps {
  progressColor: string
  progressValue: number
  backgroundColor: string
  totalValue?: number
  height?: string
  width?: string
}

export const ProgressBar = ({ progressColor, progressValue, backgroundColor, totalValue, height = '10px', width = '100px' }: ProgressBarProps): JSX.Element => {
  return (
    <ProgressMainBar backgroundColor={backgroundColor} height={height} width={width}>
      <InnerProgress progressColor={progressColor} progressValue={progressValue} />
    </ProgressMainBar>
  )
}
