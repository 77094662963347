import styled from 'styled-components'

export const FormTitle = styled.div`
  margin-top: 51px;
  h1 {
    font-size: 22px;
  }
`
export const ChangeDataButtonArea = styled.div`
  position: absolute;
  right: 3vw;
`
export const NoArrowNumberInputArea = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
