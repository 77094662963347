import { IHubChallengeBasicFormFilesState } from './contracts'

export const UPDATE_FILES = 'CHANGE_HUB_CHALLENGE_BASIC_FORM_FILES'
export const RESET_CHALLENGE_FILES = 'RESET_CHALLENGE_FILES'

const changeChallengeFiles = (challengeFiles: IHubChallengeBasicFormFilesState): any => {
  return {
    type: UPDATE_FILES,
    payload: challengeFiles
  }
}

const resetChallengeFiles = (challengeFiles?: IHubChallengeBasicFormFilesState): any => {
  return {
    type: RESET_CHALLENGE_FILES,
    payload: challengeFiles
  }
}

export { changeChallengeFiles, resetChallengeFiles }
