import styled from 'styled-components'

interface ProgressBarProps {
  backgroundColor: string
  height: string
  width: string
}

interface InnerProgressProps {
  progressColor: string
  progressValue: number
}

export const ProgressMainBar = styled.div<ProgressBarProps>`
  height: ${(props) => (props.height)};
  width: ${(props) => (props.width)};
  background-color: ${(props) => (props.backgroundColor)};
  border-radius: 50px;
  margin-top: 6px;
`

export const InnerProgress = styled.div<InnerProgressProps>`
  height: 100%;
  width: ${(props) => (props.progressValue)}%;
  background-color: ${(props) => (props.progressColor)};
  border-radius: inherit;
  text-align: right;

  transition: width 1s ease-in-out;
`
