import React, { useEffect, useState } from 'react'
import { ActionCard, BaseButton, TemplateCard } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router'
import { Challenge } from 'data/contracts'
import { SwiperWrapper } from 'components/swiper'
import { changeConfigSection, store, TReducers } from 'store'
import { fixDatas } from 'main/pages/hub/company/challenge/edit'
import { api } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { getHubRoute } from 'sharedKernel/handlers'
import { ROUTES } from 'sharedKernel/constants'
import { useSelector } from 'react-redux'
import { fillChallenge } from 'main/pages/hub/company/challenge/formBuilder/fillForm'
import { showTemplatePopup } from 'components/popup/templateModal'
import { NewTemplateActionCardBehavior } from './styles'

export interface HubCompanyChallengeTemplateCarouselListProps {
  templateList: Challenge[]
}

export const HubCompanyChallengeTemplateCarouselList = ({
  templateList
}: HubCompanyChallengeTemplateCarouselListProps): JSX.Element => {
  const navigate = useNavigate()
  const formStateValues = useSelector((state: TReducers) => state.hubChallengeBasicFormState)
  const formFilesState = useSelector((state: TReducers) => state.hubChallengeBasicFormFilesState)
  const filled = fillChallenge(formStateValues, formFilesState)
  const [tabs, setTabs] = useState(filled)

  const labels = {
    onSuccess: trans('Desafio removido'),
    actionCardTitle: trans('Novo Desafio'),
    newTemplate: trans('Novo modelo'),
    seeTemplate: trans('Ver modelo'),
    useTemplate: trans('Utilizar modelo'),
    edit: trans('Editar'),
    remove: trans('Excluir'),
    subscriptions: trans('Inscritos'),
    errorGetTemplate: trans('Falha ao obter informações do template')
  }

  const [templateData, setTemplateData] = useState<any>()

  const getTemplateData = async (challengeId: string): Promise<any> => {
    const response = await api.get(`/v1/hub/company/challenge/${challengeId}`)
    if (response.status === 200) {
      setTemplateData(response.data?.data)
    } else {
      showToast('error', labels.errorGetTemplate)
    }
  }

  useEffect(() => {
    if (templateData != null) {
      const challengeState = templateData
      challengeState.id = undefined
      fixDatas(challengeState)

      store.dispatch(changeConfigSection(challengeState))
    }
  }, [templateData])

  const selectTemplate = (): void => {
    navigate(getHubRoute(ROUTES.HUB.COMPANY.NEW_CHALLENGE))
  }

  const templates = templateList.map((template, index) => {
    return (
      <TemplateCard
        title={template.config.title}
        headerImage={template.style.coverImagePath}
        button={
          <BaseButton
            key={index}
            variant="risk"
            size="medium"
            onClick={() => {
              showTemplatePopup({
                title: template.config.title,
                confirmButton: labels.useTemplate,
                action: () => selectTemplate(),
                challengeId: template.id
              })
            }}
            btnContent={labels.seeTemplate}
          />
        }
        key={index}
      />
    )
  })

  const newTemplate = (
    <NewTemplateActionCardBehavior onClick={() => { navigate(getHubRoute(ROUTES.HUB.COMPANY.NEW_CHALLENGE)) }}>
      <ActionCard
        icon={['fas', 'add']}
        height="15.5rem"
        button={
          <BaseButton
            key={'new'}
            variant="risk-secondary"
            size="medium"
            btnContent={labels.newTemplate}
          />
        }
      />
    </NewTemplateActionCardBehavior>
  )

  const cardsList = [newTemplate, ...templates]

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      900: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      1800: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      2200: {
        slidesPerView: 6,
        spaceBetween: 20
      },
      2800: {
        slidesPerView: 7,
        spaceBetween: 20
      }
    }
  }

  return (
    <SwiperWrapper breakpoints={buildBreakpoints()} carouselItems={cardsList} arrowColor='var(--primary)' />
  )
}
