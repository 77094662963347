import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const FooterWrapper = styled(Container)`
  bottom: 0;
  height: 13rem;
  background-color: var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FooterLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FooterHr = styled.hr`
  margin: 0.55rem 0;
`

export const FooterEmail = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  text-align: center;
  color: var(--white);
  margin-bottom: 0.5rem;
`

export const FooterCopyright = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  text-align: center;
  color: var(--white);
`
