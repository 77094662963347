import _ from 'lodash'
import { IUserConfigForm } from './contracts'
import { USER_CONFIG_FORM_INITIAL_STATE } from './reducer'

export const UPDATE_USER_CONFIG_FORM = 'UPDATE_USER_CONFIG_FORM'
export const INITIALIZE_USER_CONFIG_FORM = 'INITIALIZE_USER_CONFIG_FORM'
export const RESET_USER_CONFIG_FORM = 'RESET_USER_CONFIG_FORM'

const updateConfigForm = (configForm: IUserConfigForm): any => {
  return {
    type: UPDATE_USER_CONFIG_FORM,
    payload: configForm
  }
}

const resetConfigForm = (configForm?: IUserConfigForm): any => {
  return {
    type: RESET_USER_CONFIG_FORM,
    payload: configForm
  }
}

export { updateConfigForm, resetConfigForm }
