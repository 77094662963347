import styled from 'styled-components'

export const TitleContainer = styled.div`
  max-width: 70em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SolutionContent = styled.div`
  display: flex;
  justify-content: flex-start;
  white-space: pre-wrap;
`
