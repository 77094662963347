import React from 'react'
import { BlankPage, BaseButton } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { useNavigate, useLocation } from 'react-router-dom'
import { NotFoundWrapper, PageDescription, PageTitle } from './styles'
import { getStartRoute } from 'sharedKernel/handlers'
import { cookies } from 'sharedKernel/cookies/behavior'

export const NotFoundPage = (): any => {
  const navigate = useNavigate()
  const location = useLocation()

  const setGoBackRoute = (features): string => {
    const permissions: string[] = []
    features?.map((feature, index): boolean => {
      permissions.push(String(feature.code))
      return true
    })
    return getStartRoute(permissions)
  }

  const labels = {
    title: trans('Ops! A página que você procura não pôde ser encontrada.'),
    description: trans('Parece que a página que você procura não existe ou foi removida.'),
    button: trans('Voltar ao início')
  }
  const handleBackToStartRoute = (): void => {
    const features = cookies.get('_perm')?.features
    const startPage = location.state !== null ? '/' : setGoBackRoute(features)
    navigate(startPage)
  }

  return (
    <BlankPage>
      <NotFoundWrapper>
        <PageTitle>{labels.title}</PageTitle>
        <PageDescription>{labels.description}</PageDescription>
        <BaseButton btnContent={labels.button} onClick={handleBackToStartRoute} size="medium" variant="secondary" />
      </NotFoundWrapper>
    </BlankPage>
  )
}
