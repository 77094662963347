import { cookies } from 'sharedKernel/cookies/behavior'
import { CHANGE_MENU_EVALUATOR } from './actions'
import { IMenuEvaluators } from './contracts'

const INITIAL_STATE: IMenuEvaluators = cookies.get('_eval') ?? {
  rows: []
}

export interface IMenuEvaluatorsAction {
  type: string
  payload: any
}

export const MenuEvaluatorsReducer = (state: IMenuEvaluators = INITIAL_STATE, action: IMenuEvaluatorsAction): any => {
  switch (action.type) {
    case CHANGE_MENU_EVALUATOR:
      return {
        ...state,
        rows: action.payload.rows
      }

    default:
      return state
  }
}
