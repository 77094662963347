import { Validation } from './contracts'

export class Pattern implements Validation {
  valid: boolean
  errorMessage: string
  pattern: string
  constructor(pattern: string) {
    this.valid = true
    this.errorMessage = 'Padrão Inválido'
    this.pattern = pattern
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    const regex = new RegExp(this.pattern, 'g')
    this.valid = regex.test(value) ?? true
  }
}
