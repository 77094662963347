import styled from 'styled-components'

export const ButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: end;
    
    @media (max-width: 577px) {
        flex-direction: column;
        justify-content: center;
        align-items: end;
    }
`
