import styled from 'styled-components'

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const PopUpHeader = styled.div`
  background-color: #5f2ab2;
  height: 69px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
`
export const PopUpTitle = styled.h4`
  color: #fff;
  font-size: 20px;
`
export const PopUpContent = styled.div`
  height: auto;
  min-height: 100px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  white-space: pre-line;
`

export const PopUpParagraph = styled.span`
  font-size: 16px;
  line-height: 18.75px;
  color: #464646;
  overflow: auto;
  width: 90%;
`
