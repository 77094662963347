import React from 'react'
import { ActionIcon, DisabledActionIcon } from './style'
import Tippy from '@tippyjs/react'

export interface ApproveRejectButtonsProps {
  positiveAction: Function
  negativeAction: Function
  disabled?: boolean
  tipyContent?: string
}

export const ApproveRejectButtons = ({ positiveAction, negativeAction, disabled = false, tipyContent }: ApproveRejectButtonsProps): JSX.Element => {
  if (disabled) {
    return (
      <Tippy
        content={tipyContent}
        animation="scale"
      >
        <div className='d-flex flex-nowrap'>
          <DisabledActionIcon
            aria-disabled={true}
            icon={['far', 'circle-check']}
            color="var(--grey)"
            size='2x'
            className='mr-3'
          />
          <DisabledActionIcon
            icon={['far', 'circle-xmark']}
            color="var(--grey)"
            size='2x'
          />
        </div>
      </Tippy>
    )
  }

  return (
    <div className='d-flex flex-column flex-sm-row gap-2'>
      <ActionIcon
        aria-disabled={true}
        icon={['far', 'circle-check']}
        color="var(--green)"
        size='2x'
        onClick={() => positiveAction()}
      />
      <ActionIcon
        icon={['far', 'circle-xmark']}
        color="var(--red)"
        size='2x'
        onClick={() => negativeAction()}
      />
    </div>
  )
}
