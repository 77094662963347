import React, { useEffect, useState } from 'react'
import { BaseButton } from 'iebt-zing-storybook'
import { SearchContainer, SearchIcon, SearchInput } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { trans } from 'sharedKernel/i18n'
import { Col, Row } from 'react-bootstrap'

export interface FilterProps {
  btnClick?: Function
  currentSearchTerm?: string
  searchBtn?: boolean
  onFilterChange?: (val) => void
  minSearchTermLength?: number
}

export const Filter = ({
  btnClick = () => {},
  currentSearchTerm = '',
  searchBtn = true,
  onFilterChange,
  minSearchTermLength = 3
}: FilterProps): JSX.Element => {
  const [termSearched, setTermSearched] = useState<string>('')
  const labels = {
    search: trans('Buscar'),
    clear: trans('Limpar'),
    searchPlaceholder: trans('Insira aqui os termos da sua pesquisa'),
    tooltipMessage: trans('Termo insuficiente')
  }

  const isSearchDisabled = (): boolean => {
    return termSearched.length < minSearchTermLength
  }

  useEffect(() => {
    setTermSearched(currentSearchTerm)
  }, [currentSearchTerm])

  return (
    <Row>
      <Col xs={12} sm={9} className='pr-0'>
        <SearchContainer>
          <SearchIcon id="basic-addon1">
            <FontAwesomeIcon icon={['fas', 'magnifying-glass']} color="var(--grey-lighter)" />
          </SearchIcon>
          <SearchInput
            id="inlineFormInput"
            placeholder={labels.searchPlaceholder}
            title={labels.searchPlaceholder}
            aria-describedby="basic-addon1"
            type="text"
            value={termSearched}
            onChange={(event) => {
              setTermSearched(event.target.value)
              if (onFilterChange !== undefined) {
                onFilterChange(termSearched)
              }
              if (termSearched === '') {
                btnClick(termSearched)
              }
            }}
          />
        </SearchContainer>
      </Col>
      <Col xs={12} sm={3}>
        {
          searchBtn
            ? <Tippy
              content={labels.tooltipMessage}
              animation="scale"
              disabled={!isSearchDisabled()}
            >
              <div className="pl-1">
                <BaseButton
                  disabled={isSearchDisabled()}
                  btnContent={labels.search}
                  variant='primary-invert'
                  size='medium'
                  onClick={() => {
                    btnClick(termSearched)
                  }}
                />
              </div>
            </Tippy>
            : <></>
        }
        {termSearched === ''
          ? <></>
          : <div className="pl-1">
            <BaseButton
              btnContent={labels.clear}
              variant='primary-invert'
              size='medium'
              onClick={() => {
                btnClick('')
                setTermSearched('')
              }}
            />
          </div>
        }
      </Col>
    </Row>
  )
}
