import React from 'react'
import { useSelector } from 'react-redux'
import { TReducers } from '../../store/reducers'
import { ThemeProvider } from 'styled-components'
import { darkTheme, lightTheme } from 'iebt-zing-storybook/dist/assets/theme'

export const DarkThemeProvider = ({ children }): any => {
  const isDarkMode = useSelector((state: TReducers) => state.isDarkModeOn.isDarkModeOn)

  return <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
}
