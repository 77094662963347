import _ from 'lodash'
import { IHotsiteRegisterForm } from './contracts'

export const UPDATE_REGISTER_FORM = 'UPDATE_REGISTER_FORM'
export const RESET_REGISTER_FORM = 'RESET_REGISTER_FORM'

const updateRegisterForm = (registerForm: IHotsiteRegisterForm): any => {
  return {
    type: UPDATE_REGISTER_FORM,
    payload: registerForm
  }
}

const resetRegisterForm = (registerForm?: IHotsiteRegisterForm): any => {
  return {
    type: RESET_REGISTER_FORM,
    payload: registerForm
  }
}

export { updateRegisterForm, resetRegisterForm }
