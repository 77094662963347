import styled from 'styled-components'

export interface CarouselStyleProps {
  arrowColor: string
  isMenu: boolean
}

export const CarouselStyle = styled.div<CarouselStyleProps>`
  position: relative;

  .swiper-button-next {
    color: ${(props: CarouselStyleProps) => { return props.arrowColor }}; 
    right: ${props => props.isMenu ? '0px' : '-20px'};
  };
  .swiper-button-prev { 
    color: ${(props: CarouselStyleProps) => { return props.arrowColor }}; 
    left: ${props => props.isMenu ? '0px' : '-20px'};
  };

  .swiper {
    width: 93%;
  }
`

export const CentralizeCards = styled.div`
  display: flex;
  justify-content: center;
`
