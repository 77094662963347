export const CHANGE_SIDEBAR_STATE = 'sidebar/CHANGE_SIDEBAR_STATE'
export const RESET_SIDEBAR_STATE = 'sidebar/RESET_SIDEBAR_STATE'

const changeSidebarState = (isSidebarOpen: boolean): any => {
  return {
    type: CHANGE_SIDEBAR_STATE,
    payload: isSidebarOpen
  }
}

const resetSidebarState = (): any => {
  return {
    type: RESET_SIDEBAR_STATE,
    payload: {}
  }
}

export { changeSidebarState, resetSidebarState }
