import ReactGA from 'react-ga4'

const initializeGA = (): any => {
  ReactGA.initialize('G-R2EPF6X959')
}

const logPageView = (): any => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
}

export { initializeGA, logPageView }
