import { trans } from 'sharedKernel/i18n'
import React from 'react'
import { dateFormater } from 'sharedKernel/handlers'
import { stepStyles } from './behavior'
import { Container } from 'react-bootstrap'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { StepContent } from './style'

interface TimelineProps {
  steps?: {
    launchDate?: string
    applicationStartDate?: string
    finalSelectionDate?: string
    evaluationStartDate?: string
    closingDate?: string
  }
}

export const Timeline = ({ steps = {} }: TimelineProps): JSX.Element => {
  const labels = {
    launch: trans('Início das Inscrições'),
    finalSelection: trans('Encerramento das Inscrições'),
    evaluation: trans('Início das Avaliações'),
    closing: trans('Encerramento das Avaliações')
  }

  const checkDate = (date: string | undefined): JSX.Element => {
    if (date !== undefined) {
      return <span>{dateFormater(date)}</span>
    }
    return <></>
  }

  return (
    <Container>
      <Stepper activeStep={0} alternativeLabel className='overflow-scroll' >
        <Step sx={stepStyles}>
          <StepLabel>
            <StepContent>
              <span>{labels.launch}</span>
              {checkDate(steps.launchDate)}
            </StepContent>
          </StepLabel>
        </Step>
        <Step sx={stepStyles}>
          <StepLabel>
            <StepContent>
              <span>{labels.finalSelection}</span>
              {checkDate(steps.finalSelectionDate)}
            </StepContent>
          </StepLabel>
        </Step>
        <Step sx={stepStyles}>
          <StepLabel>
            <StepContent>
              <span>{labels.evaluation}</span>
              {checkDate(steps.evaluationStartDate)}
            </StepContent>
          </StepLabel>
        </Step>
        <Step sx={stepStyles}>
          <StepLabel>
            <StepContent>
              <span>{labels.closing}</span>
              {checkDate(steps.closingDate)}
            </StepContent>
          </StepLabel>
        </Step>
      </Stepper>
    </Container>
  )
}
