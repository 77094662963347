import React from 'react'
import { EvaluationFilterSpan, FilterArea, FilterSelect } from './styles'
import { trans } from 'sharedKernel/i18n'

interface OrderByProps {
  options: FilterOrderByProps[]
  onSelect: Function
  selectedValue: FilterOrderByProps
}

export interface FilterOrderByProps {
  value: string
  label: string
}

export const OrderBy = ({ options, onSelect, selectedValue }: OrderByProps): JSX.Element => {
  const labels = {
    orderByText: trans('Ordenar por:')
  }

  return (
    <FilterArea>
      <div>
        <EvaluationFilterSpan>{labels.orderByText}</EvaluationFilterSpan>
      </div>
      <div>
        <FilterSelect value={selectedValue.value} onChange={(event) => {
          onSelect(options.find(option => option.value === event.target.value))
        }}>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </FilterSelect>
      </div>
    </FilterArea>
  )
}
