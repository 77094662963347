import { Validation } from '../contracts'

import { NotEmpty } from '../notEmpty'
import { FieldSize } from './fieldSize'

export class DynamicField implements Validation {
  valid: boolean
  errorMessage: string = ''
  notEmptyValidator: NotEmpty
  fieldSizeValidator: FieldSize

  constructor() {
    this.valid = true
    this.notEmptyValidator = new NotEmpty()
    this.fieldSizeValidator = new FieldSize()
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    this.notEmptyValidator.validate(value.name)
    this.valid = this.notEmptyValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.notEmptyValidator.message()
      return
    }
    this.notEmptyValidator.validate(value.type)
    this.valid = this.notEmptyValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.notEmptyValidator.message()
      return
    }

    this.validateTextField(value)
    this.validateMultiOptionField(value)
  }

  validateTextField(value: any): void {
    if (!this.valid) {
      return
    }
    if (value.type !== 'text') {
      return
    }

    this.notEmptyValidator.validate(value.size)
    this.valid = this.notEmptyValidator.isValid()
    if (!this.valid) {
      this.errorMessage = 'Tamanho do texto deve ser definido'
      return
    }

    this.fieldSizeValidator.validate(value.size)
    this.valid = this.fieldSizeValidator.isValid()
    if (!this.valid) {
      this.errorMessage = this.fieldSizeValidator.message()
    }
  }

  validateMultiOptionField(value: any): void {
    if (!this.valid) {
      return
    }
    if (value.type !== 'checkbox-group' && value.type !== 'radio-group' && value.type !== 'select') {
      return
    }
    if (value.options.length <= 0) {
      this.valid = false
      this.errorMessage = 'Você deve informar pelo menos uma opção'
    }
  }
}
