import { CHANGE_SOLUTIONS, RESET_SOLUTIONS } from './actions'

const INITIAL_STATE: IInitial = {
  evaluatorSolutions: []
}

export interface IInitial {
  evaluatorSolutions: string[]
}

export interface IEvaluatorSolutionAction {
  type: string
  payload: IEvaluatorSolutionState
}

export interface IEvaluatorSolutionState {
  evaluatorSolutions: string[]
}

export const evaluatorSolutionReducer = (state: IInitial = INITIAL_STATE, action: IEvaluatorSolutionAction): any => {
  switch (action.type) {
    case CHANGE_SOLUTIONS:
      return {
        ...state,
        evaluatorSolutions: action.payload
      }

    case RESET_SOLUTIONS:
      return {
        ...state,
        evaluatorSolutions: action.payload
      }

    default:
      return state
  }
}
