const dateFieldsOrder = ['launchDate', 'finalSelectionDate', 'evaluationStartDate', 'closingDate']

export const limitDataFields = (
  selectedDate: string,
  previousDate: string,
  selectedFieldName: string,
  dateRestrictions: any
): void => {
  const selectedFieldKey = dateFieldsOrder.findIndex((field) => field === selectedFieldName)

  dateFieldsOrder.forEach((field, fieldKey) => {
    if (fieldKey >= selectedFieldKey) {
      return field
    }
    const fieldName = dateFieldsOrder[fieldKey]

    if (
      selectedDate === '' &&
      dateRestrictions.max[fieldName] !== null &&
      dateRestrictions.max[fieldName].toISOString().split('T')[0] === previousDate
    ) {
      dateRestrictions.max[fieldName] = dateRestrictions.max[selectedFieldName] ?? null
      return field
    }
    if (
      dateRestrictions.max[field] === null ||
      dateRestrictions.max[field] === '' ||
      dateRestrictions.max[field] > new Date(`${selectedDate}T00:00`) ||
      dateRestrictions.max[field].toISOString().split('T')[0] === previousDate
    ) {
      dateRestrictions.max[fieldName] = new Date(`${selectedDate}T00:00`)
    }

    return field
  })

  dateFieldsOrder.forEach((field, fieldKey) => {
    if (fieldKey <= selectedFieldKey) {
      return field
    }
    const fieldName = dateFieldsOrder[fieldKey]

    if (
      selectedDate === '' &&
      dateRestrictions.min[fieldName] !== null &&
      dateRestrictions.min[fieldName].toISOString().split('T')[0] === previousDate
    ) {
      dateRestrictions.min[fieldName] = dateRestrictions.min[selectedFieldName] ?? null
      return field
    }

    if (
      dateRestrictions.min[field] === null ||
      dateRestrictions.min[field] === '' ||
      dateRestrictions.min[field] < new Date(`${selectedDate}T00:00`) ||
      dateRestrictions.min[field].toISOString().split('T')[0] === previousDate
    ) {
      dateRestrictions.min[fieldName] = new Date(`${selectedDate}T00:00`)
    }

    return field
  })
}
