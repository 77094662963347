import { DynamicFormField } from '../../contracts'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../../store'
import { RadioGroupItemProps } from 'iebt-zing-storybook/dist/components/Fields/Inputs/RadioGroup'
import { getDescription, getValue, setValue } from '../../actions/dataHandler'
import { Input } from 'iebt-zing-storybook'
import React from 'react'
import { InputWrapper } from '../invalidInput/style'

export const ApplicationFormInputRadio = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const isLaunched = applicationFormState.isLaunched ?? false
  const radioItems: RadioGroupItemProps[] = []
  const checkIsSelected = (value): boolean => {
    const currentValue = getValue(applicationFormState, question.id) ?? ''
    if (currentValue === '' || currentValue === undefined || currentValue === null) {
      return false
    }
    return currentValue === value
  }

  question.options?.map((option) => {
    radioItems.push({
      code: 'radio',
      name: question.id,
      label: option.value,
      value: option.value,
      disabled: isLaunched,
      defaultChecked: checkIsSelected(option.value),
      onClick: () => {
        if (isLaunched) {
          return
        }
        const element = event?.target as HTMLInputElement
        const newApplicationFormState = setValue(applicationFormState, question.id, element.value, question.isRequired)
        store.dispatch(changeApplicationForm(newApplicationFormState))
      }
    })
    return option
  })

  return (
    <div className="mb-3" key={question.id}>
      <InputWrapper className={`${(question.isInvalid ?? false) ? 'invalid-input' : ''}`}>
        <Input
          code={question.type}
          type={question.type}
          name={question.name}
          description={getDescription(question.description, question.isRequired)}
          label={question.name}
          radioItems={radioItems}
          isRequired={question.isRequired}
          isInvalid={question.isInvalid}
          notValidFieldMessage={question.notValidFieldMessage}
          value={getValue(applicationFormState, question.id) as string}
        />
      </InputWrapper>
      <div className="d-flex justify-content-between">
        {(question.isInvalid ?? false) && (
          <small style={{ color: 'var(--error)', width: '100%', textAlign: 'left' }}>
            {question.notValidFieldMessage}
          </small>
        )}
      </div>
    </div>
  )
}
