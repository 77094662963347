import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { hasAccess as checkHasAccess } from 'sharedKernel/auth/behavior'
import { trans } from 'sharedKernel/i18n'
import { ChallengeButtonsProps } from '../contracts'
import { useNavigate } from 'react-router'

export const SolutionsButton = ({
  challengeId,
  isOwner
}: ChallengeButtonsProps): JSX.Element => {
  const hasAccess = checkHasAccess('challenge', 'launch')
  const labels = { solutions: trans('Inscrições') }
  const navigate = useNavigate()

  if (!hasAccess || !isOwner) {
    return <></>
  }
  return (
    <div className="pb-4">
      <BaseButton
        btnContent={labels.solutions}
        variant="secondary"
        size="big"
        isFullWidth
        onClick={() => {
          navigate(`/hub/company/challenge/${challengeId}/solutions`)
        }}
      />
    </div>
  )
}
