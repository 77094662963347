export const CHANGE_SOLUTION = 'CHANGE_SOLUTION'

const changeSolution = (solution: any): any => {
  return {
    type: CHANGE_SOLUTION,
    payload: solution
  }
}

export { changeSolution }
