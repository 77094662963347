import { RESET_CHALLENGE_FILES, UPDATE_FILES } from './actions'
import { IHubChallengeBasicFormFilesState } from './contracts'

export const CHALLENGE_BASIC_FORM_FILES_STATE: IHubChallengeBasicFormFilesState = {
  challengeFiles: [],
  deletedChallengeFiles: []
}
export interface IHubChallengeBasicFormFilesStateAction {
  type: string
  payload: IHubChallengeBasicFormFilesState
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubChallengeBasicFormFilesReducer = (
  state: IHubChallengeBasicFormFilesState = CHALLENGE_BASIC_FORM_FILES_STATE,
  action: IHubChallengeBasicFormFilesStateAction
): any => {
  switch (action.type) {
    case UPDATE_FILES:
      return {
        ...state,
        challengeFiles: action.payload.challengeFiles,
        deletedChallengeFiles: action.payload.deletedChallengeFiles,
        homePageSlider: action.payload?.homePageSlider,
        presentationVideo: action.payload?.presentationVideo,
        coverImage: action.payload?.coverImage
      }
    case RESET_CHALLENGE_FILES:
      return {
        ...state,
        challengeFiles: [],
        deletedChallengeFiles: [],
        homePageSlider: undefined,
        presentationVideo: undefined,
        coverImage: undefined
      }
    default:
      return state
  }
}
