import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { DarkThemeProvider } from 'sharedKernel/theme'
import { CreateRoutes } from './routes/routes'
import './sharedKernel/i18n/translate/config'
import { QueryClientWrapper } from './sharedKernel/api'
import { store, Provider } from './store'
import { Toast } from 'components/toast'
import { APP_ENVIRONMENT } from 'sharedKernel/constants'
import './assets/scss/index.scss'
import './assets/style/steps.css'
import './assets/style/globals.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'animate.css'

Sentry.init({
  dsn: 'https://1eb42cdef3724d5f8e5b2c4e9e0fffc3@o446935.ingest.sentry.io/6564991',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: APP_ENVIRONMENT
})

ReactDOM.render(
  <Provider store={store}>
    <QueryClientWrapper>
      <DarkThemeProvider>
        <Toast />
        <CreateRoutes />
      </DarkThemeProvider>
    </QueryClientWrapper>
  </Provider>,
  document.getElementById('app-root')
)
