import React, { useCallback } from 'react'
import { ForgotPasswordForm, AuthPageLayout } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { postProvider, PostFormat } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { useNavigate } from 'react-router-dom'
import { ForgotPasswordField } from 'iebt-zing-storybook/dist/forms/ForgotPasswordForm'
import { ROUTES } from 'sharedKernel/constants'

export const ForgotPasswordPage = (): any => {
  const navigate = useNavigate()
  const { mutate: recoveryLink } = postProvider()

  const labels = {
    recoveryLinkSuccess: trans('Link de recuperação enviado com sucesso'),
    recoveryLinkFail: trans('Houve um erro ao enviar o link de recuperação')
  }

  const sucessfullyRecoveryLinkSendMessage = (): void => {
    showToast('success', labels.recoveryLinkSuccess)
    setTimeout(() => {
      navigate(ROUTES.USER_AUTH.LOGIN)
    }, 1500)
  }

  const unsuccessfullyRecoveryLinkSendMessage = (): void => {
    showToast('error', labels.recoveryLinkFail)
  }

  const handleSendRecoveryLink = useCallback((formData: ForgotPasswordField) => {
    if (formData.email !== '') {
      const request: PostFormat = {
        url: '/v1/system/user/forgot-password',
        data: {
          data: formData // { email: 'example@example.com' }
        }
      }

      recoveryLink(request, {
        onSuccess: () => {
          sucessfullyRecoveryLinkSendMessage()
        },
        onError: () => {
          unsuccessfullyRecoveryLinkSendMessage()
        }
      })
    }
  }, [])

  return (
    <AuthPageLayout>
      <ForgotPasswordForm
        formLabel={trans('Recupere sua senha')}
        submitLabel={trans('Enviar link de recuperação')}
        onSubmit={handleSendRecoveryLink}
        emailPattern={/^\w+([\\.+-]?\w+)*@\w+([\\.+-]?\w+)*(\.\w{2,9})+$/}
      />
    </AuthPageLayout>
  )
}
