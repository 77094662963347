import React from 'react'
import { Badge, BadgeArea, BaseButton, CardHeader, Carousel, CarouselHeader, DefaultCard } from 'iebt-zing-storybook'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router'
import { Challenge } from 'data/contracts'
import { DeleteFormat, deleteProvider } from 'sharedKernel/api'
import { showToast } from 'components/toast'
import { showModal } from 'components/popup'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hasAccess } from 'sharedKernel/auth/behavior'
import { SwiperWrapper } from 'components/swiper'
import { getHubRoute } from 'sharedKernel/handlers'
import { ROUTES } from 'sharedKernel/constants'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

export interface HubCompanyChallengeCarouselListProps {
  challengeList: Challenge[]
  setIsDelete?: React.Dispatch<React.SetStateAction<boolean>>
}

export const HubCompanyChallengeCarouselList = ({
  challengeList,
  setIsDelete = () => { },
  ...props
}: HubCompanyChallengeCarouselListProps): JSX.Element => {
  const navigate = useNavigate()
  const labels = {
    title: trans('Excluir Desafio'),
    message: trans('Deseja realmente excluir esse desafio?'),
    confirm: { text: trans('Sim'), value: 'yes' },
    cancel: { text: trans('Não') },
    onSuccess: trans('Desafio removido'),
    actionCardTitle: trans('Novo Desafio'),
    edit: trans('Editar'),
    remove: trans('Excluir'),
    editTooltip: trans('Editar o desafio'),
    removeTooltip: trans('Excluir o desafio'),
    subscriptions: trans('Inscritos')
  }

  const onSuccessDeletion = async (success: any): Promise<void> => {
    showToast('success', labels.onSuccess)
    setIsDelete(true)
  }

  const { mutate: deleteChallenge } = deleteProvider(onSuccessDeletion)

  const handleDeleteChallenge = (id: string): void => {
    const request: DeleteFormat = {
      url: `/v1/hub/company/challenge/${id}`
    }
    deleteChallenge(request)
  }

  const createBadges = (challenge: Challenge, index: number): JSX.Element[] => {
    const { status } = challenge

    if (status.code === undefined) {
      status.code = ''
    }

    return [
      <Badge
        key={index}
        icon={['fas', 'signal']}
        status={challenge.status.code === 'Created' ? 'pending' : 'launched'}
      />,
      <Badge
        key={index + 1}
        icon={['fas', 'lightbulb']}
        text={labels.subscriptions}
        value={String(challenge.statistics.totalIdeas)}
      />
    ]
  }

  const challenges = challengeList.map((challenge, index) => {
    const challengeShortDescription = challenge.style.shortDescription
    const challengeBadges = <BadgeArea>{createBadges(challenge, index)}</BadgeArea>
    const challengeHeader = <CardHeader coverImage={challenge.style.coverImagePath} profileImage={challenge.owner.image} />
    const challengeButtons = [
      <Tippy
        content={labels.editTooltip}
        followCursor={true}
        plugins={[followCursor]}
        animation="scale"
        arrow={false}
        theme="primary"
      >
        <div>
          <BaseButton
            key={index}
            variant="risk"
            size="medium"
            onClick={() => {
              navigate(`/hub/company/challenge/${challenge.id}/edit`)
            }}
            btnContent={labels.edit}
          />
        </div>
      </Tippy>,
      <Tippy
        content={labels.removeTooltip}
        followCursor={true}
        plugins={[followCursor]}
        animation="scale"
        arrow={false}
        theme="primary"
      >
        <div>
          <BaseButton
            key={index + 1}
            variant="risk-secondary"
            size="medium"
            onClick={() => {
              showModal('double', {
                title: labels.title,
                message: labels.message,
                buttons: [{ text: labels.confirm.text, value: labels.confirm.value }, { text: labels.cancel.text }],
                action: handleDeleteChallenge.bind(null, challenge.id)
              })
            }}
            btnContent={labels.remove}
          />
        </div>
      </Tippy>
    ]

    return (
      <DefaultCard
        title={challenge.config.title}
        shortDescription={challengeShortDescription}
        badges={challengeBadges}
        header={challengeHeader}
        buttons={challengeButtons}
        key={index}
        sendTo={() => {
          navigate(`/hub/company/challenge/${challenge.id}`)
        }}
      />
    )
  })

  const cardsList = [...challenges]

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      900: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      1800: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      2200: {
        slidesPerView: 6,
        spaceBetween: 20
      },
      2800: {
        slidesPerView: 7,
        spaceBetween: 20
      }
    }
  }

  return (
    <>
      <CarouselHeader
        title={trans('Desafios')}
        info={trans('Veja os desafios cadastrados')}
        button={
          // eslint-disable-next-line multiline-ternary
          hasAccess('challenge', 'create') ? (
            <Button
              variant="primary"
              className="fw-bold fs-6 px-4 text-nowrap"
              style={{ letterSpacing: '1.5px' }}
              onClick={() => {
                navigate(getHubRoute(ROUTES.HUB.COMPANY.CHALLENGE_TEMPLATE))
              }}
            >
              <FontAwesomeIcon icon={['fas', 'plus']} className="mr-3" />
              {trans('Criar Desafio')}
            </Button>
          ) : (
            <></>
          )
        }
      />
      <SwiperWrapper breakpoints={buildBreakpoints()} carouselItems={cardsList} arrowColor='var(--primary)' />
    </>
  )
}
