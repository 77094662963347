import { CHALLENGE_BASIC_FORM_VALIDATIONS_SET_STATE, CHALLENGE_BASIC_FORM_VALIDATIONS_STATE } from './actions'
import {
  IHubChallengeBasicFormValidationState,
  IHubChallengeBasicFormValidationStateAction
} from './contracts'

export const HubChallengeBasicFormValidationReducer = (
  state: IHubChallengeBasicFormValidationState = CHALLENGE_BASIC_FORM_VALIDATIONS_STATE,
  action: IHubChallengeBasicFormValidationStateAction
): any => {
  switch (action.type) {
    case CHALLENGE_BASIC_FORM_VALIDATIONS_SET_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
