import {
  store,
  changeApplicationForm,
  IBuildUIApplicationForm,
  IHubStartupChallengeApplicationForm,
  IAnswerApplicationForm,
  ISectionApplicationForm,
  updateApplicationFormFiles,
  IHubStartupChallengeApplicationFilesState,
  IHubStartupChallengeApplicationFileStructure
} from 'store'

// Create idea format data from applicationForm format, and fill with idea
export function initDataToSave(
  formData: IBuildUIApplicationForm,
  editIdea: any = null
): IHubStartupChallengeApplicationForm {
  const data2Save: IHubStartupChallengeApplicationForm = {
    id: null,
    title: '',
    challenge: {
      id: formData.dataFromRequest.id ?? '',
      applicationForm: {
        id: formData.dataFromRequest.applicationFormid
      }
    },
    sections: [],
    isLaunched: false
  }

  const applicationFormFile: IHubStartupChallengeApplicationFilesState = {
    applicationFormFiles: [],
    deletedApplicationFormFiles: []
  }

  formData.dataFromRequest.applicationFormSections?.map((section, sectionIdx) => {
    const dataSection: ISectionApplicationForm = {
      sectionId: section.id,
      answers: []
    }

    section.fields.forEach((field, fieldIdx) => {
      const answer: IAnswerApplicationForm = {
        questionId: field.id,
        value: ''
      }
      dataSection.answers?.push(answer)
    })

    data2Save.sections.push(dataSection)

    return section
  })

  if (editIdea !== null) {
    data2Save.id = editIdea.data.id
    data2Save.title = editIdea.data.title
    data2Save.sections = editIdea.data.sections
    data2Save.isLaunched = editIdea.data.isLaunched
    editIdea.data.sections.forEach((section) => {
      const files: IHubStartupChallengeApplicationFileStructure[] = section.answers
        .filter((field) => field.archive !== null)
        .map((field) => ({
          id: field.id,
          path: field.archive.path,
          name: field.archive.name,
          size: null,
          mimetype: field.archive.mimetype,
          extension: null,
          content: null,
          sourceFile: null,
          sectionId: section.sectionId,
          questionId: field.questionId,
          type: 'upload'
        }))
      applicationFormFile.applicationFormFiles = [...applicationFormFile.applicationFormFiles, ...files]
    })
  }

  store.dispatch(updateApplicationFormFiles(applicationFormFile))
  store.dispatch(changeApplicationForm(data2Save))

  return data2Save
}
