import React from 'react'
import swal from '@sweetalert/with-react'
import { PopUpContainer, PopUpContent, PopUpHeader, PopUpParagraph, PopUpTitle } from './style'
import '../style.css'

export interface SingleActionAlertProps {
  title: string
  message: string | JSX.Element
  textButton?: string
}

export const showSingleActionPopUp = ({ title, message, textButton = 'Fechar' }: SingleActionAlertProps): void => {
  swal({
    button: textButton,
    content: (
      <PopUpContainer>
        <PopUpHeader>
          <PopUpTitle>{title}</PopUpTitle>
        </PopUpHeader>
        <PopUpContent>
          <PopUpParagraph>{message}</PopUpParagraph>
        </PopUpContent>
      </PopUpContainer>
    )
  })
}
