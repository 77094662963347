import React, { useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useSelector, TReducers } from 'store'
import { HotsiteChallenge, HotsiteChallengeProps } from '../../store/contracts'
// Componentes
import { BadgeArea, Badge, BaseButton, OpenChallengesCard, CardHeader } from 'iebt-zing-storybook'
import { Container, Row, Col } from 'react-bootstrap'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import {
  OpenChallengesMarketplaceWrapper,
  OpenChallengesMarketplaceInfoWrapper,
  OpenChallengesMarketplaceTitle,
  OpenChallengesMarketplaceCards,
  HotsiteCardsRow,
  HotsiteCardsColumn
} from './styles'
import { Filter } from 'components/Filter'
import { Loading } from 'components/loading'
import { useInfiniteScroll } from 'sharedKernel/hooks/infiniteScroll'
import { isCurrentDateBiggerThanDeadline } from 'sharedKernel/handlers'
import { handleApplyAction } from '../../behavior'

const LAZY_LOAD_CHUNK = 20

export const OpenChallengesMarketplace = (): JSX.Element => {
  const navigate = useNavigate()

  const hotsiteChallenges: HotsiteChallengeProps = useSelector((state: TReducers) => state.hotsiteChallengesData)
  const isAuthenticated = useSelector((state: TReducers) => state.auth.isAuthenticated)
  const userPermissions = useSelector((state: TReducers) => state.auth.permissions)

  const [filterValue, setFilterValue] = useState('')
  const [totalScreenCards, setTotalScreenCards] = useState(LAZY_LOAD_CHUNK)

  const [isFetching, setIsFetching] = useInfiniteScroll(onLoadAtBottomPage, { itemsPerLoad: LAZY_LOAD_CHUNK, loadEnd: hotsiteChallenges.rows?.length })

  const openChallengesLabels = {
    title: trans('Confira alguns dos desafios abertos'),
    closed: trans('Encerrado'),
    apply: trans('Ver mais'),
    signUpTooltip: trans('Veja mais detalhes do desafio'),
    closedTooltip: trans('A data limite foi atingida')
  }

  const onFilterChange = (keyword: string): void => {
    setFilterValue(keyword.toLowerCase())
  }

  function onLoadAtBottomPage(): void {
    setTimeout(() => {
      setTotalScreenCards(totalScreenCards + LAZY_LOAD_CHUNK)
      setIsFetching(false)
    }, 500)
  }

  const handleFilterIfValid = (keyword: string): HotsiteChallenge[] => {
    if (isValidKeyword(keyword)) {
      return filterChallenges(hotsiteChallenges)
    }
    return hotsiteChallenges.rows ?? []
  }

  const isValidKeyword = (keyword: string): boolean => {
    return keyword.length >= 3
  }

  const filterChallenges = (challenges: any): any => {
    return challenges.rows?.filter((challenge) => { return challenge.config.title.toLowerCase().includes(filterValue) })
  }

  return (
    <OpenChallengesMarketplaceWrapper fluid>
      <Row className="d-flex w-full flex-column align-items-center">
        <Col>
          <OpenChallengesMarketplaceInfoWrapper>
            <OpenChallengesMarketplaceTitle>{openChallengesLabels.title}</OpenChallengesMarketplaceTitle>
          </OpenChallengesMarketplaceInfoWrapper>
        </Col>
        <Col lg={6} className="my-5">
          <Container>
            <Filter
              onFilterChange={onFilterChange}
              searchBtn={false}
            />
          </Container>
        </Col>
        <Col>
          <OpenChallengesMarketplaceCards>
            <Container>
              <HotsiteCardsRow>
                {handleFilterIfValid(filterValue)?.slice(0, totalScreenCards).map((challenge, index) => {
                  const deadline = DateTime.fromISO(challenge.config.step.finalSelectionDate).setLocale('pt').toLocaleString(DateTime.DATE_FULL)
                  const isClosed = isCurrentDateBiggerThanDeadline(challenge.config.step.finalSelectionDate)
                  const tooltipContent = isClosed ? openChallengesLabels.closedTooltip : openChallengesLabels.signUpTooltip
                  const buttons = (
                    <Tippy
                      content={tooltipContent}
                      followCursor={true}
                      plugins={[followCursor]}
                      animation="scale"
                      arrow={false}
                      theme="primary"
                    >
                      <div>
                        {isClosed
                          ? (<BaseButton
                            btnContent={openChallengesLabels.closed}
                            variant='risk-secondary'
                            size='medium'
                            disabled
                          />)
                          : (<BaseButton
                            btnContent={openChallengesLabels.apply}
                            variant='risk-secondary'
                            size='medium'
                            onClick={() => navigate(handleApplyAction(isAuthenticated, userPermissions, challenge.id))}
                          />)}
                      </div>
                    </Tippy>)
                  return (
                    <HotsiteCardsColumn key={index}>
                      <OpenChallengesCard
                        header={<CardHeader coverImage={challenge.style.coverImagePath} />}
                        title={challenge.config.title}
                        shortDescription={challenge.style.shortDescription}
                        badges={
                          <BadgeArea badgeQty={1}>
                            <Badge
                              deadline={deadline}
                              icon={['fas', 'calendar-day']}
                              type="string"
                            >
                              {deadline}
                            </Badge>
                          </BadgeArea>
                        }
                        buttons={buttons}
                      />
                    </HotsiteCardsColumn>
                  )
                })}
              </HotsiteCardsRow>
              {isFetching === true ? <Loading /> : <></>}
            </Container>
          </OpenChallengesMarketplaceCards>
        </Col>
      </Row>
    </OpenChallengesMarketplaceWrapper >
  )
}
