import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

export const trans = (text: string): string => {
  const { t } = useTranslation()

  return t(text)
}

export const changeSupportedLanguage = (supportedLanguage: string): void => {
  // 'en-US' | 'pt-BR' | 'es-ES'
  localStorage.setItem('language', supportedLanguage)
  i18n.changeLanguage(supportedLanguage)
}
