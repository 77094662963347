import React, { useState } from 'react'
import { CardHeader, BaseButton, EvaluationCard } from 'iebt-zing-storybook'
import { Accordion } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { SwiperWrapper } from 'components/swiper'
import { NoContent } from 'components/NoContent'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { trans } from 'sharedKernel/i18n'
import { changeVisibleRows, store, TReducers, useSelector } from 'store'
import {
  IMenuEvaluators,
  IMenuEvaluatorsResolvers,
  IMenuEvaluatorsSolutions,
  IMenuEvaluatorsSteps
} from 'components/menu/state/content/store/contracts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translateStatus } from 'sharedKernel/status/evaluator/behavior'

import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import 'tippy.js/themes/translucent.css'
import { AccordionDate, AccordionHeader, AccordionTitle, AccordionIcon } from './styles'
import { dateFormater, isCurrentDateBetweenDates } from 'sharedKernel/handlers'

export interface IdeasAccordionProps {
  orderBy: string
}

export const IdeasAccordion = ({ orderBy }: IdeasAccordionProps): JSX.Element => {
  const navigate = useNavigate()
  const menuEvaluations: IMenuEvaluators = useSelector((state: TReducers) => state.menuEvaluatorsState)
  const visible = useSelector((state: TReducers) => state.isEvaluationMenu)
  const [accordionOpen, setAccordionOpen] = useState(true)

  const labels = {
    toEvaluate: trans('Avaliar'),
    evaluated: trans('Ver avaliação'),
    deadline: trans('Prazo para avaliação'),
    noContent: trans('Você ainda não tem soluções atribuídas'),
    evaluationNotStartedYet: trans('Fora do período de avaliações')
  }

  const resolversItems = (): JSX.Element[] => {
    const resolvers: JSX.Element[] = []

    menuEvaluations.rows?.forEach((evaluation, index) => {
      const dateFormatting = dateFormater(evaluation.config.step.closingDate, 'dd/MM/yyyy')
      const evaluationResolverList = getResolversList(evaluation.evaluation.steps,
        evaluation.id,
        evaluation.style.coverImage,
        isCurrentDateBetweenDates(evaluation.config.step.evaluationStartDate, evaluation.config.step.closingDate),
        evaluation.config.hideIdeaTitle)
      resolvers.push(
        <Accordion.Item key={evaluation.id} eventKey={index.toString()} className='bg-transparent'>
          <div className='d-flex'>
            <AccordionHeader>
              <AccordionTitle
                onClick={() => setAccordionOpen(!accordionOpen)}
                className='mr-auto p-2'
              >
                {evaluation.config.title}
              </AccordionTitle>
              <AccordionDate
                className='p-2'
              >
                <Tippy
                  content={labels.deadline}
                  followCursor={true}
                  plugins={[followCursor]}
                  animation="scale"
                  arrow={false}
                  theme="primary"
                >
                  <span>
                    <FontAwesomeIcon
                      className="pl-4 pr-2"
                      icon={['fas', 'calendar-check']}
                      size="sm"
                      color="var(--primary)"
                    />
                  </span>
                </Tippy>
                <span>{dateFormatting}</span>
              </AccordionDate>
              <div
                className="d-flex justify-content-start align-items-center p-2"
              >
                <AccordionIcon onClick={() => {
                  navigate(`/hub/evaluator/challenge/${evaluation.id}`)
                }}
                >
                  <FontAwesomeIcon
                    icon={['fas', 'circle-info']}
                    size="sm"
                    color="var(--primary)"
                  />
                </AccordionIcon>
              </div>
            </AccordionHeader>
          </div>
          <Accordion.Body bsPrefix="accordion-body-platform">
            {renderAccordionBody(evaluationResolverList)}
          </Accordion.Body>
        </Accordion.Item>
      )
    })

    return resolvers
  }

  const renderAccordionBody = (evaluationResolverList: JSX.Element[]): JSX.Element => {
    return (
      <>
        {evaluationResolverList.length > 0
          ? <SwiperWrapper breakpoints={buildBreakpoints()} carouselItems={evaluationResolverList} arrowColor='var(--primary)' />
          : <NoContent text={labels.noContent} image={NoContentImg} imageWidth='350' imageHeight='182' />
        }
      </>
    )
  }
  const getSolutionTitle = (hide: boolean, solution: IMenuEvaluatorsSolutions): string => {
    if (hide) {
      return 'Empresa oculta'
    } else {
      return solution.title
    }
  }

  const getResolversList = (
    stepsList: IMenuEvaluatorsSteps[],
    challengeId: string,
    coverImage: string,
    hasEvaluationStarted: boolean,
    hideIdeaTitle: boolean
  ): JSX.Element[] => {
    const resolversList: JSX.Element[] = []
    stepsList.forEach((step: IMenuEvaluatorsSteps, stepIndex) => {
      const sortedSolutions = getSortedSolutions(step.resolvers)
      sortedSolutions.forEach((solution: IMenuEvaluatorsSolutions, solutionIndex) => {
        const fillPercentage = Math.round(solution.evaluationFillPercentage)
        const newValidation = hasEvaluationStarted && solution.status.name.toLocaleLowerCase() === 'launched'
        resolversList.push(
          <EvaluationCard
            solutionName={getSolutionTitle(hideIdeaTitle, solution)}
            fillPercentage={fillPercentage}
            status={translateStatus(solution.status.name)}
            points={solution.points}
            header={<CardHeader coverImage={coverImage} maxHeight="5.5rem" />}
            buttons={[
              <Tippy
                content={labels.evaluationNotStartedYet}
                animation="scale"
                arrow={false}
                theme="primary"
                disabled={newValidation}
              >
                <div>
                  <BaseButton
                    key={solution.id}
                    disabled={!newValidation}
                    variant="risk"
                    size="medium"
                    btnContent={solution.status.name === 'evaluated' ? labels.evaluated : labels.toEvaluate}
                    onClick={() =>
                      navigate(`/hub/evaluator/challenge/${challengeId}/step/${step.id}/idea/${solution.id}`)
                    }
                  />
                </div>
              </Tippy>
            ]}
            key={solution.id}
            sendTo={() => {
              if (newValidation) {
                navigate(`/hub/evaluator/challenge/${challengeId}/step/${step.id}/idea/${solution.id}`)
              }
            }}
          />
        )
      })
    })

    return resolversList
  }

  const getSortedSolutions = (resolvers: IMenuEvaluatorsResolvers[]): IMenuEvaluatorsSolutions[] => {
    const sortedSolutions: IMenuEvaluatorsSolutions[] = []

    resolvers.forEach((resolver, resolverIndex) => {
      resolver.solutions.forEach((solution: IMenuEvaluatorsSolutions, solutionIndex) => {
        sortedSolutions.push(solution)
      })
    })
    if (orderBy !== 'open_first') {
      sortedSolutions.sort((a, b) => {
        return b.evaluationFillPercentage - a.evaluationFillPercentage
      })
    }
    if (orderBy === 'open_first') {
      sortedSolutions.sort((a, b) => {
        return a.evaluationFillPercentage - b.evaluationFillPercentage
      })
    }
    return sortedSolutions
  }

  const buildBreakpoints = (): any => {
    return {
      500: {
        slidesPerView: 1
      },
      700: {
        slidesPerView: 1
      },
      900: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      1700: {
        slidesPerView: 5,
        spaceBetween: 30
      },
      2000: {
        slidesPerView: 6,
        spaceBetween: 30
      }
    }
  }

  return (
    <Accordion
      defaultActiveKey={accordionOpen ? '0' : '-1'} alwaysOpen
      activeKey={visible.visibleRows}
      flush
      onSelect={(eventKey: any) => {
        store.dispatch(changeVisibleRows(eventKey))
      }}
    >
      {resolversItems()}
    </Accordion>
  )
}
