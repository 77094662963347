/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import {
  useSelector,
  TReducers,
  IHubStartupChallengeApplicationFormProps,
  IBuildUIApplicationFormSection,
  store,
  changeApplicationForm,
  resetApplicationForm,
  resetApplicationFormFiles,
  IDynamicFormField
} from 'store'
import {
  SaveButtonSection,
  SaveAndLaunchButtonSection,
  ButtonsWrapper,
  TitleAndButtonsWrapper,
  FormIdeaAnswer,
  FormIdeaQuestion,
  FormLaunchedFieldWrapper
} from './style'
import { HubStartupIdeaSaveButton } from '../../idea/components/SaveButton'
import { ApplicationFormInput } from './Input'
import { ICarouselNavItems } from 'components/swiper/swiperNav'
import { SwiperNavForm } from 'components/swiper/swiperNav/swiperNavForm'
import { Input } from 'iebt-zing-storybook'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { FormChallengeHeader } from 'sharedKernel/styledComponents/stickHeader/style'
import { ApplicationFormWrapper, ApplicationFormWrapperHeader, Hr, IdeaTitle, TitleWrapper } from 'sharedKernel/styledComponents/solutionFormComponents/styles'
import { showToast } from 'components/toast'

export interface FormStep {
  name?: string
  component: JSX.Element | undefined
}

export function HubStartupChallengeApplicationForm({
  applicationForm
}: IHubStartupChallengeApplicationFormProps): JSX.Element {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const applicationFormFilesState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormFilesState)
  const isLaunched = applicationFormState.isLaunched ?? false

  const labels = {
    success: trans('Ideia salva com sucesso'),
    fail: trans('Houve um erro ao salvar a ideia'),
    next: trans('Próximo'),
    back: trans('Voltar'),
    save: trans('Salvar'),
    saveNLaunch: trans('Publicar'),
    step: trans('Etapa'),
    launchSuccess: trans('Parabéns, sua inscrição foi enviada para aprovação!'),
    emptyTitle: trans('Campo obrigatório'),
    titleName: trans('Qual o nome da sua startup/empresa'),
    titleDescription: trans('Campo Obrigatório'),
    titleLabel: trans('Qual o nome da sua startup/empresa'),
    titlePlaceholder: trans('Insira o nome da sua startup/empresa aqui.'),
    toastMessage: trans('Atenção: O campo \'{}\' deve ser preenchido.'),
    title: trans('Título ')
  }

  useEffect(() => {
    return () => {
      componentUnmount()
    }
  }, [])

  const componentUnmount = (): void => {
    store.dispatch(resetApplicationForm())
    store.dispatch(resetApplicationFormFiles())
  }

  const [isTitleEmpty, setIsTitleEmpty] = useState(false)
  const [validateForm, setValidateForm] = useState(false)

  const buildBreakpoints = (): any => {
    return {
      350: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      700: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
  }
  const validateFormFields = (section: number, question: IDynamicFormField): boolean => {
    if (!question.isRequired) {
      return false
    }
    const answer = applicationFormState.sections[section].answers.find((answer) => {
      return answer.questionId === question.id
    })
    switch (question.type) {
      case 'text':
        if (answer?.value === '') {
          return true
        }
        break
      case 'upload':
        if (answer?.archive === null || answer?.archive === undefined) {
          return true
        }
        break
    }
    return false
  }

  const setFormValidated = (launch: boolean, titleEmpty: boolean): boolean => {
    let formValidated: boolean = true
    if (titleEmpty) {
      showToast('error', labels.toastMessage.replace('{}', labels.title))
      formValidated = false
    }
    if (launch) {
      applicationForm.dataFromRequest.applicationFormSections.forEach((section, sectionIndex) => {
        section.fields.forEach((field, fieldIndex) => {
          const answer = applicationFormState.sections[sectionIndex].answers.find((answer) => {
            return answer.questionId === field.id
          })
          if (field.type === 'upload') {
            const file = applicationFormFilesState.applicationFormFiles.find((file) => {
              return (file.questionId === field.id && file.sectionId === section.id)
            })
            if (file === null || file === undefined) {
              showToast('error', labels.toastMessage.replace('{}', field.name))
              formValidated = false
            }
          } else {
            if (field.isRequired && answer?.value === '') {
              showToast('error', labels.toastMessage.replace('{}', field.name))
              formValidated = false
            }
          }
        })
      })
    }
    return !!formValidated
  }

  function buildSteps(sections: IBuildUIApplicationFormSection[]): { formSteps: FormStep[], carouselNavItems: ICarouselNavItems[] } {
    const carouselNavItems: ICarouselNavItems[] = []
    const formSteps: FormStep[] = []

    sections.forEach((section, sectionIndex) => {
      carouselNavItems.push({
        name: section.name ?? '',
        itemHash: section.id
      })
      formSteps.push({
        name: section.id,
        component: (
          <div className='mt-4' key={`${sectionIndex}section`}>
            <div className='d-flex flex-column'>
              <h4>{`${String(section.name)}`}</h4>
              <Hr />
            </div>
            {section.fields?.map((question, index) => {
              const questionWithSectionId = { ...question, sectionId: section.id, isInvalid: validateForm ? validateFormFields(sectionIndex, question) : false, notValidFieldMessage: labels.emptyTitle }
              return <ApplicationFormInput key={`${index}field`} {...questionWithSectionId} />
            })}
          </div>
        )
      })
      return section
    })

    return {
      carouselNavItems,
      formSteps
    }
  }

  const { carouselNavItems, formSteps } = buildSteps(applicationForm.dataFromRequest.applicationFormSections)

  const renderLaunchedAnswer = (): JSX.Element => {
    return (
      <FormLaunchedFieldWrapper>
        <FormIdeaQuestion>{labels.titleName}</FormIdeaQuestion>
        <FormIdeaAnswer>{applicationFormState.title}</FormIdeaAnswer>
      </FormLaunchedFieldWrapper>
    )
  }

  return (
    <ApplicationFormWrapper>
      <FormChallengeHeader>
        <TitleAndButtonsWrapper>
          <TitleWrapper>
            <PageTitle>{applicationForm.challengeTitle}</PageTitle>
          </TitleWrapper>
          <ButtonsWrapper>
            <SaveButtonSection>
              {applicationFormState.isLaunched === false ? (
                <HubStartupIdeaSaveButton
                  setIsTitleEmpty={setIsTitleEmpty}
                  setValidateForm={setValidateForm}
                  data={applicationFormState}
                  text={labels.save}
                  setFormValidated={setFormValidated}
                />
              ) : (
                <></>
              )}
            </SaveButtonSection>
            <SaveAndLaunchButtonSection>
              {applicationFormState.isLaunched === false ? (
                <HubStartupIdeaSaveButton
                  setIsTitleEmpty={setIsTitleEmpty}
                  setValidateForm={setValidateForm}
                  text={labels.saveNLaunch}
                  launch={true}
                  data={applicationFormState}
                  setFormValidated={setFormValidated}
                />
              ) : (
                <></>
              )}
            </SaveAndLaunchButtonSection>
          </ButtonsWrapper>
        </TitleAndButtonsWrapper>
        <ApplicationFormWrapperHeader>
          <SwiperNavForm breakpoints={buildBreakpoints()} carouselNavItems={carouselNavItems} offset={-220} />
        </ApplicationFormWrapperHeader>
      </FormChallengeHeader>
      <IdeaTitle>
        {
          isLaunched
            ? renderLaunchedAnswer()
            : <Input
              code={'text'}
              type={'text'}
              name={labels.titleName}
              description={labels.titleDescription}
              label={labels.titleLabel}
              placeholder={labels.titlePlaceholder}
              value={applicationFormState.title}
              isInvalid={isTitleEmpty}
              notValidFieldMessage={labels.emptyTitle}
              maxLength={256}
              onChange={(event) => {
                applicationFormState.title = event.target.value
                setIsTitleEmpty(applicationFormState.title === '')
                store.dispatch(changeApplicationForm(applicationFormState))
              }}
            />
        }
      </IdeaTitle>
      {formSteps.map((step, stepIndex) => {
        return (
          <section id={step.name} key={stepIndex}>
            {step.component}
          </section>
        )
      })}
    </ApplicationFormWrapper>
  )
}
