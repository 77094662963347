import { DescriptedLabel } from 'iebt-zing-storybook'
import { buildValidations, handleValidation } from '../../behaviors/validation'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { IDatePicker } from '../../constants'
import DatePicker from 'react-datepicker'
import { ErrorLabel, FormItem } from '../../style'

export function BuildDatePicker(item: IDatePicker, key: number | string): JSX.Element {
  const [date, setDate] = useState(item.value)
  const isRequired = item.isRequired ?? false
  return (
    <FormItem key={key}>
      <DescriptedLabel description={item.description} boldText={isRequired} htmlFor={item.id}>
        <span>
          {item.label}
          {isRequired && <span style={{ color: 'red' }}> *</span>}
        </span>
      </DescriptedLabel>
      <DatePicker
        id={item.id}
        disabled={item.disabled}
        className={`form-control ${item.id} ${buildValidations(item.validations)}`}
        dateFormat='dd/MM/yyyy'
        maxDate={item.maxDate}
        minDate={item.minDate}
        selected={
          date != null
            ? new Date(`${String(date)}T00:00`)
            : null}
        onChange={(selectedDate) => {
          if (selectedDate != null) {
            const pickedDate = DateTime.fromJSDate(selectedDate)
            item.value = pickedDate.toISODate()
          } else {
            item.value = null
          }
          if (item.updateFieldState !== undefined) {
            item.updateFieldState(item)
          }
          setDate(item.value)
          // Fazer Validar campo aqui, embolou o meio de campo e preciso dar vazão
          document.getElementsByClassName(`errors-${item.id}`)[0].textContent = ''
        }}
      />
      <ErrorLabel className={`errors-${item.id}`}></ErrorLabel>
    </FormItem>
  )
}
