import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { DashboardSelector, DashboardSelectorLabel } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

export interface DashboardCardSelectorProps {
  label: string
}

export const DashboardCardSelector = ({ label, ...props }: DashboardCardSelectorProps): JSX.Element => {
  const arrowDownIcon: [IconPrefix, IconName] = ['fas', 'chevron-down']

  return (
    <DashboardSelector>
      <DashboardSelectorLabel>{`${trans(label)}`}</DashboardSelectorLabel>
      <FontAwesomeIcon icon={arrowDownIcon} size={'sm'} style={{ marginTop: '3px' }} />
    </DashboardSelector>
  )
}
