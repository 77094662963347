import { IHubChallengeBasicFormState, INITIAL_EVALUATIONFORM_STEPS } from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/store'
import {
  IField,
  ISection,
  IStep,
  ITab
} from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/constants'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { challengeForm } from './index'
import { IHubChallengeBasicFormFilesState } from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/files/store'
import { formatDate } from '../../../../../../domain/feature/hub/company/challenge/FormWrapper/behaviors/date'

enum Form {
  ConfigForm = 0,
  ApplicationForm = 1,
  EvaluationForm = 2
}

enum Field {
  Title = 0,
  Niches = 1,
  ShortDescription = 2,
  LongDescription = 3,
  LaunchDate = 4,
  ApplicationDate = 5,
  FinalSelectionDate = 6,
  EvaluationStartDate = 7,
  ClosingDate = 8,
  LimitOfIdeas = 9,
  YoutubeLink = 10,
  CoverImage = 11,
  HomePageSlider = 12,
  PresentationVideo = 13,
  Archives = 14
}

export const fillChallenge = (values: IHubChallengeBasicFormState, files: IHubChallengeBasicFormFilesState): ITab[] => {
  const data = JSON.parse(JSON.stringify(challengeForm))
  _.set(data, `[${Form.ConfigForm}].content[${Field.Title}].value`, _.get(values, 'config.title'))
  _.set(data, `[${Form.ConfigForm}].content[${Field.Niches}].value`, _.get(values, 'config.niches'))
  _.set(data, `[${Form.ConfigForm}].content[${Field.ShortDescription}].value`, _.get(values, 'style.shortDescription'))
  _.set(data, `[${Form.ConfigForm}].content[${Field.LongDescription}].value`, _.get(values, 'style.longDescription'))
  _.set(
    data,
    `[${Form.ConfigForm}].content[${Field.LaunchDate}].value`,
    formatDate(_.get(values, 'config.step.launchDate'))
  )
  _.set(
    data,
    `[${Form.ConfigForm}].content[${Field.ApplicationDate}].value`,
    formatDate(_.get(values, 'config.step.launchDate'))
  )
  _.set(
    data,
    `[${Form.ConfigForm}].content[${Field.FinalSelectionDate}].value`,
    formatDate(_.get(values, 'config.step.finalSelectionDate'))
  )
  _.set(
    data,
    `[${Form.ConfigForm}].content[${Field.EvaluationStartDate}].value`,
    formatDate(_.get(values, 'config.step.evaluationStartDate'))
  )
  _.set(
    data,
    `[${Form.ConfigForm}].content[${Field.ClosingDate}].value`,
    formatDate(_.get(values, 'config.step.closingDate'))
  )
  _.set(data, `[${Form.ConfigForm}].content[${Field.LimitOfIdeas}].value`, _.get(values, 'config.limitOfIdeas'))
  _.set(data, `[${Form.ConfigForm}].content[${Field.YoutubeLink}].value`, _.get(values, 'style.youtubeLink'))

  readFiles(files, data, `[${Form.ConfigForm}].content[${Field.CoverImage}].archive`, 'coverImage')
  readFiles(files, data, `[${Form.ConfigForm}].content[${Field.HomePageSlider}].archive`, 'homePageSlider')
  readFiles(files, data, `[${Form.ConfigForm}].content[${Field.PresentationVideo}].archive`, 'presentationVideo')

  _.set(data, `[${Form.ConfigForm}].content[${Field.Archives}].archives`, [])
  files.challengeFiles.forEach((file, fileKey) => {
    readFiles(
      files,
      data,
      `[${Form.ConfigForm}].content[${Field.Archives}].archives[${fileKey}]`,
      `challengeFiles[${fileKey}]`
    )
  })

  if (values.applicationForm.id != null) {
    _.set(data, `[${Form.ApplicationForm}].content[0].id`, values.applicationForm.id)
  }

  if (values.evaluation.id != null) {
    _.set(data, `[${Form.EvaluationForm}].content[0].id`, values.evaluation.id)
  }

  values.applicationForm.sections?.forEach((section, sectionKey) => {
    const applicationFormSection: ISection = {
      fields: [],
      id: section.id ?? `${String(uuidv4())}_idScreen`,
      isDeleted: section.isDeleted ?? false,
      isHidden: section.isHidden ?? false,
      name: section.name ?? '',
      namePlaceholder: section.namePlaceholder,
      path: ''
    }

    section.fields?.forEach((field) => {
      const sectionField: IField = {
        id: field.id ?? `${String(uuidv4())}_idScreen`,
        isDeleted: field.isDeleted ?? false,
        isRequired: field.isRequired ?? false,
        isHidden: field.isHidden ?? false,
        path: '',
        type: 'dynamic',
        name: field.name,
        namePlaceholder: field.namePlaceholder,
        description: field.description,
        descriptionPlaceholder: field.descriptionPlaceholder,
        size: field.size,
        questionType: field.type,
        options: []
      }

      field.options?.forEach((option) => {
        // @ts-expect-error
        sectionField.options.push({
          id: option.id ?? `${String(uuidv4())}_idScreen`,
          value: option.value,
          isDeleted: option.isDeleted ?? false,
          path: ''
        })
      })

      // @ts-expect-error
      applicationFormSection.fields.push(sectionField)
    })
    _.set(data, `[${Form.ApplicationForm}].content[0].sections[${sectionKey}]`, applicationFormSection)
  })

  if (values.evaluation.steps.length === 0) {
    values.evaluation.steps = INITIAL_EVALUATIONFORM_STEPS
  }
  values.evaluation.steps?.forEach((step, stepKey) => {
    const evaluationStep: IStep = {
      id: step.id ?? `${String(uuidv4())}_idScreen`,
      name: step.name,
      namePlaceholder: step.namePlaceholder,
      path: '',
      isDeleted: step.isDeleted ?? false,
      sections: []
    }
    step.sections?.forEach((section) => {
      const stepSection: ISection = {
        fields: [],
        id: section.id ?? `${String(uuidv4())}_idScreen`,
        isDeleted: section.isDeleted ?? false,
        name: section.name ?? '',
        namePlaceholder: section.namePlaceholder,
        path: ''
      }

      section.fields?.forEach((field) => {
        const sectionField: IField = {
          isCriterion: true,
          weight: field.weight,
          id: field.id ?? `${String(uuidv4())}_idScreen`,
          isDeleted: field.isDeleted ?? false,
          isRequired: field.isRequired ?? true,
          isHidden: field.isHidden ?? false,
          path: '',
          type: 'dynamic',
          name: field.name,
          description: field.description,
          size: field.size,
          questionType: field.type,
          options: [],
          gradeInfo: field.gradeInfo
        }
        // @ts-expect-error
        stepSection.fields.push(sectionField)
      })
      evaluationStep.sections.push(stepSection)
    })
    _.set(data, `[${Form.EvaluationForm}].content[0].steps[${stepKey}]`, evaluationStep)
  })
  return data
}

const readFiles = (
  formFilesState: IHubChallengeBasicFormFilesState,
  form: ITab[],
  path: string,
  type: string
): void => {
  const file = _.get(formFilesState, `${type}`)
  if (file === undefined) {
    _.set(form, `${path}`, {
      id: '',
      path: null,
      isDeleted: true,
      name: null,
      size: null,
      mimetype: null,
      extension: null,
      type: null,
      content: null,
      sourceFile: null
    })
    return
  }
  _.set(form, `${path}`, {
    id: _.get(formFilesState, `${type}.id`),
    path: _.get(formFilesState, `${type}.path`),
    isDeleted: false,
    name: _.get(formFilesState, `${type}.name`),
    size: _.get(formFilesState, `${type}.size`),
    mimetype: _.get(formFilesState, `${type}.mimetype`),
    extension: _.get(formFilesState, `${type}.extension`),
    type: _.get(formFilesState, `${type}.type`),
    content: _.get(formFilesState, `${type}.content`),
    sourceFile: _.get(formFilesState, `${type}.sourceFile`)
  })
}
