import { UPDATE_CONTACT_FORM, RESET_CONTACT_FORM } from './actions'
import { IHotsiteContactForm } from './contracts'

export const CONTACT_FORM_INITIAL_STATE: IHotsiteContactForm = {
  form: {
    name: { value: '', isRequired: true, isInvalid: false, message: '' },
    niche: { value: '', isRequired: true, isInvalid: false, message: '' },
    companyName: { value: '', isRequired: true, isInvalid: false, message: '' },
    role: { value: '', isRequired: true, isInvalid: false, message: '' },
    email: { value: '', isRequired: true, isInvalid: false, message: '' },
    phone: { value: '', isRequired: true, isInvalid: false, message: '' },
    subject: { value: '', isRequired: false, isInvalid: false, message: '' }
  },
  whatsappContact: false,
  emailContact: false,
  phoneContact: false,
  acceptUserTerms: false,
  isFormValid: false
}

export interface IHotsiteContactFormStateAction {
  type: string
  payload: IHotsiteContactForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HotsiteContactFormReducer = (
  state: IHotsiteContactForm = CONTACT_FORM_INITIAL_STATE,
  action: IHotsiteContactFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_CONTACT_FORM:
      return {
        ...state,
        ...action.payload
      }

    case RESET_CONTACT_FORM:
      return {
        form: {
          name: { value: '', isRequired: true, isInvalid: false, message: '' },
          niche: { value: '', isRequired: true, isInvalid: false, message: '' },
          companyName: { value: '', isRequired: true, isInvalid: false, message: '' },
          role: { value: '', isRequired: true, isInvalid: false, message: '' },
          email: { value: '', isRequired: true, isInvalid: false, message: '' },
          phone: { value: '', isRequired: true, isInvalid: false, message: '' },
          subject: { value: '', isRequired: false, isInvalid: false, message: '' }
        },
        whatsappContact: false,
        emailContact: false,
        phoneContact: false,
        acceptUserTerms: false,
        isFormValid: false
      }

    default:
      return state
  }
}
