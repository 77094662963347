/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import swal from '@sweetalert/with-react'
import { PopUpContainer, PopUpContent, PopUpHeader, PopUpTitle } from './style'
import '../style.css'
import { showModal } from '..'
import { PopUpParagraph } from '../singleAction/style'
import { changeSolution, store } from 'store'
import { api, GetFormat, PostFormat } from 'sharedKernel/api'
import { showToast } from 'components/toast'

export interface EvaluationModalProps {
  title: string
  component: any
  buttons: Array<{ text: string, value?: string | null }>
  data: { challengeId: string, stepId: string, ideaId: string }
  isGetFromRedux?: boolean
}

export const evaluationModal = ({ title, component, buttons, data, isGetFromRedux }: EvaluationModalProps): void => {
  const [confirm, cancel] = buttons
  // Como o componente da biblioteca de modal é baseado em promises,
  // a requisição tem que ser feita dentro dele
  const getRequest: GetFormat = {
    url: `/v1/hub/evaluator/challenge/${data.challengeId}/step/${data.stepId}/solution/${data.ideaId}`
  }

  const postRequest: PostFormat = {
    url: `v1/hub/evaluator/challenge/${data.challengeId}/step/${data.stepId}/solution/${data.ideaId}`
  }
  api
    .get(getRequest.url)
    .then((response) => {
      if (!isGetFromRedux) {
        store.dispatch(changeSolution(response.data))
      }
    })
    .then(() => {
      swal({
        buttons: {
          confirm: {
            text: confirm.text,
            value: confirm.value,
            className: 'popup-button-confirm'
          },
          cancel: cancel.text
        },
        content: (
          <PopUpContainer>
            <PopUpHeader>
              <PopUpTitle>{title}</PopUpTitle>
            </PopUpHeader>
            <PopUpContent>{component}</PopUpContent>
          </PopUpContainer>
        )
      }).then((willDelete) => {
        if (willDelete) {
          swal({
            buttons: {
              confirm: {
                text: confirm.text,
                value: confirm.value,
                className: 'popup-button-confirm'
              },
              cancel: cancel.text
            },
            content: (
              <PopUpContainer style={{ height: '180px' }}>
                <PopUpHeader>
                  <PopUpTitle>{'Salvar avaliação'}</PopUpTitle>
                </PopUpHeader>
                <PopUpContent style={{ height: '50px' }}>
                  <PopUpParagraph>{`Deseja realmente alterar a nota de: ${title}`}</PopUpParagraph>
                </PopUpContent>
              </PopUpContainer>
            )
          }).then((confirm) => {
            if (confirm) {
              api
                .post(postRequest.url, store.getState().solutionReducer)
                .then(() => {
                  showToast('success', 'Avaliação salva com sucesso')
                })
            } else {
              showModal('evaluationModal', {
                title: title,
                component: React.cloneElement(component),
                buttons: [{ text: 'Salvar', value: 'labels.confirm.value' }, { text: 'Cancelar' }],
                data: data,
                isGetFromRedux: true
              })
            }
          })
        }
      })
    })
}
