export const CHANGE_MENU = 'theme/CHANGE_MENU'
export const VISIBLE_ROWS = 'theme/VISIBLE_ROWS'

const changeMenu = (isEvaluationMenu: boolean): any => {
  return {
    type: CHANGE_MENU,
    payload: isEvaluationMenu
  }
}

const changeVisibleRows = (visibleRows: string[]): any => {
  return {
    type: VISIBLE_ROWS,
    payload: visibleRows
  }
}

export { changeMenu, changeVisibleRows }
