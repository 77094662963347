import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ActionIcon = styled(FontAwesomeIcon)`
  cursor: pointer;

  transition: all 0.2s ease;
  :hover {
    filter:drop-shadow(1px 1px 6px ${props => props.color})
  }
`

export const DisabledActionIcon = styled(ActionIcon)`
  cursor: not-allowed;
  opacity: .7;
  :hover {
    filter: none;
  }
`
