import { DynamicFormField } from '../../contracts'
import {
  changeApplicationForm,
  IHubStartupChallengeApplicationFileStructure,
  store,
  TReducers,
  updateApplicationFormFiles,
  useSelector
} from '../../../../../../../../store'
import { getDescription, getFile, setFile } from '../../actions/dataHandler'
import React, { ChangeEvent } from 'react'
import { trans } from '../../../../../../../../sharedKernel/i18n'
import { showToast } from '../../../../../../../../components/toast'
import { InputUploadSingle } from '../../../../../../../../components/inputs/Upload/Single'
import { InputWrapper } from '../invalidInput/style'

export const ApplicationFormInputUpload = (question: DynamicFormField): JSX.Element => {
  const labels = {
    addFile: trans('Adicionar arquivo'),
    maxSize: trans('Tamanho máximo'),
    maxSizeError: trans('O arquivo deve ter um tamanho máximo de 50 MB'),
    deleteFileConfirmation: trans('Tem certeza que deseja excluir o arquivo?')
  }

  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const formFilesState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormFilesState)

  const currentFile = getFile(applicationFormState, question.id) as IHubStartupChallengeApplicationFileStructure
  const isLaunched = applicationFormState.isLaunched ?? false

  return (
    <div className="mb-3" key={question.id}>
      <InputWrapper className={`${(question.isInvalid ?? false) ? 'invalid-input' : ''}`}>
        <InputUploadSingle
          fieldName={'applicationFormFile'}
          disabled={isLaunched}
          acceptFiles={'*'}
          labels={{
            title: question.name,
            addFile: labels.addFile,
            maxSize: `${labels.maxSize}:`,
            description: getDescription(question.description, question.isRequired)
          }}
          maxSize={'50 MB'}
          isRequired={question.isRequired}
          isUploaded={currentFile?.name != null}
          name={currentFile?.name ?? ''}
          path={currentFile?.path ?? ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files ?? []

            if (files.length === 0) {
              return
            }
            const file = files[0]
            if (file.size > 52428800) {
              showToast('warning', labels.maxSizeError)
              return
            }

            const selectedFile: IHubStartupChallengeApplicationFileStructure = {
              id: null,
              path: null,
              name: file.name,
              size: file.size,
              mimetype: file.type,
              extension: null,
              content: null,
              sourceFile: file,
              sectionId: question.sectionId,
              questionId: question.id,
              type: 'uploadAnswer'
            }

            formFilesState.applicationFormFiles.push(selectedFile)
            setFile(applicationFormState, question.id, selectedFile)

            const newApplicationFormState = setFile(applicationFormState, question.id, selectedFile)
            store.dispatch(changeApplicationForm(newApplicationFormState))
            store.dispatch(updateApplicationFormFiles(formFilesState))
          }}
          onUploadedClick={() => {
            if (confirm(labels.deleteFileConfirmation)) {
              formFilesState.applicationFormFiles = formFilesState.applicationFormFiles.filter(
                (stateFile) => stateFile !== currentFile
              )

              if (currentFile.id != null) {
                formFilesState.deletedApplicationFormFiles.push(currentFile)
              }

              setFile(applicationFormState, question.id, undefined)
              store.dispatch(updateApplicationFormFiles(formFilesState))
            }
          }}
        />
      </InputWrapper>
    </div>
  )
}
