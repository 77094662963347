import React, { useEffect, useState } from 'react'

import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { IdeaContainer } from 'domain/feature/hub/startup/idea/components/IdeaContainer'
import { NoContent } from 'components/NoContent'
import { Loading } from 'components/loading'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { Filter } from 'components/Filter'
import { Col, Row } from 'react-bootstrap'
import { FilterOrderByProps, OrderBy } from 'components/OrderBy'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'
import NoContentImg from 'assets/images/defaults/no_ideas.png'

export const HubStartupIdeaPage = ({ ...props }): JSX.Element => {
  const request: GetFormat = {
    url: '/v1/hub/resolver/solution'
  }

  const orderByOptions = [
    {
      value: 'updated_at desc',
      label: trans('Mais recente')
    },
    {
      value: 'config.title',
      label: trans('Ordem alfabética')
    }
  ]

  const { data, isFetching, refetch } = useGet<any>(request, 'startup-ideas')
  const [isDelete, setIsDelete] = useState(false)
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderByOptions[0])
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  const labels = {
    noContent: trans('Você ainda não se inscreveu em nenhum desafio'),
    title: trans('Minhas Inscrições')
  }

  useEffect(() => {
    if (isDelete) {
      refetch()
      setIsDelete(false)
    }
  }, [refetch, isDelete])

  const onSearch = (searchTerm: string): void => {
    request.httpConfig = { params: { match: searchTerm } }
    setCurrentSearchTerm(searchTerm)
    refetch()
  }

  const onOrderBy = (selectedOrderBy: FilterOrderByProps): void => {
    request.httpConfig = { params: { sort: selectedOrderBy.value } }
    setSelectedOrderBy(selectedOrderBy)
    refetch()
  }

  const FilterOrderBarElement = (): JSX.Element => {
    return (
      <FilterOrderBar>
        <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />
        <OrderBy selectedValue={selectedOrderBy} onSelect={onOrderBy} options={orderByOptions} />
      </FilterOrderBar>
    )
  }

  const renderResponse = (): any => {
    if (isFetching) {
      return (
        <Loading />
      )
    }

    return (
      <>
        <PageTitle>{labels.title}</PageTitle>
        <Row>
          <FilterOrderBarElement />
        </Row>
        {
          data == null || data?.rows.length < 1
            ? <NoContent text={labels.noContent} image={NoContentImg} />
            : <IdeaContainer ideas={data?.rows ?? []} setIsDelete={setIsDelete} />
        }

      </>
    )
  }
  return <>{renderResponse()}</>
}
