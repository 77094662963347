import React from 'react'
import { FormAnswerCheckbox } from './FormAnswerCheckbox'
import { FormAnswerDate } from './FormAnswerDate'
import { FormIdeaQuestion, FormIdeaAnswer, AnswerLink } from './styles'
import { isNumber } from 'lodash'
import { FormAnswerUpload } from './FormAnswerUpload'
import { FormAnswerProps } from './contracts'

export const checkJSON = (answer: any): JSX.Element => {
  try {
    const answerJSON = JSON.parse(answer.value)
    if (isNumber(answerJSON)) {
      throw new Error()
    }
    const answersArray = Object.values(answerJSON)
    return (
    <>
      {answersArray.map((answerValue: any) => {
        return <FormIdeaAnswer>{answerValue}</FormIdeaAnswer>
      })}
    </>)
  } catch (exception) {
    if (answer.value === '' && answer.archive !== null) {
      return <FormAnswerUpload answer={answer} />
    } else {
      if (checkUrl(answer.value)) {
        return <AnswerLink href={answer.value} target="_blank"><FormIdeaAnswer>{answer.value}</FormIdeaAnswer></AnswerLink>
      } else {
        return <FormIdeaAnswer>{answer.value}</FormIdeaAnswer>
      }
    }
  }
}

const checkUrl = (answer: string): boolean => {
  try {
    const url = new URL(answer)
    return true
  } catch (err) {
    return false
  }
}

const getQuestionType = (answer: any): JSX.Element => {
  switch (answer.questionType) {
    case 'checkbox-group':
      return <FormAnswerCheckbox answer={answer} />
    case 'date':
      return <FormAnswerDate answer={answer} />
    case 'upload':
      return <FormAnswerUpload answer={answer} />
    default:
      return (
        <>
          {checkJSON(answer)}
        </>
      )
  }
}

export const FormAnswer = ({ answer }: FormAnswerProps): JSX.Element => {
  if (answer == null) return <></>
  return (
    <div className="mb-3">
      <FormIdeaQuestion>{answer.question}</FormIdeaQuestion>
      {getQuestionType(answer)}
    </div>
  )
}
