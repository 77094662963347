import React, { useState } from 'react'
import { SortIcon } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AnimatedSortButtonsProps {
  orderByAsc: boolean
  onClick: () => void
}

export const AnimatedSortButtons = ({ orderByAsc, onClick }: AnimatedSortButtonsProps): JSX.Element => {
  const [isRotating, setIsRotating] = useState(false)

  const handleClick = (): void => {
    setIsRotating(true)
    onClick()
  }

  const handleAnimationEnd = (): void => {
    setIsRotating(false)
  }

  return (
    <SortIcon
        className={isRotating ? 'rotating' : ''}
        onClick={handleClick}
        onAnimationEnd={handleAnimationEnd}
        >
        <FontAwesomeIcon
            icon={orderByAsc ? ['fas', 'arrow-up'] : ['fas', 'arrow-down'] }
            color='var(--primary)'
            bounce={false}
        />
    </SortIcon>
  )
}
