export const CHANGE_SOLUTIONS = 'CHANGE_SOLUTIONS'
export const RESET_SOLUTIONS = 'RESET_SOLUTIONS'

const changeEvaluatorSolutions = (evaluatorSolutions: string[]): any => {
  return {
    type: CHANGE_SOLUTIONS,
    payload: evaluatorSolutions
  }
}

const resetEvaluatorSolutions = (evaluatorSolutions?: string[]): any => {
  return {
    type: RESET_SOLUTIONS,
    payload: evaluatorSolutions
  }
}

export { changeEvaluatorSolutions, resetEvaluatorSolutions }
