import React from 'react'
import { LoadingWrapper } from './style'
import { CircularProgress } from 'react-cssfx-loading'

interface LoadingWrapperProps {
  children?: React.ReactNode
  width?: string
}

export const Loading = ({ children, width }: LoadingWrapperProps): JSX.Element => {
  return (
    <LoadingWrapper>
      {renderChildrenLoadingOrDefault(children, width)}
    </LoadingWrapper>
  )
}

const renderChildrenLoadingOrDefault = (children, width): JSX.Element => {
  return (
    children === undefined
      ? renderDefaultLoading(width)
      : children
  )
}

const renderDefaultLoading = (width): JSX.Element => {
  return (
    <CircularProgress color="var(--primary)" width={width} />
  )
}
