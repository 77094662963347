import { cookies, setCookie, removeCookie } from 'sharedKernel/cookies/behavior'
import { LOGOUT, SET_AUTH, SET_PERMISSIONS } from './authActions'

const AUTH_DEFAULT_USER_VALUE = JSON.parse('{}')
const AUTH_DEFAULT_IS_AUTHENTICATED_VALUE = undefined
const AUTH_DEFAULT_PERMISSIONS_VALUE = undefined

export const AUTH_INITIAL_STATE: IAuthState = {
  user: cookies.get('_cred')?.data ?? AUTH_DEFAULT_USER_VALUE,
  isAuthenticated: cookies.get('_cred') ?? AUTH_DEFAULT_IS_AUTHENTICATED_VALUE,
  permissions: cookies.get('_perm') ?? AUTH_DEFAULT_PERMISSIONS_VALUE
}

interface IAuthUser {
  id: string
  username: string
  role: string
  token: string
}

export interface IAuthAccess {
  id: string
  code: string
  action?: string
}

export interface IAuthFeature {
  id: string
  code: string
  access: IAuthAccess[]
}

export interface IAuthPermission {
  id: string
  name: string
  features: IAuthFeature[]
}

export interface IAuthAction {
  type: string
  payload: Partial<IAuthState>
}

export interface IAuthState {
  user?: IAuthUser
  permissions?: IAuthPermission
  isAuthenticated: boolean
}

export const AuthReducer = (state: IAuthState = AUTH_INITIAL_STATE, action: IAuthAction): any => {
  switch (action.type) {
    case SET_AUTH:
      setCookie('_cred', action.payload)
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true
      }

    case SET_PERMISSIONS:
      setCookie('_perm', action.payload)
      return {
        ...state,
        permissions: action.payload
      }

    case LOGOUT:
      removeCookie('_cred')
      removeCookie('_perm')
      removeCookie('_usrData')
      removeCookie('_profImg')
      return {
        user: AUTH_DEFAULT_USER_VALUE,
        isAuthenticated: AUTH_DEFAULT_IS_AUTHENTICATED_VALUE,
        permissions: AUTH_DEFAULT_PERMISSIONS_VALUE
      }
    default:
      return state
  }
}
