import { setCookie } from 'sharedKernel/cookies/behavior'
import { IProfileImage } from './contracts'

export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE'
export const UPDATE_PROFILE_IMAGE_LINK = 'UPDATE_PROFILE_IMAGE_LINK'
export const RESET_PROFILE_IMAGE = 'RESET_PROFILE_IMAGE'

const updateProfileImage = (profileImage: IProfileImage): any => {
  return {
    type: UPDATE_PROFILE_IMAGE,
    payload: profileImage
  }
}

const updateProfileImageLink = (imageLink: string): any => {
  setCookie('_profImg', imageLink)
  return {
    type: UPDATE_PROFILE_IMAGE_LINK,
    payload: imageLink
  }
}

const resetProfileImage = (profileImage?: IProfileImage): any => {
  return {
    type: RESET_PROFILE_IMAGE,
    payload: profileImage
  }
}

export { updateProfileImage, updateProfileImageLink, resetProfileImage }
