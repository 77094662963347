import { ChallengeVisualization } from 'domain/feature/hotsite/ChallengeVisualization'
import React from 'react'
import { useParams } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'

export const HotsiteChallengePage = (): JSX.Element => {
  const { challengeId } = useParams()

  const request: GetFormat = {
    url: `/v1/hotsite/challenge/${challengeId}`
  }
  const { data: challengeData, isFetching: isChallengeFetching } = useGet<any>(request, 'hotsite-challenge')

  const filesRequest: GetFormat = {
    url: `/v1/hotsite/challenge/${challengeId}/files`
  }
  const { data: fileData, isFetching: isFileFetching } = useGet<any>(filesRequest, 'hotsite-challenge-files')

  return (
    <ChallengeVisualization
      isFetching={isChallengeFetching}
      data={challengeData}
      isFileFetching={isFileFetching}
      fileData={fileData?.rows}
    />
  )
}
