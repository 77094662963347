import { Loading } from 'components/loading'
import React, { useEffect, useState } from 'react'
import { api, GetFormat } from 'sharedKernel/api'
import {
  FormIdeaHeader,
  FormIdeaInnerDiv,
  FormIdeaOuterDiv,
  FormIdeaSectionName,
  FormIdeaSections,
  FormTitle as FormIdeaResolverName
} from './styles'
import { ICarouselNavItems } from 'components/swiper/swiperNav'
import { FormAnswer } from 'components/Evaluation/Form/Answer/FormAnswer'
import { SolutionData } from './contracts'

export interface SolutionModalProps {
  solutionId: string
}

export const SolutionModal = ({ solutionId }: SolutionModalProps): JSX.Element => {
  const getRequest: GetFormat = {
    url: `/v1/hub/company/solution/${solutionId}`
  }
  const [solutionData, setSolutionData] = useState<SolutionData>()
  useEffect(() => {
    api.get(getRequest.url).then((response) => {
      setSolutionData(response.data)
    })
  }, [])

  if (solutionData == null) {
    return <Loading />
  }

  function buidSteps(sections: any[]): { formSteps: any[], carouselNavItems: ICarouselNavItems[] } {
    const carouselNavItems: ICarouselNavItems[] = []
    const formSteps: any[] = []

    sections.forEach((section, sectionIndex) => {
      carouselNavItems.push({
        name: section.name ?? '',
        itemHash: section.id
      })
      formSteps.push({
        name: section.id,
        component: (
          <div className='mt-2' key={`${sectionIndex}section`}>
            <div className='d-flex flex-column'>
              <FormIdeaSectionName>{`${String(section.name)}`}</FormIdeaSectionName>
            </div>
            {section.answers?.map((answer, answerIndex: string) => {
              return <FormAnswer key={`${answerIndex}answer`} answer={answer} />
            })}
          </div>
        )
      })
    })

    return {
      carouselNavItems,
      formSteps
    }
  }

  if (solutionData === null) {
    return <Loading />
  }

  const ideaApplicationData = solutionData
  const solution = ideaApplicationData.data.title
  const sections = ideaApplicationData.data.sections

  const { formSteps } = buidSteps(sections)

  return (
    <FormIdeaOuterDiv>
      <FormIdeaInnerDiv>
        <FormIdeaHeader>
          <FormIdeaResolverName>{solution}</FormIdeaResolverName>
        </FormIdeaHeader>
        <FormIdeaSections id='FormIdeaSections'>
          {formSteps.map((step, stepIndex) => {
            return (
              <section id={step.name} key={stepIndex}>
                {step.component}
              </section>
            )
          })}
        </FormIdeaSections>
      </FormIdeaInnerDiv>
    </FormIdeaOuterDiv>
  )
}
