import _ from 'lodash'

import { IFormFieldProperties } from 'domain/feature/hotsite/Forms/contracts'
import { setValid, setInvalid } from 'domain/feature/hotsite/Forms/validations'
import { regexPatterns } from 'sharedKernel/regex'

export const invalidMessages = {
  invalidPassword: 'A senha não atende aos requisitos mínimos',
  notEqualPasswords: 'As senhas não coincidem'
}

export const validatePassword = (password: IFormFieldProperties): boolean => {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!regexPatterns.strongPassword.test(password.value)) {
    setInvalid(password, invalidMessages.invalidPassword)
    return false
  }

  setValid(password)
  return true
}

export const validatePasswordEquality = (
  password: IFormFieldProperties,
  confirmPassword: IFormFieldProperties
): boolean => {
  if (password.value !== confirmPassword.value && !_.isEmpty(confirmPassword.value)) {
    setInvalid(confirmPassword, invalidMessages.notEqualPasswords)
    return false
  }

  setValid(password)
  setValid(confirmPassword)
  return true
}
