import styled from 'styled-components'
interface DotProps {
  bgColor: string
}

export const IdeaTile = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Sundry';
  border-radius: 0.25rem;
  background-color: var(--white);

  display: flex;
`

export const IdeaImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IdeaImage = styled.img`
  width: 100%;
  max-width: 11.5rem;
  height: 100%;
  max-height: 4.5rem;
  border-radius: 0.25rem;
`
export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Ellipsis = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Title = styled(Ellipsis)`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  text-align: left;
  margin-top: 0.5rem;
  
  @media only screen and (max-width: 768px) {
    font-size: 0.75rem;
  }
`
export const ChallengeName = styled(Ellipsis)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.172rem;
  text-align: left;
  margin: 0;
  word-break: break-all;
  color: var(--grey);

  @media only screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`
export const ProgramName = styled(Ellipsis)`
  font-size: 0.75rem;
  text-align: left;
  color: var(--grey);

  @media only screen and (max-width: 768px) {
    font-size: 0.6rem;
  }
`

export const LastContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    @media only screen and (max-width: 768px) {
      height: 24px !important;
      font-size: 12px !important;
      padding: 0px 16px !important;
    }
    @media only screen and (max-width: 576px) {
      height: 20px !important;
      font-size: 10px !important;
      padding: 0px 12px !important;
    }
  }
`

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.3rem;
  font-size: 0.75rem;

  @media only screen and (max-width: 576px) {
    font-size: 0.6rem;
  }
`

export const Dot = styled.div<DotProps>`
  height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 0.38rem;
  background-color: ${(props) => props.bgColor};
  margin-right: 0.4rem;
`
