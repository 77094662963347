import React from 'react'
import { toast, ToastContainer } from 'react-toastify'

export const showToast = (type: string, message: string): any => {
  switch (type) {
    case 'success':
      toast.success(message)
      break
    case 'warning':
      toast.warn(message)
      break
    case 'error':
      toast.error(message)
      break
    default:
      toast.info(message)
  }
}

export const Toast = ({ ...props }): JSX.Element => {
  return <ToastContainer {...props} />
}

export const useToast = (): any => {
  return toast
}
