import React from 'react'
import { ChallengeCard } from '../ChallengeCard'
import { ChallengeContainerBox, ChallengeCardSpacing } from './styles'

export const ChallengeContainer = (challenges: any): JSX.Element => {
  return (
    <ChallengeContainerBox>
      {challenges.challenges.map((challenge, index) => {
        return (
          <div key={index}>
            <ChallengeCardSpacing>
              <ChallengeCard challenge={challenge} />
            </ChallengeCardSpacing>
          </div>
        )
      })}
    </ChallengeContainerBox>
  )
}
