import Cookies from 'universal-cookie'

export const cookies = new Cookies()

const day = 60 * 60 * 24 * 1000

const localDomain = window.location.hostname

// the cookies are created to have a default duration of one day
export const setCookie = (cookieName: string, cookieData, milisecDuration = day): void => {
  cookies.set(cookieName, cookieData, {
    path: '/',
    expires: new Date(Date.now() + milisecDuration),
    // Em caso de erro de cookie, remova da linha 16 a 19
    domain: localDomain,
    httpOnly: false,
    sameSite: false,
    secure: false
  })
}

export const removeCookie = (cookieName: string): void => {
  cookies.remove(cookieName, { path: '/', domain: localDomain })
}
