import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { InnerPage } from 'main/pages/defaults/InnerPage'
import { Toast } from 'components/toast'
import { useParams } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'

export const HubStartupIdeaGet = ({ ...props }): JSX.Element => {
  /* const { challengeId } = useParams()

  const request: GetFormat = {
    url: `/hub/startup/challenges/${challengeId}`
  }

  const { data, isError, isLoading, isFetching } = useGet<any>(request)

  const renderResponse = (): JSX.Element => {
    if (isFetching) {
      return <p>Carregando...</p>
    }

    if (data === undefined) {
      return <p>Formulário não pode ser renderizado</p>
    }

    return <StartupHubChallengeApplicationForm dataFromRequest={data.data} challengeTitle={data.data.config.title} />
  }
*/
  return (
    <>
      <Toast />
      <>
        <br />
        <h4>{trans('Ideias')}</h4>
        {/* {renderResponse()} */}
      </>
    </>
  )
}
