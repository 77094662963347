import { IHubStartupChallengeApplicationFilesState } from './contracts'

export const UPDATE_CHALLENGE_APPLICATION_FILES = 'UPDATE_CHALLENGE_APPLICATION_FILES'
export const RESET_CHALLENGE_APPLICATION_FILES = 'RESET_CHALLENGE_APPLICATION_FILES'

const updateApplicationFormFiles = (applicationFormFiles: IHubStartupChallengeApplicationFilesState): any => {
  return {
    type: UPDATE_CHALLENGE_APPLICATION_FILES,
    payload: applicationFormFiles
  }
}

const resetApplicationFormFiles = (applicationFormFiles?: IHubStartupChallengeApplicationFilesState): any => {
  return {
    type: RESET_CHALLENGE_APPLICATION_FILES,
    payload: applicationFormFiles
  }
}

export { updateApplicationFormFiles, resetApplicationFormFiles }
