import { IFormFieldProperties } from '../../contracts'
import { setValid, setInvalid } from '..'
import { regexPatterns } from 'sharedKernel/regex'

export const invalidMessages = {
  phone: 'Telefone inválido'
}

const MASKED_PHONE_VALID_SIZE = 14

export const validatePhone = (phone: IFormFieldProperties): boolean => {
  const phoneLength = phone.value.length

  if (phoneLength !== MASKED_PHONE_VALID_SIZE && phoneLength !== 0) {
    setInvalid(phone, invalidMessages.phone)
    return false
  }

  setValid(phone)
  return true
}
