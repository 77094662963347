import React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { SwiperSlide } from 'swiper/react'
import { CarouselFormItemWrapper, CarouselFormItem } from '../style'
import { SwiperNav, ICarouselNavItems } from '..'
import '../navStyle.css'

export interface SwiperWrapperProps {
  carouselNavItems: ICarouselNavItems[]
  breakpoints: any
  arrowColor?: string
  offset: number
  containerId?: string
}

export const SwiperNavForm = ({
  carouselNavItems,
  breakpoints,
  offset,
  containerId
}: SwiperWrapperProps): JSX.Element => {
  const formSections = <>{carouselNavItems.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <CarouselFormItemWrapper>
          <CarouselFormItem offset={offset} activeClass="activeNavItem" containerId={containerId} spy to={`${item.itemHash}`}>
            {item.name}
          </CarouselFormItem>
        </CarouselFormItemWrapper>
      </SwiperSlide>
    )
  })}
  </>

  return (
    <SwiperNav breakpoints={breakpoints} sections={formSections} />
  )
}
