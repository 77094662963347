import styled from 'styled-components'
import DatePicker from 'react-datepicker'

interface DateInputProps {
  invalid?: boolean
}

export const DateInput = styled(DatePicker)<DateInputProps>`
    border-color: ${props => (props.invalid ?? false) ? 'var(--error)' : ''};
`
