import React, { useEffect, useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import { changeMenuEvaluator, store } from 'store'
import { GetFormat, useGet } from 'sharedKernel/api'
import { NoContent } from 'components/NoContent'
import { Loading } from 'components/loading'

import NoContentImg from 'assets/images/defaults/no_evaluations.png'
import { Container, Row } from 'react-bootstrap'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'
import { Filter } from 'components/Filter'
import { FilterOrderByProps, OrderBy } from 'components/OrderBy'
import { IdeasAccordion } from 'domain/feature/hub/evaluator/components/IdeasAccordion'

export const HubEvaluatorChallengePage = ({ ...props }): JSX.Element => {
  const labels = {
    noContent: trans('Você ainda não possui nenhuma avaliação')
  }

  const request: GetFormat = {
    url: '/v1/hub/evaluator/challenge'
  }

  const orderByOptions = [
    {
      value: 'closed_first',
      label: 'Finalizada'
    },
    {
      value: 'open_first',
      label: 'Não Finalizada'
    }
  ]

  const { data, isFetching, refetch } = useGet(request, 'evaluator-challenges-get')
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderByOptions[0])
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  useEffect(() => {
    if (data != null) {
      store.dispatch(changeMenuEvaluator(data))
    }
  }, [data, selectedOrderBy])

  const onSearch = (searchTerm: string): void => {
    request.httpConfig = { params: { match: searchTerm } }
    setCurrentSearchTerm(searchTerm)
    refetch()
  }

  const onOrderBy = (selectedOrderBy: FilterOrderByProps): void => {
    setSelectedOrderBy(selectedOrderBy)
  }

  const FilterOrderBarElement = (): JSX.Element => {
    return (
      <FilterOrderBar>
        <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />
        <OrderBy selectedValue={selectedOrderBy} onSelect={onOrderBy} options={orderByOptions} />
      </FilterOrderBar>
    )
  }

  const renderResponse = (): JSX.Element => {
    if (isFetching) {
      return (
        <Loading />
      )
    }
    if (data == null) {
      return <NoContent text={labels.noContent} image={NoContentImg} />
    }

    return (
    <>
      <Row>
        <FilterOrderBarElement />
      </Row>
      <Container className='p-0' fluid>
        <IdeasAccordion orderBy={selectedOrderBy.value}/>
      </Container>
    </>
    )
  }

  return <>{renderResponse()}</>
}
