import React from 'react'
import { DashboardCardLabelWrapper } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix, SizeProp } from '@fortawesome/fontawesome-svg-core'

export interface DashboardCardLabelProps {
  icon: [IconPrefix, IconName]
  color: string
  background: string
  size: SizeProp
}

export const DashboardCardLabel = ({ icon, color, background, size, ...props }: DashboardCardLabelProps): JSX.Element => {
  return (
    <>
      <DashboardCardLabelWrapper
        backgroundColor={background}
      >
        <div>
          <FontAwesomeIcon fixedWidth={true} icon={icon} color={color} size={size} />
        </div>
      </DashboardCardLabelWrapper>
    </>
  )
}
