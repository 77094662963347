import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './reducers'
import { NODE_ENVIRONMENT } from 'sharedKernel/constants'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers =
  (NODE_ENVIRONMENT === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ?? compose

export const store = createStore(reducers, composeEnhancers(applyMiddleware()))
export { useSelector, Provider } from 'react-redux'
export * from './reducers'
export * from 'sharedKernel/theme'
export * from 'domain/feature/hub/company/challenge/FormWrapper/store'
export * from 'domain/feature/hub/company/challenge/FormWrapper/store/validations'
export * from 'domain/feature/hub/company/challenge/FormWrapper/files/store'
export * from 'domain/feature/hub/startup/challenge/applicationForm/store'
export * from 'components/menu/side/store'
export * from 'components/menu/state/content/store'
export * from 'components/menu/state/control/store'
export * from 'domain/feature/hub/evaluator/challenge/store'
export * from 'domain/feature/hotsite/store'
export * from 'domain/feature/hotsite/Forms/Register/store'
export * from 'domain/feature/hotsite/Forms/Contact/store'
export * from 'domain/feature/hub/company/challenge/evaluators/assign/store'
