import React from 'react'
import { SwiperNavForm } from 'components/swiper/swiperNav/swiperNavForm'
import {
  FormIdeaInnerDiv,
  FormIdeaOuterDiv
} from './styles'
import {
  FormIdeaHeader,
  FormIdeaSections
} from '../styles'
import { FormTitle as FormIdeaResolverName } from 'sharedKernel/styledComponents/formComponents/styles'
import { IdeaEvaluation } from '../IdeaEvaluation'

export interface FormIdeaDesktopProps{
  solutionTitle: string
  breakpoints: any
  carouselNavItems: any
  setIsNavigationBlocked: Function
  resolverId: string
  evaluation: any
  formIdeaSectionsBody: JSX.Element
}
export const FormIdeaDesktop = ({ solutionTitle, breakpoints, carouselNavItems, setIsNavigationBlocked, resolverId, evaluation, formIdeaSectionsBody }: FormIdeaDesktopProps): JSX.Element => {
  return (
    <FormIdeaOuterDiv>
        <FormIdeaInnerDiv>
        <FormIdeaHeader>
            <FormIdeaResolverName>{solutionTitle}</FormIdeaResolverName>
            <SwiperNavForm breakpoints={breakpoints} carouselNavItems={carouselNavItems} offset={-10} containerId='FormIdeaSections' />
        </FormIdeaHeader>
        <FormIdeaSections id='FormIdeaSections'>
            {formIdeaSectionsBody}
        </FormIdeaSections>
        </FormIdeaInnerDiv>
        <IdeaEvaluation
            evaluation={evaluation}
            resolverId={resolverId}
            setIsNavigationBlocked={setIsNavigationBlocked}
        />
    </FormIdeaOuterDiv>
  )
}
