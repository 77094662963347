import styled from 'styled-components'

export const LikertScaleWrapper = styled.div`
  padding-top: 2rem;
`

export const LikertScaleAnswer = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
`

export const LikertScaleRange = styled.div` 
  width: 100%;
  input {
    width: 100%;
    height: 0.5rem;
  }
  .inactive {
    accent-color: var(--grey-light);
  }
  .active {
    accent-color: var(--primary);
  }
`
