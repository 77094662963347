import { IHubChallengeBasicFormState } from './contracts'

export const UPDATE_STEP = 'CHANGE_HUB_CHALLENGE_BASIC_FORM_STEP_CONFIG'
export const RESET_STEPS = 'RESET'
export const FULL_RESET_CHALLENGE = 'FULL_RESET_CHALLENGE'

const changeConfigSection = (configSection: IHubChallengeBasicFormState): any => {
  return {
    type: UPDATE_STEP,
    payload: configSection
  }
}

const resetChallengeSection = (configSection?: IHubChallengeBasicFormState): any => {
  return {
    type: RESET_STEPS,
    payload: configSection
  }
}

const fullResetChallenge = (configSection?: IHubChallengeBasicFormState): any => {
  return {
    type: FULL_RESET_CHALLENGE,
    payload: configSection
  }
}

export { changeConfigSection, resetChallengeSection, fullResetChallenge }
