import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { HubStartupChallengeApplicationForm } from 'domain/feature/hub/startup/challenge/applicationForm'
import { initDataToSave } from 'domain/feature/hub/startup/challenge/applicationForm/actions/init'
import { store, IBuildUIApplicationForm, resetApplicationForm } from 'store'
import { GetFormat, useGet } from 'sharedKernel/api'
import { Loading } from 'components/loading'

let initData: IBuildUIApplicationForm = {
  dataFromRequest: {
    applicationFormid: '',
    applicationFormSections: [],
    isLaunched: false,
    id: ''
  },
  challengeTitle: ''
}

export const HubStartupChallengeApplicationFormPage = ({ ...props }): JSX.Element => {
  const { challengeId } = useParams()

  const request: GetFormat = {
    url: `/v1/hub/resolver/challenge/${challengeId}`
  }

  const { data, isError, isLoading, isFetching } = useGet(request, 'startup-challenge')

  useEffect(() => {
    if (isFetching || isLoading || isError) {
      return
    }

    initData.dataFromRequest.applicationFormid = data.data.applicationForm.id
    initData.dataFromRequest.applicationFormSections = data.data.applicationForm.sections
    initData.dataFromRequest.isLaunched = data.data.isLaunched
    initData.dataFromRequest.id = data.data.id
    initData.challengeTitle = data.data.config.title

    initDataToSave(initData)
  }, [data, isFetching])

  useEffect(() => {
    return () => {
      componentUnmount()
    }
  }, [])

  const componentUnmount = (): void => {
    store.dispatch(resetApplicationForm())
    initData = {
      dataFromRequest: {
        applicationFormid: '',
        applicationFormSections: [],
        isLaunched: false,
        id: ''
      },
      challengeTitle: ''
    }
  }

  return (
    <>
      {/* eslint-disable-next-line multiline-ternary */}
      {data !== undefined ? (
        <HubStartupChallengeApplicationForm applicationForm={initData} challengeId={challengeId} />
      ) : (
        <Loading />
      )}
    </>
  )
}
