import React from 'react'
import { DashboardCardLabelProps } from './dashboardCardLabel'
import { DashboardCardSection } from './style'
import { HubCompanyDashboardChallengeCounter } from '../ChallengeCounter'
import { HubCompanyDashboardEvaluatorCounter } from '../EvaluatorCounter'
import { HubCompanyDashboardProgramCounter } from '../ProgramCounter'
import { HubCompanyDashboardResolverCounter } from '../ResolverCounter'

export interface GeneralStats {
  name: string
  count: number
  description: string
  info: string
  cardLabel: DashboardCardLabelProps
}

export const DashboardGeneralStatsList = (): JSX.Element => {
  return (
    <DashboardCardSection>
      <HubCompanyDashboardProgramCounter />
      <HubCompanyDashboardChallengeCounter />
      <HubCompanyDashboardResolverCounter />
      <HubCompanyDashboardEvaluatorCounter />
    </DashboardCardSection>
  )
}
