import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TReducers } from 'store'
import { makeGoogleFormContactRequest } from './behavior'
import { showToast } from 'components/toast'

export const ContactActions = (): JSX.Element => {
  const contactFormState = useSelector((state: TReducers) => state.hotsiteIHotsiteContactFormState)
  const navigate = useNavigate()

  const labels = {
    saveButton: trans('Solicitar contato'),
    contactSendMessage: trans('Obrigado por enviar seu contato, responderemos o mais breve possível!')
  }

  const handleSave = (): void => {
    makeGoogleFormContactRequest(contactFormState)
    contactSendSuccessfully()
  }

  const contactSendSuccessfully = (): void => {
    showToast('success', labels.contactSendMessage)
    navigate('/')
  }

  return (
    <>
      <Row className='px-2 mt-2'>
        <BaseButton onClick={() => handleSave()} disabled={!contactFormState.isFormValid} size="big" btnContent={labels.saveButton} variant="primary" />
      </Row>
    </>
  )
}
