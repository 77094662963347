import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import {
  LandingPageWrapper
  , LandingPageText
  , LandingPagePartners
  , LandingPagePartnersList
  , LandingPagePartnersItem
  , LandingPagePresentation
  , LandingPageTitle
  , LandingPageSubtitle
} from './style'

import imgHero from 'assets/images/defaults/landingpagePartner.png'
import imgLogoIebt from 'assets/images/defaults/logoIEBT.png'
import imgLogoFiemg from 'assets/images/partners/logoFiemgIel.png'
import imgLogoZing from 'assets/images/defaults/logoZingPreta.png'
import { trans } from 'sharedKernel/i18n'
import { LandingPageForm } from './LandingPageForm'

export const LandingPage = (): JSX.Element => {
  const labels = {
    text: trans('Olá, parceiro! \n' +
      'O IEBT Innovation, em parceria com o FIEMG Lab, lançou a 1ª edição do Mapa da Inovação, contendo um estudo  sobre 52 dos principais habitats de inovação do país. \n' +
      'Pensando em uma maior representação deste ecossistema, estamos preparando a 2ª edição, contemplando ainda mais habitats de inovação que contribuem para o desenvolvimento do ecossistema de inovação brasileiro. \n' +
      ' Se você quer indicar algum habitat de inovação ou quer que o qual você faz parte esteja no mapeamento, pode preencher o formulário ao lado*. Sua participação é muito importante! \n' +
      'Agradecemos por seu interesse e disposição em colaborar com o fortalecimento do ecossistema de inovação brasileiro!'),
    highlightedText: trans('* O preenchimento do formulário não garante a entrada da instituição no mapeamento, uma vez que todo o conteúdo passará por uma avaliação técnica.'),
    title: trans('2ª edição do Mapa da Inovação'),
    subtitle: trans('Hubs de Inovação, Aceleradoras e Incubadoras')
  }
  const formattedText = labels.text.split('\n')
  return (
    <LandingPageWrapper>
      <Row>
        <LandingPagePresentation>
          <Image
              fluid
              src={imgHero}
              alt="Image Zing Platform"
              width="450"
              height="390"
              loading='lazy'
          />
          <div>
            {formattedText.map((linha, index) => (
              <>
                <LandingPageText key={index}>
                  {linha}
                  <br />
                </LandingPageText>
              </>
            ))}
            <LandingPageText className='highlight'>{labels.highlightedText}</LandingPageText>
          </div>
        </LandingPagePresentation>
        <Col>
          <LandingPageTitle>{labels.title}</LandingPageTitle>
          <LandingPageSubtitle>{labels.subtitle}</LandingPageSubtitle>
          <LandingPageForm/>
        </Col>
      </Row>
      <LandingPagePartners>
        <LandingPagePartnersList>
          <LandingPagePartnersItem>
            <a href='https://iebtinovacao.com.br/' target='_blank'>
              <Image
                fluid
                src={imgLogoIebt}
                alt="Image Zing Platform"
                width="100"
                height="65"
                loading='lazy'
                />
              </a>
          </LandingPagePartnersItem>
          <LandingPagePartnersItem>
            <a href='https://www7.fiemg.com.br/iel' target='_blank'>
              <Image
                fluid
                src={imgLogoFiemg}
                alt="Image Zing Platform"
                width="100"
                height="65"
                loading='lazy'
              />
            </a>
          </LandingPagePartnersItem>
          <LandingPagePartnersItem>
           <a href='https://zing.iebtinnovation.com/' target='_blank'>
              <Image
                fluid
                src={imgLogoZing}
                alt="Image Zing Platform"
                width="100"
                height="65"
                loading='lazy'
              />
            </a>
          </LandingPagePartnersItem>
        </LandingPagePartnersList>
      </LandingPagePartners>
    </LandingPageWrapper>
  )
}
