import styled from 'styled-components'

export const HotsiteFormWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const HotsiteFormBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  background-color: var(--white);
  box-shadow: 0px 4px 10px 3px var(--shadow-smooth);

  width: 40rem;
`

export const FormTitle = styled.p`
  font-family: 'Roboto';
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--primary);
`
