import React, { useState } from 'react'
import { trans } from 'sharedKernel/i18n'
import { useParams } from 'react-router-dom'
import { IdeaApplicationForm } from 'domain/feature/hub/evaluator/idea/evaluate/IdeaApplicationForm'
import { GetFormat, useGet } from 'sharedKernel/api'
import { NoContent } from 'components/NoContent'
import { Loading } from 'components/loading'

export const HubEvaluatorIdeaEvaluatePage = ({ ...props }): JSX.Element => {
  const [isNavigationBlocked, setIsNavigationBlocked] = useState<boolean>(false)
  const { challengeId, stepId, ideaId } = useParams()
  const request: GetFormat = {
    url: `/v1/hub/evaluator/challenge/${challengeId}/step/${stepId}/solution/${ideaId}`
  }
  const { data, isFetching } = useGet(request, request.url)

  const labels = {
    noContent: trans('Houve um erro ao recuperar o desafio.')
  }

  const renderResponse = (): any => {
    if (isFetching) {
      return <Loading />
    } else if (data == null) {
      return <NoContent text={labels.noContent} />
    } else {
      return <IdeaApplicationForm setIsNavigationBlocked={setIsNavigationBlocked} ideaApplicationData={data.data} />
    }
  }
  return (
    <>
      <>{renderResponse()}</>
    </>
  )
}
