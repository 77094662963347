import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const OpenChallengesMarketplaceWrapper = styled(Container)`
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 425px) {
    height: 100%
  }
`

export const OpenChallengesMarketplaceInfoWrapper = styled(Container)``

export const OpenChallengesMarketplaceTitle = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  color: var(--grey);
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 1.5rem
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem
  }
`

export const OpenChallengesMarketplaceCards = styled.div``

export const HotsiteCardsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const HotsiteCardsColumn = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  width: 25%;
  @media screen and (max-width: 1399px) {
    width: 33.33%;
  }
  @media screen and (max-width: 992px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
