/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useCallback, useEffect } from 'react'
import { AuthPageLayout, LoginForm } from 'iebt-zing-storybook'
import { useNavigate } from 'react-router-dom'
import { trans } from 'sharedKernel/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { setAuth, setPermissions } from 'sharedKernel/auth'
import { LoginFields } from 'iebt-zing-storybook/dist/forms/LoginForm'
import { api, postProvider, PostFormat } from 'sharedKernel/api'
import { getStartRoute } from 'sharedKernel/handlers'
import { showToast } from 'components/toast'
import _ from 'lodash'
import { TReducers, store } from 'store'
import { updateProfileImageLink } from 'domain/feature/hub/user-config/PersonalConfigForm/store/profileImage'

export const IndexPage = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutate } = postProvider()
  const userAuth = useSelector((state: TReducers) => state.auth)

  useEffect(() => {
    redirectIfUserIsLogged()
  }, [])

  const redirectIfUserIsLogged = (): void => {
    if (userAuth.isAuthenticated) {
      const permissions: string[] | undefined = userAuth?.permissions?.features.map((feature) => feature.code)
      navigate(getStartRoute(permissions), { replace: true })
    }
  }

  const setUserPermissions = async (): Promise<void> => {
    const response = await api.get('/v1/system/user/my-permissions')
    if (response.status === 200) {
      dispatch(setPermissions(response?.data?.data))
      const permissions: string[] = []
      response?.data?.data.features.map((feature, index): any => {
        permissions.push(String(feature.code))
        return feature
      })
      const startRoute = getStartRoute(permissions)

      navigate(startRoute, { replace: true })
    }
  }

  const setUserProfileImage = async (userId: string): Promise<void> => {
    const noImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
    const response = await api.get(`/v1/system/user/${userId}`)
    if (response.status === 200) {
      const profileImageLink = response.data.data.profileImage ?? noImage
      store.dispatch(updateProfileImageLink(profileImageLink))
    }
  }

  const handleSubmit = useCallback((formData: LoginFields) => {
    const request: PostFormat = {
      url: '/v1/auth/login',
      data: { data: formData },
      httpConfig: { params: { isPublic: true } }
    }

    mutate(request, {
      onSuccess: (response) => {
        const data = response.data
        dispatch(setAuth(data))
        setUserProfileImage(data.id)
        setUserPermissions()
      },
      onError: (error) => {
        showToast('error', error.response.data.message)
      }
    })
  }, [])

  return (
    <AuthPageLayout>
      <LoginForm
        formTitle={trans('Acesse sua conta')}
        requireFormLabel={trans('Digite seu email e senha')}
        userFormLabel={trans('Email')}
        passwordFormLabel={trans('Senha')}
        rememberMeTextLabel={trans('Le')}
        onSubmit={handleSubmit}
      />
    </AuthPageLayout>
  )
}
