import React, { useState } from 'react'
import { ChallengeContainer } from 'domain/feature/hub/challenge/ChallengeContainer'
import { GetFormat, useGet } from 'sharedKernel/api'
import { trans } from 'sharedKernel/i18n'
import { Challenge } from 'data/contracts'
import { NoContent } from 'components/NoContent'
import { Loading } from 'components/loading'

import NoContentImg from 'assets/images/defaults/no_ideas.png'
import { PageDescription, PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { FilterOrderBar } from 'sharedKernel/styledComponents/filterComponents/style'
import { Filter } from 'components/Filter'
import { FilterOrderByProps, OrderBy } from 'components/OrderBy'

export const HubStartupChallengePage = ({ ...props }): JSX.Element => {
  const request: GetFormat = {
    url: '/v1/hub/resolver/challenge'
  }

  const orderByOptions = [
    {
      value: 'updated_at desc',
      label: trans('Mais recente')
    },
    {
      value: 'config.title',
      label: trans('Ordem alfabética')
    }
  ]

  const { data, isFetching, refetch } = useGet<Challenge[]>(request, 'startup-challenges')
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderByOptions[0])
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  const labels = {
    noContent: trans('Não há nenhum desafio aberto no momento.'),
    loading: trans('Carregando...'),
    title: trans('Oportunidades'),
    description: trans('Encontre programas corporativos e desafios abertos')
  }

  const onSearch = (searchTerm: string): void => {
    request.httpConfig = { params: { match: searchTerm } }
    setCurrentSearchTerm(searchTerm)
    refetch()
  }

  const onOrderBy = (selectedOrderBy: FilterOrderByProps): void => {
    request.httpConfig = { params: { sort: selectedOrderBy.value } }
    setSelectedOrderBy(selectedOrderBy)
    refetch()
  }

  const FilterOrderBarElement = (): JSX.Element => {
    return (
      <FilterOrderBar>
        <Filter currentSearchTerm={currentSearchTerm} btnClick={onSearch} />
        <OrderBy selectedValue={selectedOrderBy} onSelect={onOrderBy} options={orderByOptions} />
      </FilterOrderBar>
    )
  }

  const PageHeader = (): JSX.Element => {
    return (
      <>
        <PageTitle>{labels.title}</PageTitle>
        <PageDescription>{labels.description}</PageDescription>
        <FilterOrderBarElement />
      </>
    )
  }

  const renderResponse = (): JSX.Element => {
    if (isFetching) {
      return (
        <Loading />
      )
    }

    if (data == null || data?.rows.length < 1) {
      return (
        <>
          <PageHeader />
          <NoContent text={labels.noContent} image={NoContentImg} />
        </>
      )
    }

    return (
      <>
        <PageHeader />
        <ChallengeContainer challenges={data.rows} />
      </>
    )
  }

  return <>{renderResponse()}</>
}
