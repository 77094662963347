import styled from 'styled-components'
import { Form, InputGroup } from 'react-bootstrap'

export const SearchContainer = styled(InputGroup)`
`

export const SearchIcon = styled(InputGroup.Text)`
  background-color: var(--white );
  border: none
`

export const SearchInput = styled(Form.Control)`
  height: 2rem;
  width: 100%;
  background-color: var(--white );
  border: none;
  font-size: 0.875rem;
  line-height: 1rem;
`
