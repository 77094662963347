import React from 'react'
import swal from '@sweetalert/with-react'
import { PopUpContainer, PopUpContent, PopUpHeader, PopUpParagraph, PopUpTitle } from './style'
import '../style.css'

export interface DoubleActionAlertProps {
  title: string
  message: string
  buttons: Array<{ text: string, value?: string | null }>
  action?: () => void | Promise<void>
}

export const showDoubleActionPopUp = ({ title, message, buttons, action = () => { } }: DoubleActionAlertProps): void => {
  const [confirm, cancel] = buttons

  swal({
    buttons: {
      confirm: {
        text: confirm.text,
        value: confirm.value,
        className: 'popup-button-confirm'
      },
      cancel: cancel.text
    },
    content: (
      <PopUpContainer>
        <PopUpHeader>
          <PopUpTitle>{title}</PopUpTitle>
        </PopUpHeader>
        <PopUpContent>
          <PopUpParagraph>{message}</PopUpParagraph>
        </PopUpContent>
      </PopUpContainer>
    )
  }).then((value: string) => {
    value !== null && action()
  })
}
