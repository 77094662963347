import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

interface SectionTitleProps {
  sectionName: string
}

interface QuestionDivProps {
  questionName: string
}

export const PreviewModal = styled(Modal)`
  .modal-body {
    background: var(--background);
  }
`

export const ModalButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 6;
  background-color: var(--background);
  box-shadow: 0 21px var(--background), 0 -16px var(--background);
`

export const FormPreviewHeader = styled.div`
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  z-index: 5;
  background-color: var(--background);
`

export const PreviewFormWrapperHeader = styled.div`
  box-shadow: 0 5px 2px -2px var(--shadow-smooth);
`

export const SectionTitle = styled.h4<SectionTitleProps>`
  color: ${(props) => (props.sectionName === '' ? 'var(--red)' : 'none')};
`

export const QuestionDiv = styled.div<QuestionDivProps>`
  color: ${(props) => (props.questionName === '' ? 'var(--red)' : 'none')};
  option[value=""] {
    color: var(--red);
  }
`

export const InputCheckQuestionType = styled.div<QuestionDivProps>`
  .col-form-label {
    color: ${(props) => (props.questionName === '' ? 'var(--red)' : 'none')};
  }
  label[for=""] {
    color: var(--red);
  }
`
