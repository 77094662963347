import _ from 'lodash'

import { IHotsiteContactForm } from '../../store'

export const handleFormValidity = (contactFormState: IHotsiteContactForm): IHotsiteContactForm => {
  const validationArray = Object.values(contactFormState)
  const validForm = validateForm(validationArray)

  if (validForm && isAcceptTermsAndContactChannelChecked(contactFormState)) {
    contactFormState.isFormValid = true
    return contactFormState
  }

  contactFormState.isFormValid = false
  return contactFormState
}

const validateForm = (validationArray: any): boolean => {
  let validFormFlag: boolean = true

  validationArray.forEach((field: any) => {
    if (field.isRequired === false) {
      return
    }

    if (field.isInvalid === true || _.isEmpty(String(field.value))) {
      validFormFlag = false
    }
  })

  return validFormFlag
}

const isAcceptTermsAndContactChannelChecked = (contactFormState: IHotsiteContactForm): boolean => {
  if (contactFormState.acceptUserTerms && isAtLeastOneContactChannelChecked(contactFormState)) {
    return true
  }

  return false
}

const isAtLeastOneContactChannelChecked = (contactFormState: IHotsiteContactForm): boolean => {
  if (contactFormState.whatsappContact || contactFormState.emailContact || contactFormState.phoneContact) {
    return true
  }

  return false
}
