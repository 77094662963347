import { useState, useEffect } from 'react'

export interface LazyLoadProps {
  action: () => void
  itemsToLoadInfo: LazyLoadItems
}

export interface LazyLoadItems {
  itemsPerLoad: number
  loadEnd?: number
}

export const useInfiniteScroll = (action: () => void, itemsToLoadInfo: LazyLoadItems): any => {
  const [isFetching, setIsFetching] = useState(false)
  const [endLoad, setEndLoad] = useState(false)
  const [itemsLoaded, setItemsLoaded] = useState(itemsToLoadInfo.itemsPerLoad)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (checkAllChallengesAreLoaded()) {
      setIsFetching(false)
      setEndLoad(true)
    }
  }, [itemsLoaded])

  useEffect(() => {
    if (!isFetching) return

    setItemsLoaded(itemsLoaded + itemsToLoadInfo.itemsPerLoad)
    action()
  }, [isFetching])

  function handleScroll(): void {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight || isFetching || endLoad) return

    setIsFetching(true)
  }

  const checkAllChallengesAreLoaded = (): boolean => {
    if (itemsToLoadInfo.loadEnd !== undefined) {
      return itemsLoaded >= itemsToLoadInfo.loadEnd
    }

    return true
  }

  return [isFetching, setIsFetching]
}
