import React, { useCallback } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { api, GetFormat } from 'sharedKernel/api/index'
import { useMutation, UseMutationResult } from 'react-query'
import { DeleteFormat, PostFormat } from './contracts'

export const postProvider = (
  successCallback?: (success: any) => Promise<void>,
  errorCallback?: (err: any) => Promise<void>
): UseMutationResult<AxiosResponse<any, any>, any, PostFormat, unknown> => {
  return useMutation(postCall, {
    onSuccess: async (success) => {
      if (successCallback !== undefined) {
        successCallback(success)
      }
    },
    onError: async (err) => {
      if (errorCallback !== undefined) {
        errorCallback(err)
      }
    }
  })
}

export const getProvider = (
  successCallback?: (success: any) => Promise<void>,
  errorCallback?: (err: any) => Promise<void>
): UseMutationResult<AxiosResponse<any, any>, any, GetFormat, unknown> => {
  return useMutation(getCall, {
    onSuccess: async (success) => {
      if (successCallback !== undefined) {
        successCallback(success)
      }
    },
    onError: async (err) => {
      if (errorCallback !== undefined) {
        errorCallback(err)
      }
    }
  })
}

export const updateProvider = (
  successCallback?: (success: any) => Promise<void>,
  errorCallback?: (err: any) => Promise<void>
): UseMutationResult<AxiosResponse<any, any>, any, PostFormat, unknown> => {
  return useMutation(putCall, {
    onSuccess: async (success) => {
      if (successCallback !== undefined) {
        successCallback(success)
      }
    },
    onError: async (err) => {
      if (errorCallback !== undefined) {
        errorCallback(err)
      }
    }
  })
}

export const deleteProvider = (
  successCallback?: (success: any) => Promise<void>,
  errorCallback?: (err: any) => Promise<void>
): UseMutationResult<AxiosResponse<any, any>, any, DeleteFormat, unknown> => {
  return useMutation(deleteCall, {
    onSuccess: async (success) => {
      if (successCallback !== undefined) {
        successCallback(success)
      }
    },
    onError: async (err) => {
      if (errorCallback !== undefined) {
        errorCallback(err)
      }
    },
    retry: 0
  })
}

export const postCall = async (params: PostFormat): Promise<AxiosResponse<any, any>> => {
  return api.post(params?.url, params?.data, params?.httpConfig)
}

const getCall = async (params: GetFormat): Promise<AxiosResponse<any, any>> => {
  return api.get(params?.url, params?.httpConfig)
}

const putCall = async (params: PostFormat): Promise<AxiosResponse<any, any>> => {
  return api.put(params?.url, params?.data, params?.httpConfig)
}

const deleteCall = async (params: DeleteFormat): Promise<AxiosResponse<any, any>> => {
  return api.delete(params?.url, params?.httpConfig)
}
export * from './contracts'

/* Mutation simple usage
  const { mutate } = mutationProvider()

  const postFunction = () => {
    mutate(request,
      {
        onSuccess:(data)=>
        {
          console.log(data)
        },
        onError: (err) => {
          console.log(err)
        }
      }
    )
  }

  const request: PostFormat = {
    url: '/mock-post',
    data: {action:'teste', status:'working'}
  }
*/
