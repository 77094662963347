import { TReducers } from 'store'
import React from 'react'
import { useSelector } from 'react-redux'
import { RouteProps } from 'react-router'
import { Navigate, Route } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ROUTES } from 'sharedKernel/constants'
const cookies = new Cookies()

interface AuthRouteProps extends RouteProps {
  features?: string[]
}

export function AuthRoute({ features = [], ...props }: AuthRouteProps): JSX.Element {
  const isAuthenticated = useSelector((state: TReducers) => state.auth.isAuthenticated)
  let havePermission
  const userPermissions: string[] = []
  const userCookie = cookies.get('_perm')

  if (!isAuthenticated || cookies.get('_cred')?.data?.token === undefined) {
    return <Navigate to={ROUTES.USER_AUTH.LOGIN} />
  }

  if (userCookie !== undefined) {
    userCookie.features.forEach((permission) => {
      userPermissions.push(String(permission.code))
    })

    features?.every((feature) => {
      if (userPermissions.includes(feature)) {
        havePermission = true
        return false
      }
      return true
    })

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!havePermission) {
      return <Navigate to={'*'} />
    }
  }

  return <Route {...props} />
}
