import { Validation } from './contracts'

export class MinSize implements Validation {
  valid: boolean
  size: number
  errorMessage: string
  constructor(size: number) {
    this.valid = true
    this.size = size
    this.errorMessage = `Minimo de caracteres ${this.size}`
  }

  message(): string {
    return this.errorMessage
  }

  isValid(): boolean {
    return this.valid
  }

  validate(value: any): void {
    this.valid = value !== undefined && value !== null && value.toString().trim().length >= this.size
  }
}
