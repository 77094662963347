import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'

export const OpenChallengesWrapper = styled(Container)`
  height: 100vh;
  max-height: 45rem;
  background-color: var(--secondary-h);
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 425px) {
    height: 100%
  }
`

export const OpenChallengesInfoWrapper = styled.div``

export const OpenChallengesTitle = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  color: var(--grey);
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 1.5rem
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.75rem
  }
`

export const OpenChallengesSwiperWrapper = styled.div``
