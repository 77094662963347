import { setInvalid, setValid } from 'domain/feature/hotsite/Forms/validations'
import _ from 'lodash'
import { IUserConfigForm } from '../../PersonalConfigForm/store/configForm'

const labels = {
  phoneOrMobileRequired: 'Ao menos um dos campos de telefone ou celular deve ser preenchido'
}
export const handleUserConfigFormValidity = (form: IUserConfigForm): IUserConfigForm => {
  const validationArray = Object.values(form)

  if (validateForm(validationArray)) {
    form.isFormValid = true
    return form
  }

  form.isFormValid = false
  return form
}

const validateForm = (validationArray: any): boolean => {
  let isFormValid
  isFormValid = true
  validationArray.forEach((value: any) => {
    if (value.isRequired === false) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (value.isInvalid) {
      isFormValid = false
    }
  })
  return isFormValid
}

export const isPhoneOrMobileFilled = (configForm: IUserConfigForm): boolean => {
  if (configForm.phone?.value === '' && configForm.mobile?.value === '') {
    setInvalid(configForm.phone, labels.phoneOrMobileRequired)
    setInvalid(configForm.mobile, labels.phoneOrMobileRequired)

    return false
  }

  setValid(configForm.phone)
  setValid(configForm.mobile)

  return true
}
