import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { ChartDiv } from './style'

export interface PieChartProps {
  data: PieChartData[]
  colors: string[]
  dimensions: PieChartDimensions
  totalValue: number
}

export interface PieChartData {
  name: string
  value: number
}

export interface PieChartDimensions {
  width: number
  height: number
  outerRadius: number
}

export const PieChartWrapper = ({ data, colors, dimensions, ...props }: PieChartProps): JSX.Element => {
  return (
    <ChartDiv>
      <PieChart width={dimensions.width} height={dimensions.height}>
        <Pie data={data} labelLine={false} outerRadius={dimensions.outerRadius} dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ChartDiv>
  )
}
