import React from 'react'
import { Checkbox } from '@mui/material'

import { UserTermsCheckboxContent, CheckboxLabel } from './style'

export interface LabeledCheckboxProps {
  label: JSX.Element | string
  checked: boolean
  onChange: (event: any) => void
}

export const LabeledCheckbox = ({ label, checked, onChange, ...props }): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={checked}
        onChange={(event) => { onChange(event) }}
        {...props}
      />
      <UserTermsCheckboxContent>
        <CheckboxLabel>{label}</CheckboxLabel>
      </UserTermsCheckboxContent>
    </div>
  )
}
