import { cookies } from 'sharedKernel/cookies/behavior'
import { IUser } from './contracts/iUser'
import { store } from 'store'
class LoggedUserFactory {
  build(): LoggedUser {
    return new LoggedUser()
  }
}

class LoggedUser {
  get(): IUser {
    const personalInfo = cookies.get('_cred')?.data
    const profileImage = cookies.get('_profImg')
    return {
      name: personalInfo?.name,
      position: personalInfo?.role?.name,
      avatar: profileImage,
      company: {
        id: personalInfo?.company?.id,
        name: personalInfo?.company?.name,
        logo: profileImage
      }
    }
  }
}

const factory = new LoggedUserFactory()
export const loggedUser = factory.build()
