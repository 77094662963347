import { IApplicationForm, IField, ISection, IStep } from '../constants'
import React, { useEffect, useState } from 'react'
import { SectionArea } from './sectionArea'

export function ApplicationFormArea(formApplication: IApplicationForm, key: number, disabled: boolean = false): JSX.Element {
  return (
    <div key={key}>
      <SectionArea
        initialState={formApplication}
        stateKey={key}
        sectionIdentifier="applicationForm"
        updateState={(item) => { formApplication.sections = item.sections }}
        disabled={disabled}
      />
    </div>
  )
}
