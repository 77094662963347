import styled from 'styled-components'

interface BreadcrumbLinkProps {
  isCurPage: boolean
}

export const BreadcrumbContainer = styled.div`
  height: 50px;
`

export const BreadcrumbLink = styled.a<BreadcrumbLinkProps>`
  position: relative;
  font-size: 0.9rem;
  text-decoration: none;
  font-family: Roboto;
  transition: all 0.2s ease;
  cursor: ${(props) => (props.isCurPage ? 'default' : 'pointer')};
  color: ${(props) => (props.isCurPage ? 'var(--text-primary)' : 'var(--primary)')};
  
  :hover{
    color: ${(props) => (props.isCurPage ? 'var(--text-secondary)' : 'var(--secondary-c)')};
  }
`
