import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const Questions = styled(Col)`
  font-size: 14px;
  text-align: left;
`

export const QuestionsContainer = styled(Row)`
  overflow-y: auto;
  height: 370px;
`

export const SectionTitleWrapper = styled(Swiper)`
  width: 330px;

  .swiper-button-next,
  .swiper-button-prev {
    color: var(--primary);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
  }
`
export const SectionTitleHeader = styled.div`
  display: flex;
  height: 33px;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  background-color: var(--secondary-f);
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
`

export const SectionTitleText = styled.div`
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const LikertScaleAnswer = styled.div`
  padding-top: 1rem;
  display: grid;
  align-items: start;
  grid-template-columns: auto 1.5rem min-content;
  grid-template-rows: 2rem;
`

export const LikertScaleRange = styled.div`
  input {
    width: 100%;
    height: 0.5rem;
  }
  .inactive {
    accent-color: var(--grey-light);
  }
  .active {
    accent-color: var(--primary);
  }
`

export const ButtonArea = styled.div`
  button {
    border-radius: 4px;
    font-weight: 700;
    width: 80px;
    font-size: 12px;
    color: var(--white);
    background-color: var(--primary);
    border: 2px solid var(--white);
    height: 30px;
  }
`
export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #464646;
  appearance: none;
  border-radius: 0.375rem;
`

export const LabelTextArea = styled.label`
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
`
