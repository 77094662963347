import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Image } from 'react-bootstrap'
import {
  OurServicesImageWrapper,
  OurServicesImage,
  OurServicesInfoWrapper,
  OurServicesTitle,
  OurServicesText,
  OurServicesButtonsWrapper,
  OurServicesButton
} from './styles'
import { v4 as uuidv4 } from 'uuid'

import imgEcosystem from 'assets/images/defaults/hotsiteEcosystem.png'
import imgMap from 'assets/images/defaults/brazilMap.png'
import imgLogoIEBT from 'assets/images/defaults/logoIEBT.png'
import program from 'assets/images/defaults/program.jpeg'
import { ROUTES } from 'sharedKernel/constants'

interface OurServicesSectionProps {
  ourServices: OurServicesContentProps[]
}

interface OurServicesContentProps {
  id: string
  image: string
  altText: string
  imgWidth: number
  imgHeight: number
  title: string
  text: string
  buttonText?: string
  order: string
  justifyContent: string
  href?: string
  onClick?: () => void
}

export const OurServicesSection = (): JSX.Element => {
  const navigate = useNavigate()

  const ourServicesArray: OurServicesSectionProps = {
    ourServices: [
      {
        id: `${String(uuidv4())}`,
        image: imgEcosystem,
        altText: trans('Grupo de pessoas em uma sala discutindo ideias'),
        imgWidth: 300,
        imgHeight: 200,
        title: trans('Inovação Aberta'),
        text: trans('Configure, publique e divulgue seus programas e desafios de inovação aberta. Receba propostas de solucionadores, avalie startups e faça toda o processo de seleção, contando com uma metodologia proprietária e validada em mais de 7.000 startups avaliadas. Além disto, faça toda a gestão  do programa, provas de conceito (PoCs), projetos de inovação, gestão do relacionamento com o ecossistema e muito mais!'),
        buttonText: trans('Quero Demonstração'),
        order: 'flex-row',
        justifyContent: 'justify-content-center',
        onClick: () => navigate(ROUTES.HOTSITE.CONTACT, { state: { hotsite: true } })
      },
      {
        id: `${String(uuidv4())}`,
        image: program,
        altText: trans('Mapa do Brasil'),
        imgWidth: 300,
        imgHeight: 200,
        title: trans('Programas de Aceleração ou Incubação'),
        text: trans('Configure, publique e divulgue Programas de Aceleração ou Incubação, seja ele focado em Ideação/Pré-incubação ou Tração/Incubação. Receba propostas de idéias/startups e faça toda o processo de seleção, tudo isto com uma metodologia proprietária e validada em mais de 7.000 startups avaliadas. Além disto, faça toda a gestão  do programa com ferramentas visuais, acompanhamento das mentorias, aulas e workshops, controle de documentos, registros das atividades e muito mais!'),
        // buttonText: trans('Desafios Abertos'),
        order: 'flex-row-reverse',
        justifyContent: 'justify-content-center',
        onClick: () => navigate(ROUTES.HOTSITE.MARKETPLACE, { state: { hotsite: true } })
      },
      {
        id: `${String(uuidv4())}`,
        image: imgMap,
        altText: trans('Mapa do Brasil'),
        imgWidth: 300,
        imgHeight: 200,
        title: trans('Ecossistema com mais de 10.000 startups!'),
        text: trans('Faça parte do ecossistema ZING. Participe de Programas de Inovação e tenha acesso aos Desafios lançados por grandes empresas e governos.'),
        buttonText: trans('Desafios Abertos'),
        order: 'flex-row',
        justifyContent: 'justify-content-center',
        onClick: () => navigate(ROUTES.HOTSITE.MARKETPLACE, { state: { hotsite: true } })
      },
      {
        id: `${String(uuidv4())}`,
        image: imgLogoIEBT,
        altText: trans('Logo da IEBT Innovation'),
        imgWidth: 300,
        imgHeight: 200,
        title: trans('IEBT Innovation'),
        text: trans('A plataforma ZING é um produto digital desenvolvido pelo IEBT Innovation, empresa de Inovação Corporativa e Transformação Digital. O IEBT possui mais de 150 profissionais e 12 anos de experiência em materializar resultados por meio de Consultoria de inovação e Squads de desenvolvimento ágil de software.'),
        buttonText: trans('Saiba Mais'),
        order: 'flex-row-reverse',
        justifyContent: 'justify-content-center',
        href: 'https://www.iebtinnovation.com'
      }
    ]
  }

  return (
    <>
      {ourServicesArray.ourServices.map((item, index) => (
        //  As classes com prefixo "animate" são necessários para realizar as animações. Doc: https://animate.style/
        <Row key={item.id} className={`py-4 ${item.order} animate__animated animate__fadeInUp animate__delay-1s animate__slow`}>
          {/* Como está sendo utilizado o componente do react-bootstrap direto, utilizo as próprias classes que o bootstrap fornece */}
          <Col lg={5} className={`d-flex ${item.justifyContent}`}>
            <OurServicesImageWrapper>
              <OurServicesImage
                fluid
                src={item.image}
                alt={item.altText}
                width={item.imgWidth}
                height={item.imgHeight}
                loading='lazy'
              />
            </OurServicesImageWrapper>
          </Col>
          <Col lg={7}>
            <OurServicesInfoWrapper>
              <OurServicesTitle>{item.title}</OurServicesTitle>
              <OurServicesText>{item.text}</OurServicesText>
              {(item.buttonText != null) &&
                <OurServicesButtonsWrapper>
                  <OurServicesButton href={item.href} onClick={item.onClick} target={item.href !== undefined ? '_blank' : ''}>
                    {item.buttonText}
                  </OurServicesButton>
                </OurServicesButtonsWrapper>
              }
            </OurServicesInfoWrapper>
          </Col>
        </Row>
      ))}
    </>
  )
}
