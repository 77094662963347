import { DescriptedLabel } from 'iebt-zing-storybook'
import { buildValidations, handleValidation } from '../../behaviors/validation'
import React, { useEffect } from 'react'
import { IField } from '../../constants'
import { Form } from 'react-bootstrap'
import { ErrorLabel, FormItem } from '../../style'

export function BuildSelect(item: IField, itemKey): JSX.Element {
  useEffect(() => {
    if (item.value == null) {
      const itemDefaultValue = item.options?.[0]?.value ?? ''
      item.value = itemDefaultValue
      // @ts-expect-error
      item.updateFieldState(item)
    }
  }, [])
  return (
    <FormItem key={itemKey}>
      <DescriptedLabel description={item.description} boldText={item.isRequired ?? false} htmlFor={item.id}>
        {item.label}
      </DescriptedLabel>
      <Form.Select
        style={{ width: 'max-content' }}
        id={item.id}
        className={buildValidations(item.validations)}
        defaultValue={String(item.value)}
        onChange={() => {
          // eslint-disable-next-line no-restricted-globals
          const element = event?.target as HTMLInputElement
          item.value = element.value
          // @ts-expect-error
          item.updateFieldState(item)
          handleValidation(element, 'save')
        }}
        data-cy={item.dataCy}
        disabled={item.disabled}
      >
        {item.options?.map((option, optionKey) => {
          return (
            <option value={option.value} key={optionKey}>{option.label ?? option.value}</option>
          )
        })}
      </Form.Select>
      <ErrorLabel className={`errors-${item.id}`}></ErrorLabel>
    </FormItem>
  )
}
