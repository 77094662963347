import { TopMenuContent } from 'iebt-zing-storybook/dist/pages/Layout/PageTop'
import { ROUTES } from 'sharedKernel/constants'
import { getHubRoute } from 'sharedKernel/handlers'
import { IUser } from 'sharedKernel/user/contracts/iUser'

class TopMenuBuiderFactory {
  build(): TopMenuBuider {
    return new TopMenuBuider()
  }
}
export interface TopMenuActions {
  configAction?: () => void
  logoutAction: () => void
}

class TopMenuBuider {
  build(menuActionsBuild: TopMenuActions, user: IUser): TopMenuContent {
    const menuItems: TopMenuContent = {
      userSection: {
        avatar: user.avatar,
        name: user.name,
        company: user.company.name,
        logoutAction: () => {
          menuActionsBuild.logoutAction()
        }
      },
      menuItems: {
        userMenu: [
          {
            title: 'Configurações',
            icon: ['fas', 'wrench'],
            path: getHubRoute(ROUTES.HUB.USER_CONFIG)
          }
        ]
      }
    }
    return menuItems
  }
}

const factory = new TopMenuBuiderFactory()
export const topMenuBuider = factory.build()
