import styled from 'styled-components'

export const FormChallengeHeader = styled.div`
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 79px;
  z-index: 5;

  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth), 32px 0 var(--background), -32px 0 var(--background);
`
