import React from 'react'
import { HotsiteRegisterForm } from 'domain/feature/hotsite/Forms/Register'
import { Navigate, useParams } from 'react-router'
import { ROUTES, USER_TERMS_TYPE } from 'sharedKernel/constants'
import { useSelector } from 'react-redux'
import { TReducers } from 'store'
import { validate as uuidValidate } from 'uuid'

export const HotsiteEvaluatorRegisterPage = (): JSX.Element => {
  const { challengeId } = useParams()
  if (!uuidValidate(challengeId)) {
    return <Navigate to={ROUTES.NOT_FOUND} />
  }

  const preRegisterFormState = useSelector((state: TReducers) => state.hotsiteIHotsiteRegisterFormState)
  const registerFormState = { ...preRegisterFormState }
  delete registerFormState.form.startupName

  const registerUrl = `v1/system/evaluator/${challengeId}`

  return (
    <>
      <HotsiteRegisterForm registerFormStateProps={registerFormState} registerUrl={registerUrl} userType={USER_TERMS_TYPE.EVALUATOR} />
    </>
  )
}
