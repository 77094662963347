import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { GetFormat, PostFormat, updateProvider, useGet } from 'sharedKernel/api'
import { Loading } from 'components/loading'
import { PageTitle } from 'domain/feature/hub/pageTitle/styles'
import { FormChallengeHeader } from 'sharedKernel/styledComponents/stickHeader/style'
import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'
import { trans } from 'sharedKernel/i18n'
import { Filter } from 'components/Filter'
import { HubEvaluatorInvitesTable } from './table'
import { NoContent } from 'components/NoContent'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { IInvite } from './contracts'
import { EVALUATOR_INVITE_STATUS } from 'sharedKernel/constants'
import { getInviteStatusLabel } from './behavior'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { showToast } from 'components/toast'
import { hasTextMatch } from 'sharedKernel/handlers/text'

export const HubEvaluatorInvites = (): JSX.Element => {
  const [activeSection, setActiveSection] = useState(0)

  const [searchTerm, setSearchTerm] = useState('')

  const labelsPlural = true
  const labels = {
    noContent: trans('Não há nenhum convite no momento.'),
    pageTitle: trans('Meus convites'),
    all: trans('Todos'),
    invited: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.INVITED, labelsPlural),
    accepted: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.ACCEPTED, labelsPlural),
    refused: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.REFUSED, labelsPlural),
    canceled: getInviteStatusLabel(EVALUATOR_INVITE_STATUS.CANCELED, labelsPlural),
    noTurningBack: trans('Essa ação não poderá ser desfeita!'),
    acceptConfirmationModal: trans('Tem certeza que deseja aceitar esse convite?'),
    acceptConfirmationModalTitle: trans('Aceitar convite'),
    rejectConfirmationModal: trans('Tem certeza que deseja recusar esse convite?'),
    rejectConfirmationModalTitle: trans('Recusar convite'),
    successApprove: trans('Convite aceito com sucesso!'),
    successArchive: trans('Convite recusado com sucesso!'),
    errorApprove: trans('Houve um erro ao aceitar o convite!'),
    errorArchive: trans('Houve um erro ao recusar o convite!'),
    yes: trans('Sim'),
    no: trans('Não')
  }

  const { mutate: acceptInvite } = updateProvider()
  const { mutate: rejectdInvite } = updateProvider()

  const request: GetFormat = {
    url: '/v1/hub/evaluator/invite'
  }

  const {
    data: invitesData,
    isFetching: isInvitesFetching,
    refetch: invitesRefetch
  } = useGet<any>(request, 'invites-get')

  const handleApprovedInvite = (challengeId: string): void => {
    showDoubleActionPopUp({
      title: labels.acceptConfirmationModalTitle,
      message: labels.acceptConfirmationModal,
      buttons: [{ text: labels.yes }, { text: labels.no }],
      action: () => executeApprovedInvitePost(challengeId)
    })
  }

  const executeApprovedInvitePost = (challengeId: string): void => {
    const acceptInvitePost: PostFormat = {
      url: `/v1/hub/evaluator/challenge/${challengeId}/invite/accept`
    }
    acceptInvite(acceptInvitePost, {
      onSuccess: () => {
        showToast('success', labels.successApprove)
        invitesRefetch()
      },
      onError: () => {
        showToast('error', labels.errorApprove)
      }
    })
  }

  const handleRejectdInvite = (challengeId: string): void => {
    showDoubleActionPopUp({
      title: labels.rejectConfirmationModalTitle,
      message: labels.rejectConfirmationModal,
      buttons: [{ text: labels.yes }, { text: labels.no }],
      action: () => executeRejectdInvitePost(challengeId)
    })
  }

  const executeRejectdInvitePost = (challengeId: string): void => {
    const rejectdInvitePost: PostFormat = {
      url: `/v1/hub/evaluator/challenge/${challengeId}/invite/reject`
    }
    rejectdInvite(rejectdInvitePost, {
      onSuccess: () => {
        showToast('success', labels.successArchive)
        invitesRefetch()
      },
      onError: () => {
        showToast('error', labels.errorArchive)
      }
    })
  }

  const buildBreakpoints = (): any => {
    return {
      250: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      350: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      550: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      650: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    }
  }

  const allItemHash = 'all'
  const buildNavItems = (): any => {
    return [
      {
        name: labels.all,
        itemHash: allItemHash
      },
      {
        name: labels.invited,
        itemHash: EVALUATOR_INVITE_STATUS.INVITED
      },
      {
        name: labels.accepted,
        itemHash: EVALUATOR_INVITE_STATUS.ACCEPTED
      },
      {
        name: labels.refused,
        itemHash: EVALUATOR_INVITE_STATUS.REFUSED
      },
      {
        name: labels.canceled,
        itemHash: EVALUATOR_INVITE_STATUS.CANCELED
      }
    ]
  }

  const getFiltereInvites = (data: IInvite[]): IInvite[] => {
    return data.filter(invite =>
      hasTextMatch(labels[invite.status], searchTerm) ||
      hasTextMatch(invite.challenge.config.title, searchTerm) ||
      hasTextMatch(invite.challenge.owner.name, searchTerm))
  }

  const getTableData = (): IInvite[] => {
    const navItems = buildNavItems()
    const activeSectionHash = navItems[activeSection].itemHash
    const allInvites = invitesData?.rows
    if (activeSectionHash === allItemHash) {
      return getFiltereInvites(allInvites)
    }
    const activeSectionInvites = allInvites.filter(invite => invite.status === activeSectionHash)
    return getFiltereInvites(activeSectionInvites)
  }

  const getInvitesContent = (): JSX.Element => {
    if (invitesData == null || invitesData?.rows?.length === 0) {
      return <NoContent text={labels.noContent} image={NoContentImg} />
    }
    return <HubEvaluatorInvitesTable
      invites={getTableData()}
      acceptInvite={handleApprovedInvite}
      rejectInvite={handleRejectdInvite}
    />
  }

  return (
    <>
      <div className='mb-6'>
        <PageTitle>{labels.pageTitle}</PageTitle>
        <FormChallengeHeader>
          <SwiperNavMenu activeSection={activeSection} setActiveSection={setActiveSection} breakpoints={buildBreakpoints()} carouselNavItems={buildNavItems()} />
        </FormChallengeHeader>
      </div>
      <Row className='mb-4'>
        <Col className='mb-2' lg={6}>
          <Filter
            currentSearchTerm={searchTerm}
            btnClick={(newSearchTerm) => setSearchTerm(newSearchTerm)}
          />
        </Col>
      </Row>
      {isInvitesFetching
        ? <Loading />
        : getInvitesContent()
      }
    </>
  )
}
