import { DynamicFormField } from '../../contracts'
import { changeApplicationForm, store, TReducers, useSelector } from '../../../../../../../../store'
import { getDescription, getValue, setValue } from '../../actions/dataHandler'
import { DescriptedLabel } from 'iebt-zing-storybook'
import { DateField } from '../../../../../../../../components/inputs/date'
import { DateTime } from 'luxon'
import React from 'react'
import { FormIdeaAnswer, FormLaunchedFieldWrapper } from '../../style'
import { getUnnabreviatedDate } from 'sharedKernel/handlers'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

export const ApplicationFormInputDate = (question: DynamicFormField): JSX.Element => {
  const applicationFormState = useSelector((state: TReducers) => state.startupHubChallengeApplicationFormState)
  const isLaunched = applicationFormState.isLaunched ?? false
  const questionDescription = getDescription(question.description, question.isRequired)
  const dateString = getValue(applicationFormState, question.id)
  const selectedDate = dateString === '' || dateString == null ? null : new Date(`${String(dateString)}T00:00`)

  const renderLaunchedDateAnswer = (): JSX.Element => {
    if (dateString === undefined) {
      return (
        <></>
      )
    }

    const screenFormattedDate = getUnnabreviatedDate(String(dateString))

    return (
      <FormLaunchedFieldWrapper>
        <FormIdeaAnswer>{screenFormattedDate}</FormIdeaAnswer>
      </FormLaunchedFieldWrapper>
    )
  }

  return (
    <div className="mb-3" key={question.id}>
      <DescriptedLabel boldText={question.isRequired}>
        <span>
          {question.name}
          <Tippy
            content={getDescription(question.description, question.isRequired)}
            followCursor={true}
            plugins={[followCursor]}
            animation="scale"
            arrow={false}
            theme="primary"
          >
            <span>
              {question.isRequired && <span style={{ color: 'red' }}> *</span>}
            </span>
          </Tippy>
        </span>
      </DescriptedLabel>
      {
        isLaunched
          ? renderLaunchedDateAnswer()
          : <DateField
            name={question.name}
            questionPlaceholder={question.description}
            disabled={isLaunched}
            selectedDate={selectedDate}
            isInvalid={question.isInvalid}
            isRequired={question.isRequired}
            notValidFieldMessage={question.notValidFieldMessage}
            onChange={(date) => {
              let pickedDate
              if (date != null) {
                pickedDate = DateTime.fromJSDate(date).toISODate()
              }

              const newApplicationFormState = setValue(applicationFormState, question.id, pickedDate, question.isRequired)
              store.dispatch(changeApplicationForm(newApplicationFormState))
            }}
          />
      }

    </div>
  )
}
